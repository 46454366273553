import {
  Select as ChakraSelect,
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
  SelectProps,
} from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface Props extends SelectProps {
  containerProps?: FormControlProps
  fullWidth?: boolean
  label?: string
  labelProps?: FormLabelProps
}

const Select = forwardRef<HTMLSelectElement, Props>(
  ({ containerProps, fullWidth, label, labelProps, ...rest }, ref) => {
    return (
      <FormControl width={fullWidth ? undefined : '250px'} {...(containerProps as any)}>
        {label && <FormLabel {...(labelProps as any)}>{label}</FormLabel>}
        <ChakraSelect ref={ref} {...(rest as any)} />
      </FormControl>
    )
  }
)

export default Select
