import { Circle, Flex, Text } from '@chakra-ui/react'

interface BadgeProps {
  iconVisible?: boolean
  text: string
  color: string
}

export default function EventLiveBadge(props: BadgeProps) {
  return (
    <Flex
      bg={props.color}
      minW={75}
      maxW={80}
      h="37px"
      borderRadius="200px"
      boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.05)"
      backdropFilter="blur(27px)"
      align="center"
      justify="center"
      gap={1}
      p={3}
    >
      <Flex>
        {props.iconVisible && (
          <Circle size="15px" bg="rgba(255, 255, 255, 0.27)">
            <Circle size="5px" bg="white"></Circle>
          </Circle>
        )}
      </Flex>
      <Text textStyle="bodySmall" color="white">
        {props.text}
      </Text>
    </Flex>
  )
}
