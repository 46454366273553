import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Text } from '@chakra-ui/react'
import { ChevronLeftIcon, Panel } from 'components'
import { Link } from 'react-router-dom'
import { getPageInfo, isContentPage, useCurrentPage } from '../utils/pageUtils'

export default function BreadCrumb() {
  const page = useCurrentPage()

  if (isContentPage(page?.path)) return null

  const rootPageInfo = getPageInfo(page?.rootPath)
  const parentPageInfo = getPageInfo(page?.parentPath)
  const pageInfo = getPageInfo(page?.path)

  return (
    <Panel px={3} height="40px" direction="row" alignItems="center">
      <Breadcrumb
        separator={
          <Text fontStyle="bodySmall" opacity={0.3}>
            /
          </Text>
        }
      >
        {rootPageInfo && (
          <BreadcrumbItem>
            <BreadcrumbLink textStyle="bodySmall" as={Link} to={rootPageInfo?.path}>
              <Flex gap={1} align="center">
                <ChevronLeftIcon fontSize="md" mx={1} />
                <Text>{rootPageInfo?.title}</Text>
              </Flex>
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}

        <BreadcrumbItem>
          <BreadcrumbLink textStyle="bodySmall" as={Link} to={parentPageInfo?.path}>
            <Flex gap={1} align="center">
              {!rootPageInfo && <ChevronLeftIcon fontSize="md" mx={1} />}
              <Text>{rootPageInfo ? parentPageInfo?.identifier : parentPageInfo?.title}</Text>
            </Flex>
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink textStyle="bodySmall">{pageInfo?.identifier}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Panel>
  )
}
