import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'

declare global {
  interface Window {
    Intercom?: (command: string, ...args: any[]) => void
  }
}

export function useIntercomUtils(hide: boolean) {
  const { update } = useIntercom()

  useEffect(() => {
    update({ hideDefaultLauncher: hide })
  }, [hide])
}

export function sendIntercomMessage(message: string) {
  if (typeof window !== 'undefined' && window.Intercom) {
    window.Intercom('showNewMessage', message)
  } else {
    console.warn('Intercom is not available')
  }
}
