import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBnkbjv_dvpv2DTTva9pnj8QSuCkLTllSY',
  authDomain: 'entevate.firebaseapp.com',
  projectId: 'entevate',
  storageBucket: 'entevate.appspot.com',
  messagingSenderId: '888086230457',
  appId: '1:888086230457:web:547d8f2383f4cc97a77268',
  measurementId: 'G-H4SVQ2F7NB',
}

export default initializeApp(firebaseConfig)
