import { Flex, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import global from 'utils/globals'
import { RoutePath } from '../utils/routing'

export default function WorkspacePage() {
  const navigate = useNavigate()
  const { workspaceId } = useParams()

  useEffect(() => {
    goToEventsPage()
  })

  function goToEventsPage() {
    navigate(generatePath(RoutePath.Events, { workspaceId: workspaceId! }))
  }

  return (
    <Flex mt={global.TOP_BAR_HEIGHT}>
      <Text>Workspace page coming soon</Text>
    </Flex>
  )
}
