import { Flex, Image, Text, TextProps } from '@chakra-ui/react'

interface Props {
  title: string
  description?: string
  iconSVG?: string
  button?: JSX.Element
  textProps?: TextProps
}

export default function EmptyState({ title, description, iconSVG, button, textProps }: Props) {
  return (
    <Flex align="center" direction="column" gap={3} mx={8}>
      <Image src={iconSVG} />
      <Text textStyle="h4" textAlign="center" {...textProps}>
        {title}
      </Text>
      {description && (
        <Text
          textStyle="bodyMedium"
          opacity={0.6}
          textAlign="center"
          fontWeight="normal"
          {...textProps}
        >
          {description}
        </Text>
      )}
      {button}
    </Flex>
  )
}
