import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react'

interface RadioCardsProps<T> {
  options: RadioCardProps[]
  value: T
  onChange: (value: T) => void
}

export default function RadioCards<T extends string>({
  options,
  value,
  onChange,
}: RadioCardsProps<T>) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'radioGroup',
    value,
    onChange: onChange,
  })

  const group = getRootProps()

  return (
    <SimpleGrid columns={{ base: 2, sm: 4 }} gap={3} {...group}>
      {options.map(({ label, value, icon }) => {
        const radio = getRadioProps({ value })
        return <RadioCard key={value} label={label} icon={icon} {...radio} />
      })}
    </SimpleGrid>
  )
}

interface RadioCardProps extends UseRadioProps {
  label: string
  icon?: JSX.Element
}

export function RadioCard({ label, icon, ...props }: RadioCardProps) {
  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as="label" display="flex" justifyContent="center" alignItems="center">
      <input {...input} />
      <Flex
        cursor="pointer"
        direction="column"
        width="95px"
        height="95px"
        border="1px solid rgba(23, 23, 23, 0.20)"
        borderRadius="10px"
        backdropFilter="blur(27px)"
        align="center"
        justify="center"
        gap={3}
        p={3}
        _checked={{
          bg: 'rgba(60, 75, 211, 0.10)',
          border: '2px solid var(--Purple, #3C4BD3)',
        }}
        {...checkbox}
      >
        {icon}
        <Text textStyle="bodySmall" textAlign="center">
          {label}
        </Text>
      </Flex>
    </Box>
  )
}
