import {
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ReactNode, createContext, useCallback, useContext, useRef, useState } from 'react'
import Button from '../Button'

enum AlertType {
  Alert,
  Confirm,
  Prompt,
}

export type AlertProviderProps = {
  alert: (message: string | ReactNode, opts?: AlertOpenerProps) => Promise<boolean | null>
  confirm: (message: string | ReactNode, opts?: AlertOpenerProps) => Promise<boolean | null>
  prompt: (
    message: string | ReactNode,
    opts?: AlertOpenerProps & {
      defaultValue?: string
    }
  ) => Promise<string | null>
}

export type AlertOpenerProps = {
  okText?: string
  cancelText?: string
  icon?: ReactNode
  modalProps?: Partial<React.ComponentProps<typeof Modal>>
  okButtonProps?: Partial<React.ComponentProps<typeof Button>>
  cancelButtonProps?: Partial<React.ComponentProps<typeof Button>>
}

const defaultContext: AlertProviderProps = {
  alert() {
    throw new Error('<AlertProvider> is missing')
  },
  confirm() {
    throw new Error('<AlertProvider> is missing')
  },
  prompt() {
    throw new Error('<AlertProvider> is missing')
  },
}

export const AlertContext = createContext<AlertProviderProps>(defaultContext)

interface AnyEvent {
  preventDefault(): void
}

export const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [alert, setAlert] = useState<ReactNode | null>(null)
  const input = useRef<HTMLInputElement>(null)
  const ok = useRef<HTMLButtonElement>(null)

  const createOpener = useCallback(
    (type: AlertType) =>
      (message: string, opts: AlertOpenerProps & { defaultValue?: string } = {}) =>
        new Promise<boolean | string | undefined>(resolve => {
          const handleClose = (e?: AnyEvent) => {
            e?.preventDefault()
            setAlert(null)
            resolve(undefined)
          }

          const handleCancel = (e?: AnyEvent) => {
            e?.preventDefault()
            setAlert(null)
            if (type === AlertType.Prompt) {
              resolve(undefined)
            } else {
              resolve(false)
            }
          }

          const handleOK = (e?: AnyEvent) => {
            e?.preventDefault()
            setAlert(null)
            if (type === AlertType.Prompt) {
              resolve(input.current?.value)
            } else {
              resolve(true)
            }
          }

          setAlert(
            <Modal
              isOpen={true}
              isCentered
              onClose={handleClose}
              initialFocusRef={type === AlertType.Prompt ? input : ok}
              {...opts.modalProps}
            >
              <ModalOverlay />
              <ModalContent maxW="sm">
                <ModalBody mt={5}>
                  <Flex gap={4} alignItems="center">
                    {opts.icon}
                    <Stack spacing={5}>
                      <Text>{message}</Text>
                      {type === AlertType.Prompt && (
                        <Input ref={input} defaultValue={opts.defaultValue} />
                      )}
                    </Stack>
                  </Flex>
                </ModalBody>
                <ModalFooter>
                  {type !== AlertType.Alert && (
                    <Button
                      mr={3}
                      variant="ghost"
                      onClick={handleCancel}
                      {...(opts.cancelButtonProps as any)}
                    >
                      {opts.cancelText ?? 'Cancel'}
                    </Button>
                  )}
                  <Button
                    palette="primary"
                    onClick={handleOK}
                    ref={ok}
                    {...(opts.okButtonProps as any)}
                  >
                    {opts.okText ?? 'OK'}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )
        }),
    [children]
  )

  return (
    <AlertContext.Provider
      value={
        {
          alert: createOpener(AlertType.Alert),
          confirm: createOpener(AlertType.Confirm),
          prompt: createOpener(AlertType.Prompt),
        } as AlertProviderProps
      }
    >
      {children}
      {alert}
    </AlertContext.Provider>
  )
}

export const useAlert = () => useContext(AlertContext)
