import { Flex, FlexProps, Image, ImageProps, Text, useDisclosure } from '@chakra-ui/react'
import {
  Button,
  InputContainer,
  InputContainerProps,
  ManageMediaDialog,
  MediaDialogProps,
  PencilSquareIcon,
} from 'components'
import { getImagePath, useStorageUrl } from 'utils'

export interface Props
  extends Partial<Omit<InputContainerProps, 'onSelect'>>,
    Pick<MediaDialogProps, DialogMediaProps> {
  id?: string
  dialogProps?: Omit<MediaDialogProps, DialogMediaProps | 'isOpen'>
  imageProps?: ImageProps
  imageContainerProps?: Omit<FlexProps, 'children'>
  /** Add clear button */
  onClear?: () => void
  /** Add reset button */
  onReset?: () => void
  onSelect?: (id: string) => void
}

type DialogMediaProps = 'defaultImage' | 'type'

export default function SingleUseMediaInput({
  id,
  defaultImage,
  dialogProps,
  imageProps,
  imageContainerProps,
  onClear,
  onReset,
  onSelect,
  type,
  ...rest
}: Props) {
  const { url } = useStorageUrl(id ? getImagePath(id) : undefined)
  const disclosure = useDisclosure()

  function handleSelect(id: string) {
    onSelect!(id)
    disclosure.onClose()
  }

  const coverSrc = id ? url : defaultImage

  return (
    <InputContainer
      label="Image"
      gap={1}
      labelActions={
        <Flex>
          {onReset && (
            <Button size="sm" variant="ghost" onClick={onReset}>
              Reset
            </Button>
          )}
          {onClear && id && (
            <Button size="sm" variant="ghost" onClick={onClear}>
              Clear
            </Button>
          )}
          <Button
            size="sm"
            palette="primary"
            variant="ghost"
            leftIcon={<PencilSquareIcon type="outline" />}
            onClick={() => disclosure.onOpen()}
          >
            Edit
          </Button>
        </Flex>
      }
      height="220px"
      {...rest}
    >
      <Flex
        align="center"
        border="1px"
        borderColor="black"
        borderRadius="lg"
        cursor="pointer"
        direction="column"
        flex={1}
        gap={1}
        justify="center"
        onClick={() => disclosure.onOpen()}
        overflow="hidden"
        {...imageContainerProps}
      >
        {coverSrc ? (
          <Image flex={1} objectFit="cover" src={coverSrc} {...imageProps} />
        ) : (
          <Text>No media selected</Text>
        )}
      </Flex>

      <ManageMediaDialog
        onSelect={onSelect ? handleSelect : undefined}
        type={type}
        {...disclosure}
        dialogProps={dialogProps}
      />
    </InputContainer>
  )
}
