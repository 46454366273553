import { Flex } from '@chakra-ui/react'
import {
  AdjustmentsHorizontalIcon,
  BadgeIndicator,
  InputContainer,
  Popover,
  Switch,
  ToggleButton,
} from 'components'
import { AuthContext } from 'components/providers'
import { startCase } from 'lodash'
import { useContext } from 'react'
import { EntevatePermission } from 'types'

export default function EntevateAdminControl() {
  const {
    developmentFeatures,
    user,
    entevatePermission,
    updateDevelopmentFeatures,
    updateEntevatePermission,
  } = useContext(AuthContext)
  const palette = 'primary'

  if (!user?.isAdmin) return null

  return (
    <Popover
      closeOnBlur
      autoFocus
      header="Entevate Admin Control"
      contentProps={{ p: 2 }}
      triggerNode={
        <BadgeIndicator value={entevatePermission !== 'entevate' || developmentFeatures}>
          <AdjustmentsHorizontalIcon cursor="pointer" type="outline" />
        </BadgeIndicator>
      }
    >
      <Flex direction="column" gap={4}>
        <Switch
          colorScheme={palette}
          label="Development Features"
          isChecked={developmentFeatures}
          onChange={() => updateDevelopmentFeatures(!developmentFeatures)}
        />
        <InputContainer label="Permission">
          <ToggleButton
            border="1px"
            borderColor="gray.300"
            borderRadius="sm"
            p={1}
            options={permissions.map(permission => ({
              value: permission,
              label: startCase(permission),
            }))}
            value={entevatePermission}
            onChange={updateEntevatePermission}
          />
        </InputContainer>
      </Flex>
    </Popover>
  )
}

const permissions: EntevatePermission[] = ['entevate', 'admin', 'editor', 'viewer']
