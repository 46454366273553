import { DBProps } from './sharedTypes'

export type IUserSession = ExplorerUserSession | CustomUserSession

export interface ExplorerUserSession extends CommonUserSessionProps {
  app: 'explorer'
  traitSelections?: TraitSelection[]
}

export interface TraitSelection {
  groupTitle: string
  id?: string
  title: string
}

export interface CustomUserSession extends CommonUserSessionProps {
  app: 'custom'
  title: string
}

interface CommonUserSessionProps extends DBProps {
  deviceName?: string
  eventId: string
  endMethod?: EndSessionMethod
  lead?: SessionLead
  // leadSessionId?: string  TODO - use this if lead exists in another session or can remove lead from session into separate collection
  /** track interactions (including durations) of step sequences or actions */
  metrics?: SessionMetric[]
  /** primary application steps used to determine total session */
  steps?: SessionStep[]
  workspaceId: string
}

export interface SessionStep {
  title: string
  duration: number
}

export interface SessionMetric {
  title: string
  /** groups of similar metrics (i.e. dialogs opened, hotspots clicked, etc.) */
  items: { title: string; duration?: number }[]
}

export type EndSessionMethod =
  | 'early-button' // user left early
  | 'final-button' // user completed experience and ended
  | 'settings' // show worker ended session
  | 'timeout-button' // session timed out and user clicked button to end
  | 'timeout-auto' // session timed out and ended automatically

export interface SessionLead extends PrimaryLeadProperties, HelperLeadProperties {
  _custom?: Record<string, string>
}

export interface PrimaryLeadProperties {
  company?: string
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  title?: string
}

export type PrimaryLeadProperty = keyof PrimaryLeadProperties

export const primaryLeadProperties: PrimaryLeadProperty[] = [
  'company',
  'email',
  'firstName',
  'lastName',
  'phone',
  'title',
]

export interface HelperLeadProperties {
  /** useful for events with registration (i.e. qr code) */
  badge?: string
  followUp?: boolean
  followUpOwner?: LeadFollowUpOwner
  notes?: string
  optIn?: boolean
  apiDataNeeded?: boolean
  temp?: LeadTemperature
}

export interface LeadFollowUpOwner {
  type: 'user' | 'group'
  id?: string
  label: string
}

export type LeadTemperature = 'hot' | 'warm' | 'cold' | 'unset'

export type HelperLeadProperty = keyof HelperLeadProperties

export const helperLeadProperties: HelperLeadProperty[] = [
  'badge',
  'followUp',
  'followUpOwner',
  'notes',
  'optIn',
  'apiDataNeeded',
  'temp',
]
