import { Button, ButtonProps, IconButton, PlusIcon } from 'components'
import { AuthContext } from 'components/providers'
import { useContext } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { createDoc, usePermission, useWorkspace } from 'utils'
import { makeEmptyContent } from '../utils/contentUtils'
import { makeEmptyEvent } from '../utils/eventUtils'
import { makeEmptyFacility } from '../utils/facilityUtils'
import { getPageInfo, useCurrentPage } from '../utils/pageUtils'
import { RouteName, RoutePath } from '../utils/routing'

interface Props extends Omit<ButtonProps, 'variant'> {
  variant: 'icon' | 'button'
  onCreate?: () => void
}

export default function CreateNewButton({ variant, onCreate }: Props) {
  const navigate = useNavigate()
  const currentPage = useCurrentPage()
  const { workspace } = useWorkspace()
  const { user } = useContext(AuthContext)
  const permission = usePermission()

  const { path, isChild } = currentPage || {}
  const pageInfo = getPageInfo(path)

  async function createNew() {
    if (!workspace || !user) return

    const workspaceId = workspace.id

    switch (path) {
      case RouteName.Events: {
        const event = await createDoc(user!.id, 'events', makeEmptyEvent(workspaceId!, user.id))
        navigate(generatePath(RoutePath.EventInfo, { workspaceId: workspaceId!, id: event.id }))
        break
      }
      case RouteName.Text: {
        const content = await createDoc(user!.id, 'content', makeEmptyContent(workspaceId!))
        navigate(generatePath(RoutePath.TextInfo, { workspaceId: workspaceId!, id: content.id }))
        break
      }
      case RouteName.Facilities:
        const facility = await createDoc(
          user!.id,
          'facilities',
          makeEmptyFacility(workspaceId, user.id)
        )
        navigate(generatePath(RoutePath.FacilityInfo, { workspaceId, id: facility.id }))
        break
    }

    onCreate?.()
  }

  if (isChild) return null
  if (!path || path === RouteName.Manage || path === RouteName.Profile) return null
  if (path === RouteName.Events && permission.event === 'viewer') return null
  if (path === RouteName.Facilities && permission.facility === 'viewer') return null
  const isContent =
    path === RouteName.Content || path === RouteName.Text || path === RouteName.Media
  if (isContent && permission.content === 'viewer') return null

  const commonProps: ButtonProps = {
    onClick: createNew,
    palette: 'primary',
  }

  if (variant === 'button')
    return (
      <Button {...commonProps} leftIcon={<PlusIcon />} iconSpacing={'6px'}>
        Create {pageInfo?.identifier}
      </Button>
    )

  return <IconButton {...commonProps} icon={<PlusIcon />} aria-label="create new" />
}
