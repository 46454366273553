import {
  Avatar,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import { startCase } from 'lodash'
import { useContext } from 'react'
import { PermissionValue, UserWithId } from 'types'
import Button from '../Button'
import { LightModeBox } from '../ColorModeBoxes'
import DeleteConfirmButton from '../DeleteConfirmButton'
import { ChevronDownIcon, Cog6ToothIcon, LockClosedIcon, ShareIcon, TrashIcon } from '../icons'
import Menu from '../Menu'
import { AuthContext } from '../providers'
import PermissionWithHelp, { permissionOptions } from './PermissionWithHelp'

interface Props {
  copyInviteLink: () => void
  member?: UserWithId
  onDone?: () => void
  onRemoveMember: () => void
  openDrawer?: boolean
  updatePermissions: (permission: PermissionValue) => void
}

export function WorkspaceUserDrawer({
  copyInviteLink,
  member,
  onDone,
  onRemoveMember,
  openDrawer,
  updatePermissions,
}: Props) {
  const { user } = useContext(AuthContext)

  return (
    <Drawer
      closeOnEsc
      isOpen={openDrawer!}
      key={member?.permission}
      onClose={() => onDone?.()}
      placement={'right'}
      size={{ base: 'xs', sm: 'sm' }}
    >
      <DrawerOverlay />
      <LightModeBox>
        <DrawerContent borderRadius="md" my={2} mr={2}>
          <DrawerHeader borderBottomWidth="1px">
            <Flex gap={2} align={'center'}>
              <Cog6ToothIcon size="sm" />
              <Text textStyle={'bodyMedium'}>Manage Member</Text>
            </Flex>
          </DrawerHeader>
          <DrawerCloseButton onClick={() => onDone?.()} />
          <DrawerBody>
            {member && (
              <Flex direction={'column'} gap={6} mt={3}>
                <Flex gap={2} align="center">
                  <Avatar name={member.name} src={member.photoUrl} size="md" />
                  <Flex direction="column" justify="center">
                    {member.name && <Text textStyle="bodySmall">{member.name}</Text>}
                    <Text
                      textStyle="bodySmall"
                      fontWeight="500"
                      color={member.name || member.email === 'Pending' ? 'gray.500' : 'black'}
                    >
                      {member.email}
                    </Text>
                  </Flex>
                </Flex>

                {member.status === 'pending' && (
                  <Button
                    alignSelf="flex-start"
                    color="secondary.700"
                    variant="link"
                    leftIcon={<ShareIcon fontSize="sm" />}
                    onClick={copyInviteLink}
                  >
                    Copy invite link
                  </Button>
                )}

                <Flex gap={2} align={'center'}>
                  <LockClosedIcon fontSize={'lg'} />
                  <Text textStyle={'bodyMedium'}>Permissions</Text>
                </Flex>
                <Menu
                  menuProps={{ matchWidth: true }}
                  isDisabled={!user?.isAdmin}
                  noPortal
                  rightIcon={<ChevronDownIcon fontSize={'md'} />}
                  iconSpacing={{ base: 0, sm: 2 }}
                  borderRadius="sm"
                  variant="outline"
                  textAlign={'start'}
                  buttonLabel={`Role: ${startCase(member.permission)}`}
                  options={[
                    {
                      type: 'group',
                      isOption: true,
                      groupType: 'radio',
                      value: member.permission,
                      options: permissionOptions.map(({ value }) => ({
                        value,
                        label: <PermissionWithHelp value={value} />,
                        onClick: () => {
                          updatePermissions(value as PermissionValue)
                          onDone?.()
                        },
                      })),
                    },
                  ]}
                />

                <Flex
                  align={'flex-start'}
                  backdropFilter="blur(27px)"
                  border="1px solid rgba(23, 23, 23, 0.20)"
                  borderRadius="10px"
                  direction="column"
                  gap={3}
                  p={5}
                >
                  <Flex direction="column" gap={1}>
                    <Text textStyle="bodyMedium">Delete Member</Text>
                    <Text textStyle="bodySmall" fontWeight="normal">
                      This action cannot be undone. All associated member data will be permanently
                      deleted.
                    </Text>
                  </Flex>
                  <DeleteConfirmButton
                    p={5}
                    m={0}
                    bg="red"
                    w="100px"
                    leftIcon={<TrashIcon fontSize={'lg'} type="outline" />}
                    borderRadius="md"
                    _hover={{
                      fontWeight: 'semibold',
                    }}
                    onDelete={() => {
                      onRemoveMember()
                      onDone?.()
                    }}
                    description={
                      'This action cannot be undone. All associated member data will be permanently deleted.'
                    }
                  />
                </Flex>
              </Flex>
            )}
          </DrawerBody>
        </DrawerContent>
      </LightModeBox>
    </Drawer>
  )
}
