import { orderBy, where } from 'firebase/firestore'
import { useContext } from 'react'
import useSWR from 'swr'
import { IEvent, OmitDBProps } from 'types'
import { getDocs } from 'utils'
import { EventContext } from '../components/event'

const makeEventsKey = (workspaceId?: string) => ['events', workspaceId]

export function useEvents(workspaceId?: string) {
  const { data, ...rest } = useSWR(makeEventsKey(workspaceId), ([_, workspaceId]) =>
    workspaceId ? getEvents(workspaceId) : undefined
  )

  return { events: data, ...rest }
}

export function getEventsInSections(events: IEvent[]) {
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

  const live: IEvent[] = []
  const upcoming: IEvent[] = []
  const past: IEvent[] = []

  events.forEach(event => {
    if (event.startTime) {
      const eventStartTime = new Date(event.startTime)
      const eventStartDate = new Date(
        eventStartTime.getFullYear(),
        eventStartTime.getMonth(),
        eventStartTime.getDate()
      )

      if (event.endTime) {
        const eventEndTime = new Date(event.endTime)
        const eventEndDate = new Date(
          eventEndTime.getFullYear(),
          eventEndTime.getMonth(),
          eventEndTime.getDate()
        )

        if (eventEndDate < today) {
          past.push(event)
        } else if (eventEndDate >= today) {
          if (eventStartDate > today) {
            upcoming.push(event)
          } else {
            live.push(event)
          }
        }
      } else {
        if (eventStartDate < today) {
          past.push(event)
        } else if (eventStartDate > today) {
          upcoming.push(event)
        } else {
          live.push(event)
        }
      }
    } else {
      upcoming.push(event)
    }
  })

  upcoming.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
  past.sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())

  return { live, upcoming, past }
}

async function getEvents(workspaceId: string) {
  return getDocs('events', 0, where('workspaceId', '==', workspaceId), orderBy('endTime', 'asc'))
}

export const useEvent = () => useContext(EventContext)
export const getEventImagePath = (id: string) => `events/${id}/image`

export const makeEmptyEvent = (workspaceId: string, userId: string): OmitDBProps<IEvent> => ({
  endTime: 0,
  registrationMode: 'input',
  startTime: 0,
  title: '',
  workspaceId,
  sessionCount: 0,
  managerId: userId,
})

export function formatEventDates(startTime?: number, endTime?: number) {
  const startYear = new Date(startTime!).getFullYear()
  const endYear = new Date(endTime!).getFullYear()

  const optionsShort: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' }
  const optionsFull: Intl.DateTimeFormatOptions = { ...optionsShort, year: 'numeric' }

  const formatDate = (date: number, options: Intl.DateTimeFormatOptions) =>
    new Date(date).toLocaleString('en-US', options)

  if (startTime && endTime) {
    const startFormatted = formatDate(startTime, optionsShort)
    const endFormatted = formatDate(endTime, optionsFull)

    if (startYear === endYear) {
      return `${startFormatted} - ${endFormatted}`
    } else {
      const startFullFormatted = formatDate(startTime, optionsFull)
      return `${startFullFormatted} - ${endFormatted}`
    }
  } else if (startTime) {
    return formatDate(startTime, optionsFull)
  } else if (endTime) {
    return formatDate(endTime, optionsFull)
  }
  return ' '
}

export function isEventInSection(event: IEvent, section: IEvent[]) {
  return section.some(item => item.id === event.id)
}
