import { Flex, Text } from '@chakra-ui/react'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import { CalendarDaysIcon } from 'components'

export interface props {
  date?: Date
  handleDateChange: (date: Date, isStart: boolean) => void
  minDate?: number
  isDisabled?: boolean
}

export default function SingleDatePicker({ date, handleDateChange, minDate, isDisabled }: props) {
  const color = 'primary.500'
  return (
    <Flex
      border={'1px solid rgb(226, 232, 240)'}
      borderRadius={'6px'}
      width={{ base: '120px', sm: '150px' }}
      height={'40px'}
      direction={'row'}
      align={'center'}
    >
      <SingleDatepicker
        date={date ? new Date(date) : undefined}
        onDateChange={date => handleDateChange(date, true)}
        minDate={minDate ? new Date(minDate + 86400) : undefined}
        // showOutsideDates
        closeOnSelect
        propsConfigs={{
          triggerBtnProps: {
            leftIcon: <CalendarDaysIcon opacity={0.6} color={'black'} size={'sm'} />,
            iconSpacing: !date ? '80px' : '8px',
            isDisabled: isDisabled,
            borderRadius: 'xs',
            width: '100%',
            fontWeight: 'semibold',
            fontSize: '13px',
            color: 'black',
            opacity: 0.8,
            border: 'none',
          },
          dateNavBtnProps: {
            color,
          },
          dayOfMonthBtnProps: {
            defaultBtnProps: {
              color: 'black',
              fontWeight: 'normal',
              _hover: {
                background: color,
              },
            },
            selectedBtnProps: {
              background: color,
              color: 'white',
            },
            todayBtnProps: {
              fontWeight: 'bold',
              borderColor: color,
              border: '1px',
            },
          },
          calendarPanelProps: {
            contentProps: {
              background: 'white',
              padding: 0,
              border: '1px solid white',
            },
            dividerProps: {
              border: '1px solid rgb(226, 232, 240)',
            },
          },
          popoverCompProps: {
            popoverContentProps: {
              background: 'white',
              border: '1px solid rgb(226, 232, 240)',
            },
          },
        }}
        configs={{
          dateFormat: 'MM/dd/yyyy',
        }}
      />
      {!date && (
        <Text
          fontWeight={'normal'}
          color={'gray'}
          position={'absolute'}
          transform={'translateX(80%)'}
          pointerEvents={'none'}
          opacity={0.6}
        >
          --/--/----
        </Text>
      )}
    </Flex>
  )
}
