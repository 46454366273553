import { Circle, Flex, Text, useColorMode } from '@chakra-ui/react'
import { NoSymbolIcon, Panel, QrCodeIcon, QueueListIcon, ServerStackIcon } from 'components'
import { useEffect, useState } from 'react'
import { EventRegistrationMode } from 'types'
import { getFileUrl, useWorkspace } from 'utils'
import { useEvent } from '../../utils/eventUtils'

interface Props {
  eventManageOnOpen: () => void
  eventManageIsOpen: boolean
  qrTestOnOpen: () => void
}

export default function EventRegModeTile({
  eventManageOnOpen,
  eventManageIsOpen,
  qrTestOnOpen,
}: Props) {
  const { workspace } = useWorkspace()
  const { event } = useEvent()
  const { colorMode } = useColorMode()

  const [dbStatus, setDbStatus] = useState('')
  const dbFilePath = `/events/${event?.id}/attendees.json`
  const { qrCode, registrationMode } = event || {}
  const qrStatus =
    qrCode && qrCode.isValid ? (qrCode.leadMapping ? 'Active' : 'Mapping Required') : 'Auth Error'

  useEffect(() => {
    if (!workspace || event?.registrationMode !== 'database') return

    getFileUrl(dbFilePath, workspace.id)
      .then(() => setDbStatus('Active'))
      .catch(() => setDbStatus('Upload Required'))
  }, [workspace, event?.registrationMode, eventManageIsOpen])

  function directToStep() {
    if (registrationMode === 'database' && dbStatus !== 'Active') eventManageOnOpen()
    else if (registrationMode === 'qr-code') {
      if (qrStatus === 'Auth Error') eventManageOnOpen()
      else if (qrStatus === 'Mapping Required') qrTestOnOpen()
    }
  }

  function getQrStatusColor(target: 'circle' | 'text') {
    if (qrCode?.isValid)
      if (qrCode.leadMapping)
        return target === 'circle' ? 'green' : colorMode === 'light' ? 'black' : 'white'
      else return 'red'
    else return 'red'
  }

  function getDbStatusColor(target: 'circle' | 'text') {
    switch (dbStatus) {
      case 'Active':
        return target === 'circle' ? 'green' : colorMode === 'light' ? 'black' : 'white'
      default:
        return 'red'
    }
  }

  return (
    <Flex
      align="center"
      gap={2}
      onClick={
        registrationMode === 'database' || registrationMode === 'qr-code' ? directToStep : undefined
      }
      cursor={
        (registrationMode === 'qr-code' && qrStatus !== 'Active') ||
        (registrationMode === 'database' && dbStatus !== 'Active')
          ? 'pointer'
          : 'default'
      }
    >
      <Panel p={2} borderRadius="xl">
        {registrationMode && registrationOptions[registrationMode].icon}
      </Panel>
      <Flex direction="column">
        <Text textStyle="bodySmall">
          {registrationMode === 'qr-code'
            ? qrCode?.type
              ? 'XPress Leads'
              : 'Custom'
            : registrationMode && registrationOptions[registrationMode].label}
        </Text>
        <Flex align="center" gap={2} opacity={0.8}>
          {(qrCode?.type || registrationMode === 'database') && (
            <Circle
              size="6px"
              bg={
                registrationMode == 'database'
                  ? getDbStatusColor('circle')
                  : getQrStatusColor('circle')
              }
            />
          )}

          {(qrCode?.type || registrationMode === 'database') && (
            <Text
              textStyle="bodyXSmall"
              color={
                registrationMode == 'database' ? getDbStatusColor('text') : getQrStatusColor('text')
              }
              opacity={0.7}
            >
              {registrationMode == 'database' ? dbStatus : qrStatus}
            </Text>
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

const registrationOptions: Record<EventRegistrationMode, { label: string; icon: JSX.Element }> = {
  input: { label: 'Form', icon: <QueueListIcon size="sm" /> },
  database: { label: 'Database', icon: <ServerStackIcon size="sm" /> },
  'qr-code': { label: 'QR Code', icon: <QrCodeIcon size="sm" /> },
  none: { label: 'None', icon: <NoSymbolIcon size="sm" /> },
}
