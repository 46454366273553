import { Text, useDisclosure } from '@chakra-ui/react'
import { Button, ButtonProps, Dialog, TextField, TrashIcon } from 'components'
import { startCase } from 'lodash'
import { useRef, useState } from 'react'

export interface Props extends ButtonProps {
  onDelete: () => void
  description: string
  buttonLabel?: string
}

export default function DeleteConfirmButton({
  onDelete,
  description,
  buttonLabel = 'Delete',
  ...rest
}: Props) {
  const disclosure = useDisclosure()
  const [text, setText] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const textMatch = text.toLocaleLowerCase().includes(buttonLabel?.toLocaleLowerCase())

  function handleDelete() {
    disclosure.onClose()
    setText('')
    onDelete()
  }

  return (
    <>
      <Button
        variant="ghost"
        width="90px"
        leftIcon={
          <TrashIcon fontSize={'lg'} color={'red'} type={isHovered ? 'solid' : 'outline'} />
        }
        textAlign={'center'}
        onClick={() => disclosure.onOpen()}
        size="sm"
        fontWeight={'normal'}
        mx={2}
        _hover={{
          bg: 'white',
          fontWeight: 'semibold',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...rest}
      >
        {buttonLabel}
      </Button>

      <Dialog
        {...disclosure}
        bodyProps={{ px: 6 }}
        contentProps={{ maxW: 'sm' }}
        footer={
          <Button isDisabled={!textMatch} onClick={handleDelete} palette="danger">
            {buttonLabel}
          </Button>
        }
        initialFocusRef={inputRef}
        isCentered
        title={`${buttonLabel}?`}
      >
        <Text pb={4}>{description}</Text>
        <TextField
          autoFocus
          onChange={e => setText(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && textMatch && handleDelete()}
          placeholder={`Type ${startCase(buttonLabel)}`}
          ref={inputRef}
          value={text}
        />
      </Dialog>
    </>
  )
}
