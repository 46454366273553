import { Text, TextProps, TooltipProps, useColorMode } from '@chakra-ui/react'
import dayjs from 'dayjs'
import Tooltip from './Tooltip'

export interface Props extends Omit<TextProps, 'children'> {
  children: number
  tooltipProps?: TooltipProps
}

/** Pretty a date/time value to show time for today or a date otherwise wrapped in a tooltip */
export default function DateOrTime({ children, tooltipProps, ...rest }: Props) {
  const { colorMode } = useColorMode()
  if (!children) return null

  const date = dayjs(children)
  const today = dayjs()
  const isToday = date.isSame(today, 'day')

  const display = isToday ? date.format('hh:mm A') : date.format('MMM D, YYYY')
  const tooltip = isToday ? `Today at ${date.format('hh:mm a')}` : date.format('hh:mm A')

  return (
    <Tooltip label={tooltip} {...(tooltipProps as any)}>
      <Text {...(rest as any)}>{display}</Text>
    </Tooltip>
  )
}
