import { Flex } from '@chakra-ui/react'
import { Backdrop } from 'components'
import { WorkspaceProvider } from 'components/providers'
import { Outlet } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import SideMenu from '../components/SideMenu'
import TopBar from '../components/TopBar'
import { ResourceProvider } from '../ResourceProvider'

export default function RootPage() {
  return (
    <WorkspaceProvider>
      <ResourceProvider>
        <IntercomProvider appId="uiwmuutv" autoBoot>
          <Backdrop flex="auto" overflow="hidden">
            <Flex
              mx={{ base: 0, sm: 7 }}
              direction="row"
              flex={1}
              overflow="hidden"
              gap={{ base: 0, sm: 7 }}
              pt={{ base: `calc(env(safe-area-inset-top) + 10px)`, sm: 0 }}
              pb={{ base: `calc(env(safe-area-inset-bottom) + 35px)`, sm: 0 }}
            >
              <SideMenu />
              <Flex flex="auto" direction="column" overflow="hidden">
                <TopBar />
                <Outlet />
              </Flex>
            </Flex>
          </Backdrop>
        </IntercomProvider>
      </ResourceProvider>
    </WorkspaceProvider>
  )
}
