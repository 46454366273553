import { Circle, Flex, Text, useColorMode } from '@chakra-ui/react'
import { Menu, Panel, PanelProps } from 'components'

interface Props extends PanelProps {
  icon?: JSX.Element
  label: string
  num?: number
  children?: JSX.Element
  labelOptions?: string[]
  onLabelSelect?: (label: string) => void
}

export default function EventStatTile({
  icon,
  label,
  num,
  labelOptions,
  onLabelSelect,
  children,
  ...rest
}: Props) {
  const { colorMode } = useColorMode()

  return (
    <Panel
      borderRadius="2xl"
      p="15px"
      align="center"
      overflow="hidden"
      h={children ? '100%' : '85px'}
      minW="200px"
      {...rest}
    >
      <Flex direction="row" gap={4} width="100%">
        <Circle
          size={{ base: '40px', sm: '50px' }}
          bg={colorMode === 'light' ? 'white' : 'rgba(255, 255, 255, 0.20)'}
          boxShadow={'0px 4px 10px 0px rgba(0, 0, 0, 0.06)'}
          ml={{ base: '0', sm: '5' }}
        >
          {icon}
        </Circle>
        <Flex direction={'column'} gap={0} overflow="hidden" justify="center">
          {labelOptions ? (
            <Menu
              buttonLabel={label}
              size="xs"
              p={0}
              h="17px"
              menuProps={{ closeOnSelect: true }}
              options={[
                {
                  type: 'group',
                  groupType: 'radio',
                  defaultValue: label,
                  options: labelOptions.map(option => ({
                    value: option,
                    label: option,
                    onClick: () => onLabelSelect?.(option),
                  })),
                },
              ]}
            />
          ) : (
            <Text textStyle="bodySmall">{label}</Text>
          )}
          {num !== undefined && num !== null && (
            <Text textStyle={{ base: 'h3', sm: 'h2' }}>
              {label.toLowerCase().includes('duration')
                ? `${num.toPrecision(4)} s`
                : num.toLocaleString()}
            </Text>
          )}
        </Flex>
      </Flex>
      {children}
    </Panel>
  )
}
