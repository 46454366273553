import { Avatar, Flex, Text } from '@chakra-ui/react'
import { ArrowDownTrayIcon, Menu, UserIcon } from 'components'
import { AuthContext } from 'components/providers'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { signOut, useWorkspace } from 'utils'

export default function UserProfile() {
  const { user } = useContext(AuthContext)
  const { workspace } = useWorkspace()
  const navigate = useNavigate()

  return (
    <Menu
      menuProps={{ placement: 'bottom-end' }}
      buttonLabel={
        <Flex gap={2} alignItems={'center'}>
          <Avatar
            key={user?.photoUrl!}
            cursor="pointer"
            size="xs"
            name={user?.name!}
            src={user?.photoUrl!}
          />
          <Text fontSize="14px" textStyle="bodySmall">
            {user?.name! || user?.email}
          </Text>
        </Flex>
      }
      options={[
        {
          value: 'profile',
          onClick: () => navigate(`/${workspace?.id}/profile`),
          icon: <UserIcon type="outline" fontSize={'sm'} />,
          label: 'My Profile',
        },
        {
          value: 'logout',
          onClick: () => signOut(),
          icon: <ArrowDownTrayIcon size="sm" style={{ transform: 'rotate(90deg)' }} />,
          label: 'Log Out',
        },
      ]}
    />
  )
}
