import { createContext } from 'react'
import { useParams } from 'react-router-dom'
import { IFacility, ITemplate } from 'types'
import { FetchStatus, useDoc, useStorageUrl } from 'utils'
import { getFacilityImagePath } from '../../utils/facilityUtils'

export interface FacilityContextProps {
  facility: IFacility | null
  imageUrl?: string
  refetchImage: () => void
  status: FetchStatus
  template?: ITemplate | null
}

export const FacilityContext = createContext<FacilityContextProps>({} as any)

export function FacilityProvider({ children }: { children: any }) {
  const id = useParams().id
  const [facility, status] = useDoc('facilities', id)
  const [template] = useDoc('templates', facility?.template?.id)
  const { hasImage } = facility || {}
  const { url, mutate } = useStorageUrl(hasImage && id ? getFacilityImagePath(id) : undefined)

  return (
    <FacilityContext.Provider
      value={{
        facility: facility || null,
        imageUrl: url,
        refetchImage: () => mutate(),
        status,
        template,
      }}
    >
      {children}
    </FacilityContext.Provider>
  )
}
