import { TableProps } from 'components'
import { DataType, EditingMode, SortingMode } from 'ka-table'

export const defaultProps: TableProps = {
  columnResizing: true,
  columns: [],
  editingMode: EditingMode.Cell,
  rowKeyField: 'id',
  sortingMode: SortingMode.None,
  pinnedColumns: [],
}

export function makeTypeByField(data: { [key: string]: any }[]): { [key: string]: DataType } {
  return data.reduce((acc, current) => {
    Object.keys(current).forEach(key => {
      if (acc[key]) return

      switch (key) {
        case 'created':
        case 'updated':
          return (acc[key] = DataType.Date)
        case 'optIn':
        case 'followUp':
          return (acc[key] = DataType.Boolean)
      }

      switch (typeof current[key]) {
        case 'boolean':
          return (acc[key] = DataType.Boolean)
        case 'number':
          return (acc[key] = DataType.Number)
        case 'object':
          return (acc[key] = DataType.Object)
        default:
          return (acc[key] = DataType.String)
      }
    })
    return acc
  }, {} as { [key: string]: DataType })
}
