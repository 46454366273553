import { Flex, Image, Spacer, Text } from '@chakra-ui/react'
import {
  ArrowUturnLeftIcon,
  Button,
  DevDetailsButton,
  Dialog,
  DialogProps,
  FileDrop,
  HelpHover,
  IconButton,
  LoaderCover,
  PencilSquareIcon,
  TextField,
  TrashIcon,
} from 'components'
import { AuthContext } from 'components/providers'
import humanizeString from 'humanize-string'
import { useContext, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { IMedia, MediaType } from 'types'
import {
  acceptedImages,
  acceptedVideos,
  createImgSrc,
  getImagePath,
  getStoragePath,
  getThumbPath,
  getVideoPath,
  imageFromVideo,
  removeExtension,
  sleep,
  typeFromFile,
  updateDoc,
  updateMedia,
  uploadMedia,
  useMedia,
  useStorageUrl,
  useWorkspace,
} from 'utils'

export interface Props extends Omit<DialogProps, 'children' | 'title'> {
  data?: IMedia
  defaultImage?: string
  /** Update the media file */
  newMedia?: File
  onDelete?: () => Promise<boolean>
  onSave?: (doc: IMedia) => void
  type?: MediaType
}

interface NewMedia {
  image?: FileAndUrl
  video?: FileAndUrl
}

interface FileAndUrl {
  file: File
  url: string
}

export default function MediaDialog({
  newMedia: controlledNewMedia,
  data,
  defaultImage,
  onClose,
  onDelete,
  onSave,
  type: controlledType,
  ...rest
}: Props) {
  const { id, title = '', type } = data || {}

  const { user } = useContext(AuthContext)
  const { workspace } = useWorkspace()
  const { mutate } = useMedia(id)
  const { url: imageUrl, mutate: imageMutate } = useStorageUrl(id ? getImagePath(id) : undefined)
  const { url: videoUrl } = useStorageUrl(id && type === 'video' ? getVideoPath(id) : undefined)
  const { mutate: thumbUrlMutate } = useStorageUrl(id ? getThumbPath(id) : undefined)

  const [draftTitle, setDraftTitle] = useState('')
  const [progress, setProgress] = useState(0)
  const [newMedia, setNewMedia] = useState<NewMedia>()
  const [videoPlayed, setVideoPlayed] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const videoPlayerRef = useRef<ReactPlayer>(null)

  const imageSrc = newMedia?.image?.url || imageUrl || defaultImage
  const videoSrc = newMedia?.video?.url || videoUrl
  const madeChange = draftTitle !== title || newMedia

  useEffect(() => {
    if (!controlledNewMedia) return

    handleFiles([controlledNewMedia])
    setDraftTitle(humanizeString(removeExtension(controlledNewMedia.name)))
  }, [controlledNewMedia])

  useEffect(() => {
    setDraftTitle(title)
  }, [title])

  async function handleDone() {
    let body = { ...data, title: draftTitle }
    let docId = data?.id ?? ''

    if (data) {
      if (newMedia?.video) {
        await updateMedia(workspace!.id, newMedia.video.file, id!, setProgress)
      }

      if (newMedia?.image) {
        const dimensions = await updateMedia(workspace!.id, newMedia.image.file, id!, setProgress)
        body = { ...body, dimensions }
        imageMutate()
        sleep(3000).then(() => thumbUrlMutate()) // HACK: wait for the thumb to be generated
      }
    } else {
      if (newMedia?.video) {
        const doc = await uploadMedia(
          workspace!.id,
          user!.id,
          newMedia.video.file,
          setProgress,
          body
        )
        docId = doc?.id

        if (!newMedia.image) {
          const coverImage = await imageFromVideo(newMedia.video.file)
          if (!coverImage) throw 'Error creating thumbnail from video'

          const dimensions = await updateMedia(workspace!.id, coverImage, docId, setProgress)
          body = { ...body, dimensions }
        }
      }

      if (newMedia?.image) {
        if (docId) {
          const dimensions = await updateMedia(
            workspace!.id,
            newMedia.image.file,
            docId,
            setProgress
          )
          body = { ...body, dimensions }
        } else {
          const doc = await uploadMedia(
            workspace!.id,
            user!.id,
            newMedia.image.file,
            setProgress,
            body
          )
          onSave?.(doc)
          handleClose()
          return
        }
      }
    }

    const update = await updateDoc(user!.id, 'Media', { id: docId, ...body })
    mutate(update)
    onSave?.(update)
    handleClose()
  }

  async function handleFiles(files: File[]) {
    const file = files[0]
    const fileType = typeFromFile(file)
    const imageFile = fileType === 'image' ? file : await imageFromVideo(file)

    let update = { ...newMedia }
    if (fileType === 'video') update.video = { file, url: URL.createObjectURL(file) }
    if (imageFile && (fileType === 'image' || type !== 'video'))
      update.image = { file: imageFile, url: await createImgSrc(imageFile) }

    resetVideo()
    setNewMedia(update)
  }

  function resetVideo() {
    videoPlayerRef.current?.showPreview?.()
    setVideoPlayed(false)
  }

  function handleClose() {
    onClose()
    setNewMedia(undefined)
    setDraftTitle(title)
    setProgress(0)
  }

  async function handleDelete() {
    const deleted = await onDelete?.()
    if (deleted) handleClose()
  }

  return (
    <Dialog
      isCentered
      title={
        <Flex ref={containerRef} gap={2} alignItems="center" justifyContent="space-between">
          <Text>Manage Media</Text>

          {process.env.NODE_ENV !== 'production' && data && (
            <DevDetailsButton
              items={[
                { collection: 'Media', id, label: 'Media', type: 'db' },
                { label: 'Storage', type: 'storage', url: getStoragePath(data) },
              ]}
            />
          )}
        </Flex>
      }
      closeOnOverlayClick={false}
      bodyProps={{ display: 'flex', mb: 6, px: 6, overflow: 'hidden' }}
      contentProps={{ width: '554px', maxWidth: '80%', maxHeight: '80%', height: '629px' }}
      onClose={handleClose}
      {...rest}
    >
      <LoaderCover display="flex" flex={1} show={progress > 0} progress={progress}>
        <FileDrop
          display="flex"
          flex={1}
          onDrop={handleFiles}
          options={{ accept: acceptedFiles(type, newMedia, controlledType) }}
          alignItems="stretch"
        >
          {({ open }) => (
            <Flex direction="column" flex={1} gap={4}>
              <TextField
                label="Name"
                value={draftTitle}
                onChange={e => setDraftTitle(e.target.value)}
              />

              <Flex
                flex={1}
                align="center"
                justify="center"
                background="gray.100"
                overflow="hidden"
                position="relative"
              >
                {videoSrc ? (
                  <>
                    <ReactPlayer
                      ref={videoPlayerRef}
                      {...fullHW}
                      url={videoSrc}
                      onStart={() => setVideoPlayed(true)}
                      controls
                      playing
                      light={<Image draggable={false} objectFit="contain" src={imageSrc} />}
                    />
                    {videoPlayed && (
                      <Button
                        position="absolute"
                        top={2}
                        right={2}
                        size="sm"
                        leftIcon={<ArrowUturnLeftIcon size="sm" />}
                        onClick={resetVideo}
                      >
                        Cover
                      </Button>
                    )}
                  </>
                ) : (
                  <Image {...fullHW} draggable={false} objectFit="contain" src={imageSrc} />
                )}
              </Flex>

              <Flex align="center" gap={2}>
                {data && onDelete && (
                  <IconButton
                    variant="outline"
                    palette="danger"
                    aria-label="Delete"
                    icon={<TrashIcon />}
                    onClick={handleDelete}
                  />
                )}
                <Button variant="outline" leftIcon={<PencilSquareIcon />} onClick={open}>
                  {data || newMedia ? 'Replace' : 'Add'}
                </Button>
                {videoSrc && (
                  <HelpHover text="Select (or drag) video to replace video or image to replace cover" />
                )}
                <Spacer />
                {madeChange && (
                  <Button variant="outline" onClick={handleClose}>
                    Cancel
                  </Button>
                )}
                <Button palette="primary" onClick={handleDone}>
                  {madeChange ? 'Save' : 'Done'}
                </Button>
              </Flex>
            </Flex>
          )}
        </FileDrop>
      </LoaderCover>
    </Dialog>
  )
}

const fullHW = { height: '100%', width: '100%' }

function acceptedFiles(type?: MediaType, newMedia?: NewMedia, controlledType?: MediaType) {
  if (type) return { ...acceptedImages, ...(type === 'video' && acceptedVideos) }

  if (newMedia) return { ...acceptedImages, ...(newMedia.video && acceptedVideos) }

  return {
    ...(controlledType !== 'video' && acceptedImages),
    ...(controlledType !== 'image' && acceptedVideos),
  }
}
