import { Box, BoxProps, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'
import { Icon, IconButton } from 'components'
import { useContext, useEffect, useState } from 'react'
import {
  getImageLightness,
  getImagePath,
  getThumbPath,
  useMedia,
  usePermission,
  useStorageUrl,
} from 'utils'
import { AuthContext } from '../providers'
import MediaDialog from './MediaDialog'

export interface Props extends BoxProps {
  hideTitle?: boolean
  id: string
  onDelete?: () => Promise<boolean>
  onChoose?: () => void
}

export default function MediaThumb({ hideTitle, id, onChoose, onDelete, ...rest }: Props) {
  const disclosure = useDisclosure()
  const { user } = useContext(AuthContext)

  const { media } = useMedia(id)
  const { createdBy, type, title } = media || {}
  const permission = usePermission()
  const { url } = useStorageUrl(getThumbPath(id), getImagePath(id))

  const handleDelete = user?.isAdmin || createdBy === user?.id ? onDelete : undefined

  return (
    <Box
      aspectRatio={1}
      position="relative"
      cursor="pointer"
      onClick={onChoose || disclosure.onOpen}
      {...rest}
    >
      <Image
        height="100%"
        width="100%"
        objectFit="cover"
        borderRadius={8}
        draggable={false}
        src={url}
      />

      {!hideTitle && title && (
        <Box
          position="absolute"
          bottom={2}
          p={1}
          borderRadius={8}
          left="50%"
          transform="translateX(-50%)"
          maxWidth="calc(100% - 10px)"
          backgroundColor="#FFFFFF66"
        >
          <Text fontSize="sm" isTruncated>
            {title}
          </Text>
        </Box>
      )}

      {type === 'video' && (
        <Flex position="absolute" align="center" justify="center" inset={0}>
          <PlayIconThumb url={url} />
        </Flex>
      )}

      {permission.content !== 'viewer' && (
        <Flex position="absolute" top={2} right={2} backgroundColor="#FFFFFF66" borderRadius={8}>
          {onChoose && (
            <IconButton
              aria-label="Remove media"
              color="slateblue"
              size="sm"
              variant="ghost"
              onClick={e => {
                disclosure.onOpen()
                e.stopPropagation()
              }}
              icon={<Icon>preview</Icon>}
            />
          )}

          {handleDelete && (
            <IconButton
              aria-label="Remove media"
              color="tomato"
              size="sm"
              variant="ghost"
              onClick={e => {
                handleDelete()
                e.stopPropagation()
              }}
              icon={<Icon>delete</Icon>}
            />
          )}
        </Flex>
      )}

      {media && <MediaDialog data={media} onDelete={handleDelete} {...disclosure} />}
    </Box>
  )
}

function PlayIconThumb({ url }: { url?: string }) {
  const [brightness, setBrightness] = useState(100)

  useEffect(() => {
    if (url) getImageLightness(url).then(setBrightness)
  }, [url])

  return (
    <Icon size="lg" color={brightness < 0.5 ? 'white' : 'black'}>
      play_circle
    </Icon>
  )
}
