import { Chart as GoogleChart } from 'react-google-charts'
import { ChartProps } from 'types'
import {
  defaultChartProps,
  makeChartData,
  makeDefaultChartOptions,
  makeStackedChartData,
} from '../chartUtils'

export default function BarChart(props: ChartProps) {
  const options = {
    ...makeDefaultChartOptions(props),
    isStacked: props.isStacked,
  }
  return (
    <GoogleChart
      {...defaultChartProps}
      chartType="BarChart"
      data={props.isStacked ? makeStackedChartData(props) : makeChartData(props)}
      options={options}
    />
  )
}
