import { Divider, Flex, Text, useColorMode } from '@chakra-ui/react'
import {
  ArchiveBoxIcon,
  DebouncedTextField,
  DeleteConfirmButton,
  LightModeBox,
  Switch,
} from 'components'
import { useContext } from 'react'
import { IWorkspace } from 'types'
import { updateDoc, useWorkspace } from 'utils'
import { AuthContext } from '../providers'
import ManageFollowUpGroups from './ManageFollowUpGroups'

export interface Props {
  onDeleted?: () => void
  onRefresh?: () => void
}

export default function WorkspaceSettings({ onDeleted, onRefresh }: Props) {
  const { entevatePermission, user } = useContext(AuthContext)
  const { workspace } = useWorkspace()
  const { colorMode } = useColorMode()

  if (!workspace) return null
  const { id, title, hideBranding } = workspace

  async function update(body: Partial<IWorkspace>) {
    await updateDoc(user!.id, 'Workspace', { id: id, ...body })
  }

  async function updateTitle(title: string) {
    await update({ title })
    onRefresh?.()
  }

  async function handleArchive() {
    await updateDoc(user!.id, 'Workspace', { id: id, isArchived: true })
    onDeleted?.()
  }

  return (
    <LightModeBox
      display="flex"
      bg="linear-gradient(167deg, rgba(255, 255, 255, 0.70) 2.36%, rgba(255, 255, 255, 0.60) 92.17%)"
      px={{ base: 4, sm: 14 }}
      py={10}
      gap={5}
      borderRadius="xl"
      border="1px solid #FFF"
      boxShadow="0px 7px 16px 0px rgba(0, 0, 0, 0.08)"
      alignItems="flex-start"
      flexDirection="column"
      backdropFilter="blur(40px)"
      justifyContent="center"
    >
      <DebouncedTextField
        autoFocus
        label="Workspace Name"
        key={id}
        debounceTime={500}
        onChange={updateTitle}
        placeholder="Title"
        showStatus
        value={title}
        bg="#fff"
        backdropFilter="blur(27px)"
        borderRadius="10px"
        border="1px solid rgba(23, 23, 23, 0.20)"
      />

      <Switch
        label="Show Momentify Branding"
        isChecked={!hideBranding}
        onChange={() => update({ hideBranding: !hideBranding })}
      />

      <ManageFollowUpGroups />

      {entevatePermission === 'entevate' && (
        <Flex direction={'column'} w={{ base: '100%', sm: '70%' }} gap={5}>
          <Divider
            borderColor={
              colorMode === 'light' ? 'rgba(0, 0, 0, 0.10)' : 'rgba(255, 255, 255, 0.60)'
            }
          />
          <Flex
            align="center"
            backdropFilter="blur(27px)"
            bg="#fff"
            border="1px solid rgba(23, 23, 23, 0.20)"
            borderRadius="10px"
            direction={{ base: 'column', sm: 'row' }}
            gap={2}
            justify="space-between"
            p={5}
          >
            <Flex direction="column" gap={1}>
              <Text textStyle="bodyMedium">Archive Workspace</Text>
              <Text textStyle="bodySmall" fontWeight="normal">
                This action cannot be undone. All data in your workspace will be archived including
                your account information.
              </Text>
            </Flex>
            <DeleteConfirmButton
              p={5}
              color="white"
              bg="red"
              leftIcon={<ArchiveBoxIcon fontSize={'lg'} color={'white'} type="outline" />}
              borderRadius="md"
              _hover={{
                fontWeight: 'semibold',
              }}
              onDelete={handleArchive}
              buttonLabel="Archive"
              description={
                'This action cannot be undone. All data in your workspace will be archived including your account information.'
              }
            />
          </Flex>
        </Flex>
      )}
    </LightModeBox>
  )
}
