import { HttpsCallableResult } from 'firebase/functions'
import useSWR from 'swr'
import {
  getDocById,
  getFileMeta,
  getFileUrl,
  getImageFromSiteUrl,
  updateDoc,
  uploadFile,
  useWorkspace,
} from 'utils'

const mediaSwrKey = {
  media: (mediaId?: string) => ['media', mediaId],
  storageUrl: (workspaceId?: string, filePath?: string) => ['storageUrl', workspaceId, filePath],
  storageMeta: (workspaceId?: string, filePath?: string) => ['storageMeta', workspaceId, filePath],
}

export function useMedia(id?: string) {
  const { data: media, ...rest } = useSWR(mediaSwrKey.media(id), ([_, id]) =>
    id ? getDocById('Media', id) : undefined
  )
  return { media, ...rest }
}

export function useStorageUrl(filePath?: string, fallbackPath?: string) {
  const { workspace } = useWorkspace()
  const { data: url, ...rest } = useSWR(
    mediaSwrKey.storageUrl(workspace?.id, filePath),
    ([_, workspaceId, filePath]) =>
      workspaceId && filePath
        ? getFileUrl(filePath, workspaceId).catch(e => {
            if (fallbackPath) return getFileUrl(fallbackPath, workspaceId)
            throw e
          })
        : undefined
  )
  return { url, ...rest }
}

export function useStorageMeta(filePath?: string) {
  const { workspace } = useWorkspace()
  const { data: meta, ...rest } = useSWR(
    mediaSwrKey.storageMeta(workspace?.id, filePath),
    ([_, workspaceId, filePath]) =>
      workspaceId && filePath ? getFileMeta(filePath, workspaceId) : undefined
  )
  return { meta, ...rest }
}

export async function createImgSrc(file: File): Promise<string> {
  return new Promise(res => {
    const reader = new FileReader()
    reader.onload = () => {
      const blob = new Blob([reader.result as string], { type: 'image/jpeg' })
      const url = window.URL.createObjectURL(blob)
      res(url)
    }
    reader.readAsArrayBuffer(file)
  })
}

export function processImageForStorage(imageData: string) {
  //@ts-ignore
  const imageBuffer = imageData.imageBuffer
  const binaryString = atob(imageBuffer)
  const binaryLen = binaryString.length
  const bytes = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  //@ts-ignore
  const contentType = imageData.imageType

  if (!contentType) {
    throw new Error('Content type is null')
  }
  const blob = new Blob([bytes], { type: contentType })
  const fileExtension = contentType.split('/')[1]
  const file = new File([blob], `image.${fileExtension}`, { type: contentType })

  return file
}

export async function getImageFromUrl(siteUrl: string) {
  const timeout = new Promise((_, reject) =>
    setTimeout(() => reject(new Error('Request timed out')), 3000)
  )

  try {
    const imageResponse = (await Promise.race([
      getImageFromSiteUrl({ url: siteUrl }),
      timeout,
    ])) as HttpsCallableResult<string>

    const imageData = imageResponse.data
    const file = processImageForStorage(imageData)

    return file
  } catch (error) {
    return undefined
  }
}

export async function directUploadImageToStorage(
  userId: string,
  siteUrl: string,
  filePath: string,
  eventId: string
) {
  const file = await getImageFromUrl(siteUrl)
  if (file) {
    await uploadFile(filePath, file)
    await updateDoc(userId, 'events', { id: eventId, hasImage: true })
  }
}
