import { AlertProvider, AuthProvider } from 'components/providers'
import { RouterProvider } from 'react-router-dom'
import router, { routes } from './utils/routing'

function App() {
  return (
    <AlertProvider>
      <AuthProvider routes={routes}>
        <RouterProvider router={router} />
      </AuthProvider>
    </AlertProvider>
  )
}

export default App
