import { Flex, Text, useDisclosure } from '@chakra-ui/react'
import { Loader } from '@googlemaps/js-api-loader'
import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { Location } from 'types'
import { sleep } from 'utils'
import { DebouncedTextField, DebouncedTextFieldProps, MapPinIcon, Popover, PopoverProps } from '.'

export interface Props extends Omit<DebouncedTextFieldProps, 'onChange' | 'value'> {
  value?: Location
  popoverProps?: Omit<PopoverProps, 'children' | 'triggerNode'>
  onChange: (location?: Location) => void
}

export default function LocationAutocomplete({ popoverProps, onChange, value, ...rest }: Props) {
  const getSuggestions = useGetSuggestions()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [search, setSearch] = useState(locationText(value))
  const [suggestions, setSuggestions] = useState<Location[]>()
  const [selectedIndex, setSelectedIndex] = useState<number>()

  const didSelect = useRef(false)

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (!suggestions) return

    switch (e.key) {
      case 'ArrowUp': {
        e.preventDefault()
        setSelectedIndex(state => (!state ? suggestions.length - 1 : state - 1))
        break
      }
      case 'ArrowDown':
      case 'Tab': {
        e.preventDefault()
        setSelectedIndex(state => (state === suggestions.length - 1 ? 0 : (state ?? -1) + 1))
        break
      }
      case 'Enter': {
        const selected = selectedIndex != null ? suggestions?.[selectedIndex] : undefined
        if (selected) handleSelect(selected)
        break
      }
      case 'Escape': {
        e.preventDefault()
        handleCancel()
        break
      }
    }
  }

  function handleCancel() {
    setSearch(locationText(value))
    setSuggestions(undefined)
    onClose()
  }

  async function handleSelect(suggestion: Location) {
    didSelect.current = true
    setSearch(locationText(suggestion))
    setSuggestions(undefined)
    onChange(suggestion)
    onClose()
  }

  return (
    <Popover
      triggerNode={
        <DebouncedTextField
          controlled
          value={search}
          leftElement={{ children: <MapPinIcon type="outline" /> }}
          placeholder="Search for city.."
          autoComplete="off"
          onFocus={e => e.target.select()}
          onBlur={async () => {
            // Wait to see if user clicked on a suggestion
            await sleep(100)
            if (didSelect.current) return (didSelect.current = false)

            handleCancel()
          }}
          onChange={v => {
            onOpen()
            setSelectedIndex(undefined)
            setSearch(v)
            getSuggestions(v).then(setSuggestions)
          }}
          onKeyDown={handleKeyDown}
          {...rest}
        />
      }
      contentProps={{ borderRadius: 'xs' }}
      matchWidth
      onClose={onClose}
      hideClose
      gutter={1}
      isOpen={isOpen && Boolean(suggestions)}
      autoFocus={false}
      {...popoverProps}
    >
      <Flex direction="column">
        {suggestions?.length ? (
          suggestions.map((suggestion, index) => {
            const text = locationText(suggestion)

            return (
              <Flex
                key={text}
                align="center"
                gap={4}
                p={2}
                cursor="pointer"
                bg={index === selectedIndex ? 'gray.100' : 'transparent'}
                _hover={{ bg: 'gray.100' }}
                onClick={() => handleSelect(suggestion)}
              >
                <MapPinIcon type="outline" />
                <Text>{text}</Text>
              </Flex>
            )
          })
        ) : (
          <Text py={2} px={4} color="gray.500">
            No results found
          </Text>
        )}
      </Flex>
    </Popover>
  )
}

const { VITE_GOOGLE_MAPS_API } = import.meta.env

function useGetSuggestions() {
  const [api, setApi] = useState<google.maps.PlacesLibrary>()
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>()

  useEffect(() => {
    if (!VITE_GOOGLE_MAPS_API)
      return console.error('Missing VITE_GOOGLE_MAPS_API in environment file')

    const loader = new Loader({
      apiKey: VITE_GOOGLE_MAPS_API,
      version: 'weekly',
      libraries: ['places'],
    })

    loader.importLibrary('places').then(api => {
      setApi(api)
      setSessionToken(new api.AutocompleteSessionToken())
    })
  }, [])

  async function getSuggestions(input?: string) {
    if (!api || !sessionToken || !input) return

    const request: google.maps.places.AutocompleteRequest = {
      includedPrimaryTypes: ['locality'],
      input,
      language: 'en-US',
      sessionToken,
    }

    const { suggestions } =
      (await api.AutocompleteSuggestion.fetchAutocompleteSuggestions(request)) || {}
    if (!suggestions) return

    return suggestions.map(({ placePrediction }) => {
      const { mainText, secondaryText } = placePrediction || {}
      const city = mainText?.toString() ?? ''
      const secondary = secondaryText?.toString() ?? ''
      const [state, country] = secondary.split(', ')
      return country ? { city, country, state } : { city, country: state }
    })
  }

  return getSuggestions
}

const locationText = (l?: Location) => (l ? `${l.city}, ${l.state || l.country}` : '')
