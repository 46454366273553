import { Flex, SimpleGrid, Spacer, Text, useColorMode } from '@chakra-ui/react'
import {
  Button,
  DragList,
  IconButton,
  InputContainer,
  LightModeBox,
  MediaInput,
  Panel,
  TextField,
} from 'components'
import { CheckCircleIcon, DotsDragIcon, XMarkIcon } from 'components/icons'
import { AuthContext } from 'components/providers'
import { isEqual } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { IFacility, ITemplate } from 'types'
import { cleanObject, mergeObjects, randomString, updateDoc, useDoc } from 'utils'
import { useFacility } from '../../utils/facilityUtils'
import TemplatePreviewButton from '../TemplatePreviewButton'

interface Draft extends Omit<IFacility, 'visitorNames'> {
  namesWithId: IdAndValue[]
}

interface IdAndValue {
  id: string
  value: string
}

const maxWidth = 1000

export default function FacilitySettings() {
  const { colorMode } = useColorMode()
  const { user } = useContext(AuthContext)
  const { facility } = useFacility()
  const [templateDoc] = useDoc('templates', facility?.template?.id)

  const [draft, setDraft] = useState<Draft | null>(null)
  const [uneditedDraft, setUneditedDraft] = useState<Draft | null>(null)
  const [missingTemplate, setMissingTemplate] = useState(false)
  const [newVisitorName, setNewVisitorName] = useState('')

  useEffect(() => {
    let missingTemplateTimeout: Timer
    if (!facility) return

    if (!facility.template?.id)
      missingTemplateTimeout = setTimeout(() => setMissingTemplate(true), 1000)

    const { visitorNames, ...rest } = facility
    const namesWithId = visitorNames?.map(name => ({ id: randomString(), value: name })) ?? []
    setDraft({ ...rest, namesWithId })
    setUneditedDraft({ ...rest, namesWithId })

    return () => clearTimeout(missingTemplateTimeout)
  }, [facility])

  if (!draft?.template?.id) return missingTemplate ? <Text>Missing template</Text> : null

  const { companyName = '', namesWithId, title } = draft
  const template = mergeObjects(templateDoc, draft.template)
  const screensaver = {
    default: templateDoc?.screensaverMedia?.items?.[0]?.id,
    current: template?.screensaverMedia?.items?.[0]?.id,
  }
  const background = {
    default: templateDoc?.backgroundMedia,
    current: template?.backgroundMedia,
  }
  const hasEdits = !isEqual(draft, uneditedDraft)

  function update(body: Partial<Draft>) {
    setDraft(s => cleanObject({ ...s!, ...body }))
  }

  function updateTemplate(body: Partial<ITemplate>) {
    const template = cleanObject({ ...draft!.template!, ...body })
    update({ template })
  }

  function handleAddVisitor() {
    const value = newVisitorName.trim()
    update({ namesWithId: [...namesWithId, { id: randomString(), value }] })
    setNewVisitorName('')
  }

  function updateScreensaver(id?: string | null) {
    updateTemplate({
      screensaverMedia: {
        ...draft?.template?.screensaverMedia,
        items: id ? [{ id }] : id === null ? [] : undefined,
      },
    })
  }

  function updateBackground(id?: string | null) {
    updateTemplate({
      backgroundMedia: id,
      screensaverMedia: { ...draft?.template?.screensaverMedia, initialImage: id },
    })
  }

  async function handleSave() {
    const { namesWithId, ...rest } = draft!
    const visitorNames = namesWithId.map(({ value }) => value)
    await updateDoc(user!.id, 'facilities', { visitorNames, ...rest })
  }

  return (
    <LightModeBox flex={1} display="flex">
      <Panel mx={{ base: 4, sm: 0 }} flex={1}>
        <Flex maxWidth={maxWidth} flex={1} direction="column" maxW="800px" gap={10} p={6}>
          <Flex gap={2}>
            <Text textStyle="h4">Settings</Text>
            <Spacer />
            <Button
              isDisabled={!hasEdits}
              variant="outline"
              leftIcon={<CheckCircleIcon type="solid" />}
              onClick={handleSave}
            >
              Save
            </Button>
            <TemplatePreviewButton
              colorMode={colorMode}
              facilityId={facility?.id}
              isDisabled={hasEdits}
              label="Facility Preview Build"
              previewTitle="Facility Preview"
            />
          </Flex>
          <SimpleGrid columns={{ base: 1, lg: 2 }} gap={8}>
            <Flex direction="column" gap={6}>
              <TextField
                label="Header"
                borderRadius="sm"
                bg="white"
                value={companyName}
                onChange={e => update({ companyName: e.target.value })}
              />

              <InputContainer label="Visitor Names">
                <VisitorNames
                  items={namesWithId}
                  onChange={names => update({ namesWithId: names })}
                />

                <Flex gap={2}>
                  <TextField
                    borderRadius="sm"
                    bg="white"
                    placeholder="Enter Name..."
                    value={newVisitorName}
                    onChange={e => setNewVisitorName(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' && handleAddVisitor()}
                  />
                  <Button palette="primary" isDisabled={!newVisitorName} onClick={handleAddVisitor}>
                    Add
                  </Button>
                </Flex>
              </InputContainer>
            </Flex>
            <Flex direction="column" gap={6}>
              <MediaInput
                label="Screensaver"
                id={screensaver.current}
                type="video"
                onSelect={id => updateScreensaver(id)}
                onClear={
                  screensaver.current && screensaver.default
                    ? () => updateScreensaver(null)
                    : undefined
                }
                onReset={
                  screensaver.current !== screensaver.default
                    ? () => updateScreensaver()
                    : undefined
                }
              />
              <MediaInput
                label="Background"
                imageContainerProps={{
                  background:
                    'linear-gradient(308deg, #5B9C85 21.33%, #3E7F6D 33.42%, #1A2E73 87.76%)',
                }}
                id={background.current || undefined}
                type="image"
                onSelect={id => updateBackground(id)}
                onClear={
                  background.current && background.default
                    ? () => updateBackground(null)
                    : undefined
                }
                onReset={
                  background.current !== background.default ? () => updateBackground() : undefined
                }
              />
            </Flex>
          </SimpleGrid>
        </Flex>
      </Panel>
    </LightModeBox>
  )
}

interface VisitorNamesProps {
  items: IdAndValue[]
  onChange: (names: IdAndValue[]) => void
}

function VisitorNames({ items, onChange }: VisitorNamesProps) {
  if (!items.length) return null

  return (
    <DragList
      containerProps={{ gap: 2, mb: 2 }}
      items={items}
      onChange={items => onChange(items as IdAndValue[])}
      renderItem={item => (
        <Flex
          flex={1}
          gap={2}
          cursor="grab"
          align="center"
          justify="space-between"
          borderRadius={4}
          overflow="hidden"
        >
          <DotsDragIcon />
          <TextField
            flex={1}
            fontSize="md"
            bg="white"
            borderRadius="sm"
            rightElement={{
              pointerEvents: 'all',
              children: (
                <IconButton
                  variant="ghost"
                  color="inherit"
                  size="sm"
                  aria-label="Remove name"
                  icon={<XMarkIcon />}
                  onClick={() => onChange(items.filter(i => i.id !== item.id))}
                />
              ),
            }}
            noOfLines={1}
            onKeyDown={e => e.key === ' ' && e.stopPropagation()}
            onFocus={e => e.target.select()}
            value={item.value}
            onChange={e =>
              onChange(items.map(i => (i.id === item.id ? { ...i, value: e.target.value } : i)))
            }
          />
        </Flex>
      )}
    />
  )
}
