import { Box, BoxProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { fromSize, IconSize } from '../utils/iconUtils'

export interface Props extends BoxProps {
  size?: IconSize
}

const Icon = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { size = 'md', fontSize, ...rest } = props

  return (
    <Box
      as="span"
      className="material-symbols-outlined"
      fontSize={fontSize || fromSize(size)}
      ref={ref}
      {...rest}
    />
  )
})

export default Icon
