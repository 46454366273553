import { Flex, Image, Spacer, useToast } from '@chakra-ui/react'
import {
  Button,
  Dialog,
  DialogProps,
  FileDrop,
  IconButton,
  LinkIcon,
  LoaderCover,
  PencilSquareIcon,
  TrashIcon,
} from 'components'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { MediaType } from 'types'
import {
  acceptedImages,
  acceptedVideos,
  createImgSrc,
  deleteFile,
  getImageFromUrl,
  typeFromFile,
  uploadFile,
  useStorageMeta,
  useStorageUrl,
  useWorkspace,
} from 'utils'
import { useAlert } from '../providers'

export interface Props extends Omit<DialogProps, 'children' | 'onClose'> {
  defaultImage: string
  /** Path to image from workspace */
  filePath: string
  noFile?: boolean
  siteUrl?: string
  onClose: (action: FileAction) => void
  /** Update the media file */
  newMedia?: File
  type?: MediaType
}

type FileAction = 'save' | 'cancel' | 'delete'

interface NewImage {
  file: File
  url: string
}

export default function SingleUseMediaDialog({
  defaultImage,
  filePath,
  siteUrl,
  newMedia: controlledNewMedia,
  noFile,
  onClose,
  type,
  ...rest
}: Props) {
  const { confirm } = useAlert()
  const toast = useToast()
  const { workspace } = useWorkspace()

  const [newMedia, setNewMedia] = useState<NewImage | undefined>()
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)

  const { url } = useStorageUrl(noFile ? undefined : filePath)
  const { meta } = useStorageMeta(noFile ? undefined : filePath)
  const fullFilePath = `Workspaces/${workspace!.id}/${filePath}`
  const typeFromMeta = meta?.contentType?.includes('image') ? 'image' : 'video'
  const fileType = type || (newMedia ? typeFromFile(newMedia?.file) : typeFromMeta)

  useEffect(() => {
    if (!controlledNewMedia) return

    handleFiles([controlledNewMedia])
  }, [controlledNewMedia])

  function handleFiles(files: File[]) {
    const file = files[0]
    createImgSrc(file).then(url => setNewMedia({ file, url }))
    toast({ status: 'success', description: 'Updated media' })
    setLoading(false)
  }

  async function handleDone() {
    if (!newMedia) return onClose('cancel')

    await uploadFile(fullFilePath, newMedia.file, setProgress)
    handleClose('save')
  }

  function handleClose(action: FileAction) {
    onClose(action)
    setNewMedia(undefined)
    setProgress(0)
  }

  async function useImagefromUrl(siteUrl: string) {
    setLoading(true)
    const file = await getImageFromUrl(siteUrl)
    if (file) handleFiles([file])
    else {
      toast({
        status: 'error',
        description: 'Could not load image from event url',
      })
      setLoading(false)
    }
  }

  async function handleDelete() {
    const response = await confirm('Remove media?', { cancelText: 'No', okText: 'Remove' })
    if (!response) return

    setProgress(1)
    await deleteFile(fullFilePath)
    handleClose('delete')
  }

  return (
    <Dialog
      title="Manage Media"
      closeOnOverlayClick={!newMedia}
      onClose={() => handleClose('cancel')}
      bodyProps={{ display: 'flex', mb: 6, px: 6, overflow: 'hidden' }}
      contentProps={{ width: '554px', maxWidth: '80%', maxHeight: '80%', height: '629px' }}
      {...rest}
    >
      <LoaderCover display="flex" flex={1} show={progress > 0} progress={progress}>
        <FileDrop
          display="flex"
          flex={1}
          onDrop={handleFiles}
          options={{
            accept: {
              ...(type !== 'image' && acceptedVideos),
              ...(type !== 'video' && acceptedImages),
            },
          }}
          alignItems="stretch"
        >
          {({ open }) => (
            <Flex direction="column" flex={1} gap={4}>
              <Flex
                flex={1}
                align="center"
                justify="center"
                background="gray.100"
                overflow="hidden"
              >
                <LoaderCover show={loading}>
                  {fileType === 'video' ? (
                    <ReactPlayer {...fullHW} url={newMedia?.url || url} controls playing />
                  ) : (
                    <Image
                      {...fullHW}
                      draggable={false}
                      objectFit="contain"
                      src={newMedia?.url || (!noFile ? url : defaultImage)}
                    />
                  )}
                </LoaderCover>
              </Flex>

              <Flex align="center" gap={2}>
                {!noFile && (
                  <IconButton
                    variant="outline"
                    palette="danger"
                    aria-label="Delete"
                    icon={<TrashIcon />}
                    onClick={handleDelete}
                  />
                )}
                <Button variant="outline" leftIcon={<PencilSquareIcon />} onClick={() => open()}>
                  {!noFile || newMedia ? 'Replace' : 'Add'}
                </Button>

                {siteUrl && (
                  <Button
                    variant="outline"
                    leftIcon={<LinkIcon />}
                    onClick={() => useImagefromUrl(siteUrl)}
                  >
                    Load from URL
                  </Button>
                )}

                <Spacer />
                {newMedia && (
                  <Button variant="outline" onClick={() => handleClose('cancel')}>
                    Cancel
                  </Button>
                )}
                <Button palette="primary" onClick={handleDone}>
                  {newMedia ? 'Save' : 'Done'}
                </Button>
              </Flex>
            </Flex>
          )}
        </FileDrop>
      </LoaderCover>
    </Dialog>
  )
}

const fullHW = { height: '100%', width: '100%' }
