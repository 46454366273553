import { Flex, Text } from '@chakra-ui/react'
import { ChartProps } from 'types'
import { BarChart, ComboChart, PieChart } from './charts'
import './chartStyle.css'

export default function Chart(props: ChartProps) {
  return (
    <Flex flex={1} direction="column" overflow="hidden">
      <ChartVisual {...props} />
    </Flex>
  )
}

function ChartVisual(props: ChartProps) {
  switch (props.type) {
    case 'pie':
      return <PieChart {...props} />
    case 'combo':
      return <ComboChart {...props} />
    case 'bars':
      return <BarChart {...props} />
    default:
      return <Text>chart missing</Text>
  }
}
