import { Box, BoxProps, CircularProgress, Flex } from '@chakra-ui/react'

export interface Props extends BoxProps {
  show: boolean
  /** (0-100) */
  progress?: number
}

/** Show a loading cover over content preventing user from interacting with content */
export default function LoaderCover({ show, children, progress, ...rest }: Props) {
  return (
    <Box position="relative" {...rest}>
      {children}

      {show && (
        <Flex
          backgroundColor="#FFFFFF88"
          position="absolute"
          inset={0}
          align="center"
          justify="center"
        >
          <CircularProgress isIndeterminate={progress == null} value={progress} />
        </Flex>
      )}
    </Box>
  )
}
