import {
  AccordionButtonProps,
  CircularProgress,
  Flex,
  Grid,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  BuildingOfficeIcon,
  Chart,
  ClockIcon,
  FireIcon,
  RectangleStackIcon,
  UsersIcon,
} from 'components'
import { isValidElement, useEffect, useState } from 'react'
import { IUserSession, LeadTemperature, SeriesData } from 'types'
import { EventStatTile } from '../components/event'
import { leadTemperatures } from '../components/event/EventLeads'
import { useEvent } from '../utils/eventUtils'
import {
  aggreagteSeriesDataAsOther,
  aggregateSessionsForField,
  averageSessionsOverDayAndDevice,
  getAverageStepDurations,
} from '../utils/userSessionUtils'

export default function AnalyticsPage() {
  const { colorMode } = useColorMode()
  const { sessions } = useEvent()
  const [stepDurations, setStepDurations] = useState<SeriesData[]>([])
  const [avgSessionDuration, setAvgSessionDuration] = useState<number>(0)
  const [sessionOptIns, setSessionOptIns] = useState<SeriesData[]>([])
  const [sessionFollowUps, setSessionFollowUps] = useState<SeriesData[]>([])
  const [sessionOptInsCount, setSessionOptInsCount] = useState(0)
  const [sessionFollowUpsCount, setSessionFollowUpsCount] = useState(0)
  const [status, setStatus] = useState<string>('loading')
  const [temps, setTemps] = useState<SeriesData[]>([])
  const [tempCounts, setTempCounts] = useState(0)
  const [traitCharts, setTraitCharts] = useState<Record<string, SeriesData[]>>({})
  const [sessionByDayAndDevice, setSessionByDayAndDevice] = useState<SeriesData[]>([])
  const [sessionCompanies, setSessionCompanies] = useState<SeriesData[]>([])
  const [sessionDevices, setSessionCreators] = useState<string[]>([])
  const [sessionPrograms, setSessionPrograms] = useState<SeriesData[]>([])

  const limCount = 7
  const iconColor = colorMode === 'light' ? 'primary.500' : 'white'

  useEffect(() => {
    if (!sessions) return

    const fetchAggregatedData = async () => {
      const sessionOptInsFormatted = aggregateSessionsForField({
        sessions,
        field: 'lead',
        nestedField: 'optIn',
        blankFieldLabel: 'Unanswered',
      }).map(session => ({
        ...session,
        label: session.label === 'True' ? 'Yes' : session.label === 'False' ? 'No' : session.label,
      }))
      setSessionOptIns(sessionOptInsFormatted)
      setSessionOptInsCount(
        sessionOptInsFormatted
          .filter(optIn => optIn.label === 'Yes')
          .reduce((total, optIn) => total + (optIn.series?.[0]?.value || 0), 0)
      )

      const sessionFollowUpsFormatted = aggregateSessionsForField({
        sessions,
        field: 'lead',
        nestedField: 'followUp',
        blankFieldLabel: 'Unanswered',
      }).map(session => ({
        ...session,
        label: session.label === 'True' ? 'Yes' : session.label === 'False' ? 'No' : session.label,
      }))
      setSessionFollowUps(sessionFollowUpsFormatted)
      setSessionFollowUpsCount(
        sessionFollowUpsFormatted
          .filter(optIn => optIn.label === 'Yes')
          .reduce((total, optIn) => total + (optIn.series?.[0]?.value || 0), 0)
      )

      const sessionTemps = aggregateSessionsForField({
        sessions,
        field: 'lead',
        nestedField: 'temp',
        blankFieldLabel: 'unset',
      })
      setTemps(sessionTemps)
      setTempCounts(
        sessionTemps
          .filter(optIn => optIn.label !== 'Unset')
          .reduce((total, optIn) => total + (optIn.series?.[0]?.value || 0), 0)
      )

      setSessionCompanies(
        aggregateSessionsForField({
          sessions,
          field: 'lead',
          nestedField: 'company',
        })
      )

      const { stepDurations, totalDuration } = getAverageStepDurations(sessions)
      setStepDurations(stepDurations)
      setAvgSessionDuration(totalDuration)

      const updatedTraitCharts: Record<string, SeriesData[]> = {}
      const traits = getAllTraits(sessions)
      traits.forEach(trait => {
        updatedTraitCharts[trait] = aggregateSessionsForField({
          sessions,
          field: 'traitSelections',
          nestedField: trait,
          sortBy: 'count',
        })
      })
      setTraitCharts(updatedTraitCharts)

      const { avgSessionsOverDays, devices } = await averageSessionsOverDayAndDevice(sessions)
      setSessionCreators(devices)
      setSessionByDayAndDevice(avgSessionsOverDays)

      if (sessions[sessions.length - 1]?.app === 'explorer') {
        setSessionPrograms(
          aggregateSessionsForField({
            sessions,
            field: 'metrics',
            nestedField: 'Actions',
            sumField: 'duration',
            aggregationTypes: ['count', 'avg'],
            sortBy: 'count',
          })
        )
      }

      setStatus('success')
    }

    fetchAggregatedData()
  }, [sessions])

  function getAllTraits(sessions: IUserSession[]) {
    const traits: string[] = []
    for (const session of sessions) {
      if (session.app !== 'explorer') continue

      if (Array.isArray(session.traitSelections)) {
        for (const trait of session.traitSelections) {
          if (!traits.includes(trait.groupTitle)) traits.push(trait.groupTitle)
        }
      }
    }
    return traits
  }

  const customTempOrder = Object.keys(leadTemperatures) as LeadTemperature[]
  const sortedTemps = temps.sort((a, b) => {
    const aIndex = customTempOrder.indexOf(a.label.toLowerCase() as LeadTemperature)
    const bIndex = customTempOrder.indexOf(b.label.toLowerCase() as LeadTemperature)
    return aIndex - bIndex
  })

  let items: AccordionItem[] = [
    {
      title: 'Overview',
      content: (
        <Flex direction="column">
          <Grid templateColumns={`repeat(auto-fill, minmax(270px, 1fr))`} gap={6}>
            {sessions && sessions.length > 0 && (
              <EventStatTile
                label={'Total Sessions'}
                num={sessions!.length}
                icon={<UsersIcon color={iconColor} />}
              />
            )}
            {sessionCompanies.length > 0 && (
              <EventStatTile
                label={'Companies'}
                num={sessionCompanies.length}
                icon={<BuildingOfficeIcon color={iconColor} />}
              ></EventStatTile>
            )}

            {stepDurations.length < 3 &&
              stepDurations.map(({ label, series }) => {
                const duration = series[0]?.value
                return (
                  duration > 0 && (
                    <EventStatTile
                      key={label}
                      label={`Average ${label} Duration`}
                      num={duration}
                      icon={<ClockIcon color={iconColor} />}
                    />
                  )
                )
              })}
          </Grid>

          <Flex gap={6}>
            {sortedTemps.length > 1 && (
              <EventStatTile
                mt={6}
                width="80%"
                label="Lead Temperatures"
                num={tempCounts}
                alignItems="stretch"
                icon={<FireIcon color={iconColor} />}
              >
                <Flex gap={5} px={5}>
                  <Chart
                    type="pie"
                    data={temps}
                    legend={{ customLegend: true }}
                    seriesInfo={[{ label: 'Lead Temperatures' }]}
                  />
                  <Flex direction="column" gap={3}>
                    {sortedTemps.map(temp => (
                      <TempLabel
                        key={temp.label}
                        label={temp.label}
                        num={temp.series[0].value}
                        icon={leadTemperatures[temp.label.toLowerCase() as LeadTemperature].icon}
                      />
                    ))}
                  </Flex>
                </Flex>
              </EventStatTile>
            )}

            {stepDurations.length >= 3 && (
              <EventStatTile
                mt={6}
                w="100%"
                label="Average Session Duration"
                num={avgSessionDuration}
                alignItems="stretch"
                icon={<RectangleStackIcon color={iconColor} />}
              >
                <Chart
                  type="bars"
                  data={stepDurations}
                  isStacked
                  seriesInfo={[{ label: 'Step Durations' }]}
                />
              </EventStatTile>
            )}
          </Flex>

          {sessionByDayAndDevice.length > 0 && (
            <EventStatTile
              mt={6}
              label="Sessions By Day"
              num={Math.round(sessions!.length / sessionByDayAndDevice.length)}
              height="500px"
              alignItems="stretch"
              icon={<RectangleStackIcon color={iconColor} />}
            >
              <Chart
                type="combo"
                data={sessionByDayAndDevice}
                seriesInfo={sessionDevices.map(label => ({ label, type: 'line' }))}
                legend={{ show: true }}
              />
            </EventStatTile>
          )}
        </Flex>
      ),
    },
    {
      title: 'Content',
      content: (
        <>
          {sessionPrograms.length > 0 && (
            <EventStatTile
              key="programChart"
              alignItems="stretch"
              height="500px"
              label="Programs by Session Count & Avg. Time"
              num={sessionPrograms.length}
              icon={<RectangleStackIcon color={iconColor} />}
            >
              <Chart
                type="combo"
                data={sessionPrograms}
                orientation="column"
                seriesInfo={[
                  { label: 'Count', type: 'bars', axis: 'primary' },
                  { label: 'Average duration', type: 'scatter', axis: 'secondary' },
                ]}
                primaryAxisTitle="Count"
                secondaryAxisTitle="Duration (seconds)"
              />
            </EventStatTile>
          )}
        </>
      ),
    },
    {
      title: 'User Traits',
      content: (
        <Grid templateColumns={`repeat(auto-fill, minmax(350px, 1fr))`} gap={6}>
          {Object.keys(traitCharts).map(
            trait =>
              traitCharts[trait]?.length > 0 && (
                <EventStatTile
                  key={trait}
                  alignItems="stretch"
                  height="325px"
                  label={trait}
                  num={traitCharts[trait].length}
                  icon={<RectangleStackIcon color={iconColor} />}
                >
                  <Chart
                    type={traitCharts[trait]?.length <= 5 ? 'pie' : 'combo'}
                    data={
                      traitCharts[trait]?.length > limCount
                        ? aggreagteSeriesDataAsOther(traitCharts[trait], 'Other', limCount)
                        : traitCharts[trait]
                    }
                    seriesInfo={[{ label: 'Count' }]}
                  />
                </EventStatTile>
              )
          )}
        </Grid>
      ),
    },
    {
      title: 'User Responses',
      content: (
        <Grid templateColumns={`repeat(auto-fill, minmax(320px, 1fr))`} gap={6}>
          {sessionOptInsCount > 0 && (
            <EventStatTile
              label={'Opt In'}
              num={sessionOptInsCount}
              alignItems="stretch"
              icon={<UsersIcon color={iconColor} />}
            >
              <Chart type="pie" data={sessionOptIns} seriesInfo={[{ label: 'Count' }]} />
            </EventStatTile>
          )}

          {sessionFollowUpsCount > 0 && (
            <EventStatTile
              label={'Follow Ups'}
              num={sessionFollowUpsCount}
              alignItems="stretch"
              icon={<UsersIcon color={iconColor} />}
            >
              <Chart type="pie" data={sessionFollowUps} seriesInfo={[{ label: 'Count' }]} />
            </EventStatTile>
          )}
        </Grid>
      ),
    },
  ]

  items = items.filter(({ content }) => {
    //@ts-ignore
    const { children } = content.props
    return Array.isArray(children) ? children.some(isValidElement) : isValidElement(children)
  })

  return (
    <Flex p={4} direction="column" gap={5} overflowY="auto" mt={0.5}>
      {status === 'loading' ? (
        <Flex p={20} justifyContent="center" maxW={'100%'}>
          <CircularProgress isIndeterminate />
        </Flex>
      ) : (
        <Accordion
          allowMultiple
          alignSelf="stretch"
          items={items}
          defaultIndex={Array.from({ length: items.length }, (_, i) => i)}
          buttonProps={accordionButtonProps}
        />
      )}
    </Flex>
  )
}

const accordionButtonProps: AccordionButtonProps = {
  backgroundColor: 'transparent',
}

interface TempLabelProps {
  label: string
  num: number
  icon: JSX.Element
}

function TempLabel({ label, num, icon }: TempLabelProps) {
  return (
    <Flex direction="column">
      {/* <EventStatBanner key={label} label={label} num={num} icon={icon} /> */}
      <Flex gap={2} align="center">
        {icon}
        <Text textStyle="bodySmall">{label}</Text>
      </Flex>
      <Text textStyle="h2" textAlign="center">
        {num}
      </Text>
    </Flex>
  )
}
