import { Flex, Image, Text, useColorMode, useToast } from '@chakra-ui/react'
import {
  Backdrop,
  Button,
  CheckCircleIcon,
  DebouncedTextField,
  HomeIcon,
  LightModeBox,
} from 'components'
import { AuthContext } from 'components/providers'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { resetPassword } from 'utils'

export default function ResetPassword() {
  const { user } = useContext(AuthContext)
  const { colorMode } = useColorMode()
  const [email, setEmail] = useState(user?.email!)
  const [sentEmail, setSentEmail] = useState(false)
  const navigate = useNavigate()
  const toast = useToast()

  function handleReset() {
    resetPassword(email.trim())
      .then(() => toast({ title: 'Password reset email sent', status: 'success' }))
      .catch(handleError)
  }

  function handleError(e: any) {
    let title = 'An error occurred'
    switch (e.code) {
      case 'auth/invalid-credential':
        title = 'Invalid credentials'
        break
      case 'auth/invalid-email':
        title = 'Invalid email address'
        break
      case 'auth/missing-email':
        title = 'Please enter an email address'
        break
      case 'auth/missing-password':
        title = 'Please enter a password'
        break
      case 'auth/too-many-requests':
        title = 'Too many attempts. Try again later'
        break
      default:
        console.error(e)
    }
    toast({ title, status: 'error' })
  }

  return (
    <Backdrop flex={'auto'}>
      <Flex direction={'column'} width={'100%'} p={10} align={'center'} gap={6}>
        <Image
          pr={10}
          mb={5}
          src={
            colorMode === 'light'
              ? '/common/momentify-logo-dark.svg'
              : '/common/momentify-logo-light.svg'
          }
          alt="momentify logo"
        />
        <LightModeBox
          display="flex"
          flexDir="column"
          alignItems="center"
          gap={5}
          p={'50px'}
          bg="rgba(255, 255, 255, 0.54)"
          borderRadius={'40px'}
          boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.06)'}
        >
          {sentEmail && (
            <Flex direction={'column'} gap={2} w={'100%'} align={'center'}>
              <CheckCircleIcon fontSize={'48px'} type="outline" />
              <Text textStyle={{ base: 'h3', sm: 'h2' }}>Email Sent</Text>
              <Text textStyle={'bodySmall'}>
                Check your email and open the link we sent to continue.
              </Text>
            </Flex>
          )}
          {!sentEmail && (
            <>
              <Text textStyle={{ base: 'h3', sm: 'h2' }}>Reset Password</Text>
              <Text textStyle={'bodyMedium'} fontWeight={'normal'} textAlign={'center'}>
                Enter the email associated with your account and we will send you a link to reset
                your password.
              </Text>
              <Flex direction={'column'} gap={2} w={'100%'}>
                <Text textStyle={'bodySmall'}>Email Address</Text>
                <DebouncedTextField
                  onChange={email => setEmail(email)}
                  placeholder="Enter email..."
                  bg={'#fff'}
                  backdropFilter={'blur(27px)'}
                  borderRadius={'10px'}
                  border={'1px solid rgba(23, 23, 23, 0.20)'}
                  showStatus
                  value={user?.email!}
                />
              </Flex>
              <Button
                palette="primary"
                onClick={() => {
                  handleReset()
                  setSentEmail(true)
                }}
              >
                Send Link to Email
              </Button>
            </>
          )}
        </LightModeBox>
        <Flex
          onClick={() => {
            navigate('/')
          }}
          gap={2}
          align={'flex-start'}
        >
          <HomeIcon fontSize={'md'} />
          <Text textStyle={'bodySmall'}>Back to Home</Text>
        </Flex>
      </Flex>
    </Backdrop>
  )
}
