import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  MDXEditorProps,
  RealmPlugin,
  headingsPlugin,
  listsPlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { forwardRef } from 'react'
import './markdownStyle.css'

export interface Props extends MDXEditorProps {
  hideBlockTypes?: boolean
  limitTo1Line?: boolean
  limitTo2Lines?: boolean
}
export type Methods = MDXEditorMethods

const MarkdownEditor = forwardRef<Methods, Props>(
  ({ readOnly, hideBlockTypes, limitTo1Line, limitTo2Lines, markdown, ...rest }, ref) => {
    let plugins: RealmPlugin[] = [headingsPlugin(), listsPlugin(), thematicBreakPlugin()]

    if (!readOnly) {
      plugins.push(
        toolbarPlugin({
          toolbarContents: () => (
            <>
              {!hideBlockTypes && <BlockTypeSelect />}
              <BoldItalicUnderlineToggles />
              <ListsToggle options={['bullet', 'number']} />
            </>
          ),
        })
      )
    }

    return (
      <MDXEditor
        readOnly={readOnly}
        ref={ref}
        plugins={plugins}
        contentEditableClassName={
          limitTo1Line
            ? 'mdxeditor-contentlimited'
            : limitTo2Lines
            ? 'mdxeditor-contentlimited2'
            : 'mdxeditor-contenteditable'
        }
        markdown={parseMarkdown(markdown)}
        {...rest}
      />
    )
  }
)

export default MarkdownEditor

function parseMarkdown(input?: string) {
  if (!input) return ''
  return input.replaceAll(/<br>/gm, '\n')
}
