import { AuthContext, WorkspaceContext } from 'components/providers'
import { where } from 'firebase/firestore'
import { useContext } from 'react'
import {
  NavigateOptions,
  createSearchParams,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import useSWR from 'swr'
import { DisplayUser, IUser, IWorkspace, OmitDBProps, Permission, WorkspaceScope } from 'types'
import { getDocById, getDocs } from './db'

export const WorkspacePath = '/:workspaceId'

/** Go to a workspace */
export function useGoToWorkspace() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  function goToWorkspace(
    workspaceId?: string,
    search?: { [prop: string]: string } | null,
    options?: NavigateOptions
  ) {
    if (!workspaceId) return

    let _search = searchParams.toString()
    if (search) _search = createSearchParams(search).toString()

    navigate({ pathname: generatePath(WorkspacePath, { workspaceId }), search: _search }, options)
  }

  return goToWorkspace
}

export function useSwitchWorkspace() {
  const navigate = useNavigate()

  function switchWorkspace(workspaceId?: string, routePath?: string, options?: NavigateOptions) {
    if (!workspaceId) return

    navigate({ pathname: generatePath(`${WorkspacePath}/${routePath}`, { workspaceId }) }, options)
  }

  return switchWorkspace
}

/** Go back or to root of workspace if default route */
export default function useGoBack() {
  const { workspaceId } = useParams()
  const goToWorkspace = useGoToWorkspace()
  const location = useLocation()
  const navigate = useNavigate()

  function goBack() {
    const firstRoute = location.key === 'default'
    if (firstRoute) goToWorkspace(workspaceId)
    else navigate(-1)
  }

  return goBack
}

export const makeWorkspacesKey = (scope: WorkspaceScope, userId?: string) =>
  ['workspaces', scope, userId] as [string, WorkspaceScope, string]
export const makeWorkspaceKey = (workspaceId?: string) => ['workspace', workspaceId]

export function useWorkspaces(scope: WorkspaceScope) {
  const { user } = useContext(AuthContext)
  const userId = user?.isAdmin ? undefined : user?.id
  const { data, ...rest } = useSWR(makeWorkspacesKey(scope, userId), ([_, scope, userId]) =>
    getWorkspaces(scope, userId)
  )

  return { workspaces: data, ...rest }
}

async function getWorkspaces(scope: WorkspaceScope, userId?: string) {
  const scopeConstraint = where('scope', '==', scope)
  const archiveConstraint = where('isArchived', '==', false)
  if (!userId) return getDocs('Workspace', 0, scopeConstraint, archiveConstraint)
  return getDocs('Workspace', 0, scopeConstraint, where('userIds', 'array-contains', userId))
}

export const useWorkspace = () => useContext(WorkspaceContext)

export const makeEmptyWorkspace = (scope: IWorkspace['scope']): OmitDBProps<IWorkspace> => ({
  pendingUserByEmail: {},
  scope,
  title: '',
  isArchived: false,
  userById: {},
  userIds: [],
  traitTagById: {},
})

export function usePermission(): Permission {
  const { workspace } = useWorkspace()
  const { user, entevatePermission } = useContext(AuthContext)

  if (!workspace || !user) return {}

  if (user.isAdmin) {
    const permission = entevatePermission === 'entevate' ? 'admin' : entevatePermission
    return { content: permission, event: permission, facility: permission, workspace: permission }
  }

  const workspacePermission = workspace.userById[user.id]?.permission

  return {
    content: workspacePermission,
    event: workspacePermission,
    facility: workspacePermission,
    workspace: workspacePermission,
  }
}

export async function getUsers(userIds: string[]): Promise<{ [id: string]: IUser }> {
  const users = (
    await Promise.all(userIds.map(async userId => getDocById('users', userId)))
  ).filter(Boolean) as IUser[]

  return users.reduce((acc, user) => {
    acc[user.id] = user
    return acc
  }, {} as { [id: string]: IUser })
}

export async function getDisplayUser(id: string): Promise<DisplayUser> {
  const userInfo = await getDocById('users', id)
  return { displayName: makeUserDisplayName(userInfo) || '', photoUrl: userInfo?.photoUrl! }
}

export function useDisplayUser(id?: string) {
  const { data, ...rest } = useSWR(['getDisplayUser', id], ([_, id]) =>
    id ? getDisplayUser(id) : undefined
  )
  return { user: data, ...rest }
}

export const makeUserDisplayName = (user?: Partial<IUser> | null) =>
  user?.name || user?.email || user?.id
