import { useColorMode } from '@chakra-ui/react'
import { ReactGoogleChartProps } from 'react-google-charts'
import { ChartProps } from 'types'

export const defaultChartProps: Partial<ReactGoogleChartProps> = {
  width: '100%',
  height: '100%',
}

export const customColors = {
  hot: '#E83A5A',
  cold: '#77B6FF',
  warm: '#E8C23A',
  filler: '#bbbbbb',
}

export const pieCustomSliceColors = (label: string) => {
  switch (label) {
    case 'Hot':
      return customColors.hot
    case 'Cold':
      return customColors.cold
    case 'Warm':
      return customColors.warm
    // filler slices
    case 'Unset':
    case 'Unanswered':
    case 'NA':
      return customColors.filler
  }
}

export function makeDefaultChartOptions(props: ChartProps): ReactGoogleChartProps['options'] {
  const { legend } = props
  const { colorMode } = useColorMode()

  return {
    is3D: false,
    legend: {
      position: legend?.show ? 'end' : 'none',
      textStyle: {
        fontName: 'system-ui',
        color: colorMode === 'light' ? '#333' : '#FFF',
      },
    },
    backgroundColor: '',
    animation: { duration: 1000, easing: 'out' },
    colors: ['#3C4BD3', '#E77522', '#7E4CE1', '#0BBB67', '#1977E5'],
    curveType: 'function',
    hAxis: {
      viewWindow: {
        min: 0,
      },
      textStyle: {
        color: colorMode === 'light' ? '#333' : '#CCC',
      },
      gridlines: {
        color: colorMode === 'light' ? '#c3c3c3' : '#999999',
      },
      minorGridlines: {
        color: colorMode === 'light' ? '#e1e1e1' : '#707070',
      },
      baselineColor: colorMode === 'light' ? '#333' : '#FFF',
      slantedText: false,
      showTextEvery: 1,
    },
    vAxis: {
      viewWindow: {
        min: 0,
      },
      textStyle: {
        color: colorMode === 'light' ? '#333' : '#CCC',
      },
      gridlines: {
        color: colorMode === 'light' ? '#c3c3c3' : '#999999',
      },
      minorGridlines: {
        color: colorMode === 'light' ? '#e1e1e1' : '#707070',
      },
      baselineColor: colorMode === 'light' ? '#333' : '#FFF',
      slantedText: false,
      showTextEvery: 1,
    },
  }
}

export function makeChartData(props: ChartProps): ReactGoogleChartProps['data'] {
  const { data, seriesInfo } = props
  const header = ['Label', ...seriesInfo.map(s => s.label)]
  const body = data.map(d => [d.label, ...d.series.map(s => s.value)])
  return [header, ...body]
}

export function makeStackedChartData(props: ChartProps): ReactGoogleChartProps['data'] {
  const { data, seriesInfo } = props
  const header = [...seriesInfo.map(s => s.label), ...data.map(d => d.label)]
  const body = [...seriesInfo.map(s => s.label), ...data.map(d => d.series[0].value)]
  return [header, body]
}
