import {
  Box,
  BoxProps,
  Menu as ChakraMenu,
  Flex,
  MenuButton,
  MenuDivider,
  MenuDividerProps,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuItemProps,
  MenuList,
  MenuListProps,
  MenuOptionGroup,
  MenuOptionGroupProps,
  MenuProps,
  Portal,
  Text,
  TextProps,
} from '@chakra-ui/react'
import { Fragment, ReactNode } from 'react'
import { ButtonProps, DarkModeBox, LightModeBox } from '.'
import Button from './Button'
import { ChevronDownIcon } from './icons'

export interface Props extends Omit<ButtonProps, 'children' | 'onChange'> {
  /** if provided, show static label in trigger instead of selected value */
  buttonLabel?: ReactNode
  /** Override default color mode */
  colorMode?: 'light' | 'dark'
  label?: string
  listProps?: Omit<MenuListProps, 'children' | 'onChange'>
  menuProps?: Omit<MenuProps, 'children'>
  noPortal?: boolean
  onChange?: (value: string) => void
  options?: MenuOption[]
  /** (if !label) placeholder text when no option is selected */
  placeholder?: string
  value?: string
}

export type MenuOption = CustomItem | MenuItem | MenuDivider | MenuGroup

interface CustomItem extends Omit<BoxProps, 'children'> {
  type: 'custom'
  label: ReactNode
}

interface MenuItem extends Omit<MenuItemProps, 'children' | 'type'> {
  type?: 'item'
  value?: string
  label: ReactNode
}

interface MenuDivider extends MenuDividerProps {
  type: 'divider'
}

interface MenuGroup extends Omit<MenuOptionGroupProps, 'children' | 'type'> {
  type: 'group'
  isOption?: boolean
  groupType?: MenuOptionGroupProps['type']
  options: MenuItem[]
}

export default function Menu({
  buttonLabel,
  colorMode,
  label,
  listProps,
  menuProps,
  noPortal,
  onChange,
  options,
  placeholder = 'Select...',
  value,
  ...rest
}: Props) {
  const selectedItem = getSelectedItem(options, value)
  const _listProps = { ...listProps, options, onChange }

  const Container = noPortal ? Fragment : Portal
  const ColorContainer =
    colorMode === 'light' ? LightModeBox : colorMode === 'dark' ? DarkModeBox : Fragment

  return (
    <ChakraMenu placement="bottom" {...menuProps}>
      {({ isOpen }) => (
        <>
          <Flex direction="column" gap={2}>
            {label && (
              <Text textStyle={'bodySmall'} opacity={0.7}>
                {label}
              </Text>
            )}
            <MenuButton
              isActive={isOpen}
              rightIcon={
                <ChevronDownIcon
                  fontSize="sm"
                  transition="all 200ms ease"
                  transform={`rotateX(${isOpen ? '180' : '0'}deg)`}
                />
              }
              as={Button}
              variant="ghost"
              textAlign="left"
              isTruncated
              {...(rest as any)}
            >
              {buttonLabel || selectedItem?.label || placeholder}
            </MenuButton>
          </Flex>

          <Container>
            <ColorContainer>
              <List {..._listProps} />
            </ColorContainer>
          </Container>
        </>
      )}
    </ChakraMenu>
  )
}

export interface ListProps extends Omit<MenuListProps, 'children' | 'onChange'> {
  options?: MenuOption[]
  onChange?: (value: string) => void
}

function List({ options, onChange, ...rest }: ListProps) {
  if (!options) return null

  return (
    <MenuList {...rest}>
      {options.map((option, index) => {
        switch (option.type) {
          case 'divider': {
            const { type, ...rest } = option
            return <MenuDivider key={index} {...rest} />
          }
          case 'group': {
            const { type, options, onChange: _, groupType, isOption, title, ...rest } = option

            if (isOption) {
              return (
                <MenuOptionGroup
                  key={index}
                  type={groupType}
                  title={title}
                  onChange={val => onChange?.(val as string)}
                  {...rest}
                >
                  {option.options.map(({ value, label, type, onChange: _, ...rest }) => (
                    <MenuItemOption key={value} value={value} {...rest}>
                      <RenderLabel children={label} />
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              )
            } else {
              return (
                <Box key={index} {...rest}>
                  <MenuGroup key={index} title={title}>
                    {option.options.map(({ value = '', label, type, onChange: _, ...rest }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        onClick={() => onChange?.(value)}
                        {...rest}
                      >
                        <RenderLabel children={label} />
                      </MenuItem>
                    ))}
                  </MenuGroup>
                </Box>
              )
            }
          }
          case 'custom': {
            const { type, label, ...rest } = option
            return (
              <Box key={index} {...rest}>
                {label}
              </Box>
            )
          }
          default: {
            const { type, value = '', label, onChange: _, ...rest } = option
            return (
              <MenuItem key={value} value={value} onClick={() => onChange?.(value)} {...rest}>
                <RenderLabel children={label} />
              </MenuItem>
            )
          }
        }
      })}
    </MenuList>
  )
}

function RenderLabel({ children, ...rest }: TextProps) {
  return typeof children === 'string' ? (
    <Text textStyle="bodySmall" fontWeight="normal" {...rest}>
      {children}
    </Text>
  ) : (
    children
  )
}

function getSelectedItem(options?: MenuOption[], selected?: string) {
  if (!selected || !options) return

  for (const option of options) {
    if ((!option.type || option.type === 'item') && option.value === selected) {
      return option
    }

    if (option.type === 'group') {
      const selectedItem = option.options.find(option => option.value === selected)
      if (selectedItem) return selectedItem
    }
  }
}
