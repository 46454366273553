import { Flex } from '@chakra-ui/react'
import {
  Button,
  Collapsible,
  CollapsibleProps,
  DebouncedTextField,
  InputContainer,
  InputContainerProps,
  Tooltip,
} from 'components'
import { IEvent } from 'types'
import { mergeObjects } from 'utils'
import { useEvent } from '../../../utils/eventUtils'

interface Props extends Omit<CollapsibleProps, 'children' | 'label'> {
  event: IEvent
  updateEvent: (body: Partial<IEvent>) => Promise<void>
}

export default function ScreensaverOverrides({ event, updateEvent, ...rest }: Props) {
  const { template } = useEvent()
  const mergedTemplate = mergeObjects(template, event?.template)
  const { screensaverMedia, registration } = mergedTemplate ?? {}

  async function updateRegistration(property: 'title', value?: string) {
    if (!event?.template) return

    let registration = { ...event?.template.registration }
    if (value == null) delete registration[property]
    else registration[property] = { en: value }

    await updateEvent({ template: { ...event?.template, registration } })
  }

  async function updateScreensaverMedia(property: ScreensaverProperty, value?: string) {
    if (!event?.template) return

    let screensaverMedia = { ...event?.template.screensaverMedia }
    if (value == null) delete screensaverMedia[property]
    else screensaverMedia[property] = { en: value }

    await updateEvent({ template: { ...event?.template, screensaverMedia } })
  }

  const registrationTitle = registration?.title?.en || 'Momentify Explorer'

  return (
    <Collapsible label="Explorer Text" {...rest}>
      <Flex direction="column" gap={2} alignSelf="flex-start" mt={1} width="100%" key="nice">
        <Input
          label="Registration Title"
          onChange={v => updateRegistration('title', v)}
          value={registrationTitle}
          valueIsSet={Boolean(event?.template?.registration?.title)}
        />
        <Input
          label="Screensaver Title"
          onChange={v => updateScreensaverMedia('title', v)}
          value={screensaverMedia?.title?.en ?? registrationTitle}
          valueIsSet={Boolean(event?.template?.screensaverMedia?.title)}
        />
        <Input
          label="Screensaver Subtitle"
          onChange={v => updateScreensaverMedia('subtitle', v)}
          value={screensaverMedia?.subtitle?.en || ''}
          valueIsSet={Boolean(event?.template?.screensaverMedia?.subtitle)}
        />
        <Input
          label="Screensaver Help"
          onChange={v => updateScreensaverMedia('helpText', v)}
          value={screensaverMedia?.helpText?.en || 'Touch the screen to begin your journey.'}
          valueIsSet={Boolean(event?.template?.screensaverMedia?.helpText)}
        />
      </Flex>
    </Collapsible>
  )
}

type ScreensaverProperty = 'title' | 'subtitle' | 'helpText'

interface InputProps extends Omit<InputContainerProps, 'children' | 'onChange'> {
  onChange: (value?: string) => void
  value?: string
  valueIsSet: boolean
}

function Input({ onChange, value, valueIsSet, ...rest }: InputProps) {
  return (
    <InputContainer
      labelActions={
        <Tooltip label="Match template value">
          <Button
            visibility={valueIsSet ? 'visible' : 'hidden'}
            variant="ghost"
            size="xs"
            onClick={() => onChange()}
          >
            Reset
          </Button>
        </Tooltip>
      }
      {...rest}
    >
      <DebouncedTextField controlled showStatus value={value ?? ''} onChange={onChange} />
    </InputContainer>
  )
}
