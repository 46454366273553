import {
  Circle,
  CircularProgress,
  Divider,
  Flex,
  Hide,
  SimpleGrid,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { BoltIcon } from 'components'
import { useParams } from 'react-router-dom'
import { useWorkspace } from 'utils'
import global from 'utils/globals'
import noEventDark from '../assets/emptyStates/no_events_dark.svg'
import noEventLight from '../assets/emptyStates/no_events_light.svg'
import CreateNewButton from '../components/CreateNewButton'
import EmptyState from '../components/EmptyState'
import { EventCard, EventStatTile } from '../components/event'
import { getEventsInSections, useEvents } from '../utils/eventUtils'

export default function EventsPage() {
  const { workspaceId } = useParams()
  const { status } = useWorkspace()
  const { colorMode } = useColorMode()
  const { events = [] } = useEvents(workspaceId)
  const { live, upcoming, past } = getEventsInSections(events)

  const iconColor = colorMode === 'light' ? 'primary.500' : 'white'

  return (
    <>
      <Hide below="sm" ssr={false}>
        <Flex
          direction="column"
          gap={4}
          flex="auto"
          mr={5}
          mt={0.5}
          mb={2}
          overflowY="auto"
          overflowX="hidden"
        >
          <Text textStyle="h1" px={5} pt={2}>
            Events
          </Text>
          {status === 'loading' ? (
            <Flex p={20} justifyContent="center" maxW={'100%'}>
              <CircularProgress isIndeterminate />
            </Flex>
          ) : events?.length === 0 ? (
            <EmptyState
              title="Nothing on the calendar—let’s fix that!"
              description="Create an event and get your community engaged."
              iconSVG={colorMode === 'light' ? noEventLight : noEventDark}
              button={<CreateNewButton variant="button" />}
            />
          ) : (
            <>
              {live.length != 0 && (
                <Flex width="100%" direction="column" gap={4}>
                  <Flex direction="row" align="center" gap={2} px={5}>
                    <Circle
                      size="30px"
                      borderWidth="2px"
                      borderColor={colorMode === 'light' ? 'black' : 'white'}
                    >
                      <Text textStyle="bodyMedium">{live.length}</Text>
                    </Circle>
                    <Text textStyle="bodyLarge">Live</Text>
                  </Flex>
                  <SimpleGrid
                    spacing={4}
                    px={5}
                    templateColumns={`repeat(auto-fill, minmax(${global.EVENT_CARD_MIN_WIDTH}, 1fr))`}
                  >
                    {live.map(event => (
                      <EventCard key={event.id} {...event} isLive />
                    ))}
                  </SimpleGrid>
                </Flex>
              )}
              {live.length != 0 && upcoming.length != 0 && (
                <Divider
                  orientation="horizontal"
                  variant="dashed"
                  borderColor={'rgba(163, 174, 221, 1)'}
                  m={3}
                />
              )}

              {upcoming.length != 0 && (
                <Flex width="100%" direction="column" gap={4}>
                  <Flex direction="row" align="center" gap={2} px={5}>
                    <Circle
                      size="30px"
                      borderWidth="2px"
                      borderColor={colorMode === 'light' ? 'black' : 'white'}
                    >
                      <Text textStyle="bodyMedium">{upcoming.length}</Text>
                    </Circle>
                    <Text textStyle="bodyLarge">Upcoming</Text>
                  </Flex>
                  <SimpleGrid
                    spacing={4}
                    px={5}
                    templateColumns={`repeat(auto-fill, minmax(${global.EVENT_CARD_MIN_WIDTH}, 1fr))`}
                  >
                    {upcoming.map(event => (
                      <EventCard key={event.id} {...event} isUpcoming />
                    ))}
                  </SimpleGrid>
                </Flex>
              )}
              {upcoming.length != 0 && past.length != 0 && (
                <Divider
                  orientation="horizontal"
                  variant="dashed"
                  borderColor="rgba(163, 174, 221, 1)"
                  m={3}
                />
              )}

              {past.length != 0 && (
                <Flex width="100%" direction="column" gap={4}>
                  <Flex direction="row" align="center" gap={2} px={5}>
                    <Circle
                      size="30px"
                      borderWidth="2px"
                      borderColor={colorMode === 'light' ? 'black' : 'white'}
                    >
                      <Text textStyle="bodyMedium">{past.length}</Text>
                    </Circle>
                    <Text textStyle="bodyLarge">Past</Text>
                  </Flex>{' '}
                  <SimpleGrid
                    spacing={4}
                    px={5}
                    templateColumns={`repeat(auto-fill, minmax(${global.EVENT_CARD_MIN_WIDTH}, 1fr))`}
                  >
                    {past.map(event => (
                      <EventCard key={event.id} {...event} />
                    ))}
                  </SimpleGrid>
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Hide>
      <Hide above="sm" ssr={false}>
        <Flex
          direction="column"
          m={3}
          mt={0}
          gap={4}
          flex={'auto'}
          overflowY="auto"
          overflowX={'hidden'}
        >
          <Text textStyle="h1" px={5} pt={2}>
            All Events
          </Text>

          {status === 'loading' ? (
            <Flex p={20} justifyContent="center" maxW={'100%'}>
              <CircularProgress isIndeterminate />
            </Flex>
          ) : events?.length === 0 ? (
            <EmptyState
              title="Nothing on the calendar—let’s fix that!"
              description="Create an event and get your community engaged."
              iconSVG={colorMode === 'light' ? noEventLight : noEventDark}
              button={<CreateNewButton variant="button" />}
            />
          ) : (
            <>
              {events.length != 0 && (
                <Flex width={'100%'} direction={'column'} gap={4}>
                  <SimpleGrid columns={2} px={5} spacingX={'44vw'} spacingY={2} width={'5vw'}>
                    <EventStatTile
                      icon={<BoltIcon fontSize={'20px'} color={iconColor} />}
                      label="Total"
                      num={past.length + upcoming.length + live.length}
                      minW="160px"
                    />
                    <EventStatTile
                      icon={<BoltIcon fontSize={'20px'} color={iconColor} />}
                      label="Live"
                      num={live.length}
                      minW="160px"
                    />
                    <EventStatTile
                      icon={<BoltIcon fontSize={'20px'} color={iconColor} />}
                      label="Upcoming"
                      num={upcoming.length}
                      minW="160px"
                    />
                    <EventStatTile
                      icon={<BoltIcon fontSize={'20px'} color={iconColor} />}
                      label="Past"
                      num={past.length}
                      minW="160px"
                    />
                  </SimpleGrid>
                </Flex>
              )}
              {live.length > 0 && (
                <>
                  <Flex direction={'row'} align={'center'} gap={2} px={5}>
                    <Circle
                      size={'30px'}
                      borderWidth={'2px'}
                      borderColor={colorMode === 'light' ? 'black' : 'white'}
                    >
                      <Text textStyle={'bodyMedium'}>{live.length}</Text>
                    </Circle>
                    <Text textStyle={'bodyLarge'}>Live</Text>
                  </Flex>
                  <Flex direction={'column'} gap={3} align={'center'}>
                    {live.map(event => (
                      <EventCard key={event.id} {...event} isLive={true} />
                    ))}
                  </Flex>
                </>
              )}
              {live.length != 0 && upcoming.length != 0 && (
                <Divider
                  orientation="horizontal"
                  variant={'dashed'}
                  borderColor={'rgba(163, 174, 221, 1)'}
                  m={3}
                />
              )}

              {upcoming.length != 0 && (
                <Flex width={'100%'} direction={'column'} gap={4}>
                  <Flex direction={'row'} align={'center'} gap={2} px={5}>
                    <Circle
                      size={'30px'}
                      borderWidth={'2px'}
                      borderColor={colorMode === 'light' ? 'black' : 'white'}
                    >
                      <Text textStyle={'bodyMedium'}>{upcoming.length}</Text>
                    </Circle>
                    <Text textStyle={'bodyLarge'}>Upcoming</Text>
                  </Flex>
                  <Flex direction={'column'} gap={3} align={'center'}>
                    {upcoming.map(event => (
                      <EventCard key={event.id} {...event} isUpcoming />
                    ))}
                  </Flex>
                </Flex>
              )}
              {upcoming.length != 0 && past.length != 0 && (
                <Divider
                  orientation="horizontal"
                  variant={'dashed'}
                  borderColor={'rgba(163, 174, 221, 1)'}
                  m={3}
                />
              )}

              {past.length != 0 && (
                <Flex width={'100%'} direction={'column'} gap={4}>
                  <Flex direction={'row'} align={'center'} gap={2} px={5}>
                    <Circle
                      size={'30px'}
                      borderWidth={'2px'}
                      borderColor={colorMode === 'light' ? 'black' : 'white'}
                    >
                      <Text textStyle={'bodyMedium'}>{past.length}</Text>
                    </Circle>
                    <Text textStyle={'bodyLarge'}>Past</Text>
                  </Flex>{' '}
                  <Flex direction={'column'} gap={3} align={'center'}>
                    {past.map(event => (
                      <EventCard key={event.id} {...event} />
                    ))}
                  </Flex>
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Hide>
    </>
  )
}
