import {
  AccordionButtonProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  ArrowsRightLeftIcon,
  Button,
  ChevronLeftIcon,
  FieldMapper,
  LightModeBox,
  Menu,
} from 'components'
import { useAlert } from 'components/providers'
import { useEffect, useState } from 'react'
import {
  PrimaryLeadProperties,
  primaryLeadProperties,
  PrimaryLeadProperty,
  SessionLead,
} from 'types'
import { uploadFile, useIntercomUtils, useWorkspace } from 'utils'
import * as XLSX from 'xlsx'
import { useEvent } from '../../../utils/eventUtils'

interface Props {
  isOpen: boolean
  onClose: () => void
  fileBuffer?: ArrayBuffer
}

export default function DatabaseMappingDrawer({ isOpen, onClose, fileBuffer }: Props) {
  useIntercomUtils(isOpen)
  const { event } = useEvent()
  const { workspace } = useWorkspace()
  const [dbTabs, setDbTabs] = useState<string[]>([])
  const [selectedTab, setSelectedTab] = useState('')
  const [rawDbData, setrawDbData] = useState<Record<string, string>[]>()
  const [dbFields, setDbFields] = useState<Record<string, string>>()
  const [mappedFields, setMappedFields] = useState<PrimaryLeadProperties>({})
  const [customFields, setCustomFields] = useState<Record<string, string>>(dbFields || {})
  const [repeatedFields, setRepeatedFields] = useState<Record<string, string[]>>({})
  const { alert } = useAlert()
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  const dbFilePath = `/events/${event?.id}/attendees.json`

  useEffect(() => {
    setDbFields(undefined)
    setMappedFields({})
    if (!fileBuffer) return
    const { SheetNames } = XLSX.read(fileBuffer)
    setDbTabs(SheetNames)
    if (SheetNames.length == 1) setSelectedTab(SheetNames[0])
  }, [fileBuffer])

  useEffect(() => {
    if (dbFields) {
      const customFields = Object.keys(dbFields).reduce((acc, key) => {
        const valuesInMappedFields = new Set(Object.values(mappedFields ?? {}))
        if (!valuesInMappedFields.has(key)) {
          acc[key] = dbFields[key]
        }
        return acc
      }, {} as Record<string, string>)
      setCustomFields(customFields)
    }
  }, [mappedFields])

  useEffect(() => {
    if (!selectedTab) return
    processFile()
  }, [selectedTab])

  async function processFile() {
    const { Sheets } = XLSX.read(fileBuffer)
    const sheet = Sheets[selectedTab]
    const data = XLSX.utils.sheet_to_json(sheet) as Record<string, string>[]
    setrawDbData(data)
    setDbFields(data[0])
  }

  function updateMappedFields(field: PrimaryLeadProperty, value: string) {
    setMappedFields(prev => {
      if (value === '') {
        const { [field]: _, ...rest } = prev || {}

        setRepeatedFields(prev => {
          const updated = { ...prev }

          Object.keys(updated).forEach(key => {
            if (updated[key].includes(field)) {
              if (updated[key].length === 2) {
                delete updated[key]
              } else {
                updated[key] = updated[key].filter(f => f !== field)
                if (updated[key].length === 0) delete updated[key]
              }
            }
          })

          return updated
        })

        return rest
      } else {
        const updatedFields = { ...prev, [field]: value }
        const repeatedKeys = Object.keys(updatedFields).filter(
          key => updatedFields[key as PrimaryLeadProperty] === value
        )

        setRepeatedFields(prev => {
          const updated = { ...prev }

          if (repeatedKeys.length > 1) {
            updated[value] = Array.from(new Set([...(updated[value] || []), ...repeatedKeys]))
            alert('Please map each field to a unique value')
          } else {
            Object.keys(updated).forEach(key => {
              if (updated[key].includes(field)) {
                if (updated[key].length === 2) {
                  delete updated[key]
                } else {
                  updated[key] = updated[key].filter(f => f !== field)
                  if (updated[key].length === 0) delete updated[key]
                }
              }
            })
          }
          return updated
        })
        return updatedFields
      }
    })
  }

  async function saveMapping() {
    if (!rawDbData || !mappedFields) return

    const formatted = rawDbData.map(row => {
      return Object.entries(row).reduce((acc, [key, value]) => {
        const mappedKey = Object.keys(mappedFields).find(
          mappedFieldKey => mappedFields[mappedFieldKey as PrimaryLeadProperty] === key
        )

        if (mappedKey) {
          return { ...acc, [mappedKey]: value }
        } else {
          return {
            ...acc,
            _custom: { ...(acc._custom || {}), [key]: value as string },
          }
        }
      }, {} as SessionLead)
    })

    const jsonFile = new File([JSON.stringify(formatted)], 'attendees.json', {
      type: 'application/json',
    })

    try {
      await uploadFile(`Workspaces/${workspace!.id}${dbFilePath}`, jsonFile)
      onClose()
    } catch (error) {
      console.error('File upload failed:', error)
    }
  }

  const customFieldItems: AccordionItem[] = [
    {
      title: `Custom Fields ${
        Object.keys(customFields).length > 0 ? ` (${Object.keys(customFields).length})` : ''
      }`,
      content: (
        <Flex direction="column" gap={3}>
          <Text textStyle="bodyXSmall" opacity={0.6} fontWeight="normal">
            Any additional fields brought in from the database that does not match the mapping above
            will appear here.
          </Text>
          <Flex w="100%" gap={3}>
            <Text textStyle="bodySmall" w="60%">
              Field Name
            </Text>
            <Text textStyle="bodySmall" w="40%">
              Field Value
            </Text>
          </Flex>
          {Object.entries(customFields).map(([key, value]) => (
            <Flex key={key} w="100%" gap={3}>
              <Text w="60%" {...textStyling}>
                {key}
              </Text>
              <Text {...textStyling} w="40%">
                {value}
              </Text>
            </Flex>
          ))}
        </Flex>
      ),
    },
  ]

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      autoFocus={false}
      size={isMobile ? 'xs' : 'lg'}
      isOpen={isOpen}
      closeOnEsc
    >
      <DrawerOverlay />
      <LightModeBox>
        <DrawerContent borderRadius="md" my={2} mr={2}>
          <DrawerHeader borderBottom="1px solid rgba(222, 226, 244, 1)" py={2}>
            <Button
              variant="ghost"
              leftIcon={<ChevronLeftIcon fontSize="lg" />}
              onClick={onClose}
              pl={0}
            >
              Back
            </Button>

            <DrawerCloseButton h="40px" />
          </DrawerHeader>

          <DrawerBody>
            <Flex direction="column" gap={2}>
              {dbTabs && (
                <Flex gap={5} align="center">
                  <Text textStyle="h6">Database tab:</Text>
                  <Menu
                    variant="outline"
                    borderRadius="10px"
                    menuProps={{ matchWidth: true }}
                    value={selectedTab}
                    noPortal
                    options={dbTabs.map(tab => ({ label: tab, value: tab }))}
                    onChange={value => setSelectedTab(value)}
                  />
                </Flex>
              )}

              {dbFields && (
                <Flex direction="column" gap={3}>
                  <Flex gap={2} align="center" p={2}>
                    <ArrowsRightLeftIcon fontSize="xl" />
                    <Text textStyle="h6">Map Fields</Text>
                  </Flex>
                  <SimpleGrid w="100%" columns={3} spacing={3}>
                    <Text textStyle="bodySmall">Momentify Fields</Text>
                    <Text textStyle="bodySmall">Database Fields</Text>
                    <Text textStyle="bodySmall">Example Data Input</Text>
                  </SimpleGrid>
                  {primaryLeadProperties.map((field, index) => (
                    <FieldMapper
                      key={index}
                      referenceField={field}
                      targetFieldsAndValues={dbFields}
                      onChange={value => updateMappedFields(field, value)}
                      error={Object.values(repeatedFields).some(fields => fields.includes(field))}
                    />
                  ))}

                  {customFields && (
                    <Accordion
                      alignSelf="stretch"
                      allowToggle
                      items={customFieldItems}
                      buttonProps={accordionButtonProps}
                    />
                  )}
                </Flex>
              )}
            </Flex>
          </DrawerBody>

          <DrawerFooter>
            {Object.keys(mappedFields).length !== 0 && (
              <Button
                palette="primary"
                isDisabled={Object.keys(repeatedFields).length > 0}
                onClick={saveMapping}
              >
                Save
              </Button>
            )}
          </DrawerFooter>
        </DrawerContent>
      </LightModeBox>
    </Drawer>
  )
}

const textStyling = {
  bg: 'rgba(23, 23, 23, 0.10)',
  borderRadius: '10px',
  minW: '100px',
  h: '40px',
  py: '10px',
  px: '16px',
  textStyle: 'bodyMedium',
  fontWeight: '500',
  isTruncated: true,
}

const accordionButtonProps: AccordionButtonProps = {
  backgroundColor: 'transparent',
  textStyle: 'bodySmall',
  pl: 0,
}
