import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorMode,
  useToast,
} from '@chakra-ui/react'
import {
  Button,
  InputContainer,
  LightModeBox,
  LoaderCover,
  TextField,
  UserPlusIcon,
} from 'components'
import { AuthContext } from 'components/providers'
import { forwardRef, useContext, useState } from 'react'
import { PermissionValue, WorkspaceUser } from 'types'
import { sendInvite, updateDoc, useWorkspace, validEmail } from 'utils'
import PermissionWithHelp, { permissionOptions } from './PermissionWithHelp'

interface Props {
  isOpen: boolean
  onClose: () => void
  onComplete: (user?: WorkspaceUser) => void
  existingUsers?: string[]
}

const NewUserForm = forwardRef<HTMLInputElement, Props>(
  ({ isOpen, onClose, onComplete: onDone, existingUsers }, ref) => {
    const toast = useToast()
    const { user } = useContext(AuthContext)
    const { workspace } = useWorkspace()
    const { colorMode } = useColorMode()

    const [data, setData] = useState<WorkspaceUser>({
      email: '',
      permission: 'editor',
      created: new Date().getTime(),
    })
    const [creating, setCreating] = useState(false)

    const { id, pendingUserByEmail } = workspace || {}
    const existingUser = !creating && existingUsers?.includes(data.email)

    async function handleCreate() {
      if (!data) return

      setCreating(true)

      try {
        await sendInvite({ email: data.email, workspaceId: id! })

        const updatedPendingUserByEmail = { ...pendingUserByEmail, [data.email]: data }
        await updateDoc(user!.id, 'Workspace', {
          id: id!,
          pendingUserByEmail: updatedPendingUserByEmail,
        })

        onDone()
        setData({ email: '', permission: 'editor', created: new Date().getTime() })
      } catch (e: any) {
        console.error(e)
        toast({
          title: 'Failed to send invite',
          description: e.message,
          status: 'error',
          isClosable: true,
        })
      } finally {
        setCreating(false)
      }
    }

    function updateData(body: Partial<WorkspaceUser>) {
      setData(s => ({ ...s, ...body }))
    }

    return (
      <LightModeBox>
        <Modal
          onClose={onClose}
          closeOnOverlayClick={false}
          isOpen={isOpen}
          size={{ base: 'sm', sm: 'lg' }}
        >
          <ModalOverlay />
          <ModalContent color={'black'} borderRadius={'20px'} overflow="hidden">
            <LoaderCover show={creating}>
              <ModalHeader borderBottom={'1px solid rgba(222, 226, 244, 1)'} py={3}>
                <Flex align={'center'} gap={2}>
                  <UserPlusIcon />
                  <Text>Invite Member</Text>
                </Flex>
              </ModalHeader>
              <ModalBody p={2}>
                <Flex direction="column" gap={2} m={4}>
                  <TextField
                    label="Email Address:"
                    ref={ref}
                    bg={'#fff'}
                    backdropFilter={'blur(27px)'}
                    borderRadius={'10px'}
                    type="email"
                    placeholder="Email"
                    value={data.email}
                    isError={existingUser}
                    helperText={existingUser ? 'User already exists' : undefined}
                    onChange={e => updateData({ email: e.target.value })}
                  />
                  <InputContainer label="Permission:">
                    <RadioGroup
                      onChange={(permission: PermissionValue) => updateData({ permission })}
                      value={data.permission}
                    >
                      <Stack direction="row" gap={4}>
                        {permissionOptions.map(({ value }) => (
                          <Radio key={value} value={value}>
                            <PermissionWithHelp value={value} />
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  </InputContainer>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Flex justify="space-between" w="100%">
                  <Button onClick={onClose} variant="outline">
                    Cancel
                  </Button>

                  <Button
                    alignSelf="flex-end"
                    isDisabled={!validEmail(data.email) || Boolean(existingUser)}
                    onClick={handleCreate}
                    palette={colorMode === 'light' ? 'primary' : 'secondary'}
                  >
                    Send Invite
                  </Button>
                </Flex>
              </ModalFooter>
            </LoaderCover>
          </ModalContent>
        </Modal>
      </LightModeBox>
    )
  }
)

export default NewUserForm
