import { AcceptInvitePage, HomePage, ManageWorkspacePage } from 'components/pages'
import { createBrowserRouter } from 'react-router-dom'
import { IRoute } from 'types'
import { WorkspacePath } from 'utils'
import {
  AnalyticsPage,
  ContentPage,
  EventsInfoPage,
  EventsPage,
  FacilitiesPage,
  FacilityInfoPage,
  NewPassword,
  ProfilePage,
  ResetPassword,
  RootPage,
  WorkspacePage,
} from '../pages'

export enum RouteName {
  Media = 'media',
  Manage = 'manage',
  Profile = 'profile',
  Events = 'events',
  EventInfo = 'eventInfo',
  Facilities = 'facilities',
  Analytics = 'analytics',
  Content = 'content',
  Text = 'text',
}

export enum RoutePath {
  Home = '/',
  Invite = `/invite${WorkspacePath}`,
  ManageMedia = `${WorkspacePath}/${RouteName.Media}`,
  ManageWorkspace = `${WorkspacePath}/${RouteName.Manage}`,
  Workspace = `${WorkspacePath}`,
  Events = `${WorkspacePath}/${RouteName.Events}`,
  EventInfo = `${WorkspacePath}/${RouteName.Events}/:id`,
  Analytics = `${WorkspacePath}/${RouteName.Events}/:id/${RouteName.Analytics}`,
  Facilities = `${WorkspacePath}/${RouteName.Facilities}`,
  FacilityInfo = `${WorkspacePath}/${RouteName.Facilities}/:id`,
  Content = `${WorkspacePath}/${RouteName.Content}`,
  Text = `${WorkspacePath}/${RouteName.Text}/`,
  TextInfo = `${WorkspacePath}/${RouteName.Text}/:id`,
  Profile = `${WorkspacePath}/${RouteName.Profile}`,
  GlobalProfile = `/${RouteName.Profile}`,
  ResetPassword = '/reset-password',
  NewPassword = `/new-password/`,
}

export const routes: IRoute[] = [
  {
    path: RoutePath.Invite,
    element: <AcceptInvitePage />,
    isPublic: true,
  },
  {
    path: RoutePath.Home,
    element: <HomePage scope={'emp'} />,
  },
  {
    path: RoutePath.GlobalProfile,
    element: <ProfilePage />,
  },
  {
    path: RoutePath.ResetPassword,
    element: <ResetPassword />,
  },
  {
    path: RoutePath.NewPassword,
    element: <NewPassword />,
    isPublic: true,
  },
  {
    path: RoutePath.Workspace,
    element: <RootPage />,
    children: [
      {
        path: RoutePath.Workspace,
        element: <WorkspacePage />,
      },
      {
        path: RoutePath.ManageMedia,
        element: <ContentPage />,
      },
      {
        path: RoutePath.Profile,
        element: <ProfilePage />,
      },
      {
        path: RoutePath.ManageWorkspace,
        element: <ManageWorkspacePage />,
      },
      {
        path: RoutePath.Events,
        element: <EventsPage />,
      },
      {
        path: RoutePath.EventInfo,
        element: <EventsInfoPage />,
      },
      {
        path: RoutePath.Facilities,
        element: <FacilitiesPage />,
      },
      {
        path: RoutePath.FacilityInfo,
        element: <FacilityInfoPage />,
      },
      {
        path: RoutePath.Analytics,
        element: <AnalyticsPage />,
      },
      {
        path: RoutePath.Content,
        element: <ContentPage />,
      },
      {
        path: RoutePath.Text,
        element: <ContentPage />,
      },
      {
        path: RoutePath.TextInfo,
        element: <ContentPage />,
      },
      {
        path: '*',
        element: <HomePage scope={'emp'} />,
      },
    ],
  },
]

export default createBrowserRouter(routes)
