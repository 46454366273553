import { Flex, Image, Spacer, Text, useColorMode, useToast } from '@chakra-ui/react'
import {
  AnimatedTiles,
  Backdrop,
  Button,
  CheckCircleIcon,
  LightModeBox,
  TextField,
  Tooltip,
} from 'components'
import { useState } from 'react'
import { resetPassword, signIn, validEmail } from 'utils'
import { existingUser } from 'utils/auth'

export default function SignIn() {
  const { colorMode } = useColorMode()
  const toast = useToast()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [resetRequired, setResetRequired] = useState(false)

  function submit() {
    signIn(email.trim(), password).catch(handleError)
  }

  async function handleReset() {
    if (await existingUser(email)) {
      resetPassword(email.trim())
        .then(() => toast({ title: 'Password reset email sent', status: 'success' }))
        .catch(handleError)
      setResetRequired(true)
    } else {
      toast({ title: 'Could not find an account with that email', status: 'error' })
    }
  }

  function handleError(e: any) {
    let title = 'An error occurred'
    switch (e.code) {
      case 'auth/invalid-credential':
        title = 'Invalid credentials'
        break
      case 'auth/invalid-email':
        title = 'Invalid email address'
        break
      case 'auth/missing-email':
        title = 'Please enter an email address'
        break
      case 'auth/missing-password':
        title = 'Please enter a password'
        break
      case 'auth/too-many-requests':
        title = 'Too many attempts. Try again later'
        break
      default:
        console.error(e)
    }
    toast({ title, status: 'error' })
  }

  return (
    <Backdrop justify="center" align="center" flex={1} gap={14}>
      <LightModeBox
        display="flex"
        bg="linear-gradient(167deg, rgba(255, 255, 255, 0.70) 2.36%, rgba(255, 255, 255, 0.60) 92.17%)"
        px={14}
        py={10}
        width="500px"
        maxWidth="100vw"
        height={{ base: '100vh', sm: 'auto' }}
        borderRadius={{ base: 0, sm: '40px' }}
        border="1px solid #FFF"
        boxShadow="0px 7px 16px 0px rgba(0, 0, 0, 0.08)"
        alignItems="flex-start"
        flexDirection="column"
        backdropFilter="blur(40px)"
        justifyContent="center"
      >
        {resetRequired ? (
          <Flex direction={'column'} gap={2} w={'100%'} align={'center'}>
            <CheckCircleIcon fontSize={'48px'} type="outline" />
            <Text textStyle={{ base: 'h3', sm: 'h2' }}>Email Sent</Text>
            <Text textStyle={'bodySmall'}>
              Check your email and open the link we sent to continue.
            </Text>
          </Flex>
        ) : (
          <>
            <Image
              src="/common/momentify-logo-dark.svg"
              alt="momentify logo"
              height="30px"
              mb={14}
            />
            <Flex direction="column" gap={3} mb={8}>
              <Text textStyle="h1">Welcome!</Text>
              <Text fontSize="xl">Log in to your momentify account.</Text>
            </Flex>
            <Flex direction="column" alignSelf="stretch" gap={4}>
              <TextField
                label="Email"
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter email..."
                type="email"
                value={email}
                variant="filled"
                border={'1px solid rgba(0, 0, 0, 0.2)'}
              />
              <TextField
                label="Password"
                onChange={e => setPassword(e.target.value)}
                onKeyDown={e => e.key === 'Enter' && submit()}
                placeholder="Enter password..."
                type="password"
                value={password}
                variant="filled"
                border={'1px solid rgba(0, 0, 0, 0.2)'}
              />
              <Flex direction={'row'} width="100%">
                <Button
                  isDisabled={!validEmail(email) || !password}
                  onClick={submit}
                  palette="primary"
                >
                  Log in
                </Button>
                <Spacer />
                <Tooltip label={!validEmail(email) ? 'Enter a valid email' : undefined}>
                  <Button
                    variant="link"
                    color="secondary.700"
                    isDisabled={!validEmail(email)}
                    onClick={() => handleReset()}
                  >
                    Forgot Password?
                  </Button>
                </Tooltip>
              </Flex>
            </Flex>
          </>
        )}
      </LightModeBox>

      <AnimatedTiles />
    </Backdrop>
  )
}
