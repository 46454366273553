import { Flex } from '@chakra-ui/react'
import { isEqual } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { randomString, updateDoc, useWorkspace } from 'utils'
import Button from '../Button'
import Dialog from '../Dialog'
import DragList from '../DragList'
import HelpHover from '../HelpHover'
import IconButton from '../IconButton'
import { DotsDragIcon, XMarkIcon } from '../icons'
import InputContainer from '../InputContainer'
import { AuthContext, useAlert } from '../providers'
import Switch from '../Switch'
import TextField from '../TextField'

interface IdAndValue {
  id: string
  value: string
}

export default function ManageFollowUpGroups() {
  const { confirm } = useAlert()
  const { user } = useContext(AuthContext)
  const { workspace } = useWorkspace()
  const [open, setOpen] = useState(false)
  const [initialGroups, setInitialGroups] = useState<IdAndValue[]>([])
  const [usersAsOwners, setUsersAsOwners] = useState(false)
  const [draft, setDraft] = useState<IdAndValue[]>([])
  const [newGroup, setNewGroup] = useState('')

  const { followUpGroups, followUpUsersDisabled } = workspace || {}
  const dirty = !isEqual(draft, initialGroups) || usersAsOwners !== !followUpUsersDisabled

  useEffect(() => {
    if (workspace) init()
  }, [workspace])

  function init() {
    const groups = followUpGroups?.map(g => ({ id: randomString(), value: g.title })) || []
    setUsersAsOwners(!workspace?.followUpUsersDisabled)
    setDraft(groups)
    setInitialGroups(groups)
  }

  function handleAdd() {
    const value = newGroup.trim()
    setDraft(s => [...s, { id: randomString(), value }])
    setNewGroup('')
  }

  async function handleCancel() {
    if (dirty) {
      const confirmed = await confirm('Discard changes?')
      if (!confirmed) return
    }

    setOpen(false)
  }

  async function handleDone() {
    setOpen(false)

    if (dirty) {
      const groups = draft.map(g => ({ title: g.value }))
      await updateDoc(user!.id, 'Workspace', {
        id: workspace!.id,
        followUpGroups: groups,
        followUpUsersDisabled: !usersAsOwners,
      })
    }
  }

  return (
    <InputContainer label="Follow Up Groups">
      <Button variant="outline" onClick={() => setOpen(true)}>
        {`${followUpGroups?.length ?? 0} groups configured`}
      </Button>

      <Dialog
        isOpen={open}
        onClose={handleCancel}
        title="Manage Follow Up Groups"
        bodyProps={{ p: 6, gap: 4, display: 'flex', flexDirection: 'column' }}
      >
        <DragList
          containerProps={{ gap: 2, mb: 2 }}
          items={draft}
          onChange={setDraft}
          renderItem={item => (
            <Flex
              flex={1}
              gap={2}
              cursor="grab"
              align="center"
              justify="space-between"
              borderRadius={4}
              overflow="hidden"
            >
              <DotsDragIcon />
              <TextField
                flex={1}
                fontSize="md"
                bg="white"
                borderRadius="sm"
                rightElement={{
                  pointerEvents: 'all',
                  children: (
                    <IconButton
                      variant="ghost"
                      color="inherit"
                      size="sm"
                      aria-label="Remove name"
                      icon={<XMarkIcon />}
                      onClick={() => setDraft(s => s.filter(i => i.id !== item.id))}
                    />
                  ),
                }}
                noOfLines={1}
                onKeyDown={e => e.key === ' ' && e.stopPropagation()}
                onFocus={e => e.target.select()}
                value={item.value}
                onChange={e =>
                  setDraft(s =>
                    s.map(i => (i.id === item.id ? { ...i, value: e.target.value } : i))
                  )
                }
              />
            </Flex>
          )}
        />

        <Flex gap={2}>
          <TextField
            borderRadius="sm"
            bg="white"
            placeholder="Enter Name..."
            value={newGroup}
            onChange={e => setNewGroup(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleAdd()}
          />
          <Button palette="primary" isDisabled={!newGroup} onClick={handleAdd}>
            Add
          </Button>
        </Flex>

        <Flex gap={2} align="center">
          <Switch
            onChange={setUsersAsOwners}
            isChecked={usersAsOwners}
            label="Workspace Users as Owners"
            colorScheme="primary"
            borderRadius={'11px'}
            labelProps={{ fontSize: 'sm' }}
          />
          <HelpHover
            text="Enable Workspace Users as being listed as potential Lead Follow Up Owners"
            borderRadius={'5px'}
            iconProps={{ size: 'sm' }}
          />
        </Flex>

        <Flex justifyContent="flex-end">
          {dirty && (
            <Button variant="ghost" onClick={init}>
              Reset
            </Button>
          )}
          <Button alignSelf="flex-start" palette="primary" onClick={handleDone} w="75px">
            {dirty ? 'Save' : 'Done'}
          </Button>
        </Flex>
      </Dialog>
    </InputContainer>
  )
}
