import { Box, Flex } from '@chakra-ui/react'
import { ArrowUpOnSquareIcon, Button, FileDrop, LoaderCover, MediaDialog } from 'components'
import { useAlert } from 'components/providers'
import { useEffect, useState } from 'react'
import { MediaType } from 'types'
import { acceptedImages, acceptedVideos, deleteDoc, getMediaIds, useWorkspace } from 'utils'
import MediaGrid from './MediaGrid'

export interface Props {
  onSelect?: (id: string) => void
  noCreate?: boolean
  onRemove?: (id: string) => void
  type?: MediaType
}

export default function ManageMedia({ onSelect, noCreate, onRemove, type }: Props) {
  const { confirm } = useAlert()
  const { workspace } = useWorkspace()
  const [loading, setLoading] = useState(false)
  const [ids, setIds] = useState<string[]>([])
  const [newMedia, setNewMedia] = useState<File>()

  const workspaceId = workspace?.id

  useEffect(() => {
    if (!workspaceId) return

    setLoading(true)
    getMediaIds({ workspaceId, type }).then(res => {
      setIds(res.data)
      setLoading(false)
    })
  }, [workspaceId])

  async function handleRemove(id: string) {
    const response = await confirm('Delete file and remove from all models that use it?', {
      cancelText: 'No',
      okText: 'Remove',
    })
    if (!response) return false

    await deleteDoc('Media', id)
    const filtered = ids.filter(_id => _id !== id)
    if (filtered.length !== ids.length) setIds(filtered)
    onRemove?.(id)
    return true
  }

  return (
    <LoaderCover flex={1} display="flex" show={loading}>
      <FileDrop
        flex={1}
        flexDirection="column"
        gap={2}
        options={{
          disabled: noCreate,
          accept:
            type === 'image'
              ? acceptedImages
              : type === 'video'
              ? acceptedVideos
              : { ...acceptedImages, ...acceptedVideos },
        }}
        onDrop={files => setNewMedia(files[0])}
      >
        {({ open }) => (
          <Flex direction="column" gap={2}>
            {!noCreate && (
              <Button
                leftIcon={<ArrowUpOnSquareIcon type="outline" />}
                alignSelf="flex-start"
                onClick={open}
              >
                Upload
              </Button>
            )}
            <MediaGrid
              templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
              overflow="auto"
              autoRows="1fr"
              ids={ids}
              onRemove={handleRemove}
              onSelect={onSelect}
            />
            <Box />
          </Flex>
        )}
      </FileDrop>

      <MediaDialog
        isOpen={Boolean(newMedia)}
        newMedia={newMedia}
        onClose={() => setNewMedia(undefined)}
        onSave={doc => {
          if (!onSelect) return setIds(s => [...s, doc.id])

          onSelect(doc.id)
          setNewMedia(undefined)
        }}
      />
    </LoaderCover>
  )
}
