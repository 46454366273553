import { Flex, Text } from '@chakra-ui/react'
import { PanelProps } from 'components'
import EventStatIcon from './EventStatIcon'

interface Props extends PanelProps {
  icon?: JSX.Element
  label: string
  num: number
  isLoading?: boolean
}

export default function EventStatBanner({ icon, label, num, isLoading }: Props) {
  return (
    <Flex align="center" gap={2}>
      {icon && <EventStatIcon icon={icon} />}
      <Flex direction="column">
        <Text textStyle="h6" opacity={isLoading ? 0.5 : 1}>
        {label.toLowerCase().includes('duration')
              ? `${num.toPrecision(4)} s`
              : num.toLocaleString()}
        </Text>
        <Text textStyle="bodyXSmall" opacity={0.6}>
          {label}
        </Text>
      </Flex>
    </Flex>
  )
}
