import { AuthContext } from 'components/providers'
import {
  DocumentChangeType,
  FirestoreError,
  QueryConstraint,
  QueryDocumentSnapshot,
  addDoc,
  doc,
  collection as firestoreCollection,
  deleteDoc as firestoreDeleteDoc,
  getDocs as firestoreGetDocs,
  onSnapshot as firestoreOnSnapshot,
  setDoc as firestoreSetDoc,
  updateDoc as firestoreUpdateDoc,
  getDoc,
  getFirestore,
  limit,
  query,
  startAfter,
} from 'firebase/firestore'
import { isEqual } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { Collection, CollectionName, OmitDBProps } from 'types'
import firebaseApp from './firebase'

const db = getFirestore(firebaseApp)

export async function createDoc<T extends CollectionName>(
  userId: string,
  collection: T,
  data: OmitDBProps<Collection[T]>
) {
  const created = new Date().getTime()
  const createdBy = userId
  const updated = created
  const updatedBy = createdBy

  const body = { ...data, created, createdBy, updated, updatedBy }
  const docRef = await addDoc(firestoreCollection(db, collection), body)
  return { id: docRef.id, ...body } as Collection[T]
}

export async function getDocById<T extends CollectionName>(collection: T, id: string) {
  const docSnap = await getDoc(doc(db, collection, id))
  if (!docSnap.exists()) return null

  return { id: docSnap.id, ...docSnap.data() } as Collection[T]
}

export async function updateDoc<T extends CollectionName>(
  userId: string,
  collection: T,
  body: { id: string } & Partial<Collection[T]>
) {
  const { id, ...rest } = body
  const ref = doc(db, collection, id)
  const updated = new Date().getTime()
  const updatedBy = userId
  const data = { ...rest, updated, updatedBy }
  await firestoreUpdateDoc(ref, data)
  return { id, ...data } as Collection[T]
}

export async function setDoc<T extends CollectionName>(
  userId: string,
  collection: T,
  body: Collection[T]
) {
  const { id, created = new Date().getTime(), createdBy = userId, ...rest } = body
  const ref = doc(db, collection, id)
  const updated = new Date().getTime()
  const updatedBy = userId
  const data = { ...rest, created, createdBy, updated, updatedBy }
  await firestoreSetDoc(ref, data)
  return { id, ...data } as Collection[T]
}

export function deleteDoc<T extends CollectionName>(collection: T, id: string) {
  return firestoreDeleteDoc(doc(db, collection, id))
}

let lastPageRefs: Partial<Record<CollectionName, QueryDocumentSnapshot>> = {}

export async function getDocs<T extends CollectionName>(
  collection: T,
  page = 0,
  ...constraints: QueryConstraint[]
) {
  const lastPageRef = lastPageRefs[collection]
  const _constraints = [
    ...constraints,
    limit(25),
    ...(page !== 0 && lastPageRef ? [startAfter(lastPageRef)] : []),
  ]
  let q = query(firestoreCollection(db, collection), ..._constraints)

  return firestoreGetDocs(q).then(snap => {
    if (page === 0) lastPageRefs[collection] = snap.docs[snap.docs.length - 1]
    return snap.docs.map(makeDoc<Collection[T]>)
  })
}

export type FetchStatus = 'loading' | 'error' | 'success' | 'empty'

export function useDoc<T extends CollectionName>(collection: T, id?: string) {
  const { user } = useContext(AuthContext)
  const [data, setData] = useState<Collection[T] | null | undefined>(undefined)
  const [status, setStatus] = useState<FetchStatus>('loading')

  useEffect(() => {
    setStatus('loading')
    if (!user || !id) {
      setData(user ? undefined : null)
      setStatus(user === null ? 'error' : user ? 'empty' : 'loading')
      return
    }

    const unsubscribe = onDoc(collection, id, ({ doc, error }) => {
      setStatus(error ? 'error' : 'success')
      setData(doc)
    })

    return unsubscribe
  }, [collection, user, id])

  return [data, status] as const
}

export interface OnDocCallbackProps<T extends CollectionName> {
  doc: Collection[T] | null
  error?: FirestoreError
}

export function onDoc<T extends CollectionName>(
  collection: T,
  id: string,
  callback: (props: OnDocCallbackProps<T>) => void
) {
  const documentRef = doc(db, collection, id)

  return firestoreOnSnapshot(
    documentRef,
    snap => {
      callback({ doc: snap.exists() ? makeDoc<Collection[T]>(snap) : null })
    },
    error => {
      callback({ doc: null, error })
    }
  )
}

export function useDocs<T extends CollectionName>(collection: T, constraints: QueryConstraint[]) {
  const { user } = useContext(AuthContext)
  const [data, setData] = useState<Collection[T][]>([])
  const [status, setStatus] = useState<FetchStatus>('loading')

  const constraintsRef = useRef(constraints)
  if (!isEqual(constraintsRef.current, constraints)) constraintsRef.current = constraints

  useEffect(() => {
    setStatus('loading')
    if (!user) return

    const unsubscribe = onDocs(collection, constraintsRef.current, ({ docs, error }) => {
      setData(docs)
      setStatus(error ? 'error' : 'success')
    })

    return unsubscribe
  }, [collection, user, constraintsRef.current])

  return [data, status] as [Collection[T][], FetchStatus]
}

export interface OnDocsCallbackProps<T extends CollectionName> {
  docs: Collection[T][]
  changes: DocumentChange<T>[]
  error?: FirestoreError
}

interface DocumentChange<N extends CollectionName> {
  type: DocumentChangeType
  data: Collection[N]
}

export function onDocs<T extends CollectionName>(
  collection: T,
  constraints: QueryConstraint[],
  callback: (props: OnDocsCallbackProps<T>) => void
) {
  const q = query(firestoreCollection(db, collection), ...constraints)
  return firestoreOnSnapshot(
    q,
    snap => {
      const docs = snap.docs.map(doc => makeDoc<Collection[T]>(doc))
      const changes = snap.docChanges().map(({ type, doc }) => {
        return { type, data: makeDoc<Collection[T]>(doc) }
      })
      callback({ docs, changes })
    },
    error => {
      callback({ docs: [], changes: [], error })
    }
  )
}

export function makeDoc<T>(doc: QueryDocumentSnapshot): T {
  return { ...doc.data(), id: doc.id } as T
}
