import { Circle, useColorMode } from '@chakra-ui/react'
import { PanelProps } from 'components'

interface Props extends PanelProps {
  icon?: JSX.Element
}

export default function EventStatTile({ icon }: Props) {

  return (
    <Circle
      size='40px'
      bg='white'
      boxShadow={'0px 4px 10px 0px rgba(0, 0, 0, 0.06)'}
      ml={{ base: '0', sm: '5' }}
    >
      {icon }
    </Circle>
  )
}
