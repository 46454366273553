import { Flex, Image, Text, useColorMode } from '@chakra-ui/react'
import { ArrowDownTrayIcon, Backdrop, Button } from 'components'
import { AuthContext } from 'components/providers'
import { useContext, useEffect } from 'react'
import { WorkspaceScope } from 'types'
import {
  createDoc,
  makeEmptyWorkspace,
  signOut,
  useGoToWorkspace,
  usePrefCurrentWorkspace,
  useWorkspaces,
} from 'utils'

export default function HomePage(props: { scope: WorkspaceScope }) {
  const [currentWorkspace] = usePrefCurrentWorkspace()
  const goToWorkspace = useGoToWorkspace()

  useEffect(() => {
    if (currentWorkspace) goToWorkspace(currentWorkspace)
  }, [])

  if (currentWorkspace) return

  return <GetWorkspaces onSelect={goToWorkspace} {...props} />
}

interface GetWorkspacesProps {
  scope: WorkspaceScope
  onSelect: (id: string) => void
}

function GetWorkspaces({ onSelect, scope }: GetWorkspacesProps) {
  const goToWorkspace = useGoToWorkspace()
  const { user } = useContext(AuthContext)
  const { isLoading, workspaces } = useWorkspaces(scope)
  const { colorMode } = useColorMode()

  useEffect(() => {
    if (isLoading) return

    if (workspaces?.length) return onSelect(workspaces[0].id)
  }, [user, isLoading, workspaces])

  async function createWorkspace() {
    const workspace = await createDoc(user?.id!, 'Workspace', makeEmptyWorkspace(scope))
    const params = { manage: 'true' }
    goToWorkspace(workspace.id, params)
  }

  if (isLoading || workspaces?.length) return

  return (
    <Backdrop
      display="flex"
      flexDirection="column"
      overflow="hidden"
      m="auto"
      align="center"
      gap={10}
    >
      <Image
        src={
          colorMode == 'light'
            ? '/common/momentify-icon-dark.svg'
            : '/common/momentify-icon-light.svg'
        }
        alt="momentify logo"
        height="120px"
      />
      <Flex direction="column" align="center" gap={1}>
        <Text textStyle="h3">No workspaces</Text>
        {user?.isAdmin ? (
          <Button onClick={createWorkspace}>Create one</Button>
        ) : (
          <Text textStyle="bodyMedium" fontWeight="normal">
            Sorry, you don't seem to have any workspaces. Please reach out to Entevate to get
            plugged in.
          </Text>
        )}
      </Flex>
      <Button
        onClick={() => signOut()}
        gap={2}
        maxW={'150px'}
        bg={colorMode === 'light' ? 'rgba(255, 255, 255, 0.74)' : ''}
      >
        <ArrowDownTrayIcon color="red" size="sm" style={{ transform: 'rotate(90deg)' }} />
        <Text>Sign out</Text>
      </Button>
    </Backdrop>
  )
}
