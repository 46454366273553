import { Grid, GridItem, GridItemProps, GridProps, Text } from '@chakra-ui/react'
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Icon } from 'components'
import { usePermission } from 'utils'
import MediaThumb from './MediaThumb'

export interface Props extends Omit<GridProps, 'onSelect'> {
  ids: string[]
  /** Allow drag and drop reordering */
  onReorder?: (ids: string[]) => void
  /** Add a new media tile at the end */
  onNew?: () => void
  /** Change the preview to icon and main click as a select */
  onSelect?: (id: string) => void
  /** add a remove icon on each item */
  onRemove?: (id: string) => Promise<boolean>
}

export default function MediaGrid({ ids, onNew, onSelect, onReorder, onRemove, ...rest }: Props) {
  const permission = usePermission()

  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 5 } })
  const keyboardSensor = useSensor(KeyboardSensor)
  const sensors = useSensors(mouseSensor, keyboardSensor)

  const isViewer = permission.content === 'viewer'

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (!over || active.id === over.id) return

    const oldIndex = ids.indexOf(active.id as string)
    const newIndex = ids.indexOf(over.id as string)
    onReorder?.(arrayMove(ids, oldIndex, newIndex))
  }

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <SortableContext disabled={isViewer || !onReorder} items={ids}>
        <Grid gap={2} width="100%" {...(rest as any)}>
          {ids.map(id => (
            <MediaItem
              key={id}
              id={id}
              onRemove={onRemove ? () => onRemove(id) : undefined}
              onChoose={onSelect ? () => onSelect(id) : undefined}
            />
          ))}
          {onNew && !isViewer && <NewItem onClick={onNew} />}
        </Grid>
      </SortableContext>
    </DndContext>
  )
}

interface MediaItemProps {
  id: string
  onRemove?: () => Promise<boolean>
  onChoose?: () => void
}

function MediaItem({ id, onRemove, onChoose }: MediaItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? 1 : 0,
    transition,
  }

  return (
    <GridItem ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <MediaThumb id={id} onDelete={onRemove} onChoose={onChoose} />
    </GridItem>
  )
}

function NewItem(props: GridItemProps) {
  return (
    <GridItem
      aspectRatio={1}
      display="flex"
      border="1px"
      borderColor="gray"
      borderRadius={8}
      position="relative"
      cursor="pointer"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
      {...(props as any)}
    >
      <Icon fontSize="4xl">add_circle</Icon>
      <Text>Add</Text>
    </GridItem>
  )
}
