import {
  AccordionButton,
  AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  AccordionPanelProps,
  AccordionProps,
  Accordion as ChakraAccordion,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { ReactNode } from 'react'

export interface Props extends Omit<AccordionProps, 'children'> {
  contentProps?: AccordionPanelProps
  buttonProps?: AccordionButtonProps
  isFlex?: boolean
  items: AccordionItem[]
}

export interface AccordionItem extends Omit<AccordionItemProps, 'content' | 'title'> {
  content: ReactNode
  forceScroll?: boolean
  title: ReactNode
}

export default function Accordion({ contentProps, buttonProps, isFlex, items, ...rest }: Props) {
  const { colorMode } = useColorMode()

  return (
    <ChakraAccordion
      borderRadius={8}
      {...(isFlex && { display: 'flex', flexDirection: 'column', overflow: 'auto' })}
      {...(rest as any)}
    >
      {items.map(({ title, content, forceScroll, sx, ...rest }, index) => (
        <AccordionItem
          key={index}
          sx={{ ...(isFlex && { '& > .chakra-collapse': { display: 'flex !important' } }), ...sx }}
          borderTop="none"
          borderBottom={
            index == items.length - 1
              ? '0'
              : colorMode === 'light'
              ? '1px solid #DEDEDE'
              : '1px solid rgba(255, 255, 255, 0.1)'
          }
          {...(rest as any)}
        >
          <AccordionButton
            {...(isFlex && { flexShrink: 0 })}
            backgroundColor="lightyellow"
            justifyContent="space-between"
            {...(buttonProps as any)}
          >
            <Text textStyle={buttonProps?.textStyle || 'bodyLarge'}>{title}</Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel
            px={0}
            {...(isFlex && { display: 'flex', overflowY: 'auto', flex: 1 })}
            {...(forceScroll && {
              overflowY: 'scroll',
              sx: { scrollbarColor: 'grey transparent', ...contentProps?.sx },
            })}
            {...(contentProps as any)}
          >
            {content}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </ChakraAccordion>
  )
}
