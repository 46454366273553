import { Box, BoxProps, DarkMode, LightMode } from '@chakra-ui/react'
import { forwardRef } from 'react'

// Force color mode fix per https://github.com/chakra-ui/chakra-ui/issues/6916#issuecomment-1304080946

export const LightModeBox = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <LightMode>
    <Box ref={ref} data-theme="light" color="chakra-body-text" {...props} />
  </LightMode>
))

export const DarkModeBox = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <DarkMode>
    <Box ref={ref} data-theme="dark" color="chakra-body-text" {...props} />
  </DarkMode>
))
