import {
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalOverlayProps,
  ModalProps,
  useColorMode,
} from '@chakra-ui/react'
import { ReactNode } from 'react'

export interface Props extends ModalProps {
  bodyProps?: ModalBodyProps
  contentProps?: ModalContentProps
  footer?: JSX.Element
  hideClose?: boolean
  overlayProps?: ModalOverlayProps
  title?: ReactNode
}

export default function Dialog({
  bodyProps,
  children,
  contentProps,
  footer,
  hideClose,
  overlayProps,
  title,
  ...rest
}: Props) {
  const { colorMode } = useColorMode()

  return (
    <Modal {...rest}>
      <ModalOverlay {...overlayProps} />
      <ModalContent
        overflow="auto"
        color={colorMode === 'light' ? 'black' : 'white'}
        {...(contentProps as any)}
      >
        {title && (
          <ModalHeader fontSize={24} pl={6} pr={12} pt={4} pb={2}>
            {title}
          </ModalHeader>
        )}
        {!hideClose && <ModalCloseButton right={4} top={4} />}
        <ModalBody p={0} {...(bodyProps as any)}>
          {children}
        </ModalBody>

        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  )
}
