import { Avatar, Card, CardBody, CardFooter, Circle, Flex, Image, Text } from '@chakra-ui/react'
import {
  BoltIcon,
  CalendarIcon,
  ClockIcon,
  DocumentTextIcon,
  LightModeBox,
  MapPinIcon,
} from 'components'
import { generatePath, useNavigate } from 'react-router-dom'
import { IEvent } from 'types'
import { useDisplayUser, useStorageUrl } from 'utils'
import global from 'utils/globals'
import defaultImage from '../../assets/event_tile.png'
import { formatEventDates, getEventImagePath } from '../../utils/eventUtils'
import { RoutePath } from '../../utils/routing'

interface Props extends IEvent {
  isUpcoming?: boolean
  isLive?: boolean
}

export default function EventCard(event: Props) {
  const navigate = useNavigate()
  const daysAway = getDaysAway(event.startTime!)
  const { user: eventManager } = useDisplayUser(event.managerId)
  const imageUrl = useStorageUrl(
    event.id && event.hasImage ? getEventImagePath(event.id) : undefined
  )?.url

  function goToEvent(id: string, workspaceId: string) {
    navigate(generatePath(RoutePath.EventInfo, { workspaceId: workspaceId!, id }))
  }

  return (
    <LightModeBox
      borderRadius="xl"
      maxW={{ base: global.EVENT_CARD_MOB_MAX_WIDTH, sm: global.EVENT_CARD_MAX_WIDTH }}
      boxShadow={'0px 4px 16px 0px rgba(0, 0, 0, 0.10)'}
      overflow="hidden"
    >
      <Card cursor="pointer" onClick={() => goToEvent(event.id, event.workspaceId)}>
        <Image
          objectFit="cover"
          width="308px"
          height="144px"
          src={imageUrl || defaultImage}
          alt={event.title}
          draggable="false"
        />
        <CardBody pb={3}>
          <Flex direction="column" gap={1} overflow="hidden">
            {event.title ? (
              <Text textStyle="bodyLarge" isTruncated textOverflow="ellipsis">
                {event.title}
              </Text>
            ) : (
              <Text textStyle="bodyLarge" as="i" color="gray">
                Event title
              </Text>
            )}
            <Flex justify="space-between" gap={3}>
              <Flex direction="column" overflow="hidden" gap={1}>
                <Flex alignItems="center" gap={1}>
                  <CalendarIcon
                    fontSize="lg"
                    color={event.startTime ? 'rgba(23, 23, 23, 1)' : 'rgba(137, 137, 137, 1)'}
                  />
                  <Text textStyle="bodyXSmall" opacity={0.7} isTruncated textOverflow="ellipsis">
                    {event.startTime ? formatEventDates(event.startTime, event.endTime) : '--'}
                  </Text>
                </Flex>
                <Flex alignItems="center" gap={1}>
                  <MapPinIcon fontSize="lg" opacity={event.location ? 1 : 0} />
                  <Text
                    textStyle="bodyXSmall"
                    opacity={event.location ? 0.7 : 0}
                    isTruncated
                    textOverflow="ellipsis"
                  >{`${event.location?.city}, ${
                    event.location?.state || event.location?.country
                  }`}</Text>
                </Flex>
              </Flex>
              <Flex align="center">
                <Avatar name={eventManager?.displayName} src={eventManager?.photoUrl} size="sm" />
              </Flex>
            </Flex>
          </Flex>
        </CardBody>
        {event.isLive ? (
          <CardFooter
            display={'flex'}
            bg={'rgba(30, 166, 68, 1)'}
            p={3}
            pl={4}
            backdropFilter={'blur(40px)'}
            boxShadow={'0px 7px 16px 0px rgba(0, 0, 0, 0.08)'}
          >
            <Flex direction={'row'} alignItems={'center'} gap={2}>
              <Circle size={'15px'} bg={'rgba(255, 255, 255, 0.27)'}>
                <Circle size={'5px'} bg={'white'}></Circle>
              </Circle>
              <Text textStyle="bodySmall" color={'white'}>
                Live
              </Text>
            </Flex>
          </CardFooter>
        ) : (
          <CardFooter
            display={'flex'}
            bg={'rgba(137, 137, 137, 0.05)'}
            p={3}
            pl={4}
            backdropFilter={'blur(40px)'}
            boxShadow={'0px 7px 16px 0px rgba(0, 0, 0, 0.08)'}
          >
            {event.isUpcoming ? (
              <Flex direction={'row'} alignItems={'center'} gap={2}>
                {daysAway ? (
                  <ClockIcon type="outline" />
                ) : (
                  <DocumentTextIcon color={'rgba(137, 137, 137, 1)'} />
                )}
                <Text
                  textStyle={'bodySmall'}
                  color={daysAway ? 'rgba(23, 23, 23, 1)' : 'rgba(137, 137, 137, 1)'}
                >
                  {daysAway ? daysAway : 'Draft'}
                </Text>
              </Flex>
            ) : (
              <Flex direction={'row'} alignItems={'center'} gap={2}>
                <BoltIcon fontSize={'md'} />
                <Text textStyle="bodySmall">{event.sessionCount?.toLocaleString() || 0} Leads</Text>
              </Flex>
            )}
          </CardFooter>
        )}
      </Card>
    </LightModeBox>
  )
}

function getDaysAway(startTime?: number) {
  if (!startTime) return ''

  const oneDay = 24 * 60 * 60 * 1000
  const today = new Date().getTime()

  const diffDays = Math.round(Math.abs((startTime - today) / oneDay))
  if (diffDays == 1) return `${diffDays} Day Away`
  else return `${diffDays.toLocaleString()} Days Away`
}
