import { Chart as GoogleChart, ReactGoogleChartProps } from 'react-google-charts'
import { ChartProps } from 'types'
import { defaultChartProps, makeChartData, makeDefaultChartOptions } from '../chartUtils'

export default function ComboChart(props: ChartProps) {
  const defaultProps = makeDefaultChartOptions(props)
  const options: ReactGoogleChartProps['options'] = {
    ...defaultProps,
    ...defaultProps,
    seriesType: 'bars',
    orientation: props.orientation === 'bar' ? 'vertical' : 'horizontal',
    series: props.seriesInfo.map(dataSeries => ({
      type: dataSeries.type,
      targetAxisIndex: dataSeries.axis === 'secondary' ? 1 : 0,
    })),
    chartArea: {
      left: props.orientation === 'bar' ? 180 : props.secondaryAxisTitle ? 90 : 50,
      right: props.legend?.show ? 200 : props.secondaryAxisTitle ? 90 : 20,
      top: 20,
      bottom: 30,
    },
    vAxes: {
      0: {
        viewWindow: {
          min: 0,
        },
        title: props.primaryAxisTitle,
      },
      1: {
        viewWindow: {
          min: 0,
        },
        gridlines: {
          color: '',
        },
        minorGridlines: {
          color: '',
        },
        title: props.secondaryAxisTitle,
        textStyle: { color: '#E77522' },
      },
    },
  }

  return (
    <GoogleChart
      {...defaultChartProps}
      chartType="ComboChart"
      data={makeChartData(props)}
      options={options}
    />
  )
}
