import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { useLocalStorage } from 'usehooks-ts'

export const usePrefCurrentWorkspace = () => useLocalStorage('current-workspace', '')

type WithVersion<T> = T & { _version?: number }

/** Access local storage based on key, initial value and version. Incrementing version number resets back to initial value */
export function usePreference<T extends object | null>(
  key: string,
  initialValue: T | null,
  version: number
) {
  const _initial = initialValue ? { ...initialValue, _version: version } : null
  const [data, setData] = useLocalStorage<WithVersion<T> | null>(key, _initial)
  const wrongVersion = data && data?._version !== version

  useEffect(() => {
    if (wrongVersion) {
      setData(_initial)
      location.reload() // needed to refresh page if runtime issues occur before version is updated
    }
  }, [wrongVersion])

  const updateData: Dispatch<SetStateAction<T>> = value => {
    const addVersion = (value: T) => (value ? { ...value, _version: version } : null)

    if (typeof value === 'function') {
      setData(prev => {
        // @ts-ignore
        const newValue = value(prev)
        return addVersion(newValue)
      })
    } else {
      setData(addVersion(value))
    }
  }

  const woVersion = useMemo(() => {
    if (!data) return null
    const { _version, ...rest } = data
    return rest
  }, [data])

  return [woVersion, updateData] as [T | null, Dispatch<SetStateAction<T>>]
}
