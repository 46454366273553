import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react'
import {
  Button,
  Cog6ToothIcon,
  DebouncedTextField,
  DeleteConfirmButton,
  HelpHover,
  InputContainer,
  LightModeBox,
  LocationAutocomplete,
  LockClosedIcon,
  Menu,
  NoSymbolIcon,
  QrCodeIcon,
  QueueListIcon,
  RadioCards,
  Select,
  ServerStackIcon,
  SingleDatePicker,
  SingleUseMediaInput,
  UserSelect,
} from 'components'
import { AuthContext, useAlert } from 'components/providers'
import { useContext, useEffect, useRef } from 'react'
import { EventRegistrationMode, IEvent, QRCodeConfiguration, XPressLeadsConfiguration } from 'types'
import { deleteDoc, directUploadImageToStorage, updateDoc, useIntercomUtils } from 'utils'
import { useGoToWorkspace, usePermission, useWorkspace } from 'utils/workspaceUtils'
import defaultImage from '../../../assets/event_tile.png'
import { getEventImagePath, useEvent } from '../../../utils/eventUtils'
import { useTemplates } from '../../../utils/templateUtils'
import DatabaseLink from './DatabaseLink'
import QrLink from './QrLink'
import SessionOptions from './SessionOptions'
import TemplateOverrides from './TemplateOverrides'

export interface Props {
  isOpen: boolean
  onClose: () => void
}

export default function ManageEvent({ isOpen, onClose }: Props) {
  const toast = useToast()
  const { confirm } = useAlert()
  const { user } = useContext(AuthContext)
  const { workspace } = useWorkspace()
  const permission = usePermission()
  const templates = useTemplates(workspace?.id)
  const goToWorkspace = useGoToWorkspace()
  const goBack = () => goToWorkspace(workspace?.id, {})
  const { event, refetchImage } = useEvent()
  useIntercomUtils(isOpen)
  var allowStartDateEdit = false
  var allowEndDateEdit = false
  var allowManagerEdit = false
  const contentRef = useRef<HTMLDivElement>(null)

  const {
    endTime,
    id,
    sessionCount,
    registrationMode,
    qrCode,
    startTime,
    template,
    title = '',
    location,
    managerId,
    url = '',
    hasImage,
  } = event || {}
  const midPointTime = getMidpointUtcString(startTime!, endTime!)

  if (!event) return null

  if (permission.event !== 'viewer') {
    if (!startTime || Date.now() <= new Date(startTime).getTime()) allowStartDateEdit = true
    if (Date.now() <= new Date(new Date(startTime!).getTime() + 7 * 24 * 60 * 60 * 1000).getTime())
      allowEndDateEdit = true
  }

  if (permission.event === 'admin' || user?.id === managerId) allowManagerEdit = true

  useEffect(() => {
    if (!templates.length || !event || event.template?.id) return
    updateEvent({ template: { id: templates[0].id } })
  }, [templates, event?.template])

  function pullImageFromUrl() {
    if (id && !hasImage) {
      const fullFilePath = `Workspaces/${workspace!.id}/${getEventImagePath(id)}`
      directUploadImageToStorage(user?.id!, url, fullFilePath, id)
    }
  }

  async function updateEvent(body: Partial<IEvent>) {
    await updateDoc(user!.id, 'events', { id: id!, ...body })
  }

  async function handleDelete() {
    await deleteDoc('events', event!.id).catch(() =>
      toast({ status: 'error', description: 'Failed to delete event' })
    )
    goBack()
  }

  function clearDates() {
    updateEvent({ startTime: 0, endTime: 0 })
  }

  async function confirmManagerChange(newManagerId: string) {
    if (user!.id === managerId && permission.event !== 'admin') {
      const response = await confirm(
        'You will no longer be able to assign/change the event manager if you click Confirm. Please reach out to your workspace admin to be added back.',
        {
          cancelText: 'Cancel',
          okText: 'Confirm',
        }
      )
      if (!response) return false

      updateEvent({ managerId: newManagerId })
      return true
    } else updateEvent({ managerId: newManagerId })
  }

  async function regModeChange(value: EventRegistrationMode) {
    if (value !== 'qr-code' && qrCode?.isValid) {
      const response = await confirm(
        'Changing registration mode will reset the current registration settings. Are you sure you want to proceed?',
        { okText: 'Proceed', cancelText: 'Cancel' }
      )
      if (!response) return
    }

    updateEvent({ registrationMode: value, qrCode: value === 'qr-code' ? defaultQrCode : null })
  }

  function handleDateChange(date: Date, isStartDate: Boolean) {
    if (permission.event === 'viewer') return
    if (isStartDate) {
      updateEvent({ startTime: date.getTime(), endTime: 0 })
    } else {
      if (startTime! === date.getTime()) updateEvent({ startTime: startTime!, endTime: 0 })
      else updateEvent({ endTime: date.getTime() })
    }
  }

  function getMidpointUtcString(startTime: number, endTime: number): string {
    const midpointTimestamp = (startTime + endTime) / 2
    const midpointDate = new Date(midpointTimestamp)
    const utcString = midpointDate.toISOString().replace('T', ' ').substring(0, 19)
    return utcString
  }

  if (!event) return null

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      size={{ base: 'xs', sm: 'sm' }}
      isOpen={isOpen}
      closeOnEsc
      trapFocus={false}
    >
      <DrawerOverlay />
      <LightModeBox>
        <DrawerContent ref={contentRef} borderRadius="md" my={2} mr={2}>
          <DrawerHeader borderBottom="1px solid rgba(222, 226, 244, 1)" p={2}>
            <Flex width="100%" align="center" gap={1} px={4} h="40px">
              <Cog6ToothIcon fontSize="20px" />
              <Text textStyle="bodyMedium">Manage Event</Text>
              <Spacer />
              {(permission.event === 'admin' ||
                (permission.event === 'editor' && event.sessionCount === 0)) && (
                <Menu
                  menuProps={{ placement: 'bottom-start' }}
                  buttonLabel="Actions"
                  mr={6}
                  noPortal
                  options={[
                    {
                      type: 'custom',
                      label: (
                        <DeleteConfirmButton
                          onDelete={handleDelete}
                          description='If you are sure type "Delete" below.'
                        />
                      ),
                    },
                  ]}
                />
              )}
              <DrawerCloseButton m={1} />
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <LightModeBox display="flex" flexDirection="column" height="95%" mt={3} gap={5}>
              <DebouncedTextField
                label="Name"
                id="title"
                value={title}
                placeholder="Please enter event name"
                onChange={title => updateEvent({ title })}
                showStatus
                autoFocus
                {...inputProps}
              />

              <InputContainer
                label="Event Dates"
                labelActions={
                  startTime != 0 &&
                  allowStartDateEdit &&
                  allowEndDateEdit && (
                    <Button
                      variant="ghost"
                      palette="danger"
                      height={5}
                      size="sm"
                      fontSize={'12px'}
                      onClick={clearDates}
                    >
                      Clear dates
                    </Button>
                  )
                }
              >
                <Flex gap={0} align={'center'} justify={'space-between'} width={'100%'}>
                  <SingleDatePicker
                    date={startTime ? new Date(startTime) : undefined}
                    handleDateChange={(date: Date) => handleDateChange(date, true)}
                    isDisabled={!allowStartDateEdit}
                  />
                  <Text textStyle={'bodySmall'} opacity={0.6} fontWeight={'normal'}>
                    -
                  </Text>
                  <SingleDatePicker
                    date={endTime ? new Date(endTime) : undefined}
                    handleDateChange={(date: Date) => handleDateChange(date, false)}
                    isDisabled={!allowEndDateEdit || startTime === 0}
                    minDate={startTime ? startTime + 86400 : undefined}
                  />
                </Flex>
              </InputContainer>

              <LocationAutocomplete
                label="Event Location"
                value={location}
                onChange={location => updateEvent({ location })}
                popoverProps={{ portalProps: { containerRef: contentRef } }}
                {...inputProps}
              />

              {allowManagerEdit && managerId && (
                <UserSelect
                  label="Event Manager"
                  value={managerId}
                  onChange={confirmManagerChange}
                  permissionsAllowed={['admin', 'editor']}
                />
              )}

              <DebouncedTextField
                label="Event URL"
                id="eventURL"
                value={url}
                placeholder="Please enter event URL"
                onChange={url => updateEvent({ url })}
                onBlur={pullImageFromUrl}
                showStatus
                {...inputProps}
              />

              <InputContainer label="Template">
                <Flex align="center" gap={2}>
                  <Select
                    isDisabled={Boolean(sessionCount)}
                    value={template?.id || ''}
                    placeholder="Select a template"
                    onChange={e => updateEvent({ template: { ...template, id: e.target.value } })}
                  >
                    {templates.map(t => (
                      <option key={t.id} value={t.id}>
                        {t.title.en}
                      </option>
                    ))}
                  </Select>
                  {Boolean(sessionCount) && (
                    <>
                      <LockClosedIcon color="red" type="outline" size="sm" />
                      <HelpHover
                        text="Can't change template once sessions have been captured. Delete existing sessions to update."
                        borderRadius="5px"
                        iconProps={{ size: 'sm' }}
                      />
                    </>
                  )}
                </Flex>
              </InputContainer>

              {id && (
                <SingleUseMediaInput
                  type="image"
                  defaultImage={defaultImage}
                  siteUrl={url}
                  filePath={getEventImagePath(id)}
                  noFile={!hasImage}
                  onClose={async action => {
                    if (action === 'save' && !hasImage) {
                      await updateDoc(user!.id, 'events', { id, hasImage: true })
                    } else if (action === 'delete' && hasImage) {
                      await updateDoc(user!.id, 'events', { id, hasImage: false })
                    }
                    refetchImage()
                  }}
                />
              )}
              <Divider />

              <InputContainer label="Registration Mode">
                <RadioCards
                  value={registrationMode || 'none'}
                  options={registrationOptions}
                  onChange={regModeChange}
                />
              </InputContainer>

              {registrationMode === 'database' && <DatabaseLink />}

              {registrationMode === 'qr-code' && (
                <QrLink
                  eventTitle={title}
                  testScanTime={midPointTime}
                  qrCode={qrCode}
                  onProviderChange={(value: string) => {
                    const updatedQRCode = {
                      ...qrCode,
                      type: value == 'not-listed' ? '' : value,
                    } as QRCodeConfiguration
                    updateEvent({ qrCode: updatedQRCode ?? null })
                  }}
                  onConfigurationChange={(value: Partial<XPressLeadsConfiguration>) => {
                    const updatedQRCode = {
                      ...qrCode,
                      ...value,
                      isValid: value.isValid || false,
                      leadMapping: null,
                    } as QRCodeConfiguration
                    updateEvent({ qrCode: updatedQRCode })
                  }}
                />
              )}

              <TemplateOverrides event={event} updateEvent={updateEvent} />
              <SessionOptions event={event} updateEvent={updateEvent} />
              <Box pt={5} />
            </LightModeBox>
          </DrawerBody>
        </DrawerContent>
      </LightModeBox>
    </Drawer>
  )
}

const inputProps = {
  textStyle: 'bodyLarge',
  fontWeight: 'normal',
  border: '1px solid rgb(226, 232, 240)',
  borderRadius: '10px',
}

const registrationOptions: { value: EventRegistrationMode; label: string; icon: JSX.Element }[] = [
  { value: 'input', label: 'Form', icon: <QueueListIcon /> },
  { value: 'database', label: 'Database', icon: <ServerStackIcon /> },
  { value: 'qr-code', label: 'QR Code', icon: <QrCodeIcon /> },
  { value: 'none', label: 'None', icon: <NoSymbolIcon /> },
]

const defaultQrCode: QRCodeConfiguration = {
  authKey: '',
  eventCode: '',
  exhibitorId: '',
  type: 'xpressleads',
  isValid: false,
  leadMapping: null,
}
