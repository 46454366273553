import { Box, BoxProps, Text, useToast } from '@chakra-ui/react'
import { forwardRef, useImperativeHandle } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

export interface Props extends Omit<BoxProps, 'children' | 'onDrop'> {
  children: (api: FileDropHandle) => React.ReactNode
  options?: Omit<DropzoneOptions, 'onDrop'>
  onDrop: DropzoneOptions['onDrop']
  overlayProps?: BoxProps
}

export interface FileDropHandle {
  open: () => void
}

export default forwardRef<FileDropHandle, Props>(
  ({ overlayProps, children, options, onDrop, ...rest }, ref) => {
    const toast = useToast()

    const handleDrop: DropzoneOptions['onDrop'] = (acceptedFiles, fileRejections, event) => {
      if (!onDrop) return
      if (!acceptedFiles.length) return toast({ title: 'Wrong file type', status: 'error' })

      onDrop(acceptedFiles, fileRejections, event)
    }

    const { getRootProps, getInputProps, isDragActive, open, isDragAccept } = useDropzone({
      onDrop: handleDrop,
      maxFiles: 1,
      noClick: true,
      noDragEventsBubbling: true,
      ...options,
    })

    const api = { open }
    useImperativeHandle(ref, () => api)

    return (
      <Box {...getRootProps()} position="relative" {...rest}>
        <input {...getInputProps()} />

        {onDrop && isDragActive && (
          <Box
            inset={0}
            position="absolute"
            bg={isDragAccept ? 'rgba(0,0,0,0.5)' : 'danger.300'}
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            border={'1px dashed gray'}
            zIndex={999}
            {...overlayProps}
          >
            <Text>{isDragAccept ? 'Drop files here' : 'File not accepted'}</Text>
          </Box>
        )}

        {children(api)}
      </Box>
    )
  }
)
