import { Avatar, Box, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'
import { Button, ChevronLeftIcon, Cog6ToothIcon, MapPinIcon, Panel } from 'components'
import { useEffect } from 'react'
import { usePermission } from 'utils'
import useGoBack, { useDisplayUser } from 'utils/workspaceUtils'
import defaultImage from '../assets/default_tile.png'
import { FacilityManage, FacilitySettings } from '../components/Facility'
import { useFacility } from '../utils/facilityUtils'
import { useBreakpoint } from '../utils/webHelpers'

export default function Facility() {
  const permission = usePermission()
  const { facility, imageUrl } = useFacility()
  const { user: manager } = useDisplayUser(facility?.managerId)
  const goBack = useGoBack()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const breakpoint = useBreakpoint()

  useEffect(() => {
    if (facility?.title === '') onOpen()
  }, [facility])

  if (!facility) return null

  const { location, title, hasImage } = facility
  const isViewer = permission.facility === 'viewer'

  return (
    <Flex
      overflow="auto"
      direction="column"
      align="stretch"
      gap={4}
      flex={1}
      px={{ base: 0, sm: 4, xl: 12 }}
      pt={{ base: 0, sm: 4 }}
    >
      <Flex direction={{ base: 'column', lg: 'row' }} gap={4}>
        {breakpoint < 3 ? (
          <Flex
            height="200px"
            borderRadius={{ base: 0, sm: '3xl' }}
            bgImage={hasImage ? imageUrl : defaultImage}
            bgSize="cover"
            bgPosition="center"
            p={4}
            align="flex-start"
          >
            {breakpoint < 1 && (
              <Panel>
                <Button
                  variant="ghost"
                  onClick={goBack}
                  leftIcon={<ChevronLeftIcon fontSize={'lg'} />}
                >
                  All Facilities
                </Button>
              </Panel>
            )}
          </Flex>
        ) : (
          <Image
            width="400px"
            borderRadius="3xl"
            height="200px"
            objectFit="cover"
            src={hasImage ? imageUrl : defaultImage}
          />
        )}

        <Flex overflow="hidden" direction="column" gap={6} mx={{ base: 4, sm: 0 }}>
          <Flex direction={'column'} justify="space-between" gap={2}>
            <Text textStyle={{ base: 'h5', lg: 'h1' }}>{title ? title : '--'}</Text>
            {location && (
              <Flex align="center" gap={2}>
                <MapPinIcon />
                <Text textStyle="body2">{`${location.city}, ${
                  location.state || location.country
                }`}</Text>
              </Flex>
            )}
            <Flex direction="column" alignItems="flex-start" gap={4} mt={1}>
              <Flex align="center" gap={2}>
                <Text textStyle="bodySmall">Facility Manager: </Text>
                <Avatar name={manager?.displayName} src={manager?.photoUrl} size={'xs'} />
                <Text textStyle="bodySmall">{manager?.displayName}</Text>
              </Flex>

              {!isViewer && (
                <Panel>
                  <Button variant="ghost" leftIcon={<Cog6ToothIcon />} onClick={onOpen}>
                    Manage Facility
                  </Button>
                </Panel>
              )}
            </Flex>
          </Flex>

          <FacilityManage isOpen={isOpen} onClose={onClose} />
        </Flex>
      </Flex>

      <FacilitySettings />

      <Box />
    </Flex>
  )
}
