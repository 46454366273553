import {
  Avatar,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Spacer,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import {
  ArrowDownTrayIcon,
  Button,
  CheckIcon,
  IconButton,
  PencilSquareIcon,
  PlusIcon,
  XMarkIcon,
} from 'components'
import ColorModeSwitch from 'components/ColorModeSwitch'
import { AuthContext } from 'components/providers'
import { useContext } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import {
  createDoc,
  makeEmptyWorkspace,
  signOut,
  usePermission,
  useSwitchWorkspace,
  useWorkspaces,
} from 'utils'
import { useCurrentPage } from '../utils/pageUtils'
import { RoutePath } from '../utils/routing'
import { useSideMenuItems } from '../utils/sideMenuUtils'
import CreateNewButton from './CreateNewButton'
import SideMenuButton from './SideMenuButton'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export default function SideMenuDrawer({ isOpen, onClose }: Props) {
  const { entevatePermission, user } = useContext(AuthContext)
  const { colorMode } = useColorMode()
  const { workspaceId } = useParams()
  const permission = usePermission()
  const { workspaces, mutate } = useWorkspaces('emp')
  const switchWorkspace = useSwitchWorkspace()
  const currentPage = useCurrentPage()
  const currentPagePath = currentPage?.isChild
    ? currentPage?.rootPath
      ? currentPage.rootPath
      : currentPage.parentPath
    : currentPage?.path
  const navigate = useNavigate()
  const sideMenuItems = useSideMenuItems()

  const goToManage = (workspaceId: string) =>
    navigate(generatePath(RoutePath.ManageWorkspace, { workspaceId }))

  async function handleNewWorkspace() {
    const doc = await createDoc(user?.id!, 'Workspace', makeEmptyWorkspace('emp'))
    mutate()
    goToManage(doc.id)
    onClose()
  }

  return (
    <Drawer placement={'left'} onClose={onClose} isOpen={isOpen} closeOnOverlayClick={true}>
      <DrawerOverlay>
        <DrawerContent borderRightRadius="sm" pb={5}>
          <DrawerHeader>
            <Flex
              width={'100%'}
              justify={'center'}
              align={'center'}
              justifyContent={'space-between'}
            >
              <Flex align="center" gap={10}>
                <IconButton
                  variant="ghost"
                  aria-label="close drawer"
                  icon={<XMarkIcon />}
                  onClick={onClose}
                />
                <Image
                  src={
                    colorMode === 'light'
                      ? '/common/momentify-logo-dark.svg'
                      : '/common/momentify-logo-light.svg'
                  }
                  alt="momentify logo"
                  height={colorMode === 'light' ? '20px' : '22px'}
                />
              </Flex>
              <CreateNewButton variant="icon" onCreate={onClose} />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Flex width={'100%'} height={'100%'} gap={2} direction={'column'} pb={3}>
              <Flex direction="column">
                {sideMenuItems.map((item, index) => (
                  <SideMenuButton
                    key={index}
                    py={2}
                    children={item.text}
                    leftIcon={<item.LeftIcon fontSize={'lg'} />}
                    isCurrent={item.isCurrent}
                    onClick={() => {
                      if (workspaceId)
                        navigate({
                          pathname: generatePath(item.page, { workspaceId, id: null }),
                        })
                      onClose()
                    }}
                    isDisabled={item.isDisabled}
                  />
                ))}
              </Flex>
              <Divider />
              <Flex direction={'column'} gap={1} pl={4} py={1}>
                <Text
                  textStyle={'bodyXSmall'}
                  color={
                    colorMode === 'light' ? 'rgba(23, 23, 23, 0.6)' : 'rgba(255, 255, 255, 0.6)'
                  }
                >
                  {workspaces?.length == 1 ? 'Workspace' : 'Workspaces'}
                </Text>
                {workspaces?.map(({ id, title }) => (
                  <Flex key={id}>
                    <Button
                      leftIcon={<CheckIcon fontSize={'lg'} opacity={workspaceId === id ? 1 : 0} />}
                      borderRadius="sm"
                      flex={1}
                      variant="ghost"
                      justifyContent={'flex-start'}
                      onClick={() => {
                        switchWorkspace(id, currentPagePath)
                        onClose()
                      }}
                    >
                      <Text
                        textStyle="bodySmall"
                        isTruncated
                        opacity={title ? 1 : 0.5}
                        as={title ? 'p' : 'i'}
                      >
                        {title || 'Draft'}
                      </Text>
                    </Button>
                    {permission.workspace === 'admin' && (
                      <IconButton
                        aria-label="manage workspace"
                        borderRadius="sm"
                        variant="ghost"
                        icon={<PencilSquareIcon fontSize={'lg'} opacity={0.6} />}
                        onClick={() => {
                          goToManage(id)
                          onClose()
                        }}
                      />
                    )}
                  </Flex>
                ))}
                {entevatePermission === 'entevate' && (
                  <Button
                    leftIcon={<PlusIcon />}
                    borderRadius="sm"
                    variant="outline"
                    onClick={handleNewWorkspace}
                  >
                    Create New
                  </Button>
                )}
              </Flex>
              <Divider />
              <Flex
                direction={'row'}
                gap={2}
                align={'center'}
                px={4}
                py={1}
                onClick={() => {
                  navigate(`/${workspaceId}/profile`)
                  onClose()
                }}
              >
                <Avatar
                  key={user?.photoUrl!}
                  cursor="pointer"
                  size="sm"
                  name={user?.name!}
                  src={user?.photoUrl!}
                />
                <Text textStyle={'bodySmall'}>{user?.name}</Text>
                <Spacer />
              </Flex>
              <Divider />

              <Button
                leftIcon={<ArrowDownTrayIcon size="sm" style={{ transform: 'rotate(90deg)' }} />}
                variant="ghost"
                justifyContent="flex-start"
                onClick={() => signOut()}
              >
                <Text textStyle={'bodySmall'}>Log Out</Text>
              </Button>

              <Divider />
              <Flex direction={'row'} justifyContent={'space-between'} align={'center'} pl={4}>
                <Text textStyle={'bodySmall'}>Theme</Text>
                <ColorModeSwitch />
              </Flex>

              <Spacer />
              <Flex direction={'row'} justify={'center'}>
                <Flex direction={'column'} opacity={colorMode === 'light' ? 0.7 : 1}>
                  <Text fontSize={'10px'}>powered by</Text>
                  <Image
                    src={
                      colorMode === 'light'
                        ? '/common/entevate_logo_black.svg'
                        : '/common/entevate_logo_white.svg'
                    }
                    height={6}
                  />
                </Flex>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}
