import { Flex, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react'
import { startCase } from 'lodash'
import { useEffect, useState } from 'react'
import { ArrowLongLeftIcon, XMarkIcon } from './icons'
import Menu from './Menu'

interface Props {
  referenceField: string
  targetFieldsAndValues: Record<string, string>
  error?: boolean
  onChange: (val: string) => void
}

export default function FieldMapper({
  referenceField,
  targetFieldsAndValues,
  error,
  onChange,
}: Props) {
  const [selectedKey, setSelectedKey] = useState('')
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })

  useEffect(() => {
    if (selectedKey === '' && Object.keys(targetFieldsAndValues).length > 0) {
      const closestKey = getClosestKey(referenceField)
      onChange(closestKey)
      setSelectedKey(closestKey)
    }
  }, [referenceField])

  function getClosestKey(key: string) {
    const keys = Object.keys(targetFieldsAndValues)
    const normalizedKey = key.toLowerCase().replace(/\s+/g, '')

    let mostSimilarMatch = ''

    for (const curr of keys) {
      const currLower = curr.toLowerCase().replace(/\s+/g, '')

      if (currLower === normalizedKey) return curr

      if (currLower.includes(normalizedKey)) {
        if (mostSimilarMatch === '' || curr.length < mostSimilarMatch.length) {
          mostSimilarMatch = curr
        }
      }
    }
    return mostSimilarMatch
  }

  return (
    <SimpleGrid w="100%" columns={3} spacing={3}>
      <Flex gap={3} justify="space-between" align="center">
        <Text {...textProps} w="100%">
          {startCase(referenceField)}
        </Text>
        {!isMobile && <ArrowLongLeftIcon transform="rotateY(180deg)" />}
      </Flex>
      <Menu
        noPortal
        border={error ? '1px solid red' : '1px solid rgba(23, 23, 23, 0.20)'}
        menuProps={{ closeOnSelect: true, matchWidth: true }}
        listProps={{ overflow: 'auto', maxH: '200px' }}
        buttonLabel={startCase(selectedKey)}
        options={[
          {
            type: 'group',
            groupType: 'radio',
            options: [
              ...(selectedKey !== ''
                ? [
                    {
                      type: 'item' as const,
                      value: '',
                      label: (
                        <Flex gap={1} align="center">
                          <XMarkIcon color="red" fontSize="md" />
                          <Text textStyle="bodySmall" color="red" fontWeight="500" as="i">
                            Clear
                          </Text>
                        </Flex>
                      ),
                      onClick: () => {
                        onChange('')
                        setSelectedKey('')
                      },
                    },
                  ]
                : []),
              ...Object.keys(targetFieldsAndValues).map(key => ({
                type: 'item' as const,
                value: key,
                label: startCase(key),
                onClick: () => {
                  onChange(key)
                  setSelectedKey(key)
                },
              })),
            ],
          },
        ]}
        {...menuStyling}
      />
      <Text {...textProps}>{targetFieldsAndValues[selectedKey]}</Text>
    </SimpleGrid>
  )
}

const textProps = {
  bg: 'rgba(23, 23, 23, 0.10)',
  borderRadius: '10px',
  minW: '100px',
  h: '40px',
  py: '10px',
  px: '16px',
  textStyle: 'bodyMedium',
  fontWeight: '500',
  isTruncated: true,
}

const menuStyling = {
  minW: '100px',
  borderRadius: '10px',
  textStyle: 'bodyLarge',
  fontWeight: '500',
}
