import { Box, Switch, useColorMode } from '@chakra-ui/react'
import { MoonIcon, SunIcon } from 'components'

export default function ColorModeSwitch() {
  const { colorMode, setColorMode } = useColorMode()

  return (
    <Box position="relative" display="inline-block" width="74px" height="40px">
      <Switch
        colorScheme={colorMode === 'light' ? 'gray' : ''}
        defaultChecked={colorMode === 'dark'}
        onChange={e => setColorMode(e.target.checked ? 'dark' : 'light')}
        sx={{
          '.chakra-switch__track': {
            width: '70px',
            height: '36px',
            borderRadius: '14px',
            bg: colorMode === 'light' ? 'rgba(255, 255, 255, 0.70)' : 'rgba(255, 255, 255, 0.1)',
            boxShadow: '0px 7px 16px 0px rgba(0, 0, 0, 0.08)',
          },
          '.chakra-switch__thumb': {
            width: '30px',
            height: '30px',
            borderRadius: '10px',
            bg: colorMode === 'light' ? 'rgba(60, 75, 211, 0.10)' : 'rgba(255, 255, 255, 0.35)',
            transform: 'translateX(4px) translateY(3px) ',
          },
          '.chakra-switch__thumb[data-checked]': {
            transform: 'translateX(37px) translateY(3px)',
          },
        }}
        position="relative"
      />
      <Box
        position="absolute"
        top="27%"
        left="17%"
        pointerEvents="none"
        color={colorMode === 'light' ? 'primary.500' : 'gray.600'}
      >
        <SunIcon fontSize="lg" />
      </Box>
      <Box
        position="absolute"
        top="25%"
        right="15%"
        pointerEvents="none"
        color={colorMode === 'dark' ? 'white' : 'gray.500'}
      >
        <MoonIcon fontSize="md" />
      </Box>
    </Box>
  )
}
