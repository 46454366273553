import { CircularProgress, Flex, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import {
  Button,
  CheckCircleIcon,
  ChevronRightIcon,
  Cog6ToothIcon,
  DebouncedTextField,
  EnvelopeIcon,
  InputContainer,
  Menu,
} from 'components'
import { useState } from 'react'
import { QRCodeConfiguration, XPressLeadsConfiguration } from 'types'
import { sendIntercomMessage, testQrConnection } from 'utils'
import QrMappingDrawer from './QrMappingDrawer'

interface Props {
  qrCode?: QRCodeConfiguration
  onProviderChange?: (value: string) => void
  onConfigurationChange?: (value: Partial<XPressLeadsConfiguration>) => void
  eventTitle?: string
  testScanTime?: string
}

export default function QrLink({
  qrCode,
  onProviderChange,
  onConfigurationChange,
  eventTitle,
  testScanTime,
}: Props) {
  const [authKeyError, setAuthKeyError] = useState('')
  const [exhibitorIdError, setExhibitorIdError] = useState('')
  const [eventCodeError, setEventCodeError] = useState('')
  const [loading, setLoading] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const readyForAuthentication =
    qrCode?.type === 'xpressleads' && qrCode.authKey && qrCode.exhibitorId && qrCode.eventCode

  async function authenticateApi(scanTime: string) {
    if (!qrCode) return

    try {
      await testQrConnection({ ...qrCode, scanTime })
      setLoading(false)
      onConfigurationChange?.({ isValid: true })
    } catch (error) {
      setLoading(false)
      setErrorMessage(String(error))
      onConfigurationChange?.({ isValid: false })
    }
  }

  function setErrorMessage(apiError: string) {
    if (apiError.includes('Invalid authentication key'))
      setAuthKeyError('Authentication Key is incorrect, please try again.')
    if (apiError.includes('key has expired') || apiError.includes('exhibitor ID'))
      setExhibitorIdError('Exhibitor Id is incorrect, please try again.')
    if (apiError.includes('Invalid event code'))
      setEventCodeError('Event code is incorrect, please try again.')
  }

  function clearErrorMessages() {
    setAuthKeyError('')
    setExhibitorIdError('')
    setEventCodeError('')
  }

  return (
    <Flex direction="column" gap={5}>
      <InputContainer label="Select a Service">
        <Menu
          variant="outline"
          borderRadius="10px"
          menuProps={{ matchWidth: true }}
          noPortal
          options={[
            {
              type: 'group',
              options: qRServiceProviders.map(({ value, label }) => ({ value, label })),
            },
          ]}
          value={qrCode?.type || 'not-listed'}
          onChange={value => onProviderChange?.(value as string)}
        />
      </InputContainer>

      {qrCode?.type === 'xpressleads' && (
        <Flex direction="column" gap={3}>
          <DebouncedTextField
            label="Authentication Key"
            id="authKey"
            value={qrCode.authKey || ''}
            placeholder="Please enter authentication key"
            onChange={value => onConfigurationChange?.({ authKey: value, isValid: false })}
            isError={!!authKeyError}
            helperText={authKeyError}
            {...inputProps}
          />

          <DebouncedTextField
            label="Exhibitor ID"
            id="exhibitorId"
            value={qrCode.exhibitorId || ''}
            placeholder="Please enter exhibitor id"
            onChange={value => onConfigurationChange?.({ exhibitorId: value, isValid: false })}
            isError={!!exhibitorIdError}
            helperText={exhibitorIdError}
            {...inputProps}
          />

          <DebouncedTextField
            label="Event Code"
            id="eventCode"
            value={qrCode.eventCode || ''}
            placeholder="Please enter event code"
            onChange={value => onConfigurationChange?.({ eventCode: value, isValid: false })}
            isError={!!eventCodeError}
            helperText={eventCodeError}
            {...inputProps}
          />

          {readyForAuthentication && !qrCode.isValid && (
            <Button
              w={loading ? '160px' : '114px'}
              palette="primary"
              leftIcon={
                loading ? (
                  <CircularProgress isIndeterminate={true} color="white" size="20px" />
                ) : undefined
              }
              onClick={() => {
                clearErrorMessages()
                setLoading(true)
                authenticateApi(
                  testScanTime || new Date().toISOString().slice(0, 19).replace('T', ' ')
                )
              }}
            >
              <Text opacity={loading ? 0.6 : 1}>
                {loading ? 'Authenticating...' : 'Authenticate'}
              </Text>
            </Button>
          )}

          {readyForAuthentication && qrCode.isValid && (
            <Flex direction="column" gap={2}>
              <Flex gap={1} opacity={0.6}>
                <CheckCircleIcon fontSize="sm" />
                <Text textStyle="bodyXSmall">Authenticated</Text>
              </Flex>
              <Button
                variant="outline"
                leftIcon={<Cog6ToothIcon fontSize="lg" />}
                justifyContent="flex-start"
                onClick={onOpen}
              >
                <Flex gap={1} flex={1}>
                  <Text textStyle="bodySmall">Map Fields </Text>
                  {!qrCode.leadMapping && (
                    <Text textStyle="bodySmall" color="red">
                      (Required)
                    </Text>
                  )}
                  <Spacer />
                  <ChevronRightIcon fontSize="md" />
                </Flex>
              </Button>
            </Flex>
          )}

          {}
        </Flex>
      )}

      {!qrCode?.type && (
        <Button
          variant="outline"
          leftIcon={<EnvelopeIcon fontSize="lg" />}
          h="40px"
          onClick={() =>
            sendIntercomMessage(
              `Hello team, I need help with my QR service provider setup for the event ${eventTitle}!`
            )
          }
        >
          Reach out to Momentify team for setup support
        </Button>
      )}

      {isOpen && <QrMappingDrawer isOpen={isOpen} onClose={onClose} />}
    </Flex>
  )
}

const qRServiceProviders: { value: string; label: string }[] = [
  { value: 'xpressleads', label: 'XPress Leads' },
  { value: 'not-listed', label: 'Not Listed' },
]

const inputProps = {
  textStyle: 'bodyLarge',
  fontWeight: 'normal',
  border: '1px solid rgb(226, 232, 240)',
  borderRadius: '10px',
}
