import { LogoLoader, SignIn } from 'components'
import { createContext, useEffect, useMemo, useState } from 'react'
import { matchPath } from 'react-router-dom'
import { AuthUser, EntevatePermission, IRoute, IUser, OmitDBProps } from 'types'
import { isDevelopment, onAuthChanged, onDoc, updateDoc } from 'utils'

export interface AuthProviderProps {
  developmentFeatures: boolean
  entevatePermission: EntevatePermission
  updateDevelopmentFeatures: (value: boolean) => void
  updateEntevatePermission: (permission: EntevatePermission) => void
  updateUser: (user: Partial<OmitDBProps<IUser>>, id: string) => void
  /** undefined initial state, then null if no user found */
  user?: User | null
}

type User = AuthUser & Partial<IUser>

export const AuthContext = createContext<AuthProviderProps>({} as any)

interface Props {
  children: React.ReactNode
  routes?: IRoute[]
}

export function AuthProvider({ children, routes }: Props) {
  const [authUser, setAuthUser] = useState<AuthUser | null | undefined>(undefined)
  const [entevatePermission, setEntevatePermission] = useState<EntevatePermission>('viewer')
  const [developmentFeatures, setDevelopmentFeatures] = useState(isDevelopment)
  const [userDoc, setUserDoc] = useState<IUser | null | undefined>(undefined)

  const route = routes?.find(({ path }) => matchPath(path, window.location.pathname))

  async function updateUser(user: Partial<IUser>, id: string) {
    await updateDoc(id, 'users', { id: id, ...user })
  }

  useEffect(() => {
    const unsubscribe = onAuthChanged(user => {
      setAuthUser(user)
      setEntevatePermission(user?.isAdmin ? 'entevate' : 'viewer')
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    if (authUser === undefined) return
    if (authUser === null) return setUserDoc(null)

    const unsubscribe = onDoc('users', authUser.id, ({ doc }) => {
      setUserDoc(doc)
    })

    return unsubscribe
  }, [authUser])

  const user = useMemo(() => {
    if (authUser === undefined || userDoc === undefined) return undefined
    return authUser ? { ...authUser, ...userDoc } : null
  }, [authUser, userDoc])

  if (!route?.isPublic) {
    if (authUser === undefined) return <LogoLoader />
    if (authUser === null) return <SignIn />
  }

  return (
    <AuthContext.Provider
      value={{
        developmentFeatures,
        entevatePermission,
        updateDevelopmentFeatures: setDevelopmentFeatures,
        updateEntevatePermission: setEntevatePermission,
        updateUser,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
