import {
  Box,
  Popover as ChakraPopover,
  Flex,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Portal,
  PortalProps,
  Text,
} from '@chakra-ui/react'
import { ReactNode, useState } from 'react'

export interface Props extends PopoverProps {
  children: ReactNode
  contentProps?: PopoverContentProps
  header?: ReactNode
  hideClose?: boolean
  portalProps?: Omit<PortalProps, 'children'>
  triggerNode: ReactNode
}

export default function Popover({
  children,
  contentProps,
  header,
  hideClose,
  placement = 'bottom-end',
  portalProps,
  triggerNode,
  isOpen,
  onClose,
  closeOnBlur,
  onOpen,
  ...rest
}: Props) {
  const [open, setOpen] = useState(false)

  function handleToggle() {
    if (isOpen === undefined) setOpen(s => !s)
    else isOpen ? onClose?.() : onOpen?.()
  }

  const _open = isOpen ?? open

  return (
    <ChakraPopover
      isOpen={_open}
      placement={placement}
      onOpen={handleToggle}
      onClose={handleToggle}
      {...rest}
    >
      <PopoverTrigger>{triggerNode}</PopoverTrigger>

      <Portal {...portalProps}>
        <PopoverContent overflow="auto" width="inherit" {...(contentProps as any)}>
          <Flex justifyContent="space-between" p={header || !hideClose ? 1 : 0}>
            {header ? (
              typeof header === 'string' ? (
                <Text fontWeight="bold">{header}</Text>
              ) : (
                header
              )
            ) : (
              <div />
            )}
            {!hideClose && <PopoverCloseButton position="static" />}
          </Flex>
          <PopoverBody p={0}>{children}</PopoverBody>
        </PopoverContent>
        {closeOnBlur && (
          <Box
            display={_open ? 'block' : 'none'}
            onClick={handleToggle}
            position="absolute"
            zIndex={2}
            inset={0}
          />
        )}
      </Portal>
    </ChakraPopover>
  )
}
