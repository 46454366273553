import { Box, Flex, Text, useTheme } from '@chakra-ui/react'
import { CheckBox, DateOrTime } from 'components'
import {
  DataType,
  IKaTableProps,
  ITableProps,
  Table as KaTable,
  SortDirection,
  kaReducer,
} from 'ka-table'
import { Column as KaColumn } from 'ka-table/models'
import { ICellTextProps, IDataRowProps } from 'ka-table/props'
import 'ka-table/style.css'
import { ChildAttributesItem, DispatchFunc } from 'ka-table/types'
import { PropsWithChildren, useEffect, useRef, useState } from 'react'

export interface Props extends Omit<IKaTableProps, 'dispatch'> {
  columns: TableColumn[]
  emptyText?: string
  pinnedColumns: string[]
  /** Passing onChange will convert table to controlled */
  onChange?: (props: Props) => void
  onRowClick?: (id: string) => void
  onSelectedRowsChange?: (selectedRows: string[]) => void
  selectedRows?: string[]
  updates?: RowUpdate[]
  customSortOrder?: Record<string, string[]>
}

export interface TableColumn extends KaColumn {
  renderCell?: (props: PropsWithChildren<ICellTextProps<any>>) => JSX.Element | null
}

export interface RowUpdate {
  id: string
  type: 'added' | 'modified' | 'removed'
}

export default function Table(props: Props) {
  const {
    columns,
    emptyText,
    onChange,
    onRowClick,
    onSelectedRowsChange,
    pinnedColumns,
    selectedRows,
    updates,
    customSortOrder,
    ...rest
  } = props
  const [tableProps, setTableProps] = useState<ITableProps>({ ...rest, columns })
  const [selectAll, setSelectAll] = useState(false)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const visibleColumnsRef = useRef<TableColumn[]>([])
  useEffect(() => {
    const newVisibleColumns = columns.filter(c => c.visible)
    if (
      visibleColumnsRef.current.length > 0 &&
      newVisibleColumns.length > visibleColumnsRef.current.length
    ) {
      const lastColumn = tableContainerRef.current?.querySelector(`.ka-thead-cell:last-child`)
      lastColumn?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' })
    }
    visibleColumnsRef.current = newVisibleColumns
  }, [columns])

  const handleDispatch: DispatchFunc = action => {
    if (onChange) onChange(kaReducer(props, action))
    else setTableProps(s => kaReducer(s, action))
  }

  const handleRowSelection = (rowId: string) => {
    if (selectedRows) {
      const newSelectedRows = selectedRows.includes(rowId)
        ? selectedRows.filter(id => id !== rowId)
        : [...selectedRows, rowId]

      onSelectedRowsChange?.(newSelectedRows)
    }
  }

  const handleSelectAll = () => {
    const newSelectedRows = selectAll ? [] : rest.data?.map(row => row.id) || []
    setSelectAll(!selectAll)
    onSelectedRowsChange?.(newSelectedRows)
  }

  function isPinnedColumn(columnKey: string) {
    if (!pinnedColumns) return false
    return pinnedColumns.includes(columnKey)
  }

  function getLeftOffset(columnKey: string) {
    const pinnedIndex = pinnedColumns.findIndex(col => col === columnKey)
    return pinnedColumns.slice(0, pinnedIndex).reduce((acc, key) => {
      const column = columns.find(col => col.key === key)
      return acc + (typeof column?.width === 'number' ? column.width : 100)
    }, 0)
  }

  return (
    <Box
      display="flex"
      overflowX="hidden"
      ref={tableContainerRef}
      sx={{
        '& .ka-thead-cell': { p: 4, fontWeight: 'bold' },
        '& .ka-cell': { p: 3, pl: 4, textStyle: 'bodySmall' },
        '& .ka-thead-cell-resize': { left: '12px' },
        color: 'black',
      }}
      boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.10)'}
      backdropFilter={'blur(18px)'}
    >
      <KaTable
        virtualScrolling={{ enabled: true, bottomInvisibleCount: 10, topInvisibleCount: 10 }}
        columns={columns}
        loading={{ enabled: !rest.data }}
        noData={rest.data?.length === 0 ? { text: emptyText || 'No data found' } : undefined}
        sort={({ column }) => {
          const sortOrder = customSortOrder?.[column.key]

          if (!sortOrder) return undefined

          return (a, b) => {
            const aValue = a ?? ''
            const bValue = b ?? ''
            const aIndex = sortOrder.indexOf(aValue)
            const bIndex = sortOrder.indexOf(bValue)

            if (aIndex === -1 || bIndex === -1) {
              return aIndex === -1 ? 1 : -1
            }
            return column.sortDirection === SortDirection.Ascend
              ? aIndex < bIndex
                ? -1
                : aIndex > bIndex
                ? 1
                : 0
              : aIndex > bIndex
              ? -1
              : aIndex < bIndex
              ? 1
              : 0
          }
        }}
        childComponents={{
          headCell: {
            elementAttributes: props => {
              const pinned = isPinnedColumn(props.column.key)
              return {
                style: {
                  ...props.column.style,
                  position: pinned ? 'sticky' : undefined,
                  left: pinned ? getLeftOffset(props.column.key) : undefined,
                  zIndex: pinned ? 10 : undefined,
                  background: '#fff',
                },
              }
            },
            content: props => (
              <Flex
                cursor="pointer"
                onClick={() =>
                  handleDispatch({ type: 'UpdateSortDirection', columnKey: props.column.key })
                }
                gap={1}
                overflow="hidden"
                align="center"
              >
                {isPinnedColumn(props.column.key) &&
                  props.column.key === columns[0].key &&
                  selectedRows && (
                    <CheckBox
                      mr={3}
                      isChecked={selectAll}
                      onChange={e => {
                        e.stopPropagation()
                        handleSelectAll()
                      }}
                    />
                  )}
                <Text overflow="hidden" textOverflow="ellipsis" noOfLines={1} flex={1}>
                  {props.column.title}
                </Text>
                <SortIcon direction={props.column.sortDirection} />
              </Flex>
            ),
          },
          cell: {
            elementAttributes: props => {
              if (isPinnedColumn(props.column.key)) {
                const updated = updates?.find(u => u.id === props.rowData.id)
                if (updated) return

                return {
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    left: getLeftOffset(props.column.key),
                    zIndex: 9,
                    background: '#fff',
                  },
                }
              }
            },
          },
          cellText: {
            content: props => {
              const column = columns.find(c => c.key === props.column.key)
              const customRender = column?.renderCell?.(props)

              return (
                <Flex justify="space-between" overflow="hidden">
                  {isPinnedColumn(props.column.key) &&
                    props.column.key === columns[0].key &&
                    selectedRows && (
                      <CheckBox
                        isChecked={selectedRows?.includes(props.rowData.id) ?? false}
                        onChange={e => {
                          e.stopPropagation()
                          handleRowSelection(props.rowData.id)
                        }}
                      />
                    )}
                  {customRender ? (
                    customRender
                  ) : props.column.dataType === DataType.Date ? (
                    <DateOrTime>{props.value}</DateOrTime>
                  ) : (
                    <Text
                      as={props.value === 'Anonymous' ? 'i' : 'span'}
                      opacity={props.value === 'Anonymous' ? 0.5 : 1}
                      noOfLines={1}
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {props.column.dataType === DataType.Boolean
                        ? props.value
                          ? 'Yes'
                          : props.value === false
                          ? 'No'
                          : ''
                        : props.value}
                    </Text>
                  )}
                </Flex>
              )
            },
          },
          dataRow: {
            elementAttributes: props => {
              let attributes: ChildAttributesItem<IDataRowProps<any>> = {
                onClick: () => {
                  onRowClick?.(props.rowData.id)
                },
                style: { cursor: onRowClick ? 'pointer' : 'default' },
                onMouseEnter: e => (e.currentTarget.style.backgroundColor = '#D3F7E1'),
                onMouseLeave: e => (e.currentTarget.style.backgroundColor = ''),
              }
              const updated = updates?.find(u => u.id === props.rowData.id)
              if (updated) {
                attributes = {
                  ...attributes,
                  style: {
                    backgroundColor: updated.type === 'added' ? 'lightcyan' : 'lightsalmon',
                  },
                }
              }
              return attributes
            },
          },
        }}
        {...(onChange ? rest : tableProps)}
        dispatch={handleDispatch}
      />
    </Box>
  )
}

function SortIcon({ direction }: { direction?: SortDirection }) {
  const theme = useTheme()
  const color = theme.colors.secondary['700']
  const isAsc = direction === SortDirection.Ascend

  if (!direction) return null

  return (
    <svg
      style={{ flexShrink: 0 }}
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.46804 0.504296C5.11648 0.1625 4.54554 0.162499 4.19398 0.504296L0.593981 4.0043C0.335231 4.25586 0.259294 4.63047 0.399919 4.95859C0.540544 5.28672 0.866794 5.5 1.23242 5.5L8.43242 5.49727C8.79523 5.49727 9.12429 5.28398 9.26492 4.95586C9.40554 4.62773 9.32679 4.25312 9.07086 4.00156L5.47086 0.501562L5.46804 0.504296Z"
        opacity={isAsc ? 1 : 0.2}
        fill={color}
      />
      <path
        d="M4.19602 15.4957C4.54758 15.8375 5.11852 15.8375 5.47008 15.4957L9.07008 11.9957C9.32883 11.7441 9.40477 11.3695 9.26414 11.0414C9.12352 10.7133 8.79727 10.5 8.43164 10.5L1.23164 10.5027C0.868831 10.5027 0.539769 10.716 0.399144 11.0441C0.258519 11.3723 0.337269 11.7469 0.593206 11.9984L4.19321 15.4984L4.19602 15.4957Z"
        opacity={isAsc ? 0.2 : 1}
        fill={color}
      />
    </svg>
  )
}
