import { createContext, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IWorkspace } from 'types'
import { FetchStatus, useDoc, usePrefCurrentWorkspace } from 'utils'
import { AuthContext } from './AuthProvider'

export interface WorkspaceContextProps {
  workspace: IWorkspace | null
  status: FetchStatus
}

export const WorkspaceContext = createContext<WorkspaceContextProps>({} as any)

export function WorkspaceProvider({ children }: { children: any }) {
  const { user } = useContext(AuthContext)
  const id = useParams().workspaceId
  const navigate = useNavigate()
  const [_, updateWorkspaceId] = usePrefCurrentWorkspace()
  const [workspace, status] = useDoc('Workspace', id)

  useEffect(() => {
    if (status === 'loading') return

    const hasAccess = user?.isAdmin || Boolean(user && workspace?.userById[user.id])
    if (!workspace || !hasAccess) {
      updateWorkspaceId('')
      return navigate('/', { replace: true })
    }

    updateWorkspaceId(id ?? '')
  }, [workspace, status])

  return (
    <WorkspaceContext.Provider value={{ workspace: workspace || null, status }}>
      {children}
    </WorkspaceContext.Provider>
  )
}
