import { Flex, FlexProps, useColorMode } from '@chakra-ui/react'
import { forwardRef } from 'react'
import { borderRadiusMap } from 'utils'

export interface Props extends Omit<FlexProps, 'borderRadius'> {
  /** default md */
  borderRadius?: keyof typeof borderRadiusMap
}

export default forwardRef<HTMLDivElement, Props>(({ borderRadius = 'md', ...rest }, ref) => {
  const { colorMode } = useColorMode()

  return (
    <Flex
      ref={ref}
      direction="column"
      backdropFilter="blur(40px)"
      boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.06)"
      borderRadius={borderRadius}
      bg={colorMode === 'light' ? 'rgba(255, 255, 255, 0.54)' : 'rgba(255, 255, 255, 0.10)'}
      _before={
        colorMode === 'dark'
          ? {
              content: '""',
              position: 'absolute',
              inset: 0,
              padding: '1px',
              borderRadius: borderRadiusMap[borderRadius],
              background:
                'linear-gradient(to bottom right, rgba(255, 255, 255, 0.4) 0%, rgba(105, 105, 105, 0.56) 56%, rgba(255, 255, 255, 0.25) 100%)',
              WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              maskComposite: 'exclude',
              zIndex: -1,
            }
          : undefined
      }
      {...rest}
    />
  )
})
