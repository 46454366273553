import {
  CircularProgress,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { ManageMedia } from 'components'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useWorkspace } from 'utils'
import { ManageTextContentPage } from '.'
import { useCurrentPage } from '../utils/pageUtils'
import { RouteName, RoutePath } from '../utils/routing'

export default function ContentPage() {
  const { workspaceId } = useParams()
  const { status } = useWorkspace()
  const { colorMode } = useColorMode()
  const currentPage = useCurrentPage()
  const navigate = useNavigate()

  return (
    <Flex direction="column" gap={4} flex={'auto'} mr={5} mb={2} overflowY={'auto'}>
      <Text textStyle="h2" px={5} pt={7} pb={3}>
        Content
      </Text>
      {status === 'loading' ? (
        <Flex p={20} justifyContent="center" maxW={'100%'}>
          <CircularProgress isIndeterminate />
        </Flex>
      ) : (
        <Flex px={5}>
          <Tabs
            width={'100%'}
            size={'md'}
            index={getCurrentTab(currentPage?.path)}
            defaultIndex={2}
          >
            <TabList
              color={colorMode === 'light' ? 'rgba(23, 23, 23, 0.7)' : 'rgba(255, 255, 255, 0.7)'}
              borderBottom={
                colorMode === 'light'
                  ? '2px solid rgba(0, 0, 0, 0.10)'
                  : '2px solid rgba(255, 255, 255, 0.10)'
              }
            >
              <Tab
                isDisabled
                textStyle={'bodyMedium'}
                _selected={{
                  borderBottom:
                    colorMode === 'light' ? '1px solid rgba(60, 75, 211, 1)' : '1px solid white',
                  color: colorMode === 'light' ? 'rgba(23, 23, 23, 1)' : 'rgba(255, 255, 255, 1)',
                }}
              >
                Workflow Templates
              </Tab>
              <Tab
                isDisabled
                textStyle={'bodyMedium'}
                _selected={{
                  borderBottom:
                    colorMode === 'light' ? '1px solid rgba(60, 75, 211, 1)' : '1px solid white',
                  color: colorMode === 'light' ? 'rgba(23, 23, 23, 1)' : 'rgba(255, 255, 255, 1)',
                }}
              >
                Traits
              </Tab>
              <Tab
                textStyle={'bodyMedium'}
                onClick={() => {
                  if (!workspaceId) return
                  navigate({ pathname: generatePath(RoutePath.Text, { workspaceId }) })
                }}
                _selected={{
                  borderBottom:
                    colorMode === 'light' ? '1px solid rgba(60, 75, 211, 1)' : '1px solid white',
                  color: colorMode === 'light' ? 'rgba(23, 23, 23, 1)' : 'rgba(255, 255, 255, 1)',
                }}
              >
                Text
              </Tab>
              <Tab
                textStyle={'bodyMedium'}
                onClick={() => {
                  if (!workspaceId) return
                  navigate({ pathname: generatePath(RoutePath.ManageMedia, { workspaceId }) })
                }}
                _selected={{
                  borderBottom:
                    colorMode === 'light' ? '1px solid rgba(60, 75, 211, 1)' : '1px solid white',
                  color: colorMode === 'light' ? 'rgba(23, 23, 23, 1)' : 'rgba(255, 255, 255, 1)',
                }}
              >
                Media
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>Coming soon</TabPanel>
              <TabPanel>Coming soon</TabPanel>
              <TabPanel>
                <ManageTextContentPage />
              </TabPanel>
              <TabPanel>
                <ManageMedia />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      )}
    </Flex>
  )
}

function getCurrentTab(routeName?: string) {
  switch (routeName) {
    case RouteName.Text:
      return 2
    case RouteName.Media:
      return 3
    default:
      return 2
  }
}
