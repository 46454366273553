import { Box, BoxProps } from '@chakra-ui/react'
import { CheckIcon } from 'components'
import { MouseEventHandler } from 'react'

interface Props extends Omit<BoxProps, 'onClick' | 'onChange'> {
  isChecked: boolean
  onChange?: MouseEventHandler<HTMLButtonElement>
}

export default function CheckBox({ isChecked, onChange, ...rest }: Props) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="xs"
      w="16px"
      h="16px"
      borderWidth="1px"
      borderColor={isChecked ? 'blue.500' : 'gray.300'}
      bg={isChecked ? 'rgba(23, 23, 23, 1)' : 'transparent'}
      onClick={onChange}
      {...rest}
    >
      {isChecked && <CheckIcon color="white" w={3} h={3} />}
    </Box>
  )
}
