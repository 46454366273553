import { Flex, InputGroup, InputLeftElement, Text, useColorMode } from '@chakra-ui/react'
import {
  ArrowUturnLeftIcon,
  Button,
  CheckBox,
  DebouncedTextField,
  MagnifyingGlassIcon,
  Menu,
  TableCellsIcon,
  TableColumn,
} from 'components'
import { useState } from 'react'
import { defaultColumnSpecs } from '../../utils/userSessionUtils'

interface Props {
  columns: TableColumn[]
  onUpdate: (columns: TableColumn[]) => void
}

export default function LeadTableColumnSelector({ columns, onUpdate }: Props) {
  const { colorMode } = useColorMode()
  const [search, setSearch] = useState('')

  const selected = columns.filter(c => c.visible)

  function columnSelectionToggle(column: TableColumn) {
    onUpdate(columns.map(c => ({ ...c, visible: c.key === column.key ? !c.visible : c.visible })))
  }

  function selectAllColumns() {
    onUpdate(columns.map(c => ({ ...c, visible: selected.length !== columns.length })))
  }

  function handleReset() {
    onUpdate(columns.map(c => ({ ...c, visible: defaultColumnSpecs.some(d => d.key === c.key) })))
  }

  function handleClose() {
    if (!selected.length) handleReset()
    setSearch('')
  }

  const columnOptions = columns
    .filter(c => c.key.toLowerCase().includes(search.toLowerCase()))
    .map(c => ({
      value: c.key,
      label: (
        <Flex gap={3} pl={2}>
          <CheckBox flexShrink={0} isChecked={c.visible ?? false} />
          <Text textStyle={'bodySmall'} fontWeight={'normal'}>
            {c.title}
          </Text>
        </Flex>
      ),
      onClick: () => columnSelectionToggle(c),
    }))

  return (
    <Menu
      colorMode="light"
      rightIcon={undefined}
      menuProps={{ closeOnSelect: false, placement: 'top-end', onClose: handleClose }}
      listProps={{ p: 0, overflow: 'hidden', width: '370px' }}
      leftIcon={<TableCellsIcon />}
      iconSpacing={{ base: 0, sm: 2 }}
      isDisabled={columns.length == 0}
      borderRadius="sm"
      variant="solid"
      buttonLabel="Columns"
      options={[
        {
          type: 'custom',
          label: (
            <Flex width={'100%'} direction={'column'} gap={4} px={5} pt={5}>
              <Text textStyle={'bodyXSmall'} fontWeight={'bold'} opacity={0.6}>
                View Columns
              </Text>
              <InputGroup width={'100%'} boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.06)'}>
                <InputLeftElement pointerEvents="none">
                  <MagnifyingGlassIcon color="rgba(23, 23, 23, 0.6)" />
                </InputLeftElement>
                <DebouncedTextField
                  isDisabled={columns.length === 0}
                  onChange={setSearch}
                  placeholder="Search..."
                  value={search}
                  width={'100%'}
                  controlled
                  boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.06)'}
                  backdropFilter={'blur(14px)'}
                  bg={
                    colorMode === 'light'
                      ? 'rgba(255, 255, 255, 0.74)'
                      : 'rgba(255, 255, 255, 0.14)'
                  }
                  borderRadius={'10px'}
                  border={0}
                  pl={10}
                />
              </InputGroup>
              <Flex align={'center'} justify="space-between">
                <Flex align={'center'} gap={2} cursor="pointer" onClick={selectAllColumns}>
                  <CheckBox isChecked={columns.every(c => c.visible)} />
                  {selected.length > 0 ? (
                    <Text textStyle={'bodySmall'}>
                      {selected.length} of {columns.length} Columns Selected
                    </Text>
                  ) : (
                    <Text textStyle={'bodySmall'}>{columns.length} Total Columns</Text>
                  )}
                </Flex>
                <Button
                  variant="ghost"
                  size="sm"
                  leftIcon={<ArrowUturnLeftIcon fontSize={'lg'} />}
                  isDisabled={columns.every(c => {
                    const defaultColumn = defaultColumnSpecs.find(d => d.key === c.key)
                    if (defaultColumn) return Boolean(c.visible)
                    else return !c.visible
                  })}
                  onClick={handleReset}
                >
                  <Text textStyle={'bodySmall'} textAlign={'center'}>
                    Reset
                  </Text>
                </Button>
              </Flex>
            </Flex>
          ),
        },
        { type: 'divider', m: 0 },
        {
          type: 'group',
          options: columnOptions,
          py: 2,
          maxHeight: '200px',
          overflow: 'auto',
        },
      ]}
    />
  )
}
