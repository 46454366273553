import { Flex } from '@chakra-ui/react'
import { Collapsible, CollapsibleProps, HelpHover, Switch, SwitchProps } from 'components'
import { IEvent } from 'types'
import { usePermission } from 'utils'

interface Props extends Omit<CollapsibleProps, 'children' | 'label'> {
  event: IEvent
  updateEvent: (body: Partial<IEvent>) => Promise<void>
}

export default function SessionOptions({ event, updateEvent, ...rest }: Props) {
  const { registrationMode, registrationSkip, registrationInput } = event || {}

  return (
    <Collapsible label="Session Creation Options" {...rest}>
      <Flex direction="column" gap={2} alignSelf="flex-start" mt={1} width="100%">
        {registrationMode !== 'none' && (
          <SwitchWithHelp
            helpText="Allow users to skip registration using Guest mode"
            isChecked={registrationSkip || false}
            label="Allow skip"
            onChange={() => updateEvent({ registrationSkip: !registrationSkip })}
          />
        )}
        {(registrationMode === 'qr-code' || registrationMode === 'database') && (
          <SwitchWithHelp
            helpText="Allow user to bypass the registration with Form input mode"
            isChecked={registrationInput || false}
            label="Allow input"
            onChange={() => updateEvent({ registrationInput: !registrationInput })}
          />
        )}
      </Flex>
    </Collapsible>
  )
}

interface SwitchWithHelpProps extends SwitchProps {
  helpText?: string
}

function SwitchWithHelp({ helpText, ...rest }: SwitchWithHelpProps) {
  const permission = usePermission()

  return (
    <Flex justify="flex-start" align="center" gap={2}>
      <Switch
        colorScheme="primary"
        borderRadius={'11px'}
        labelProps={{ fontSize: 'sm' }}
        isDisabled={permission.event === 'viewer'}
        {...rest}
      />
      {helpText && <HelpHover text={helpText} borderRadius={'5px'} iconProps={{ size: 'sm' }} />}
    </Flex>
  )
}
