import { Icon, type IconProps } from '@chakra-ui/react'
import { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, SVGProps } from 'react'
import { fromSize, IconSize } from 'utils/iconUtils'

export interface HeroIconProps extends Omit<Props, 'Component'> {
  /** (default solid) */
  type?: 'solid' | 'outline'
}

interface Props extends Omit<IconProps, 'children'> {
  Component: ForwardRefExoticComponent<PropsWithoutRef<SVGProps<SVGSVGElement>>>
  /** (default md) icon size that can be overwritten by fontSize */
  size?: IconSize
  /** (default solid) icon variant */
  type?: 'solid' | 'outline'
}

const HeroIcon = forwardRef<SVGSVGElement, Props>((props, ref) => {
  const { Component, size = 'md', type = 'solid', fontSize, ...rest } = props
  const _fontSize = fontSize || fromSize(size)

  return <Icon ref={ref} as={Component} fontSize={_fontSize} {...rest} />
})

export default HeroIcon
