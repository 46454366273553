import { Flex, Hide, IconButton, Spacer, Text, useColorMode } from '@chakra-ui/react'
import { MDXEditorMethods } from '@mdxeditor/editor'
import {
  CubeTransparentIcon,
  MarkdownEditor,
  Panel,
  PanelProps,
  PencilSquareIcon,
} from 'components'
import { useEffect, useRef } from 'react'
import { MultiLanguageString } from 'types'

interface Props extends Omit<PanelProps, 'children'> {
  contentId: string
  workspaceId: string
  contentName?: string
  contentValue?: MultiLanguageString
  isSubContent?: boolean
  onEdit?: () => void
  contentChildCount?: number
}

export default function ContentTile({
  contentId,
  contentName,
  contentValue,
  workspaceId,
  isSubContent,
  onEdit,
  contentChildCount,
  ...props
}: Props) {
  const { colorMode } = useColorMode()
  const editorRef = useRef<MDXEditorMethods>(null)

  useEffect(() => {
    if (editorRef.current && contentValue?.en) {
      editorRef.current.setMarkdown(contentValue.en)
    }
  }, [contentValue])

  return (
    <Panel
      flexDirection="row"
      borderRadius="sm"
      height={'78px'}
      px={'30px'}
      align={'center'}
      gap={3}
      {...props}
    >
      <CubeTransparentIcon />

      <Flex direction={'column'} width={{ base: '27vw', sm: '35vw' }}>
        <Text
          textStyle={'bodyLarge'}
          fontWeight={'semibold'}
          width={{ base: '30vw', sm: '100%' }}
          textAlign={'left'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
        >
          {contentName}
        </Text>

        <Hide below="sm" ssr={false}>
          <Flex maxHeight={'40px'}>
            <MarkdownEditor
              ref={editorRef}
              markdown={contentValue?.en ? contentValue?.en : ''}
              readOnly
              limitTo1Line
            ></MarkdownEditor>
          </Flex>
        </Hide>
      </Flex>

      <Spacer />
      <IconButton
        aria-label="edit content"
        icon={<PencilSquareIcon />}
        onClick={onEdit}
        borderRadius={'10px'}
        h={'40px'}
        p={3}
        bg={colorMode === 'light' ? 'rgba(255, 255, 255, 0.54)' : ''}
        boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.06)'}
      />

      <Hide below="sm" ssr={false}>
        {!isSubContent && (
          <Flex
            p={2}
            borderRadius={'44px'}
            border={
              colorMode === 'light' ? '1px solid #D0D0D0' : '1px solid rgba(255,255, 255, 0.30)'
            }
            boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.06)'}
          >
            <Text width={'135px'} textStyle={'bodySmall'} textAlign={'center'}>
              {contentChildCount} Sub Content {contentChildCount! > 1 ? 'Items' : 'Item'}
            </Text>
          </Flex>
        )}
      </Hide>
    </Panel>
  )
}
