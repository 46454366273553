import { RefObject, useRef } from 'react'
import { useResizeObserver } from 'usehooks-ts'

type Options = Parameters<typeof useResizeObserver>[0]

export function useElementSize<T extends HTMLDivElement>(options?: Options) {
  const { ref, ...rest } = options || {}
  const internalRef = useRef<T>(null)
  const _ref = (ref as RefObject<T>) || internalRef

  const { width = 0, height = 0 } = useResizeObserver({ box: 'border-box', ref: _ref, ...rest })

  return { ref: _ref, height, width }
}

export function openFullscreen() {
  document.documentElement.requestFullscreen()
}

export function closeFullscreen() {
  document.exitFullscreen()
}
