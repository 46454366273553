import { Flex, FlexProps } from '@chakra-ui/react'
import {
  DndContext,
  DndContextProps,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, SortableContextProps, arrayMove, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CSSProperties, useState } from 'react'
import { createPortal } from 'react-dom'

export interface Props<T> extends DndContextProps {
  containerProps?: Omit<FlexProps, 'children'>
  disabled?: boolean
  sortProps?: Omit<SortableContextProps, 'items'>
  items: T[]
  onChange: (items: T[]) => void
  renderItem: (item: T, isDragging: boolean, index: number) => React.ReactNode
}

export interface Item {
  id: string
  [key: string]: any
}

export default function DragList<T extends Item>(props: Props<T>) {
  const {
    containerProps,
    disabled = false,
    items,
    onDragEnd,
    onDragStart,
    onChange,
    sortProps,
    renderItem,
    ...rest
  } = props
  const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 5 } })
  const keyboardSensor = useSensor(KeyboardSensor)
  const touchSensor = useSensor(TouchSensor, { activationConstraint: { distance: 5 } })
  const sensors = useSensors(mouseSensor, keyboardSensor, touchSensor)
  const [activeId, setActiveId] = useState<string | null>(null)

  const activeIndex = activeId == null ? null : items.findIndex(i => i.id === activeId)

  function handleDragStart(event: DragStartEvent) {
    onDragStart?.(event)
    setActiveId(event.active.id as string)
  }

  function handleDragEnd(event: DragEndEvent) {
    onDragEnd?.(event)
    setActiveId(null)
    const { active, over } = event
    if (!over || active.id === over.id) return

    const oldIndex = items.findIndex(i => i.id === (active.id as string))
    const newIndex = items.findIndex(i => i.id === (over.id as string))
    const updated = arrayMove(items, oldIndex, newIndex)
    onChange(updated)
  }

  return (
    <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors} {...rest}>
      <SortableContext items={items} disabled={disabled} {...sortProps}>
        <Flex direction="column" {...containerProps}>
          {items.map((item, i) => (
            <DragItem key={item.id} id={item.id}>
              {renderItem(item, false, i)}
            </DragItem>
          ))}

          {createPortal(
            <DragOverlay zIndex={2000}>
              {activeIndex != null ? renderItem(items[activeIndex], true, activeIndex) : null}
            </DragOverlay>,
            document.body
          )}
        </Flex>
      </SortableContext>
    </DndContext>
  )
}

interface DragItemProps extends FlexProps {
  id: string
}

function DragItem({ id, ...rest }: DragItemProps) {
  const { attributes, listeners, setNodeRef, transform, isDragging, transition, isSorting } =
    useSortable({ id })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    visibility: isDragging ? 'hidden' : 'visible',
    transition: isSorting ? transition : undefined,
  }

  return <Flex {...attributes} {...listeners} ref={setNodeRef} style={style} {...rest} />
}
