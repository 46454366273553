import { Avatar, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { IUser, PermissionValue } from 'types'
import { getUsers, makeUserDisplayName, useWorkspace } from 'utils'
import { MenuProps } from '.'
import Menu from './Menu'

interface Props extends Omit<MenuProps, 'onChange'> {
  value: string
  onChange: (userId: string) => void
  permissionsAllowed?: Array<PermissionValue>
}

export default function UserSelect({ value, onChange, permissionsAllowed, ...rest }: Props) {
  const { workspace } = useWorkspace()
  const [users, setUsers] = useState<{ [id: string]: IUser }>({})

  useEffect(() => {
    if (workspace) {
      const fetchUsersInfo = async () => {
        if (workspace && workspace.userById) {
          var userIds = Object.keys(workspace.userById)
          if (permissionsAllowed) {
            userIds = userIds.filter(
              userId =>
                userId && permissionsAllowed!.includes(workspace.userById[userId].permission)
            )
          }
          if (userIds.length > 0) {
            setUsers(await getUsers(userIds))
          }
        }
      }
      fetchUsersInfo()
    }
  }, [workspace])

  return (
    <Menu
      width="100%"
      variant="outline"
      borderRadius="xs"
      menuProps={{ matchWidth: true }}
      noPortal
      options={[
        {
          type: 'group',
          options: Object.keys(users).map(id => {
            const user = users[id]
            const displayName = makeUserDisplayName(user)

            return {
              value: id,
              label: (
                <Flex align="center" gap={2}>
                  <Avatar name={displayName} src={user.photoUrl} size="sm" />
                  <Text textStyle="bodySmall" fontWeight="500">
                    {displayName}
                  </Text>
                </Flex>
              ),
            }
          }),
        },
      ]}
      value={value}
      onChange={onChange}
      {...rest}
    />
  )
}
