import { Flex, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePermission } from 'utils'
import ManageWorkspace from '../ManageWorkspace'

export default function ManageWorkspacePage() {
  const navigate = useNavigate()
  const permission = usePermission()
  const workspacePermission = permission.workspace

  useEffect(() => {
    if (!workspacePermission) return
    if (workspacePermission !== 'admin') navigate('/', { replace: true })
  }, [workspacePermission, navigate])

  if (workspacePermission !== 'admin') return null

  return (
    <Flex
      flexDirection="column"
      gap={6}
      maxW="1182px"
      mx={{ base: 4, sm: 0, xl: 'auto' }}
      mt={4}
      mb={4}
      overflow="hidden"
    >
      <Text textStyle="h1">Manage Workspace</Text>

      <ManageWorkspace onDeleted={() => navigate('/', { replace: true })} />
    </Flex>
  )
}
