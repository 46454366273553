import { Avatar, Divider, Flex, Hide, Text, useColorMode, useDisclosure } from '@chakra-ui/react'
import {
  BoltIcon,
  BuildingOfficeIcon,
  Button,
  CalendarIcon,
  ChartPieIcon,
  ClockIcon,
  Cog6ToothIcon,
  FireIcon,
  IconButton,
  LinkIcon,
  MapPinIcon,
  Panel,
  Tooltip,
} from 'components'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { SeriesData } from 'types'
import { useDisplayUser, usePermission, useWorkspace } from 'utils/workspaceUtils'
import defaultImage from '../assets/event_tile.png'
import {
  EventLeads,
  EventLiveBadge,
  EventManage,
  EventRegModeTile,
  EventStatBanner,
} from '../components/event'
import QrMappingDrawer from '../components/event/EventManage/QrMappingDrawer'
import TemplatePreviewButton from '../components/TemplatePreviewButton'
import {
  formatEventDates,
  getEventsInSections,
  isEventInSection,
  useEvent,
  useEvents,
} from '../utils/eventUtils'
import { RoutePath } from '../utils/routing'
import { aggregateSessionsForField, getAverageStepDurations } from '../utils/userSessionUtils'

const maxWidth = 1900

export default function EventInfoPage() {
  const { workspace } = useWorkspace()
  const permission = usePermission()
  const { colorMode } = useColorMode()
  const { event, sessions, sessionsStatus, imageUrl } = useEvent()
  const { user: eventManager } = useDisplayUser(event?.managerId)
  const { events = [] } = useEvents(workspace?.id)
  const {
    isOpen: eventManageIsOpen,
    onOpen: eventManageOnOpen,
    onClose: eventManageOnClose,
  } = useDisclosure()
  const { isOpen: qrTestIsOpen, onOpen: qrTestOnOpen, onClose: qrTestOnClose } = useDisclosure()
  const [expandTable, setExpandTable] = useState(false)
  const { live, upcoming } = getEventsInSections(events)
  const isViewer = permission.event === 'viewer'
  const [sessionCompanies, setSessionCompanies] = useState<SeriesData[]>([])
  const [avgSessionDuration, setAvgSessionDuration] = useState<number>(0)
  const [hotLeads, setHotLeads] = useState<number>(0)
  const navigate = useNavigate()

  const customStats = {
    'Avg. Session Duration': avgSessionDuration,
    Companies: sessionCompanies.length,
    'Hot Leads': hotLeads,
  }

  const iconColor = 'primary.500'

  useEffect(() => {
    if (event?.title === '') eventManageOnOpen()
  }, [event])

  useEffect(() => {
    if (sessions) {
      setSessionCompanies(
        aggregateSessionsForField({ sessions, field: 'lead', nestedField: 'company' })
      )
      const { totalDuration } = getAverageStepDurations(sessions)
      setAvgSessionDuration(totalDuration)
      const hotLead = aggregateSessionsForField({
        sessions,
        field: 'lead',
        nestedField: 'temp',
      }).find(temp => temp.label === 'Hot')
      const hotCount = hotLead ? hotLead.series[0].value : 0
      setHotLeads(hotCount)
    }
  }, [sessions])

  if (!event) return null

  const cleanEventUrl = event.url?.match(/(?:https?:\/\/)?(?:www\.)?([^\/]+)/)?.[1]

  return (
    <>
      <Flex
        overflow="auto"
        direction="column"
        pt={{ base: 0, sm: 4 }}
        pb={4}
        maxW={expandTable ? 'inherit' : maxWidth}
      >
        {!expandTable && (
          <>
            <Hide below="sm" ssr={false}>
              <Panel
                overflow="hidden"
                flexShrink={0}
                flexDirection="column"
                h={event.sessionCount > 0 ? '313px' : '234px'}
                backdropFilter="none"
              >
                <Flex
                  bg={colorMode == 'light' ? 'rgba(255, 255, 255, 0.44)' : ''}
                  borderBottom={
                    colorMode == 'light' ? 'none' : '1px solid rgba(255, 255, 255, 0.15)'
                  }
                  flexDirection="row"
                  h="234px"
                  borderBottomRadius={0}
                  p={5}
                >
                  <Flex
                    w="332px"
                    h="197px"
                    bgImage={imageUrl || defaultImage}
                    bgSize="cover"
                    borderRadius="14px"
                    bgPosition="center"
                    align="end"
                    justify="end"
                    p={4}
                  >
                    {isEventInSection(event, live) ? (
                      <EventLiveBadge text="Live" iconVisible color="rgba(30, 166, 68, 1)" />
                    ) : isEventInSection(event, upcoming) ? (
                      <EventLiveBadge text="Upcoming" color="yellow.600" />
                    ) : (
                      <EventLiveBadge text="Past" color="gray" />
                    )}
                  </Flex>

                  <Flex direction="column" flex={1} justify="center" py={3} px={10} gap={3}>
                    <Flex justify="space-between">
                      <Flex direction="column">
                        <Text textStyle="h3">{event.title ? event.title : '--'}</Text>
                        <Flex gap={5} height="30px" opacity={0.6}>
                          <Flex align={'center'} gap={1}>
                            <CalendarIcon fontSize="lg" />
                            <Text textStyle="bodySmall">
                              {event.startTime
                                ? formatEventDates(event.startTime, event.endTime)
                                : '--'}
                            </Text>
                          </Flex>
                          {event.location && (
                            <Flex align="center" gap={2}>
                              <MapPinIcon fontSize="lg" />
                              <Text textStyle="bodySmall">{`${event.location.city}, ${
                                event.location.state || event.location.country
                              }`}</Text>
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                      <Flex gap={2}>
                        <Panel flexDirection="row" height="44px" px={2} gap={2} align="center">
                          <TemplatePreviewButton
                            colorMode={colorMode}
                            device="ipad"
                            eventId={event.id}
                            previewTitle="Event Preview"
                          />
                          {!isViewer && (
                            <>
                              <Divider height="40%" orientation="vertical" />
                              <Tooltip label="Manage">
                                <IconButton
                                  aria-label="Manage event"
                                  icon={<Cog6ToothIcon />}
                                  onClick={eventManageOnOpen}
                                />
                              </Tooltip>
                            </>
                          )}
                        </Panel>
                      </Flex>
                    </Flex>

                    <Divider />

                    <Flex gap={10}>
                      <Flex align="center" gap={2}>
                        <Avatar
                          name={eventManager?.displayName}
                          src={eventManager?.photoUrl}
                          size="sm"
                        />
                        <Flex direction="column">
                          <Text textStyle="bodySmall">{eventManager?.displayName}</Text>
                          <Text textStyle="bodyXSmall" opacity={0.6}>
                            Event Manager
                          </Text>
                        </Flex>
                      </Flex>

                      {event.registrationMode !== 'none' && (
                        <EventRegModeTile
                          eventManageOnOpen={eventManageOnOpen}
                          eventManageIsOpen={eventManageIsOpen}
                          qrTestOnOpen={qrTestOnOpen}
                        />
                      )}

                      {cleanEventUrl && (
                        <Flex
                          align="center"
                          gap={2}
                          onClick={() => window.open(event.url, '_blank')}
                          cursor="pointer"
                        >
                          <Panel p={2} borderRadius="xl">
                            <LinkIcon size="sm" />
                          </Panel>
                          <Flex direction="column">
                            <Text textStyle="bodySmall">{cleanEventUrl}</Text>
                            <Text textStyle="bodyXSmall" opacity={0.6}>
                              Event Link
                            </Text>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex align="center" flex={1}>
                  <Flex flex={1} justify="space-between" gap={10} pr="60px">
                    <Flex gap="60px">
                      <EventStatBanner
                        icon={<BoltIcon color={iconColor} fontSize="20px" />}
                        num={event.sessionCount}
                        label={event.sessionCount == 1 ? 'Total Session' : 'Total Sessions'}
                      />

                      <EventStatBanner
                        icon={<BuildingOfficeIcon color={iconColor} fontSize="20px" />}
                        isLoading={sessionsStatus == 'loading'}
                        num={customStats['Companies']}
                        label={customStats['Companies'] == 1 ? 'Company' : 'Companies'}
                      />

                      <EventStatBanner
                        icon={<ClockIcon color={iconColor} fontSize="20px" />}
                        isLoading={sessionsStatus == 'loading'}
                        num={customStats['Avg. Session Duration']}
                        label="Avg Session Duration"
                      />
                      <EventStatBanner
                        icon={<FireIcon color={iconColor} fontSize="20px" />}
                        isLoading={sessionsStatus == 'loading'}
                        num={customStats['Hot Leads']}
                        label={customStats['Hot Leads'] == 1 ? 'Hot Lead' : 'Hot Leads'}
                      />
                    </Flex>
                    {sessions?.length != 0 && (
                      <Panel>
                        <Button
                          leftIcon={<ChartPieIcon />}
                          variant="ghost"
                          onClick={() =>
                            navigate(
                              generatePath(RoutePath.Analytics, {
                                workspaceId: workspace?.id!,
                                id: event.id,
                              })
                            )
                          }
                        >
                          <Text textStyle="bodySmall">All Analytics</Text>
                        </Button>
                      </Panel>
                    )}
                  </Flex>
                </Flex>
              </Panel>
            </Hide>
            <Hide above="sm" ssr={false}>
              <Flex direction={'column'} align={'center'}>
                <Flex
                  width="100vw"
                  h="216px"
                  bgImage={`url(${imageUrl})`}
                  bgSize="cover"
                  bgPosition="center"
                  direction="column"
                >
                  <Flex flex={1} gap={3} justify="end" p={5}>
                    {!isViewer && (
                      <Panel h="52px" w="60px" justify="center">
                        <IconButton
                          aria-label="Manage event"
                          icon={<Cog6ToothIcon />}
                          onClick={eventManageOnOpen}
                        />
                      </Panel>
                    )}
                  </Flex>
                  <Flex direction="row" p={5}>
                    {isEventInSection(event, live) ? (
                      <EventLiveBadge text="Live" iconVisible color="rgba(30, 166, 68, 1)" />
                    ) : isEventInSection(event, upcoming) ? (
                      <EventLiveBadge text="Upcoming" color="yellow.600" />
                    ) : (
                      <EventLiveBadge text="Past" color="gray" />
                    )}
                  </Flex>
                </Flex>
                <Flex
                  width="100%"
                  bg={
                    colorMode === 'light'
                      ? 'rgba(255, 255, 255, 0.34)'
                      : 'rgba(255, 255, 255, 0.10)'
                  }
                  direction="column"
                >
                  <Flex
                    flex={1}
                    direction="column"
                    gap={3}
                    p={5}
                    bg={
                      colorMode === 'light'
                        ? 'rgba(255, 255, 255, 0.44)'
                        : 'rgba(255, 255, 255, 0.1)'
                    }
                  >
                    {event.title ? (
                      <Text textStyle="h4">{event.title}</Text>
                    ) : (
                      <Text textStyle="h4" as="i" color="gray">
                        Event title
                      </Text>
                    )}
                    <Flex direction={'row'} alignItems={'center'} gap={3} opacity={0.6}>
                      <Flex align="center" gap={1}>
                        <CalendarIcon fontSize="lg" />
                        <Text textStyle="bodyXSmall">
                          {event.startTime
                            ? formatEventDates(event.startTime, event.endTime)
                            : '--'}
                        </Text>
                      </Flex>
                      {event.location && (
                        <Flex align="center" gap={1}>
                          <MapPinIcon fontSize="lg" />
                          <Text textStyle="bodyXSmall">{`${event.location.city}, ${
                            event.location.state || event.location.country
                          }`}</Text>
                        </Flex>
                      )}
                    </Flex>

                    <Divider
                      borderColor={
                        colorMode == 'light' ? 'rgba(23, 23, 23, 0.1)' : 'rgba(255, 255, 255, 0.2)'
                      }
                    />

                    <Flex gap={10}>
                      <Flex align="center" gap={2}>
                        <Avatar
                          name={eventManager?.displayName}
                          src={eventManager?.photoUrl}
                          size="sm"
                        />
                        <Flex direction="column">
                          <Text textStyle="bodySmall">{eventManager?.displayName}</Text>
                          <Text textStyle="bodyXSmall" opacity={0.6}>
                            Event Manager
                          </Text>
                        </Flex>
                      </Flex>

                      {cleanEventUrl && (
                        <Flex
                          align="center"
                          gap={2}
                          onClick={() => window.open(event.url, '_blank')}
                          cursor="pointer"
                        >
                          <Panel p={2} borderRadius="xl">
                            <LinkIcon size="sm" />
                          </Panel>
                          <Flex direction="column">
                            <Text textStyle="bodySmall">{cleanEventUrl}</Text>
                            <Text textStyle="bodyXSmall" opacity={0.6}>
                              Event Link
                            </Text>
                          </Flex>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                  {event.sessionCount > 0 && (
                    <Flex flex={1} p={5} bg="" direction="column" gap={4}>
                      <Flex justify="space-between" gap={1}>
                        <EventStatBanner
                          num={event.sessionCount}
                          label={event.sessionCount == 1 ? 'Total Session' : 'Total Sessions'}
                        />
                        <Divider
                          orientation="vertical"
                          borderColor={
                            colorMode == 'light'
                              ? 'rgba(23, 23, 23, 0.1)'
                              : 'rgba(255, 255, 255, 0.2)'
                          }
                        />
                        <EventStatBanner
                          num={customStats['Companies']}
                          label={customStats['Companies'] == 1 ? 'Company' : 'Companies'}
                          isLoading={sessionsStatus == 'loading'}
                        />
                        <Divider
                          orientation="vertical"
                          borderColor={
                            colorMode == 'light'
                              ? 'rgba(23, 23, 23, 0.1)'
                              : 'rgba(255, 255, 255, 0.2)'
                          }
                        />
                        <EventStatBanner
                          num={customStats['Avg. Session Duration']}
                          label="Avg Session Duration"
                          isLoading={sessionsStatus == 'loading'}
                        />
                        <Divider
                          orientation="vertical"
                          borderColor={
                            colorMode == 'light'
                              ? 'rgba(23, 23, 23, 0.1)'
                              : 'rgba(255, 255, 255, 0.2)'
                          }
                        />
                        <EventStatBanner
                          num={customStats['Hot Leads']}
                          label={customStats['Hot Leads'] == 1 ? 'Hot Lead' : 'Hot Leads'}
                          isLoading={sessionsStatus == 'loading'}
                        />
                      </Flex>
                      <Panel w="100%">
                        <Button
                          leftIcon={<ChartPieIcon fontSize="lg" />}
                          variant="ghost"
                          isDisabled={!sessions}
                          onClick={() =>
                            navigate(
                              generatePath(RoutePath.Analytics, {
                                workspaceId: workspace?.id!,
                                id: event.id,
                              })
                            )
                          }
                        >
                          All Analytics
                        </Button>
                      </Panel>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Hide>
          </>
        )}

        <EventLeads updateExpanded={setExpandTable} expanded={expandTable} />
      </Flex>
      <EventManage isOpen={eventManageIsOpen} onClose={eventManageOnClose} />
      <QrMappingDrawer isOpen={qrTestIsOpen} onClose={qrTestOnClose} />
    </>
  )
}
