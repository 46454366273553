import {
  Button as ChakraButton,
  type ButtonProps as ChakraButtonProps,
  ResponsiveValue,
  useColorMode,
} from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface Props extends Omit<ChakraButtonProps, 'variant' | 'disabled'> {
  palette?: 'primary' | 'secondary' | 'danger'
  /** default solid */
  variant?: 'solid' | 'outline' | 'ghost' | 'link'
}

export default forwardRef<HTMLButtonElement, Props>(
  ({ palette, variant = 'solid', fontSize, size = 'md', ...rest }, ref) => {
    const { colorMode } = useColorMode()
    const baseColor = colorMode === 'light' ? 'black' : 'white'
    const baseBorderColor =
      colorMode === 'light' ? 'rgba(23, 23, 23, 0.2)' : 'rgba(255, 255, 255, 0.2)'

    return (
      <ChakraButton
        ref={ref}
        background={
          variant === 'solid'
            ? palette
              ? `${palette}.500`
              : colorMode === 'light'
              ? 'white'
              : 'rgba(255, 255, 255, 0.14)'
            : variant === 'outline' && !palette
            ? colorMode === 'light'
              ? 'white'
              : 'rgba(255, 255, 255, 0.05)'
            : 'transparent'
        }
        color={
          variant === 'solid'
            ? palette
              ? 'white'
              : baseColor
            : palette
            ? `${palette}.500`
            : baseColor
        }
        borderColor={
          variant === 'outline'
            ? palette
              ? `${palette}.500`
              : `${baseBorderColor} !important`
            : undefined
        }
        border={variant === 'outline' ? `1px` : undefined}
        boxShadow={
          variant === 'solid' || variant === 'outline'
            ? '0px 1px 4px 0px rgba(0, 0, 0, 0.06)'
            : undefined
        }
        fontSize={fontSize || getFontSize(size)}
        _hover={{
          background:
            variant === 'solid'
              ? palette
                ? `${palette}.700`
                : colorMode === 'light'
                ? '#FFFFFF88'
                : 'rgba(255, 255, 255, 0.24)'
              : variant === 'link'
              ? undefined
              : palette
              ? palette === 'primary'
                ? 'rgba(60, 75, 211, 0.03)'
                : `${palette}.200`
              : baseBorderColor,
          textDecoration: variant === 'link' ? 'underline' : undefined,
        }}
        size={size}
        {...rest}
      />
    )
  }
)

function getFontSize(size: ResponsiveValue<string>): ResponsiveValue<string> {
  switch (size) {
    case 'xs':
      return 'xs'
    case 'sm':
      return 'xs'
    case 'md':
      return 'sm'
    default:
      return 'md'
  }
}
