import {
  FormControl,
  FormControlProps,
  FormHelperTextProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputLeftElementProps,
  InputProps,
  InputRightElement,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface Props extends InputProps {
  controlProps?: FormControlProps
  isError?: boolean
  helperText?: string
  helperTextProps?: FormHelperTextProps
  label?: string
  leftElement?: InputLeftElementProps
  rightElement?: InputLeftElementProps
}

const TextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    borderRadius = 'xs',
    variant = 'outline',
    controlProps,
    helperText,
    isError,
    label,
    leftElement,
    rightElement,
    width,
    helperTextProps,
    ...rest
  } = props

  const { colorMode } = useColorMode()

  return (
    <FormControl width={width} {...(controlProps as any)}>
      {label && (
        <Text mb={2} textStyle={'bodySmall'} opacity={0.7}>
          {label}
        </Text>
      )}
      <InputGroup>
        {leftElement && <InputLeftElement pointerEvents="none" {...leftElement} />}
        <Input
          ref={ref}
          {...(isError && {
            borderColor: 'red.500',
            _focus: { borderColor: 'red.500', boxShadow: '0 0 0 1px rgba(255, 0, 0, 0.1)' },
          })}
          background={colorMode === 'light' ? 'white' : 'rgba(255, 255, 255, 0.05)'}
          borderColor={colorMode === 'light' ? 'rgba(23, 23, 23, 0.2)' : 'rgba(255, 255, 255, 0.2)'}
          borderRadius={borderRadius}
          {...(rest as any)}
        />
        {rightElement && <InputRightElement pointerEvents="none" {...rightElement} />}
      </InputGroup>
      {helperText && (
        <Text
          textStyle={'bodyXSmall'}
          mt={1}
          opacity={0.6}
          color={isError ? 'red.500' : undefined}
          {...helperTextProps}
        >
          {helperText}
        </Text>
      )}
    </FormControl>
  )
})

export default TextField
