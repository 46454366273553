import { Box, BoxProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface Props extends BoxProps {
  badgeProps?: BoxProps
  /** if true, show dot, else render text */
  value?: string | number | boolean
}

const BadgeIndicator = forwardRef<HTMLDivElement, Props>(
  ({ badgeProps, children, value, ...rest }, ref) => {
    const palette = 'primary'

    const isText = typeof value === 'string' || typeof value === 'number'

    return (
      <Box ref={ref} position="relative" {...rest}>
        <Box
          position="absolute"
          transform={`scale(${value ? 1 : 0})`}
          transition="200ms ease"
          top={0}
          right={0}
          py={isText ? 0 : 1}
          px={1}
          borderRadius={8}
          color="white"
          fontSize={8}
          backgroundColor={`${palette}.500`}
          {...badgeProps}
        >
          {isText && value}
        </Box>

        {children}
      </Box>
    )
  }
)

export default BadgeIndicator
