import { useLocation } from 'react-router-dom'
import { EventProvider } from './components/event'
import { FacilityProvider } from './components/Facility'

export function ResourceProvider({ children }: { children: any }) {
  const location = useLocation()
  const isEventPage = location.pathname.includes('/events')
  const isFacilityPage = location.pathname.includes('/facilities')

  if (isEventPage) return <EventProvider>{children}</EventProvider>

  if (isFacilityPage) return <FacilityProvider>{children}</FacilityProvider>

  return <>{children}</>
}
