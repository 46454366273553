import {
  Box,
  Divider,
  Flex,
  Hide,
  Image,
  Spacer,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import {
  Bars3Icon,
  BellIcon,
  Button,
  ChevronLeftIcon,
  DevDetailsButton,
  IconButton,
  Panel,
  UserProfile,
} from 'components'
import ColorModeSwitch from 'components/ColorModeSwitch'
import { useWorkspace } from 'utils'
import global from 'utils/globals'
import useGoBack from 'utils/workspaceUtils'
import { useEvent } from '../utils/eventUtils'
import { useCurrentPage } from '../utils/pageUtils'
import { RouteName } from '../utils/routing'
import { useBreakpoint } from '../utils/webHelpers'
import BreadCrumb from './BreadCrumb'
import CreateNewButton from './CreateNewButton'
import EntevateAdminControl from './EntevateAdminControl'
import SideMenuDrawer from './SideMenuDrawer'

export default function TopBar() {
  const { colorMode } = useColorMode()
  const currentPage = useCurrentPage()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const goBack = useGoBack()
  const breakpoint = useBreakpoint()

  return (
    <>
      <Hide below="sm" ssr={false}>
        <Flex
          flexDirection="column"
          flex="auto"
          maxH={global.TOP_BAR_HEIGHT}
          mr={global.SIDE_PADDING}
        >
          <Box display="flex" py={4} flexDirection="row" gap={3} w="100%" alignItems="center">
            <Panel>
              <IconButton
                variant="ghost"
                isDisabled
                icon={<BellIcon />}
                aria-label="Notifications"
                width="24px"
              />
            </Panel>
            <CreateNewButton variant={breakpoint > 2 ? 'button' : 'icon'} />
            {currentPage?.isChild && <BreadCrumb />}
            {currentPage?.path === RouteName.Manage && (
              <Panel>
                <Button
                  variant="ghost"
                  onClick={goBack}
                  leftIcon={<ChevronLeftIcon fontSize="md" />}
                >
                  Back
                </Button>
              </Panel>
            )}
            <Spacer />
            <Flex gap={3} alignItems="center">
              <EntevateAdminControl />

              <Panel>
                <ColorModeSwitch />
              </Panel>
              <Panel height={'40px'} alignItems={'center'}>
                <UserProfile />
              </Panel>

              <TopBarDevButton />
            </Flex>
          </Box>
          <Divider
            orientation="horizontal"
            borderColor={colorMode === 'light' ? 'black' : 'white'}
            opacity={colorMode === 'light' ? 0.1 : 0.2}
          />
        </Flex>
      </Hide>
      <Hide above="sm" ssr={false}>
        <Box
          display="flex"
          p={2}
          maxWidth="100vw"
          minH={global.TOP_BAR_HEIGHT_MOBILE}
          maxH={global.TOP_BAR_HEIGHT_MOBILE}
          flexDirection="row"
          alignItems={'end'}
          justifyItems="center"
          bg={colorMode === 'light' ? 'rgba(255, 255, 255, 0.40)' : 'rgba(255, 255, 255, 0.10)'}
          borderBottom={
            colorMode === 'light' ? '1px solid #DEDEDE' : '1px solid rgba(255, 255, 255, 0.1)'
          }
          boxShadow={'0px 4px 10px 0px rgba(0, 0, 0, 0.10)'}
          backdropFilter={'blur(20px)'}
        >
          <Flex align={'center'}>
            <IconButton
              aria-label="side-menu"
              icon={
                <Bars3Icon color={colorMode === 'light' ? 'rgba(103, 103, 103, 1)' : 'white'} />
              }
              bg={''}
              onClick={onOpen}
            />
            <Image
              position={'absolute'}
              left={'33vw'}
              src={
                colorMode === 'light'
                  ? '/common/momentify-logo-dark.svg'
                  : '/common/momentify-logo-light.svg'
              }
              alt="momentify logo"
              height={colorMode === 'light' ? '22px' : '24px'}
            />
          </Flex>
        </Box>
        <SideMenuDrawer isOpen={isOpen} onClose={onClose} />
      </Hide>
    </>
  )
}

function TopBarDevButton() {
  const { workspace } = useWorkspace()
  const { event } = useEvent()

  if (!workspace?.id) return null

  return (
    <DevDetailsButton
      items={[
        { collection: 'Workspace', id: workspace?.id, label: 'Workspace', type: 'db' },
        { collection: 'events', id: event?.id, label: 'Event', type: 'db' },
        { collection: 'templates', id: event?.template?.id, label: 'Template', type: 'db' },
        { label: 'Event Storage', type: 'storage', url: `events/${event?.id}`, isFolder: true },
      ]}
    />
  )
}
