import { Collapse, Flex, FlexProps, Text, useDisclosure } from '@chakra-ui/react'
import { ChevronDownIcon } from './icons'

export interface Props extends FlexProps {
  label: JSX.Element | string
  open?: boolean
  onToggle?: (open: boolean) => void
}

export default function Collapsible({
  children,
  label,
  open,
  onToggle: _onToggle,
  ...rest
}: Props) {
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Flex direction="column" gap={1} {...rest}>
      <Flex alignItems="center" justifyContent="space-between" onClick={onToggle} cursor="pointer">
        {typeof label === 'string' ? <Text textStyle="bodyMedium">{label}</Text> : label}
        <ChevronDownIcon
          size="sm"
          transform={isOpen ? 'rotate(-180deg)' : undefined}
          transition="all 300ms"
        />
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </Flex>
  )
}
