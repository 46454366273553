import {
  Avatar,
  Flex,
  Hide,
  InputGroup,
  InputLeftElement,
  ListProps,
  Spacer,
  Text,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  Button,
  ChevronDownIcon,
  DebouncedTextField,
  IconButton,
  MagnifyingGlassIcon,
  Menu,
  PencilSquareIcon,
  PlusIcon,
  ShareIcon,
  StarIcon,
  Table,
  TableColumn,
  TableProps,
  Tooltip,
} from 'components'
import { SortDirection } from 'ka-table'
import { startCase } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { IUser, IWorkspace, PermissionValue, Status, UserWithId, WorkspaceUser } from 'types'
import { getUsers, makeUserDisplayName, useIntercomUtils } from 'utils'
import { defaultProps, makeTypeByField } from 'utils/tableUtils'
import NewUserForm from './NewUserForm'
import { WorkspaceUserDrawer } from './WorkspaceUserDrawer'

interface Props extends ListProps {
  onUpdate: (body: Partial<IWorkspace>) => void
  workspace?: IWorkspace
}

export default function WorkspaceUsers({ onUpdate, workspace }: Props) {
  const [isLoading, setIsLoading] = useState(true)
  const [permissionFilter, setPermissionFilter] = useState('')
  const [search, setSearch] = useState('')
  const [selectedUser, setSelectedUser] = useState<UserWithId>()
  const [statusFilter, setStatusFilter] = useState('')
  const [tableProps, setTableProps] = useState(userTableProps)
  const [userInfo, setUserInfo] = useState<{ [id: string]: IUser }>({})
  const { colorMode } = useColorMode()
  const toast = useToast()
  const displayUserFields = ['email', 'status', 'created', 'permission']
  const displayUserFieldsMobile = ['email']
  const newUserRef = useRef<HTMLInputElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  useIntercomUtils(Boolean(selectedUser))
  const { userById, pendingUserByEmail } = workspace || {}
  const [openDrawer, setOpenDrawer] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  async function updatePermission(email: string, permission: PermissionValue) {
    const user = users.find(u => u.email === email)
    if (!user) return

    if (user.id) {
      await onUpdate({
        userById: {
          ...userById!,
          [user.id]: { ...userById?.[user.id]!, permission },
        },
      })
    } else {
      await onUpdate({
        pendingUserByEmail: {
          ...pendingUserByEmail!,
          [user.email]: { ...pendingUserByEmail?.[email], permission } as WorkspaceUser,
        },
      })
    }
  }

  async function handleRemove(email: string) {
    const user = users.find(u => u.email === email)
    if (!user) return

    if (user.id) {
      await onUpdate({
        userById: Object.fromEntries(Object.entries(userById!).filter(([id]) => id !== user.id)),
      })
    } else {
      await onUpdate({
        pendingUserByEmail: Object.fromEntries(
          Object.entries(pendingUserByEmail!).filter(([email]) => email !== user.email)
        ),
      })
    }
  }

  function handleOpenDrawer(user: UserWithId) {
    setSelectedUser(user)
    setOpenDrawer(true)
  }

  useEffect(() => {
    const fetchUsersInfo = async () => {
      if (workspace && userById) {
        const userIds = Object.keys(userById).filter(Boolean)
        if (userIds.length > 0) {
          setUserInfo(await getUsers(userIds))
        }
      }
      setIsLoading(false)
    }

    fetchUsersInfo()
  }, [workspace])

  const users = useMemo(() => {
    if (workspace && !isLoading) {
      return parseUsers(workspace, userInfo)
    }
    return []
  }, [workspace, userInfo, isLoading])

  useEffect(() => {
    if (!users) return
    if (!users.length) return setTableProps(s => ({ ...s, data: [] }))

    const filteredUsers = users.filter(user => {
      const permissionMatches = permissionFilter ? user.permission === permissionFilter : true
      const statusMatches = statusFilter ? user.status === statusFilter : true
      return permissionMatches && statusMatches
    })

    const generatedCols = makeColumns(filteredUsers)
    const sortedColumn = tableProps.columns.find(c => c.sortDirection)
    const columns = generatedCols.map(c => {
      const defaultSort = c.key === 'email' ? SortDirection.Ascend : undefined
      const sortDirection = c.key === sortedColumn?.key ? sortedColumn.sortDirection : defaultSort
      return { ...c, sortDirection }
    })

    setTableProps(s => ({ ...s, columns, data: filteredUsers }))
  }, [users, permissionFilter, statusFilter])

  function handleLinkCopy(email: string) {
    const inviteLink = `https://momentify.entevate.com/invite/${workspace?.id}?email=${email}`

    navigator.clipboard
      .writeText(inviteLink)
      .then(() => {
        toast({ status: 'success', description: 'Invite link copied to clipboard!' })
      })
      .catch(() => {
        toast({ status: 'error', description: 'Error copying invite link' })
      })
  }

  return (
    <Flex direction="column" gap={2} overflow="hidden">
      <Flex justify="space-between" ref={ref} h="44px">
        <InputGroup maxW="250px">
          <InputLeftElement pointerEvents="none">
            <MagnifyingGlassIcon
              color={colorMode === 'light' ? 'rgba(23, 23, 23, 0.6)' : 'white'}
            />
          </InputLeftElement>
          <DebouncedTextField
            isDisabled={isLoading || !users?.length}
            onChange={setSearch}
            placeholder="Search..."
            value={search}
            width={'100%'}
            controlled
            boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.06)'}
            backdropFilter={'blur(14px)'}
            bg={colorMode === 'light' ? 'rgba(255, 255, 255, 0.74)' : 'rgba(255, 255, 255, 0.14)'}
            borderRadius={'10px'}
            border={0}
            pl={10}
          />
        </InputGroup>
        <Spacer />
        <Flex gap={3} ml={3}>
          {!isMobile && (
            <>
              <Menu
                isDisabled={isLoading || !users?.length}
                variant="solid"
                rightIcon={<ChevronDownIcon fontSize={'md'} />}
                iconSpacing={{ base: 0, sm: 2 }}
                borderRadius={'10px'}
                buttonLabel={
                  <Text textStyle={'bodySmall'} fontSize={{ base: '0', sm: 'sm' }}>
                    Status: {startCase(statusFilter) || 'All'}
                  </Text>
                }
                options={[
                  {
                    type: 'group',
                    isOption: true,
                    defaultValue: 'all',
                    options: [
                      { value: 'all', label: 'All', onClick: () => setStatusFilter('') },
                      {
                        value: 'active',
                        label: 'Active',
                        onClick: () => setStatusFilter('active'),
                      },
                      {
                        value: 'pending',
                        label: 'Pending',
                        onClick: () => setStatusFilter('pending'),
                      },
                    ],
                  },
                ]}
              />
              <Menu
                isDisabled={isLoading || !users?.length}
                variant="solid"
                rightIcon={<ChevronDownIcon fontSize={'md'} />}
                iconSpacing={{ base: 0, sm: 2 }}
                borderRadius={'10px'}
                buttonLabel={
                  <Text textStyle={'bodySmall'} fontSize={{ base: '0', sm: 'sm' }}>
                    Permission: {startCase(permissionFilter) || 'All'}
                  </Text>
                }
                options={[
                  {
                    type: 'group',
                    isOption: true,
                    defaultValue: 'all',
                    options: [
                      { value: 'all', label: 'All', onClick: () => setPermissionFilter('') },
                      {
                        value: 'admin',
                        label: 'Admin',
                        onClick: () => setPermissionFilter('admin'),
                      },
                      {
                        value: 'editor',
                        label: 'Editor',
                        onClick: () => setPermissionFilter('editor'),
                      },
                      {
                        value: 'viewer',
                        label: 'Viewer',
                        onClick: () => setPermissionFilter('viewer'),
                      },
                    ],
                  },
                ]}
              />
            </>
          )}

          <Button palette="primary" onClick={onOpen} leftIcon={<PlusIcon />}>
            <Hide below="sm">
              <Text textStyle={'bodySmall'}>Invite New Member</Text>
            </Hide>
            <NewUserForm
              ref={newUserRef}
              onComplete={() => onClose()}
              isOpen={isOpen}
              onClose={onClose}
              existingUsers={users?.map(u => u.email)}
            />
          </Button>
        </Flex>
      </Flex>

      <Flex bg="white" borderRadius="14px" overflowY="auto">
        <Table
          {...tableProps}
          onRowClick={
            isMobile ? id => handleOpenDrawer(tableProps!.data?.find(r => r.id === id)) : undefined
          }
          emptyText="No members found"
          onChange={setTableProps}
          searchText={search}
        />
      </Flex>

      <WorkspaceUserDrawer
        copyInviteLink={() => handleLinkCopy(selectedUser?.email!)}
        member={selectedUser}
        openDrawer={openDrawer}
        updatePermissions={v => updatePermission(selectedUser?.email!, v)}
        onRemoveMember={() => handleRemove(selectedUser?.email!)}
        onDone={() => setOpenDrawer(false)}
      />
    </Flex>
  )

  function makeColumns(users: Partial<UserWithId>[]): TableProps['columns'] {
    if (!users?.length) return []
    const userCols = makeTypeByField(users)
    const defCols = isMobile
      ? Object.entries(userCols).filter(([key]) => displayUserFieldsMobile.includes(key))
      : Object.entries(userCols).filter(([key]) => displayUserFields.includes(key))

    return defCols.map(([key, value]) => ({
      isEditable: false,
      key,
      dataType: value,
      title: getColumnTitle(key),
      width: getWidth(key),
      renderCell: getRenderCell(key),
      isResizable: true,
    }))
  }

  function getRenderCell(key: string): TableColumn['renderCell'] {
    switch (key) {
      case 'email':
        return props => {
          const email = props.value
          const name = props.rowData['name']
          const photoUrl = props.rowData['photoUrl']
          return (
            <Flex gap={2} align="center">
              <Avatar name={name} src={photoUrl} size="sm" />
              <Flex direction="column" justify="center">
                {name && <Text textStyle="bodySmall">{name}</Text>}
                <Text
                  textStyle="bodySmall"
                  fontWeight="500"
                  color={name || props.rowData['status'] === 'pending' ? 'gray.500' : 'black'}
                >
                  {email}
                </Text>
              </Flex>
            </Flex>
          )
        }

      case 'status':
        return props => {
          return (
            <Flex gap={3}>
              <Text
                padding="10px 18px"
                bg={props.value === 'pending' ? 'rgba(23, 23, 23, 0.10)' : 'rgba(15, 186, 0, 0.14)'}
                borderRadius="50px"
                color={props.value === 'pending' ? 'rgba(23, 23, 23, 1)' : 'rgba(8, 103, 0, 1)'}
              >
                {startCase(props.value)}
              </Text>
              {props.value === 'pending' && (
                <Tooltip label="Copy invite link" mt={2}>
                  <IconButton
                    aria-label="share the signup link"
                    icon={<ShareIcon fontSize={'sm'} color="black" />}
                    variant="ghost"
                    onClick={() => handleLinkCopy(props.rowData['email'])}
                  />
                </Tooltip>
              )}
            </Flex>
          )
        }

      case 'permission':
        return props => (
          <Flex flex={1} gap={1} align={'center'}>
            {props.value === 'admin' && <StarIcon fontSize="md" />}
            <Text>{startCase(props.value)}</Text>
            <Spacer />
            <Tooltip placement="top" label="Manage Member" alignContent="center" h="30px" hasArrow>
              <IconButton
                icon={
                  <PencilSquareIcon
                    fontSize={'lg'}
                    color={'rgba(0, 0, 0, 0.5)'}
                    _hover={{
                      color: 'rgba(0, 0, 0, 1)',
                    }}
                  />
                }
                aria-label={'Edit Member'}
                variant="ghost"
                onClick={() => handleOpenDrawer(props.rowData)}
              />
            </Tooltip>
          </Flex>
        )
    }
  }
}

function parseUsers(workspace: IWorkspace, userInfo: { [id: string]: IUser }): UserWithId[] {
  return [
    ...Object.keys(workspace.userById).map(id => ({
      id: id,
      name: makeUserDisplayName(userInfo[id]),
      email: workspace.userById[id].email,
      photoUrl: userInfo[id]?.photoUrl,
      status: 'active' as Status,
      created: workspace.userById[id].created,
      permission: workspace.userById[id].permission,
    })),
    ...Object.entries(workspace.pendingUserByEmail).map(([email, { permission, created }]) => ({
      email: email,
      status: 'pending' as Status,
      permission: permission,
      created: created,
    })),
  ]
}

function getColumnTitle(key: string) {
  switch (key) {
    case 'permission':
      return 'Permission Level'
    case 'email':
      return 'Member'
    case 'created':
      return 'Date Added'
    default:
      return startCase(key)
  }
}

function getWidth(key: string) {
  switch (key) {
    case 'created':
      return 140
    case 'member':
      return 140
    case 'permissionLevel':
      return 120
    case 'status':
      return 100
    default:
      return 175
  }
}

const userTableProps: TableProps = {
  ...defaultProps,
  rowKeyField: 'email',
}
