import {
  Switch as ChakraSwitch,
  Flex,
  FlexProps,
  SwitchProps,
  Text,
  TextProps,
} from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface Props extends Omit<FlexProps & SwitchProps, 'onChange'> {
  label?: string
  /** (default right) */
  labelPosition?: 'left' | 'right'
  labelProps?: TextProps
  onChange: (checked: boolean) => void
}

export default forwardRef<HTMLDivElement, Props>(
  (
    {
      colorScheme = 'primary',
      isChecked = false,
      isDisabled,
      isFocusable,
      isInvalid,
      isReadOnly,
      label,
      labelPosition = 'right',
      labelProps,
      onChange,
      size,
      ...rest
    },
    ref
  ) => {
    return (
      <Flex
        ref={ref}
        alignItems="center"
        gap={2}
        cursor="pointer"
        onClick={e => {
          onChange(!isChecked)
          e.preventDefault()
        }}
        {...rest}
      >
        {labelPosition === 'left' && label && <Text {...labelProps}>{label}</Text>}
        <ChakraSwitch
          {...{ colorScheme, isChecked, isDisabled, isFocusable, isInvalid, isReadOnly, size }}
        />
        {labelPosition === 'right' && label && <Text {...labelProps}>{label}</Text>}
      </Flex>
    )
  }
)
