import merge from 'deepmerge'
import { MediaDimensions, Point, WorkspaceScope } from 'types'
import { removeExtension } from './storage'

export function makePosition({ x, y, z }: Point): [number, number, number] {
  return [x, y, z]
}

export function addPoints(...points: Array<Point | undefined>): Point {
  let point = { x: 0, y: 0, z: 0 }

  points.forEach(p => {
    if (!p) return
    point.x += p.x
    point.y += p.y
    point.z += p.z
  })

  return point
}

export function scalePoint(point: Point, scale: number): Point {
  if (!point) return { x: 0, y: 0, z: 0 }
  return { x: point.x * scale, y: point.y * scale, z: point.z * scale }
}

/** Needed to support lef-handed Unity framework */
export function flipX(point: Point): Point {
  return { x: -point.x, y: point.y, z: point.z }
}

/** returns a promise that sleeps for specified time in ms. default 0 */
export function sleep(ms: number = 0) {
  return new Promise(resolve => setTimeout(() => resolve(null), ms))
}

export function validEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export function getImageLightness(urlPath: string) {
  let img = document.createElement('img')
  img.src = urlPath
  img.crossOrigin = 'Anonymous'
  img.style.display = 'none'
  document.body.appendChild(img)

  let colorSum = 0

  return new Promise<number>((resolve, reject) => {
    img.onload = function () {
      let canvas = document.createElement('canvas')
      //@ts-ignore
      canvas.width = this.width
      //@ts-ignore
      canvas.height = this.height

      let ctx = canvas.getContext('2d')
      if (!ctx) return reject('Could not get 2d context')

      //@ts-ignore
      ctx.drawImage(this, 0, 0)

      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      let data = imageData.data
      let r, g, b, avg

      for (let x = 0, len = data.length; x < len; x += 4) {
        r = data[x]
        g = data[x + 1]
        b = data[x + 2]

        avg = Math.floor((r + g + b) / 3)
        colorSum += avg
      }

      const brightness = Math.floor(colorSum / (canvas.width * canvas.height))
      resolve(brightness / 255)
    }
  })
}

interface VideoToImageOptions {
  /** default 0.5 seconds */
  frameTimeInSeconds?: number
  /** default same as video file */
  filename?: string
  /** default png */
  extension?: string
}

export function imageFromVideo(videoFile: File, options?: VideoToImageOptions) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) return

  const video = document.createElement('video')
  const source = document.createElement('source')
  const urlRef = URL.createObjectURL(videoFile)

  video.style.display = 'none'
  canvas.style.display = 'none'

  source.setAttribute('src', urlRef)
  video.setAttribute('crossorigin', 'anonymous')
  video.setAttribute('preload', 'metadata')

  video.appendChild(source)
  document.body.appendChild(canvas)
  document.body.appendChild(video)

  const videoName = removeExtension(videoFile.name)
  const { frameTimeInSeconds = 0.5, extension = 'png', filename = videoName } = options || {}

  video.currentTime = frameTimeInSeconds
  video.load()

  return new Promise<File>((resolve, reject) => {
    video.addEventListener('loadedmetadata', function () {
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
    })

    video.addEventListener('loadeddata', function () {
      setTimeout(() => {
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
        const type = 'image/' + extension
        canvas.toBlob(blob => {
          if (blob) {
            resolve(new File([blob], `${filename}.${extension}`, { type }))
            URL.revokeObjectURL(urlRef)
          } else {
            reject()
          }

          video.remove()
          canvas.remove()
        }, type)
      }, 2000)
    })
  })
}

export function getImageDimensions(file: File): Promise<MediaDimensions> {
  const fr = new FileReader()

  return new Promise((resolve, reject) => {
    fr.onload = function () {
      // file is loaded
      if (typeof this.result !== 'string') throw reject('File is not a string')

      const img = new Image()
      img.onload = function () {
        resolve({ width: img.width, height: img.height })
      }

      img.src = this.result // is the data URL because called with readAsDataURL
    }

    fr.readAsDataURL(file)
  })
}

export function getApp(): WorkspaceScope {
  switch (window.location.hostname) {
    case 'product-tour.entevate.com':
    case 'localhost:5173':
      return 'ar'
    default:
      return 'emp'
  }
}

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0
}

export const isDevelopment = process.env.NODE_ENV === 'development'

export const startsWithVowel = (word: string) => /^[aeiou]/i.test(word)

export function toPascalCase(text: string) {
  return text.replace(/(^\w|-\w)/g, t => t.replace(/-/, '').toUpperCase())
}

export function randomString(length = 8) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  return Array.from({ length }, () => chars[Math.floor(Math.random() * chars.length)]).join('')
}

/** Create deep copy of object removing undefined props */
export const cleanObject = (obj: object) => JSON.parse(JSON.stringify(obj))

export function mergeObjects<T extends object | null | undefined>(obj1: T, obj2: Partial<T>): T {
  if (!obj1) return obj1
  if (!obj2) return obj1
  return merge(obj1, obj2, { arrayMerge: (_, sourceArray) => sourceArray })
}
