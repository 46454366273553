import {
  deleteObject,
  getBlob as firebaseGetBlob,
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
  StorageReference,
  uploadBytesResumable,
} from 'firebase/storage'
import humanizeString from 'humanize-string'
import { IMedia, MediaType } from 'types'
import { createDoc } from './db'
import firebaseApp from './firebase'
import { getImageDimensions, imageFromVideo } from './helpers'

const bucket = 'gs://entevate.appspot.com'

const storage = getStorage(firebaseApp, bucket)

export function getFileUrl(filePath: string, workspaceId?: string) {
  const fileRef = getRef(filePath, workspaceId)
  return getDownloadURL(fileRef)
}

export function getFileMeta(filePath: string, workspaceId?: string) {
  const fileRef = getRef(filePath, workspaceId)
  return getMetadata(fileRef)
}

export function getBlob(filePath: string, workspaceId?: string) {
  const fileRef = getRef(filePath, workspaceId)
  return firebaseGetBlob(fileRef)
}

export function getRef(filePath: string, workspaceId?: string) {
  return ref(storage, workspaceId ? `Workspaces/${workspaceId}/${filePath}` : filePath)
}

export function getStoragePath(media: IMedia) {
  if (media.type === 'image') return getImagePath(media.id)
  else if (media.type === 'video') return getVideoPath(media.id)
  else return ''
}

export const getImagePath = (mediaId: string) => `media/images/${mediaId}`
export const getVideoPath = (mediaId: string) => `media/videos/${mediaId}`
export const getThumbPath = (mediaId: string) => `media/images/thumbs/${mediaId}`

export function uploadFileResumable(filePath: string, file: File) {
  if (!file) return

  const storageRef = ref(storage, filePath)
  const uploadTask = uploadBytesResumable(storageRef, file)

  return uploadTask
}

export function deleteFile(filePath: string) {
  return deleteObject(ref(storage, filePath))
}

export function fileFromPath(path: string) {
  const parts = path.split('/')
  return parts[parts.length - 1]
}

export const removeExtension = (filename: string) => filename.replace(/\.[^/.]+$/, '')
export const getExtension = (filename: string) => filename.split('.').pop()
export const typeFromFile = (file: File): MediaType =>
  file.type.includes('image') ? 'image' : 'video'
export const acceptedImages = {
  'image/*': ['.png', '.jpg', '.jpeg', '.svg'],
}
export const acceptedVideos = { 'video/*': ['.mp4'] }
export const acceptedSpreadsheets = {
  'application/vnd.ms-excel*': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
  'text/csv': [],
}

export async function uploadMedia(
  workspaceId: string,
  userId: string,
  file: File,
  onProgress?: (progress: number) => void,
  docProps?: Partial<IMedia>
) {
  const type = typeFromFile(file)
  const directory = `Workspaces/${workspaceId}/media`
  let dimensions = type === 'image' ? await getImageDimensions(file) : { width: 0, height: 0 }
  const title = humanizeString(removeExtension(file.name))
  const doc = await createDoc(userId, 'Media', {
    desc: '',
    dimensions,
    title,
    type,
    workspaceId,
    ...docProps,
  })

  await uploadFile(`${directory}/${type}s/${doc.id}`, file, onProgress)
  return doc
}

export async function updateMedia(
  workspaceId: string,
  file: File,
  mediaId: string,
  onProgress?: (progress: number) => void
) {
  const type = typeFromFile(file)
  const directory = `Workspaces/${workspaceId}/media`
  await uploadFile(`${directory}/${type}s/${mediaId}`, file, onProgress)

  const imageFile = type === 'image' ? file : await imageFromVideo(file)
  if (!imageFile) throw 'Error creating thumbnail from video'

  return getImageDimensions(imageFile)
}

export async function uploadFile(
  filePath: string,
  file: File,
  onProgress?: (progress: number) => void
) {
  return new Promise<StorageReference>(async (resolve, reject) => {
    const task = uploadFileResumable(filePath, file)
    task?.on(
      'state_changed',
      snapshot => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        onProgress?.(progress)
      },
      e => {
        if (e.code === 'storage/unauthorized') {
          reject('You are not authorized to upload files')
        } else {
          reject('Error uploading file')
          console.error(e)
        }
      },
      async () => resolve(task?.snapshot.ref)
    )
  })
}
