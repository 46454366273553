import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { HelpHover } from 'components'
import { PermissionValue } from 'types'
import { getApp } from 'utils'

interface Props extends FlexProps {
  value: PermissionValue
}

export default function PermissionWithHelp({ value, ...rest }: Props) {
  const permission = permissionOptions.find(p => p.value === value)
  const { help, label } = permission || {}

  return (
    <Flex align="center" {...(rest as any)} gap={2}>
      <Text textStyle={'bodySmall'} fontWeight={'normal'}>
        {label}
      </Text>
      <HelpHover text={help!} borderRadius={'5px'} iconProps={{ size: 'sm' }} />
    </Flex>
  )
}

interface Option {
  help: string
  label: string
  value: PermissionValue
}

const app = getApp()

export const permissionOptions: Array<Option> = [
  {
    help:
      app === 'emp'
        ? 'Can manage workspaces and users.'
        : 'Can manage content, update workspace settings and manage users',
    label: 'Admin',
    value: 'admin',
  },
  {
    help:
      app === 'emp'
        ? 'Can manage events in an authorized workspace.'
        : 'Can manage content in workspace',
    label: 'Editor',
    value: 'editor',
  },
  {
    help:
      app === 'emp' ? 'Can view archived and upcoming events.' : 'Can view content in workspace',
    label: 'Viewer',
    value: 'viewer',
  },
]
