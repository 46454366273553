import {
  Divider,
  Flex,
  Hide,
  Image,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Skeleton,
  Spacer,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  IconButton,
  Panel,
  PencilSquareIcon,
  PlusIcon,
  Tooltip,
} from 'components'
import { AuthContext } from 'components/providers'
import { useContext, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import {
  createDoc,
  makeEmptyWorkspace,
  usePermission,
  useSwitchWorkspace,
  useWorkspace,
  useWorkspaces,
} from 'utils'
import { useCurrentPage } from '../utils/pageUtils'
import { RoutePath } from '../utils/routing'
import { useSideMenuItems } from '../utils/sideMenuUtils'
import SideMenuButton from './SideMenuButton'

export default function SideMenu() {
  const { user, entevatePermission } = useContext(AuthContext)
  const { workspaces, mutate } = useWorkspaces('emp')
  const { colorMode } = useColorMode()
  const { workspaceId } = useParams()
  const { workspace } = useWorkspace()
  const permission = usePermission()
  const currentPage = useCurrentPage()
  const currentPagePath = currentPage?.isChild
    ? currentPage?.rootPath
      ? currentPage.rootPath
      : currentPage.parentPath
    : currentPage?.path
  const switchWorkspace = useSwitchWorkspace()
  const navigate = useNavigate()
  const [sideMenuCollapsed, setSideMenuCollapsed] = useState(false)
  const [sideMenuWidth, setSideMenuWidth] = useState(200)
  const sideMenuItems = useSideMenuItems()

  const SideMenuIcon = sideMenuCollapsed ? ArrowUpTrayIcon : ArrowDownTrayIcon

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      const mdScreenWidth = 768
      if (width < mdScreenWidth) {
        setSideMenuWidth(70)
        setSideMenuCollapsed(true)
      } else {
        setSideMenuWidth(200)
        setSideMenuCollapsed(false)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function toggleSideMenu() {
    setSideMenuCollapsed(prevState => !prevState)
    if (sideMenuCollapsed) {
      setSideMenuWidth(200)
      setSideMenuCollapsed(false)
    } else {
      setSideMenuWidth(70)
      setSideMenuCollapsed(true)
    }
  }

  async function handleNewWorkspace() {
    const doc = await createDoc(user?.id!, 'Workspace', makeEmptyWorkspace('emp'))
    mutate()
    navigate(generatePath(RoutePath.ManageWorkspace, { workspaceId: doc.id }))
  }

  return (
    <Hide below="sm" ssr={false}>
      <Panel width={sideMenuWidth} flexShrink={0} my={4} p={3} gap={1}>
        {!sideMenuCollapsed && (
          <Image
            pr={10}
            mb={5}
            src={
              colorMode === 'light'
                ? '/common/momentify-logo-dark.svg'
                : '/common/momentify-logo-light.svg'
            }
            alt="momentify logo"
            height={colorMode === 'light' ? '20px' : '22px'}
          />
        )}
        {sideMenuCollapsed && (
          <Image
            mb={5}
            src={
              colorMode === 'light'
                ? '/common/momentify-icon-dark.svg'
                : '/common/momentify-icon-light.svg'
            }
            alt="momentify logo"
            height={5}
          />
        )}
        <Menu>
          {({ isOpen, onClose }) => (
            <>
              <Tooltip
                label={workspace?.title}
                isDisabled={
                  !workspace?.title || (workspace?.title.length <= 20 && !sideMenuCollapsed)
                }
                alignContent={'center'}
                h={'30px'}
              >
                <Panel backdropFilter="none">
                  {workspaces?.length == 1 ? (
                    <Flex direction={'column'} width={'80%'} h="50px" justify="center" px={4}>
                      {!sideMenuCollapsed && (
                        <Text
                          textStyle={'bodyXSmall'}
                          color={
                            colorMode === 'light'
                              ? 'rgba(23, 23, 23, 0.4)'
                              : 'rgba(255, 255, 255, 0.5)'
                          }
                          textAlign={'left'}
                        >
                          Workspace
                        </Text>
                      )}
                      {workspace ? (
                        <Text
                          textStyle="bodySmall"
                          textAlign="left"
                          isTruncated
                          textOverflow="ellipsis"
                        >
                          {workspace.title}
                        </Text>
                      ) : (
                        <Skeleton height="18px" width="100px" />
                      )}
                    </Flex>
                  ) : (
                    <MenuButton
                      isActive={isOpen}
                      isDisabled={workspaces?.length == 1}
                      as={Button}
                      h="50px"
                    >
                      <Flex gap={2} justify={'space-between'} align={'center'}>
                        <Flex direction={'column'} width={'80%'}>
                          {!sideMenuCollapsed && (
                            <Text
                              textStyle={'bodyXSmall'}
                              color={
                                colorMode === 'light'
                                  ? 'rgba(23, 23, 23, 0.4)'
                                  : 'rgba(255, 255, 255, 0.5)'
                              }
                              textAlign={'left'}
                            >
                              Workspace
                            </Text>
                          )}
                          {workspace ? (
                            workspace.title ? (
                              <Text
                                textStyle="bodySmall"
                                textAlign="left"
                                isTruncated
                                textOverflow="ellipsis"
                              >
                                {workspace.title}
                              </Text>
                            ) : (
                              <Text textStyle="bodySmall" opacity={0.5} as="i" textAlign="left">
                                Draft
                              </Text>
                            )
                          ) : (
                            <Skeleton height="18px" width="100px" />
                          )}
                        </Flex>
                        {!sideMenuCollapsed &&
                          (isOpen ? (
                            <ChevronUpIcon fontSize="sm" />
                          ) : (
                            <ChevronDownIcon fontSize="sm" />
                          ))}
                      </Flex>
                    </MenuButton>
                  )}
                </Panel>
              </Tooltip>
              <Portal>
                <MenuList
                  borderRadius="md"
                  boxShadow={'0px 4px 24px 0px rgba(0, 0, 0, 0.10)'}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  alignItems="flex-start"
                  p={4}
                >
                  <MenuOptionGroup
                    defaultValue={workspaceId}
                    opacity={0.6}
                    textStyle={'bodyXSmall'}
                    title="Workspaces"
                    type="radio"
                  >
                    {workspaces?.map(({ id, title }) => (
                      <MenuItemOption
                        as="div"
                        cursor={'pointer'}
                        h={'40px'}
                        key={id}
                        pr="0"
                        onClick={() => switchWorkspace(id, currentPagePath)}
                        value={id}
                      >
                        <Flex align={'center'} gap={2} justify={'space-between'}>
                          <Text
                            textStyle="bodySmall"
                            opacity={title ? 1 : 0.5}
                            as={title ? 'p' : 'i'}
                          >
                            {title || 'Draft'}
                          </Text>
                          {permission.workspace === 'admin' && (
                            <IconButton
                              aria-label="manage workspace"
                              icon={<PencilSquareIcon fontSize={'lg'} opacity={0.6} />}
                              onClick={async e => {
                                e.stopPropagation()
                                navigate(
                                  generatePath(RoutePath.ManageWorkspace, { workspaceId: id })
                                )
                                onClose()
                              }}
                            />
                          )}
                        </Flex>
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                  {entevatePermission === 'entevate' && (
                    <Button
                      leftIcon={<PlusIcon />}
                      onClick={() => {
                        handleNewWorkspace()
                        onClose()
                      }}
                      variant="outline"
                    >
                      <Text textStyle={'bodySmall'}>Create New</Text>
                    </Button>
                  )}
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
        <Flex direction="column" gap={2} mt={4}>
          {sideMenuItems!.map((item, index) => (
            <SideMenuButton
              key={index}
              children={sideMenuCollapsed ? undefined : item.text}
              leftIcon={<item.LeftIcon fontSize={'lg'} />}
              isCurrent={item.isCurrent}
              onClick={() => {
                if (workspaceId)
                  navigate({
                    pathname: generatePath(item.page, { workspaceId, id: null }),
                  })
              }}
              isDisabled={item.isDisabled}
            />
          ))}
        </Flex>
        <Spacer />
        <Flex direction={'row'}>
          <Spacer />
          <IconButton
            aria-label="side-nav-menu"
            icon={<SideMenuIcon fontSize={'25px'} style={{ transform: 'rotate(90deg)' }} />}
            opacity={colorMode === 'light' ? 0.7 : 1}
            onClick={toggleSideMenu}
          />
          {!sideMenuCollapsed && (
            <>
              <Divider
                mx={2}
                orientation="vertical"
                borderColor={{
                  base: 'rgba(23, 23, 23, 0)',
                  md: colorMode === 'light' ? 'rgba(23, 23, 23, 0.10)' : 'white',
                }}
              />
              <Flex direction={'column'} opacity={colorMode === 'light' ? 0.7 : 1}>
                <Text fontSize={'10px'}>powered by</Text>
                <Image
                  src={
                    colorMode === 'light'
                      ? '/common/entevate_logo_black.svg'
                      : '/common/entevate_logo_white.svg'
                  }
                  height={6}
                ></Image>
              </Flex>
            </>
          )}
          <Spacer />
        </Flex>
      </Panel>
    </Hide>
  )
}
