import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { Falsey } from 'lodash'

export interface Props extends FlexProps {
  label: string
  labelActions?: JSX.Element | Falsey
}

export default function InputContainer({ children, label, labelActions, ...rest }: Props) {
  return (
    <Flex direction={'column'} gap={2} {...rest}>
      <Flex justify={'space-between'} align="center">
        <Text textStyle={'bodySmall'} opacity={0.7}>
          {label}
        </Text>

        {labelActions || null}
      </Flex>

      {children}
    </Flex>
  )
}
