import { generatePath, matchPath, useLocation } from 'react-router-dom'
import { usePrefCurrentWorkspace } from 'utils'
import { useEvent } from './eventUtils'
import { useFacility } from './facilityUtils'
import { RouteName, RoutePath } from './routing'

export function useCurrentPage() {
  const location = useLocation()
  const match = (path: string) => matchPath(path, location.pathname)

  if (match(RoutePath.Events)) return { path: RouteName.Events, isChild: false }
  if (match(RoutePath.EventInfo))
    return { path: RouteName.EventInfo, isChild: true, parentPath: RouteName.Events }
  if (match(RoutePath.Facilities)) return { path: RouteName.Facilities, isChild: false }
  if (match(RoutePath.FacilityInfo))
    return { path: RouteName.Facilities, isChild: true, parentPath: RouteName.Facilities }
  if (match(RoutePath.Analytics))
    return {
      path: RouteName.Analytics,
      isChild: true,
      parentPath: RouteName.EventInfo,
      rootPath: RouteName.Events,
    }
  if (match(RoutePath.Content)) return { path: RouteName.Content, isChild: false }
  if (match(RoutePath.ManageWorkspace)) return { path: RouteName.Manage, isChild: false }
  if (match(RoutePath.ManageMedia)) return { path: RouteName.Media, isChild: false }
  if (match(RoutePath.Text)) return { path: RouteName.Text, isChild: false }
  if (match(RoutePath.TextInfo)) return { path: RouteName.Text, isChild: true }
}

export function isContentPage(routeName?: RouteName) {
  switch (routeName) {
    case RouteName.Content:
    case RouteName.Media:
    case RouteName.Text:
      return true
    default:
      return false
  }
}

export function getPageInfo(routeName?: RouteName) {
  const [workspaceId] = usePrefCurrentWorkspace()
  const { event } = useEvent()
  const { facility } = useFacility()

  if (isContentPage(routeName)) {
    return {
      title: 'Content',
      identifier: 'Content',
      path: generatePath(RoutePath.Content, { workspaceId: workspaceId }),
    }
  }

  switch (routeName) {
    case RouteName.Events:
      return {
        title: 'Events',
        identifier: 'Event',
        path: generatePath(RoutePath.Events, { workspaceId: workspaceId }),
      }
    case RouteName.EventInfo:
      if (!event) return { title: 'Events', identifier: 'Event Detail' }
      else
        return {
          title: 'Events',
          identifier: `${event.title}`,
          path: generatePath(RoutePath.EventInfo, { workspaceId: workspaceId, id: event.id }),
        }
    case RouteName.Facilities:
      if (!facility) return { title: 'Facilities', identifier: 'Facility' }
      return {
        title: 'Facilities',
        identifier: `${facility.title}`,
        path: generatePath(RoutePath.Facilities, { workspaceId: workspaceId }),
      }
    case RouteName.Analytics:
      return {
        title: 'Analytics',
        identifier: 'Analytics',
        path: ' ',
      }
  }
}
