import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react'
import {
  Cog6ToothIcon,
  DebouncedTextField,
  DeleteConfirmButton,
  InputContainer,
  LightModeBox,
  LocationAutocomplete,
  Menu,
  Select,
  SingleUseMediaInput,
  UserSelect,
} from 'components'
import { AuthContext, useAlert } from 'components/providers'
import { useContext, useEffect, useRef } from 'react'
import { deleteDoc, updateDoc, useIntercomUtils } from 'utils'
import { useGoToWorkspace, usePermission, useWorkspace } from 'utils/workspaceUtils'
import { getFacilityImagePath, useFacility } from '../../utils/facilityUtils'
import { useTemplates } from '../../utils/templateUtils'

import defaultImage from '../../assets/default_tile.png'
import { IFacility } from 'types'

export interface Props {
  isOpen: boolean
  onClose: () => void
}

export default function FacilityManage({ isOpen, onClose }: Props) {
  const toast = useToast()
  const { confirm } = useAlert()
  const { user } = useContext(AuthContext)
  const { workspace } = useWorkspace()
  const permission = usePermission()
  const templates = useTemplates(workspace?.id)
  const goToWorkspace = useGoToWorkspace()
  const goBack = () => goToWorkspace(workspace?.id, {})
  const { facility, refetchImage } = useFacility()
  useIntercomUtils(isOpen)
  const contentRef = useRef<HTMLDivElement>(null)

  const templateId = facility?.template?.id

  useEffect(() => {
    if (!templates.length || !facility || templateId) return
    update({ template: { id: templates[0].id } })
  }, [templates, templateId])

  if (!facility) return null

  const { id, title = '', template, location, hasImage, managerId } = facility
  const allowManagerEdit = permission.facility === 'admin' || user?.id === managerId

  async function update(body: Partial<IFacility>) {
    await updateDoc(user!.id, 'facilities', { id, ...body })
  }

  async function handleManagerChange(managerId: string) {
    if (permission.facility !== 'admin') {
      const response = await confirm(
        'You will no longer be able to assign/change the facility manager if you click Confirm. Please reach out to your workspace admin to be added back.',
        { cancelText: 'Cancel', okText: 'Confirm' }
      )
      if (!response) return
    }

    update({ managerId })
  }

  async function handleDelete() {
    await deleteDoc('facilities', id!).catch(() =>
      toast({ status: 'error', description: 'Failed to delete facility' })
    )
    goBack()
  }

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      size={{ base: 'xs', sm: 'sm' }}
      isOpen={isOpen}
      closeOnEsc
    >
      <DrawerOverlay />
      <LightModeBox>
        <DrawerContent ref={contentRef} borderRadius="md" my={2} mr={2}>
          <DrawerHeader borderBottom={'1px solid rgba(222, 226, 244, 1)'} p={2}>
            <Flex width={'100%'} align={'center'} gap={1} px={4} h={'40px'}>
              <Cog6ToothIcon fontSize={'20px'} />
              <Text textStyle={'bodyMedium'}>Manage Facility</Text>
              <Spacer />
              {permission.facility === 'admin' && (
                <Menu
                  menuProps={{ placement: 'bottom-start' }}
                  buttonLabel="Actions"
                  mr={6}
                  noPortal
                  options={[
                    {
                      type: 'custom',
                      label: (
                        <DeleteConfirmButton
                          onDelete={handleDelete}
                          description='If you are sure type "Delete" below.'
                        />
                      ),
                    },
                  ]}
                />
              )}
              <DrawerCloseButton m={1} />
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            <Flex gap={5} direction={'column'} mt={3} height={'95%'}>
              <DebouncedTextField
                label="Name"
                placeholder="Please enter facility name"
                value={title}
                onChange={title => update({ title })}
                showStatus
                autoFocus
              />

              <LocationAutocomplete
                label="City"
                value={location}
                onChange={location => update({ location })}
                popoverProps={{ portalProps: { containerRef: contentRef } }}
              />

              {allowManagerEdit && (
                <UserSelect
                  label="Facility Manager"
                  value={managerId}
                  onChange={handleManagerChange}
                  permissionsAllowed={['admin', 'editor']}
                />
              )}

              <InputContainer label="Template">
                <Select
                  value={templateId || ''}
                  placeholder="Select a template"
                  onChange={e => update({ template: { ...template, id: e.target.value } })}
                >
                  {templates.map(t => (
                    <option key={t.id} value={t.id}>
                      {t.title.en}
                    </option>
                  ))}
                </Select>
              </InputContainer>

              <SingleUseMediaInput
                type="image"
                defaultImage={defaultImage}
                filePath={getFacilityImagePath(id)}
                noFile={!hasImage}
                onClose={async action => {
                  if (action === 'save' && !hasImage) {
                    await updateDoc(user!.id, 'facilities', { id, hasImage: true })
                  } else if (action === 'delete' && hasImage) {
                    await updateDoc(user!.id, 'facilities', { id, hasImage: false })
                  }
                  refetchImage()
                }}
              />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </LightModeBox>
    </Drawer>
  )
}
