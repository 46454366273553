import { useToast } from '@chakra-ui/react'
import { Parser } from '@json2csv/plainjs'
import { RowUpdate, TableColumn } from 'components'
import { AuthContext } from 'components/providers'
import dayjs from 'dayjs'
import { orderBy, where } from 'firebase/firestore'
import { produce } from 'immer'
import { DataType, SortDirection } from 'ka-table'
import { startCase, uniq } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { IEvent, IUserSession, SeriesData, SessionLead, SessionMetric, TraitSelection } from 'types'
import {
  deleteDoc,
  getBlob,
  getDisplayUser,
  onDocs,
  refreshCachedUserSessions,
  setDoc,
} from 'utils'

/** ms time until the cache is considered stale to get updated userSessions */
const CACHE_STALE_TIME = 5 * 60 * 1000

export const defaultColumnSpecs: TableColumn[] = [
  { key: 'temp', width: 90 },
  { key: 'name', width: 175 },
  { key: 'created', width: 140, sortDirection: SortDirection.Descend, dataType: DataType.Date },
  { key: 'email', width: 210 },
  { key: 'company', width: 200 },
  { key: 'title', width: 170 },
  { key: 'notes', width: 90 },
  { key: 'optIn', width: 90, dataType: DataType.Boolean },
  { key: 'followUp', width: 105, dataType: DataType.Boolean },
]

export const mobileDefSessionFields = ['name', 'created']

export function useEventSessions(event: IEvent | null) {
  const toast = useToast()
  const { user } = useContext(AuthContext)
  const [sessions, setSessions] = useState<IUserSession[] | undefined>(undefined)
  const [status, setStatus] = useState<'loading' | 'error' | 'success'>('loading')

  useEffect(() => {
    if (!event) {
      setSessions(undefined)
      setStatus('loading')
      return
    }

    const { id, lastCachedSessionsTime = 0 } = event
    const now = new Date().getTime()
    const cacheTime = now - CACHE_STALE_TIME
    const refresh = lastCachedSessionsTime < cacheTime
    const getSessions = () =>
      refresh ? refreshCachedUserSessions({ eventId: id }) : getCachedSessions(event)

    getSessions().then(res => {
      setSessions(s => {
        setStatus('success')
        const data = res.data.reduce(
          (acc, cur) => {
            if (!acc.find(r => r.id === cur.id)) acc.push(cur)
            return acc
          },
          [...(s ?? [])]
        )
        return [...data].sort((a, b) => a.created - b.created)
      })
    })

    const unsubscribe = subscribeToSessions(id, cacheTime)
    return () => unsubscribe()
  }, [event?.id])

  async function getCachedSessions(event: IEvent) {
    const blob = await getBlob(`events/${event.id}/userSessions.json`, event.workspaceId)
    const data = JSON.parse(await blob.text()) as IUserSession[]
    return { data }
  }

  const updates = useRef<RowUpdate[]>([])
  const init = useRef(true)

  function subscribeToSessions(eventId: string, cacheTime: number) {
    const _event = where('eventId', '==', eventId)
    const _start = where('created', '>', cacheTime)
    const _sort = orderBy('created', 'desc')

    return onDocs('userSessions', [_event, _start, _sort], ({ changes }) => {
      if (!changes.length) return

      const reversed = changes.slice().reverse()
      setSessions(s => {
        let newUpdates: RowUpdate[] = []
        const data = produce(s, (draft = []) => {
          reversed.forEach(({ data, type }) => {
            const id = data.id
            const index = draft.findIndex(r => r.id === id)
            switch (type) {
              case 'added':
                if (index === -1) {
                  draft.unshift(data)
                  newUpdates.push({ id, type: 'added' })
                } else {
                  draft[index] = data
                }
                break
              case 'modified':
                if (index === -1) return
                draft[index] = data
                newUpdates.push({ id, type: 'modified' })
                break
              case 'removed':
                if (index !== -1) {
                  draft.splice(index, 1)
                  newUpdates.push({ id, type: 'removed' })
                }
                break
            }
          })
        })

        if (init.current) init.current = false
        else updates.current = newUpdates

        return data
      })
    })
  }

  async function updateLead(id: string, body: Partial<SessionLead>) {
    const currentSession = sessions?.find(session => session.id === id)
    if (!currentSession) return

    setSessions(s =>
      s?.map(session =>
        session.id === id ? { ...session, lead: { ...session.lead, ...body } } : session
      )
    )
    try {
      await setDoc(user!.id, 'userSessions', {
        ...currentSession,
        lead: { ...currentSession.lead, ...body },
      }) // Using set because sessions can have special characters in the keys which can cause updateDoc to fail
      toast({ status: 'success', description: 'Updated lead' })
    } catch (err) {
      console.error(err)
      toast({ status: 'error', description: 'Failed to update lead' })
      setSessions(s => s?.map(session => (session.id === id ? currentSession : session)))
    }
  }

  async function deleteSessions(ids: string[]) {
    await Promise.all(
      ids.map(async id => {
        await deleteDoc('userSessions', id).catch(() =>
          toast({ status: 'error', description: 'Failed to delete lead' })
        )
      })
    )

    setSessions(s => s?.filter(session => !ids.includes(session.id)))
  }

  return { sessions, status, updates: updates.current, updateLead, deleteSessions }
}

export interface LeadWithProps
  extends Omit<FormattedSession, 'traitFieldMap' | 'lead'>,
    Omit<SessionLead, '_custom' | 'followUpOwner'> {
  [key: string]: any
}

export function createLeadWithProps(session: IUserSession): LeadWithProps {
  const { lead, traitFieldMap, followUpOwner, ...rest } = formatSession(session)
  const { _custom, ...leadRest } = lead || {}
  return { ...rest, ...leadRest, ..._custom, ...traitFieldMap, followUpOwner }
}

export interface FormattedSession
  extends Pick<
    IUserSession,
    'app' | 'created' | 'createdBy' | 'deviceName' | 'endMethod' | 'id' | 'lead'
  > {
  duration?: number
  lead?: SessionLead
  name: string
  followUpOwner?: string
  traitFieldMap?: Record<string, string>
}

export function formatSession(session: IUserSession): FormattedSession {
  const { created, createdBy, endMethod, deviceName, steps, id, lead, app } = session
  const { firstName, lastName, email, followUpOwner } = lead || {}

  const name =
    `${firstName || ''} ${lastName || ''}`.trim() ||
    `${firstName || ''}`.trim() ||
    `${lastName || ''}`.trim() ||
    `${email || ''}`.trim() ||
    'Anonymous'
  const duration = steps?.reduce((acc, cur) => acc + cur.duration, 0)
  const formatted = {
    app,
    created,
    createdBy,
    deviceName,
    duration,
    endMethod,
    id,
    lead,
    name,
    followUpOwner: followUpOwner?.label,
    traitFieldMap: {},
  }

  switch (session.app) {
    case 'explorer': {
      formatted.traitFieldMap = createTraitFieldMap(session.traitSelections)
      break
    }
  }

  return formatted
}

export function createTraitFieldMap(traitSelections?: TraitSelection[]) {
  let fieldMap: { [group: string]: string } = {}
  if (!traitSelections) return fieldMap

  const groupTitles = uniq(traitSelections.map(t => t.groupTitle || '')).filter(Boolean)

  groupTitles.forEach(groupTitle => {
    if (typeof groupTitle === 'string') {
      const titlesArray: string[] = traitSelections
        .filter((trait: TraitSelection) => trait.groupTitle === groupTitle)
        .map((trait: TraitSelection) => trait.title)

      fieldMap[groupTitle] = titlesArray.join(', ')
    }
  })

  return fieldMap
}

export function getTargetedSessionMetrics(metrics: SessionMetric[], targetMetric: string) {
  const metric = metrics?.find(m => m.title === targetMetric)
  return metric?.items
}

export async function exportSessionFile(sessions: FormattedSession[], eventTitle: string) {
  try {
    const cleaned = cleanSessionsForExport(sessions)
    const parser = new Parser()
    const csv = encodeURIComponent(parser.parse(cleaned))
    downloadCSV(csv, eventTitle)
  } catch (err) {
    console.error(err)
  }
}

export function hideColumns(
  leadWithProps: Partial<LeadWithProps>,
  display: 'table' | 'panel' | 'export'
): Record<string, any> {
  const {
    name,
    temperature,
    firstName,
    lastName,
    badgeNumber,
    createdBy,
    updated,
    updatedBy,
    event,
    id,
    metrics,
    workspaceId,
    traitSelections,

    ...rest
  } = leadWithProps

  switch (display) {
    case 'table':
      return { ...rest, name }
    case 'panel':
    case 'export':
      return { ...rest, firstName, lastName }
  }
}

function cleanSessionsForExport(sessions: FormattedSession[]) {
  return sessions.map(session => {
    let cleaned = hideColumns(session, 'export')
    //@ts-ignore
    cleaned.created = dayjs(cleaned.created).format('YYYY-MM-DD HH:mm:ss')
    return Object.entries(cleaned).reduce((acc, [key, value]) => {
      acc[startCase(key)] = value
      return acc
    }, {} as any)
  })
}

function downloadCSV(fileContents: string, name: string) {
  var dataStr = 'data:text/csv;charset=utf-8,' + fileContents
  var downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  const time = dayjs().format('YYYY.MM.DD_hh.mma')
  const title = `${name}_${time}.csv`
  downloadAnchorNode.setAttribute('download', title)
  document.body.appendChild(downloadAnchorNode)
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

interface AggregateSessionsProps {
  sessions: IUserSession[]
  field: string
  nestedField?: string
  sumField?: string
  aggregationTypes?: AggregationTypes[]
  sortBy?: AggregationTypes
  blankFieldLabel?: string
}

export function aggregateSessionsForField({
  sessions,
  field,
  nestedField,
  sumField,
  aggregationTypes = ['count'],
  sortBy,
  blankFieldLabel,
}: AggregateSessionsProps) {
  const fieldAggregations: Map<any, { sum: number; count: number }> = new Map()

  for (const session of sessions) {
    if (nestedField) {
      if (!(field in session)) continue

      if (session.app === 'explorer') {
        if (field === 'traitSelections' && Array.isArray(session.traitSelections)) {
          for (const trait of session.traitSelections) {
            if (trait.groupTitle === nestedField) {
              const title = trait.title
              if (title) {
                const prev = fieldAggregations.get(title) || { sum: 0, count: 0 }
                fieldAggregations.set(title, { sum: prev.sum + 1, count: prev.count + 1 })
              }
            }
          }
          continue
        }

        if (field === 'metrics' && Array.isArray(session.metrics)) {
          for (const metric of session.metrics) {
            if (metric.title === nestedField) {
              for (const item of metric.items) {
                const title = item.title
                let increment =
                  item.duration && typeof item.duration === 'number' ? item.duration : 1
                if (title !== undefined) {
                  const prev = fieldAggregations.get(title) || { sum: 0, count: 0 }
                  fieldAggregations.set(title, { sum: prev.sum + increment, count: prev.count + 1 })
                }
              }
            }
          }
          continue
        }
      }
      const nestedValue = (session as any)[field]?.[nestedField]
      if (nestedValue !== undefined) {
        const prev = fieldAggregations.get(nestedValue) || { sum: 0, count: 0 }
        fieldAggregations.set(nestedValue, { sum: prev.sum + 1, count: prev.count + 1 })
      }
    } else {
      const value = (session as any)[field]
      if (value !== undefined && value !== '') {
        const prev = fieldAggregations.get(value) || { sum: 0, count: 0 }
        let increment =
          sumField && typeof (session as any)[sumField] === 'number'
            ? (session as any)[sumField]
            : 1
        fieldAggregations.set(value, { sum: prev.sum + increment, count: prev.count + 1 })
      }
    }
  }

  const result: SeriesData[] = []
  let totalAggregatedCount = 0
  fieldAggregations.forEach(({ sum, count }, value) => {
    if (value === '') return
    const series = []
    if (aggregationTypes.includes('count')) {
      series.push({ aggregationType: 'count', value: count })
    }
    if (aggregationTypes.includes('sum')) {
      series.push({ aggregationType: 'sum', value: sum })
    }
    if (aggregationTypes.includes('avg')) {
      series.push({ aggregationType: 'avg', value: count > 0 ? sum / count : 0 })
    }
    totalAggregatedCount += count
    result.push({
      label: startCase(String(value)),
      series,
    })
  })

  if (blankFieldLabel) {
    result.push({
      label: startCase(blankFieldLabel),
      series: [{ aggregationType: 'unset', value: sessions.length - totalAggregatedCount }],
    })
  }

  if (sortBy) {
    result.sort((a, b) => {
      const aValue = a.series.find(s => s.aggregationType === sortBy)?.value ?? 0
      const bValue = b.series.find(s => s.aggregationType === sortBy)?.value ?? 0
      return bValue - aValue
    })
  } else {
    result.sort((a, b) => a.label.localeCompare(b.label))
  }
  return result
}

export async function averageSessionsOverDayAndDevice(sessions: IUserSession[]) {
  const fieldCounts: Map<string, Map<string, number>> = new Map()
  const deviceMap = new Set<string>()
  let creatorNameById: Record<string, string> = {}

  for (const session of sessions) {
    const { created, createdBy, deviceName } = session

    if (created) {
      const createdDate = new Date(created).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
      if (!fieldCounts.has(createdDate)) {
        fieldCounts.set(createdDate, new Map())
      }
      const deviceCounts = fieldCounts.get(createdDate)!

      let device = deviceName
      if (!device) {
        if (createdBy) {
          if (!creatorNameById[createdBy])
            creatorNameById[createdBy] = (await getDisplayUser(createdBy)).displayName
          device = creatorNameById[createdBy] || 'Unknown'
        } else {
          device = 'Unknown'
        }
      }

      deviceCounts.set(device, (deviceCounts.get(device) || 0) + 1)
      deviceMap.add(device)
    }
  }

  const avgSessionsOverDays: SeriesData[] = []
  fieldCounts.forEach((deviceCounts, createdDate) => {
    const series = Array.from(deviceMap).map(creator => ({
      value: deviceCounts.get(creator) || 0,
    }))

    avgSessionsOverDays.push({ label: createdDate, series })
  })

  return { avgSessionsOverDays, devices: Array.from(deviceMap) }
}

export function getAverageStepDurations(sessions: IUserSession[]): {
  stepDurations: SeriesData[]
  totalDuration: number
} {
  let sumByStep: Record<string, number> = {}

  if (!sessions) return { stepDurations: [], totalDuration: 0 }

  sessions.forEach(session => {
    session.steps?.forEach(({ title, duration }) => {
      sumByStep[title] = (sumByStep[title] || 0) + duration
    })
  })
  const stepDurations = Object.entries(sumByStep).map(([title, duration]) => ({
    label: title,
    series: [
      {
        value: duration / sessions.length,
      },
    ],
  }))

  const totalDuration = stepDurations.reduce((sum, item) => sum + item.series[0].value, 0)

  return { stepDurations, totalDuration }
}

export function aggreagteSeriesDataAsOther(data: SeriesData[], label: string, limCount: number) {
  if (data.length <= limCount) return data
  const sortedData = data.sort((a, b) => b.series[0].value - a.series[0].value)
  const topResults = sortedData.slice(0, limCount)
  const otherValue = sortedData.slice(limCount).reduce((sum, item) => sum + item.series[0].value, 0)
  if (otherValue > 0) {
    topResults.push({ label, series: [{ value: otherValue }] })
  }
  return topResults
}

type AggregationTypes = 'sum' | 'count' | 'avg'
