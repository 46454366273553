import { Menu, MenuButton, MenuItem, MenuList, Spacer } from '@chakra-ui/react'
import { Icon } from 'components'
import { CollectionName } from 'types'
import { isDevelopment, useWorkspace } from 'utils'

const cloudBaseUrl = 'https://console.cloud.google.com'
const dbUrl = `${cloudBaseUrl}/firestore/databases/-default-/data/panel`
const storageFileUrl = `${cloudBaseUrl}/storage/browser/_details/entevate.appspot.com`
const storageFolderUrl = `${cloudBaseUrl}/storage/browser/entevate.appspot.com`

export interface Props {
  items: DevItem[]
}

type DevItem = DBDevItem | StorageDevItem

interface DBDevItem {
  collection: CollectionName
  id?: string
  label: string
  type: 'db'
}

interface StorageDevItem {
  label: string
  type: 'storage'
  isFolder?: boolean
  url?: string
}

export default function DevDetailsButton({ items }: Props) {
  const { workspace } = useWorkspace()

  if (!isDevelopment || !workspace?.id) return null

  return (
    <Menu>
      <MenuButton>
        <Icon>bug_report</Icon>
      </MenuButton>
      <MenuList>
        {items.map((item, index) => {
          let url = ''
          if (item.type === 'db') {
            if (!item.id) return null
            url = `${dbUrl}/${item.collection}/${item.id}`
          } else if (item.type === 'storage') {
            if (!item.url) return null
            const baseUrl = item.isFolder ? storageFolderUrl : storageFileUrl
            url = `${baseUrl}/Workspaces/${workspace.id}/${item.url}`
          }

          return (
            <MenuItem key={index} onClick={() => window.open(url, '_blank')?.focus()} gap={2}>
              <Icon>{item.type === 'db' ? 'database' : 'folder'}</Icon>
              {item.label}
              {item.type === 'db' && (
                <>
                  <Spacer />
                  <Icon
                    onClick={e => {
                      e.stopPropagation()
                      navigator.clipboard.writeText(item.id ?? '')
                    }}
                  >
                    content_copy
                  </Icon>
                </>
              )}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}
