import { Icon } from '@chakra-ui/react'
import { forwardRef } from 'react'
import HeroIcon, { HeroIconProps } from '../HeroIcon'

export default forwardRef<SVGSVGElement, HeroIconProps>((props, ref) => {
  const { type = 'solid', ...rest } = props
  return <HeroIcon ref={ref} Component={Component} {...rest} />
})

const Component = forwardRef<SVGSVGElement, any>((props, ref) => {
  return (
    <Icon ref={ref} width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8.99756 5L8.99756 5.01M8.99756 12L8.99756 12.01M8.99756 19L8.99756 19.01M8.99756 6C8.44527 6 7.99756 5.55228 7.99756 5C7.99756 4.44772 8.44527 4 8.99756 4C9.54984 4 9.99756 4.44772 9.99756 5C9.99756 5.55228 9.54984 6 8.99756 6ZM8.99756 13C8.44527 13 7.99756 12.5523 7.99756 12C7.99756 11.4477 8.44527 11 8.99756 11C9.54984 11 9.99756 11.4477 9.99756 12C9.99756 12.5523 9.54984 13 8.99756 13ZM8.99756 20C8.44527 20 7.99756 19.5523 7.99756 19C7.99756 18.4477 8.44527 18 8.99756 18C9.54984 18 9.99756 18.4477 9.99756 19C9.99756 19.5523 9.54984 20 8.99756 20Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9976 5L14.9976 5.01M14.9976 12L14.9976 12.01M14.9976 19L14.9976 19.01M14.9976 6C14.4453 6 13.9976 5.55228 13.9976 5C13.9976 4.44772 14.4453 4 14.9976 4C15.5498 4 15.9976 4.44772 15.9976 5C15.9976 5.55228 15.5498 6 14.9976 6ZM14.9976 13C14.4453 13 13.9976 12.5523 13.9976 12C13.9976 11.4477 14.4453 11 14.9976 11C15.5498 11 15.9976 11.4477 15.9976 12C15.9976 12.5523 15.5498 13 14.9976 13ZM14.9976 20C14.4453 20 13.9976 19.5523 13.9976 19C13.9976 18.4477 14.4453 18 14.9976 18C15.5498 18 15.9976 18.4477 15.9976 19C15.9976 19.5523 15.5498 20 14.9976 20Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
})
