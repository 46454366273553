import { Flex, FlexProps, useColorMode } from '@chakra-ui/react'
import { borderRadiusMap } from 'utils'

export interface Props<T extends string> extends Omit<FlexProps, 'onChange'> {
  borderRadius?: keyof typeof borderRadiusMap
  buttonProps?: Omit<FlexProps, 'children' | 'onClick'>
  value: string
  options: Option<T>[]
  onChange: (value: T) => void
}

interface Option<T extends string> {
  value: T
  label: string
}

export default function ToggleButton<T extends string>({
  borderRadius = 'full',
  buttonProps,
  value,
  options,
  onChange,
  ...rest
}: Props<T>) {
  const { colorMode } = useColorMode()
  const palette = 'primary' 

  const radiusPx = Number(borderRadiusMap[borderRadius].split('px')[0])

  return (
    <Flex borderRadius={borderRadius} {...rest}>
      {options.map(o => (
        <Flex
          key={o.value}
          borderRadius={`${radiusPx - 2}px`}
          py={2}
          px={4}
          mx={0.5}
          bg={o.value === value ? `${palette}.500` : 'transparent'}
          color={o.value === value ? 'white' : 'inherit'}
          cursor="pointer"
          textStyle="bodySmall"
          _hover={{
            bg: o.value === value ? '' : `${palette}.${colorMode === 'light' ? '2' : '7'}00`,
          }}
          onClick={e => {
            e.stopPropagation()
            onChange(o.value)
          }}
          {...buttonProps}
        >
          {o.label}
        </Flex>
      ))}
    </Flex>
  )
}
