import { Box, HTMLChakraProps, Text } from '@chakra-ui/react'
import { isTouchDevice } from 'utils'
import { HeroIconProps, Popover, QuestionMarkCircleIcon, Tooltip } from '.'

export interface Props extends Omit<HTMLChakraProps<'div'>, 'children'> {
  text: string
  iconProps?: HeroIconProps
}

export default function HelpHover({ iconProps, text, ...rest }: Props) {
  if (isTouchDevice()) {
    return (
      <Popover
        hideClose
        closeOnBlur
        autoFocus
        triggerNode={<QuestionMarkCircleIcon type="outline" {...iconProps} />}
        placement="bottom"
        {...rest}
      >
        <Box p={2} backgroundColor="#5B5B5B" maxWidth="250px">
          <Text fontSize="small" color="white">
            {text}
          </Text>
        </Box>
      </Popover>
    )
  }

  return (
    <Tooltip label={text} placement="bottom-start" width={'250px'} mt={2} {...(rest as any)}>
      <QuestionMarkCircleIcon type="outline" cursor="help" {...iconProps} />
    </Tooltip>
  )
}
