import { getFunctions, httpsCallable } from 'firebase/functions'
import {
  GetFacilityProps,
  GetMediaProps,
  GetTemplateProps,
  IUserSession,
  InviteDataProps,
  RefreshCachedUserSessionProps,
  SiteUrlProps,
  SlimFacility,
  SlimTemplate,
  UserWorkspaceStatus,
  UserWorkspaceStatusProps,
  XPressLeadsAuthCall,
  XPressLeadsGetInfoCall,
} from 'types'
import firebaseApp from './firebase'

const functions = getFunctions(firebaseApp)

export const acceptInvite = httpsCallable<InviteDataProps>(functions, 'acceptInvite')

export const getTemplates = httpsCallable<GetTemplateProps, SlimTemplate[]>(
  functions,
  'getTemplates'
)

export const getFacilities = httpsCallable<GetFacilityProps, SlimFacility[]>(
  functions,
  'getFacilities'
)

export const getImageFromSiteUrl = httpsCallable<SiteUrlProps, string>(
  functions,
  'getImageFromSiteUrl'
)

export const testQrConnection = httpsCallable<XPressLeadsAuthCall>(functions, 'testQrConnection')

export const getQrInfo = httpsCallable<XPressLeadsGetInfoCall, Record<string, string>>(
  functions,
  'getQrInfo'
)

export const getMediaIds = httpsCallable<GetMediaProps, string[]>(functions, 'getMediaIds')

export const refreshCachedUserSessions = httpsCallable<
  RefreshCachedUserSessionProps,
  IUserSession[]
>(functions, 'refreshCachedUserSessions')

export const sendInvite = httpsCallable<InviteDataProps>(functions, 'sendInvite')

export const userWorkspaceStatus = httpsCallable<UserWorkspaceStatusProps, UserWorkspaceStatus>(
  functions,
  'userWorkspaceStatus'
)
