import { useEffect, useState } from 'react'
import { SlimTemplate } from 'types'
import { getTemplates } from 'utils'

let templates: SlimTemplate[] = []

export function useTemplates(workspaceId?: string) {
  const [_, render] = useState(false)

  useEffect(() => {
    if (!workspaceId) {
      templates = []
      render(s => !s)
    } else {
      getTemplates({ workspaceId }).then(res => {
        templates = res.data.sort((a, b) => a.title.en.localeCompare(b.title.en))
        render(s => !s)
      })
    }
  }, [workspaceId])

  return templates
}
