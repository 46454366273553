import { forwardRef, ReactElement } from 'react'
import { Button, ButtonProps } from '.'

export interface Props extends Omit<ButtonProps, 'leftIcon' | 'rightIcon'> {
  'aria-label': string
  icon: ReactElement
}

export default forwardRef<HTMLButtonElement, Props>(({ icon, ...rest }, ref) => {
  return <Button ref={ref} variant="ghost" leftIcon={icon} iconSpacing={0} px={2} {...rest} />
})
