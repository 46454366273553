import { Flex, Image, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'

export default function AnimatedTiles() {
  return (
    <Flex
      direction="column"
      gap={0}
      display={{ base: 'none', xl: 'block' }}
      overflow="hidden"
      h="500px"
      w="700px"
      alignContent="end"
    >
      <Flex position="relative">
        <motion.div
          whileHover={{ scale: 1.1 }}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.3 }}
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '80px',
            width: '400px',
            height: '320px',
          }}
        >
          <Image src="/common/login/login-base.svg" draggable="false" />
        </motion.div>
        <motion.div
          animate={{
            bottom: ['280px', '285px', '285px', '280px'],
          }}
          style={{
            position: 'absolute',
            bottom: '280px',
            left: '40px',
            width: '250px',
            height: '133px',
          }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            times: [0, 0.4, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 0,
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.6 }}
          >
            <Image src="/common/login/login-1.svg" draggable="false" />
          </motion.div>
        </motion.div>
        <motion.div
          animate={{
            left: ['400px', '405px', '405px', '400px'],
          }}
          style={{
            position: 'absolute',
            bottom: '70px',
            left: '400px',
            width: '250px',
            height: '335px',
          }}
          transition={{
            duration: 1.5,
            ease: 'easeInOut',
            times: [0, 0.4, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 0.1,
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.7 }}
          >
            <Image src="/common/login/login-2.svg" draggable="false" />
          </motion.div>
        </motion.div>
      </Flex>
      <motion.div
        initial={{ opacity: 0, scale: 0.8, y: -40 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        transition={{ delay: 0.8 }}
      >
        <Text textStyle="h1" textAlign="center">
          Empower Every Moment.
        </Text>
      </motion.div>
    </Flex>
  )
}
