import { Image, useColorMode } from '@chakra-ui/react'
import { Backdrop } from 'components'

export default function LogoLoader() {
  const { colorMode } = useColorMode()

  return (
    <Backdrop justify="center" align="center" flex={1} gap={14}>
      <Image
        src={
          colorMode == 'light'
            ? '/common/momentify-icon-dark.svg'
            : '/common/momentify-icon-light.svg'
        }
        alt="momentify logo"
        height="200px"
      />
    </Backdrop>
  )
}
