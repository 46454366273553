import { extendTheme } from '@chakra-ui/react'

const colors = {
  black: '#171717',
  primary: {
    50: '#e8ecff',
    100: '#e8ecff',
    200: '#c0c5f6',
    300: '#969fea',
    400: '#6c79df',
    500: '#254FE5',
    600: '#2a39bb',
    700: '#202c93',
    800: '#151f6a',
    900: '#0a1342',
  },
  secondary: {
    50: '#adfff6',
    100: '#7dfff0',
    200: '#4dffea',
    300: '#26ffe4',
    400: '#15e6cb',
    500: '#00BBA5',
    600: '#008071',
    700: '#004e44',
    800: '#001c18',
    900: '#000c18',
  },
  danger: {
    50: '#FFEEF2',
    100: '#ffe4eb',
    200: '#fcb8c6',
    300: '#f48c9f',
    400: '#ed5e79',
    500: '#e73153',
    600: '#ce1839',
    700: '#a1112d',
    800: '#73091f',
    900: '#480312',
  },
}

export enum borderRadiusMap {
  none = '0',
  xs = '5px',
  sm = '10px',
  base = '12px',
  md = '14px',
  lg = '18px',
  xl = '22px',
  '2xl' = '28px',
  '3xl' = '36px',
  full = '9999px',
}

export enum fontSizeMap {
  xs = '12px',
  sm = '14px',
  md = '16px',
  lg = '18px',
  xl = '20px',
  '2xl' = '22px',
  '3xl' = '28px',
  '4xl' = '32px',
  '5xl' = '36px',
  '6xl' = '44px',
}

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
}

export const theme = extendTheme({
  radii: borderRadiusMap,
  fontSizes: fontSizeMap,
  textStyles: {
    h1: {
      fontSize: fontSizeMap['6xl'],
      fontWeight: 'semibold',
      lineHeight: 'normal',
      letterSpacing: '-1.2px',
    },
    h2: {
      fontSize: fontSizeMap['5xl'],
      fontWeight: 'semibold',
      lineHeight: 'normal',
    },
    h3: {
      fontSize: fontSizeMap['4xl'],
      fontWeight: 'semibold',
      lineHeight: 'normal',
      letterSpacing: '-1.6px',
    },
    h4: {
      fontSize: fontSizeMap['3xl'],
      fontWeight: 'semibold',
      lineHeight: 'normal',
      letterSpacing: '-1.4px',
    },
    h5: {
      fontSize: fontSizeMap['2xl'],
      fontWeight: 'semibold',
      lineHeight: 'normal',
      letterSpacing: '-1.1px',
    },
    h6: {
      fontSize: fontSizeMap['xl'],
      fontWeight: 'semibold',
      lineHeight: 'normal',
      letterSpacing: '-1px',
    },
    bodyLarge: {
      fontSize: fontSizeMap.lg,
      fontWeight: '600',
      lineHeight: 'normal',
      letterSpacing: '-0.32px',
    },
    bodyMedium: {
      fontSize: fontSizeMap.md,
      fontWeight: 'semibold',
      lineHeight: 'normal',
      letterSpacing: '-0.32px',
    },
    bodySmall: {
      fontSize: fontSizeMap.sm,
      fontWeight: 'semibold',
      lineHeight: 'normal',
      letterSpacing: '-0.28px',
    },
    bodyXSmall: {
      fontSize: fontSizeMap.xs,
      fontWeight: '500',
      lineHeight: 'normal',
      letterSpacing: '-0.12px',
    },
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
      },
    },
  },
  colors,
})
