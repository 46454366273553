import { Component, Suspense, lazy, memo } from 'react'
import { KitIconType } from 'types'
import { toPascalCase } from 'utils'
import Icon from '../Icon'
import { SvgProps } from './MakeSvg'
import AgricultureIcon from './icons/AgricultureIcon'

export interface Props extends SvgProps {
  type: KitIconType
}

const KitIcon = memo<Props>(props => {
  return (
    <ErrorBoundary>
      <Render {...props} />
    </ErrorBoundary>
  )
})

export default KitIcon

function Render({ type, ...rest }: Props) {
  const PascalType = toPascalCase(type)
  const Icon = lazy(() => import(`./icons/${PascalType}Icon.tsx`))

  return (
    <Suspense fallback={<AgricultureIcon {...rest} color="transparent" />}>
      <Icon {...rest} />
    </Suspense>
  )
}

class ErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    console.error('Error loading KitIcon component')
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <Icon>priority_high</Icon>
    }

    return this.props.children
  }
}
