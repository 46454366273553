import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react'

export interface Props extends Omit<TooltipProps, 'title'> {}

export default function Tooltip(props: Props) {
  return (
    <ChakraTooltip
      bg="white"
      border="1px solid rgba(0, 0, 0, 0.1)"
      borderRadius="md"
      boxShadow="0px 4px 10px 0px rgba(0, 0, 0, 0.10)"
      color="black"
      hasArrow
      placement="top"
      {...props}
    />
  )
}
