import { Flex, Image, Text, useColorMode, useToast } from '@chakra-ui/react'
import {
  Backdrop,
  Button,
  CheckCircleIcon,
  HomeIcon,
  InputContainer,
  LightModeBox,
  TextField,
  XCircleIcon,
} from 'components'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { performPasswordReset, signOut, verifyResetCode } from 'utils'

export default function NewPassword() {
  const { colorMode } = useColorMode()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [pass, setPass] = useState('')
  const [confirm, setConfirm] = useState('')
  const [passValid, setPassValid] = useState(false)
  const [confirmValid, setConfirmValid] = useState(false)
  const [resetValid, setResetValid] = useState(false)
  const [error, setError] = useState('')
  const toast = useToast()

  const resetCode = searchParams.get('oobCode') || ''

  async function performReset() {
    try {
      if (await verifyResetCode(resetCode)) {
        setResetValid(true)
        await performPasswordReset(resetCode, pass)
        navigate('/')
        signOut()
      } else {
        toast({
          title: 'Invalid reset code.',
          status: 'error',
          isClosable: true,
        })
      }
    } catch (error) {
      toast({
        title: 'Error during password reset.',
        status: 'error',
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if (pass.length >= 8) setPassValid(true)
    else setPassValid(false)
    if (confirm.length >= 8) {
      if (pass === confirm) {
        setConfirmValid(true)
        setError('')
      }
      setError('Passwords entered do not match')
    } else {
      setConfirmValid(false)
      setError('Password must be at least 8 characters long.')
    }
  }, [pass, confirm])

  return (
    <Backdrop flex={'auto'}>
      <Flex direction={'column'} width={'100%'} p={10} align={'center'} gap={6}>
        <Image
          pr={10}
          mb={5}
          src={
            colorMode === 'light'
              ? '/common/momentify-logo-dark.svg'
              : '/common/momentify-logo-light.svg'
          }
          alt="momentify logo"
        />
        <LightModeBox
          display="flex"
          flexDir="column"
          alignItems="center"
          gap={5}
          p={'50px'}
          w={'45%'}
          bg="rgba(255, 255, 255, 0.54)"
          borderRadius={'40px'}
          boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.06)'}
        >
          {!resetValid && (
            <>
              <Text textStyle={{ base: 'h3', sm: 'h2' }}>Enter New Password</Text>
              <InputContainer label="Password" width={'100%'}>
                <TextField
                  onChange={pass => setPass(pass.target.value)}
                  placeholder="Enter password..."
                  bg={'#fff'}
                  backdropFilter={'blur(27px)'}
                  type="password"
                  borderRadius={'10px'}
                  border={pass && !passValid ? '1px solid red' : '1px solid rgba(23, 23, 23, 0.20)'}
                  rightElement={{
                    children: passValid ? <CheckCircleIcon color="green.500" type="outline" /> : '',
                  }}
                />
                {pass && !passValid && (
                  <Flex color="red.500" gap={2} align={'center'} width={'100%'}>
                    <XCircleIcon type="outline" />
                    <Text textStyle={'bodySmall'}>
                      Password must be at least 8 characters long.
                    </Text>
                  </Flex>
                )}
              </InputContainer>
              <InputContainer label="Confirm Password" width={'100%'}>
                <TextField
                  onChange={confirm => setConfirm(confirm.target.value)}
                  placeholder="Confirm password..."
                  bg={'#fff'}
                  backdropFilter={'blur(27px)'}
                  type="password"
                  borderRadius={'10px'}
                  border={
                    confirm && !confirmValid ? '1px solid red' : '1px solid rgba(23, 23, 23, 0.20)'
                  }
                  rightElement={{
                    children: confirmValid ? (
                      <CheckCircleIcon color="green.500" type="outline" />
                    ) : (
                      ''
                    ),
                  }}
                />
                {confirm && !confirmValid && (
                  <Flex color="red.500" gap={2} align={'center'} width={'100%'}>
                    <XCircleIcon type="outline" />
                    <Text textStyle={'bodySmall'}>{error}</Text>
                  </Flex>
                )}
              </InputContainer>

              <Button palette="primary" onClick={performReset} isDisabled={!confirmValid}>
                <Text textStyle={'bodySmall'}>Reset Password</Text>
              </Button>
            </>
          )}
          {resetValid && (
            <Flex direction={'column'} gap={2} w={'100%'} align={'center'}>
              <CheckCircleIcon fontSize={'48px'} type="outline" />
              <Text textStyle={{ base: 'h3', sm: 'h2' }}>Password Reset</Text>

              <Button palette="primary" onClick={() => signOut()}>
                <Text textStyle={'bodySmall'}>Login Now</Text>
              </Button>
            </Flex>
          )}
        </LightModeBox>
        <Flex
          onClick={() => {
            navigate('/')
          }}
          gap={2}
          align={'flex-start'}
        >
          <HomeIcon fontSize={'md'} />
          <Text textStyle={'bodySmall'}>Back to Home</Text>
        </Flex>
      </Flex>
    </Backdrop>
  )
}
