import { Avatar, Divider, Flex, Text, useToast } from '@chakra-ui/react'
import {
  ArrowPathRoundedSquareIcon,
  Button,
  CalendarDaysIcon,
  DebouncedTextField,
  DebouncedTextFieldProps,
  EnvelopeIcon,
  FileDrop,
  IconButton,
  LightModeBox,
  PencilSquareIcon,
  TrashIcon,
} from 'components'
import { AuthContext } from 'components/providers'
import { getDownloadURL } from 'firebase/storage'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { acceptedImages, uploadFile } from 'utils'

export default function ProfilePage() {
  const { user, updateUser } = useContext(AuthContext)
  const toast = useToast()
  const navigate = useNavigate()

  function removeProfilePicture() {
    updateUser({ photoUrl: '' }, user!.id)
    toast({ title: 'Profile picture removed', status: 'success', isClosable: true })
  }

  async function handleImageFile(files: File[]) {
    try {
      if (!files?.length) throw 'File type not accepted'

      const imageFile = await files[0]

      const ref = await uploadFile(`users/profile-pictures/${user!.id}`, imageFile)
      const downloadURL = await getDownloadURL(ref)
      updateUser({ photoUrl: downloadURL }, user!.id)
      toast({ title: 'Profile picture updated', status: 'success', isClosable: true })
    } catch (error: any) {
      console.error(error)
      toast({ title: error.message, status: 'error', isClosable: true })
    }
  }

  if (!user?.created) return null

  const createdText = user.created
    ? new Date(user.created).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    : ''

  return (
    <Flex width={'100%'} height={'100%'} direction={'column'} gap={5} px={10} overflowY={'auto'}>
      <Text textStyle="h1" pt={7} pb={3}>
        My Account
      </Text>
      <LightModeBox
        display="flex"
        flexDir={{ base: 'column', lg: 'row' }}
        gap={8}
        p={{ base: '25px', sm: '50px' }}
        w={'100%'}
        bg="rgba(255, 255, 255, 0.54)"
        borderRadius={'40px'}
        boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.06)'}
      >
        <Flex alignItems="flex-start">
          <FileDrop
            overlayProps={{ borderRadius: 'md' }}
            onDrop={handleImageFile}
            options={{ accept: acceptedImages, maxSize: 2 * 1024 * 1024 }}
          >
            {({ open }) => (
              <Flex flexDirection="row" gap={5}>
                <Avatar
                  key={user.photoUrl!}
                  name={user.name!}
                  src={user.photoUrl!}
                  size={{ base: 'xl', sm: '2xl' }}
                />
                <Flex direction={'column'} gap={3}>
                  <IconButton
                    aria-label="edit profile picture"
                    variant="solid"
                    icon={<PencilSquareIcon fontSize={'xl'} />}
                    p={'14px 18px'}
                    onClick={open}
                  />
                  {user.photoUrl && (
                    <IconButton
                      aria-label="remove profile picture"
                      variant="solid"
                      icon={<TrashIcon fontSize={'xl'} />}
                      p={'14px 18px'}
                      onClick={removeProfilePicture}
                    />
                  )}
                </Flex>
              </Flex>
            )}
          </FileDrop>
        </Flex>
        <Flex direction="column" flex={1} gap={3}>
          <Input
            maxW="500px"
            label="Full Name"
            onChange={name => updateUser({ name: name }, user!.id)}
            value={user.name || ''}
            placeholder="Enter full name..."
          />
          <Divider borderColor={'rgba(23, 23, 23, 0.10)'} mt={2} />
          <Flex gap={4} maxW="500px" direction={{ base: 'column', lg: 'row' }}>
            <Input
              label="Company (Optional)"
              onChange={company => updateUser({ company: company }, user!.id)}
              value={user.company || ''}
              placeholder="Enter company..."
            />
            <Input
              label="Title (Optional)"
              onChange={title => updateUser({ title: title }, user!.id)}
              value={user.title || ''}
              placeholder="Enter title..."
            />
          </Flex>
          <Divider borderColor={'rgba(23, 23, 23, 0.10)'} mt={2} />
          <Flex gap={2}>
            <EnvelopeIcon fontSize={'lg'} />
            <Text textStyle={'bodyMedium'} opacity={0.7}>
              Email:
            </Text>
            <Text textStyle={'bodyMedium'}>{user.email}</Text>
          </Flex>
          <Flex gap={2}>
            <CalendarDaysIcon fontSize={'lg'} />
            <Text textStyle={'bodyMedium'} opacity={0.7}>
              Date joined:
            </Text>
            <Text textStyle={'bodyMedium'}>{createdText}</Text>
          </Flex>
          <Button
            alignSelf="flex-start"
            mt={3}
            leftIcon={<ArrowPathRoundedSquareIcon fontSize={'lg'} />}
            variant="solid"
            onClick={() => navigate('/reset-password')}
          >
            <Text textStyle={'bodySmall'}>Reset Password</Text>
          </Button>
        </Flex>
      </LightModeBox>
    </Flex>
  )
}

function Input(props: DebouncedTextFieldProps) {
  return (
    <DebouncedTextField
      bg={'#fff'}
      backdropFilter={'blur(27px)'}
      borderRadius={'10px'}
      border={'1px solid rgba(23, 23, 23, 0.20)'}
      showStatus
      {...props}
    />
  )
}
