import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from 'firebase/auth'
import { AuthUser } from 'types'
import firebaseApp from './firebase'

let auth = getAuth(firebaseApp)

export function signIn(email: string, password: string) {
  return signInWithEmailAndPassword(auth, email, password)
}

export function signUp(email: string, password: string) {
  return createUserWithEmailAndPassword(auth, email, password)
}

export function resetPassword(email: string) {
  return sendPasswordResetEmail(auth, email)
}

export async function verifyResetCode(code: string) {
  try {
    return await verifyPasswordResetCode(auth, code)
  } catch (error) {
    return false
  }
}

export async function performPasswordReset(code: string, password: string) {
  try {
    return await confirmPasswordReset(auth, code, password)
  } catch (error) {
    return false
  }
}

export async function signOut(signOutOnly?: boolean) {
  await auth.signOut()
  if (signOutOnly) return

  localStorage.removeItem('current-workspace')
  window.location.href = '/'
}

export async function existingUser(email: string) {
  const signInMethods = await (await fetchSignInMethodsForEmail(auth, email)).length
  return signInMethods > 0 ? true : false
}

export function onAuthChanged(callback: (user: AuthUser | null) => void) {
  return onAuthStateChanged(auth, async authUser => {
    if (!authUser) return callback(null)

    const token = await authUser.getIdTokenResult()
    callback({ id: authUser.uid, email: authUser.email!, isAdmin: token.claims.admin === true })
  })
}
