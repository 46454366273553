import { ChakraProvider } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { isDevelopment, theme } from 'utils'
import App from './App.tsx'
import './index.css'

if (!isDevelopment) {
  Sentry.init({
    dsn: 'https://4bf1f8270d7f6261965865dc53ce7e97@o4508932318822400.ingest.us.sentry.io/4508933006295040',
    integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', 'https://web.momentifyapp.com'],
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>
)
