import { Flex, Image, Text } from '@chakra-ui/react'
import {
  Backdrop,
  Button,
  ButtonProps,
  DarkModeBox,
  Dialog,
  EyeIcon,
  IconButton,
  LightModeBox,
  Tooltip,
  XMarkIcon,
} from 'components'
import { lazy, Suspense, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { closeFullscreen, openFullscreen } from 'utils'
import ipadOutline from '../assets/device-outline-ipad.png'
import tvOutline from '../assets/device-outline-tv.png'

const EmbeddedExplorer = lazy(() => import('desktop-app'))

interface Props extends Omit<ButtonProps, 'onClick'> {
  colorMode?: 'light' | 'dark'
  /** default desktop */
  device?: Device
  eventId?: string
  facilityId?: string
  label?: string
  previewTitle: string
}

type Device = 'desktop' | 'ipad'

export default function TemplatePreviewButton({
  colorMode,
  device = 'desktop',
  eventId,
  facilityId,
  label,
  previewTitle,
  ...rest
}: Props) {
  const { width = 0, height = 1 } = useWindowSize()
  const [showPreview, setShowPreview] = useState(false)

  if (!eventId && !facilityId) return null

  function toggleFullscreen() {
    if (showPreview) {
      setShowPreview(false)
      closeFullscreen()
    } else {
      setShowPreview(true)
      openFullscreen()
    }
  }

  const dimensions = getDimensions(device, width, height)
  const { border, deviceHeight, deviceWidth, headerHeight, xOffset, yOffset } = dimensions
  const ColorBox = colorMode === 'dark' ? DarkModeBox : LightModeBox

  return (
    <>
      {label ? (
        <Button
          palette="primary"
          leftIcon={<EyeIcon type="solid" />}
          onClick={toggleFullscreen}
          {...rest}
        >
          {label}
        </Button>
      ) : (
        <Tooltip label="Preview">
          <IconButton
            aria-label="preview-button"
            icon={<EyeIcon type="solid" />}
            onClick={toggleFullscreen}
            {...rest}
          />
        </Tooltip>
      )}

      <Dialog
        hideClose
        isOpen={showPreview}
        motionPreset="none"
        onClose={toggleFullscreen}
        size="full"
      >
        <ColorBox display="flex" flex={1}>
          <Backdrop position="relative" flex={1} align="center" justify="center">
            <Flex direction="column" height={deviceHeight + headerHeight * 2} width={deviceWidth}>
              <Flex height={headerHeight} justify="space-between">
                <Text textStyle="h3">{previewTitle}</Text>
                <IconButton
                  aria-label="close-preview-button"
                  icon={<XMarkIcon size="lg" />}
                  onClick={toggleFullscreen}
                />
              </Flex>
              <Image
                height="100%"
                width="100%"
                src={device === 'desktop' ? tvOutline : ipadOutline}
              />
              <Flex height={headerHeight} />
            </Flex>

            <Flex
              background="gray.800"
              boxShadow="dark-lg"
              height={deviceHeight - border * 2 + yOffset}
              left={(width - deviceWidth + xOffset) / 2 + border}
              overflow="hidden"
              position="absolute"
              top={(height - deviceHeight + yOffset) / 2 + border}
              width={deviceWidth - border * 2 + xOffset}
            >
              <Suspense>
                <EmbeddedExplorer preview eventId={eventId} facilityId={facilityId} />
              </Suspense>
            </Flex>
          </Backdrop>
        </ColorBox>
      </Dialog>
    </>
  )
}

function getDimensions(device: Device, width: number, height: number) {
  const windowRatio = width / height

  const deviceRatio = device === 'desktop' ? 1.7 : 1.3
  const deviceWidth = (windowRatio > deviceRatio ? height * deviceRatio : width) * 0.8
  const deviceHeight = deviceWidth / deviceRatio
  const headerHeight = 60
  const xOffset = 0 * deviceWidth
  const yOffset = (device === 'desktop' ? -0.026 : 0) * deviceHeight
  const border = (device === 'desktop' ? 0.014 : 0.05) * deviceHeight

  return { border, deviceHeight, deviceWidth, headerHeight, xOffset, yOffset }
}
