export type IconSize = 'sm' | 'md' | 'lg'

export function fromSize(size: IconSize) {
  switch (size) {
    case 'sm':
      return '16px'
    case 'md':
      return '24px'
    case 'lg':
      return '32px'
  }
}
