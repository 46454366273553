import { Flex, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { Button, CheckCircleIcon, FileDrop } from 'components'
import { useEffect, useState } from 'react'
import { acceptedSpreadsheets, getFileUrl, useWorkspace } from 'utils'
import { useEvent } from '../../../utils/eventUtils'
import DatabaseMappingDrawer from './DatabaseMappingDrawer'

export default function DatabaseLink() {
  const { workspace } = useWorkspace()
  const { event } = useEvent()
  const [hasDatabase, setHasDatabase] = useState(false)
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [buffer, setBuffer] = useState<ArrayBuffer>()

  const { registrationMode, id } = event || {}
  const dbFilePath = `/events/${id}/attendees.json`

  useEffect(() => {
    if (!workspace || registrationMode !== 'database') return

    getFileUrl(dbFilePath, workspace.id)
      .then(() => setHasDatabase(true))
      .catch(() => setHasDatabase(false))
  }, [workspace, registrationMode, isOpen])

  async function handleDatabaseSpreadsheet(files: File[]) {
    try {
      if (!files?.length) throw 'File type not accepted'
      setBuffer(await files[0].arrayBuffer())
      onOpen()
    } catch (error: any) {
      console.error(error)
      toast({ title: error.message, status: 'error', isClosable: true })
    }
  }

  return (
    <>
      <FileDrop onDrop={handleDatabaseSpreadsheet} options={{ accept: acceptedSpreadsheets }}>
        {({ open }) => (
          <Flex direction="column" gap={2}>
            <Flex
              mt={2}
              p={3}
              border="1px dashed gray"
              align="center"
              justifyContent="center"
              borderRadius="md"
              gap={2}
            >
              <Button onClick={open} borderRadius="sm" palette="primary" size="sm">
                {hasDatabase ? 'Replace' : 'Upload'}
              </Button>
              <Text textStyle="bodySmall" fontWeight="normal" color="gray.500">
                or drop a file here
              </Text>
            </Flex>
            {hasDatabase && (
              <Flex gap={1} opacity={0.6}>
                <CheckCircleIcon fontSize="sm" />
                <Text textStyle="bodyXSmall">Database Uploaded</Text>
              </Flex>
            )}
          </Flex>
        )}
      </FileDrop>
      <DatabaseMappingDrawer isOpen={isOpen} onClose={onClose} fileBuffer={buffer} />
    </>
  )
}
