import { Avatar, Flex, Image, SimpleGrid, Text, useColorMode } from '@chakra-ui/react'
import { LightModeBox, MapPinIcon } from 'components'
import { generatePath, useNavigate } from 'react-router-dom'
import { IFacility } from 'types'
import { useDisplayUser, useStorageUrl } from 'utils'
import defaultImage from '../assets/default_tile.png'
import noFacilityDark from '../assets/emptyStates/no_facilities_dark.svg'
import noFacilityLight from '../assets/emptyStates/no_facilities_light.svg'
import EmptyState from '../components/EmptyState'
import { getFacilityImagePath, useFacilities } from '../utils/facilityUtils'
import { RoutePath } from '../utils/routing'
import CreateNewButton from '../components/CreateNewButton'

export default function FacilitiesPage() {
  const { facilities } = useFacilities()
  const { colorMode } = useColorMode()

  return (
    <Flex
      direction="column"
      gap={4}
      flex={'auto'}
      mr={5}
      mb={2}
      overflowY="auto"
      overflowX={'hidden'}
    >
      <Text textStyle="h1" px={5} pt={2}>
        Facilities
      </Text>
      {facilities?.length == 0 && (
        <EmptyState
          title="No Facilities"
          description='No facilities created yet.'
          iconSVG={colorMode === 'light' ? noFacilityLight : noFacilityDark}
          button={<CreateNewButton variant={'button'} />}
        />
      )}

      <SimpleGrid spacing={5} columns={{ base: 0, lg: 2, xl: 3 }} px={5}>
        {facilities?.map(facility => (
          <FacilityCard key={facility.id} {...facility} />
        ))}
      </SimpleGrid>
    </Flex>
  )
}

function FacilityCard(props: IFacility) {
  const { location, id, title, workspaceId, hasImage, managerId } = props
  const { user: manager } = useDisplayUser(managerId)
  const navigate = useNavigate()
  const imageUrl = useStorageUrl(id && hasImage ? getFacilityImagePath(id) : undefined)?.url

  function goToFacility(id: string, workspaceId: string) {
    navigate(generatePath(RoutePath.FacilityInfo, { workspaceId, id }))
  }

  return (
    <LightModeBox
      backgroundColor="white"
      borderRadius="xl"
      boxShadow={'0px 4px 16px 0px rgba(0, 0, 0, 0.10)'}
      cursor="pointer"
      display="flex"
      flexDirection="column"
      onClick={() => goToFacility(id, workspaceId)}
      overflow="hidden"
    >
      <Image height="175px" objectFit="cover" src={hasImage ? imageUrl : defaultImage} />
      <Flex p={5} align="center" justify="space-between">
        <Flex direction="column" gap={1}>
          <Text
            textStyle="bodyLarge"
            color={title ? 'inherit' : 'gray'}
            isTruncated
            textOverflow="ellipsis"
          >
            {title || '--'}
          </Text>
          <Flex align="center" gap={1}>
            <MapPinIcon size="sm" />
            <Text textStyle="body2">
              {location ? `${location.city}, ${location.state || location.country}` : ''}&nbsp;
            </Text>
          </Flex>
        </Flex>

        <Avatar name={manager?.displayName} src={manager?.photoUrl} size="sm" />
      </Flex>
    </LightModeBox>
  )
}
