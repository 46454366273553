import { useUpdateEffect } from '@chakra-ui/react'
import { ChangeEvent, forwardRef, useRef, useState } from 'react'
import { Icon, TextField, TextFieldProps } from '.'

export interface Props extends Omit<TextFieldProps, 'onChange'> {
  /** by default it is not controlled to allow async values to come in. Setting this to true will force the value to stay in sync with the parent */
  controlled?: boolean
  /** (default 300) ms debounce */
  debounceTime?: number
  onChange: (val: string) => void
  showStatus?: boolean
}

type Status = 'idle' | 'pending' | 'synced' | 'error'

const DebouncedTextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { value, onChange, controlled, debounceTime = 300, showStatus, ...rest } = props
  const [draft, setDraft] = useState(value as string)
  const [status, setStatus] = useState<Status>('idle')

  const syncTimeout = useRef<Timer>()
  useUpdateEffect(() => {
    if (!showStatus || value !== draft) {
      if (controlled) setDraft(value as string)
      return
    }

    clearTimeout(syncTimeout.current)
    setStatus('synced')
    syncTimeout.current = setTimeout(() => setStatus('idle'), 2000)

    return () => clearTimeout(syncTimeout.current)
  }, [showStatus, value])

  const debounceTimeout = useRef<Timer>()
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const nextValue = e.target.value
    setDraft(nextValue)
    clearTimeout(debounceTimeout.current)
    debounceTimeout.current = setTimeout(() => onChange(nextValue), debounceTime)
    if (!showStatus || nextValue === value) return

    setStatus('pending')
    clearTimeout(syncTimeout.current)
    syncTimeout.current = setTimeout(() => setStatus('error'), 2000)
  }

  const showStatusIcon = showStatus && status !== 'idle'

  return (
    <TextField
      {...(showStatusIcon && {
        rightElement: { children: <Icon color={iconColor(status)}>cached</Icon> },
      })}
      ref={ref}
      value={props.value === undefined ? undefined : draft}
      onChange={handleChange}
      style={{ border: rest.isError ? '1px solid red' : undefined }}
      {...rest}
    />
  )
})

function iconColor(status: Status) {
  switch (status) {
    case 'pending':
      return 'grey.500'
    case 'synced':
      return 'green.500'
    case 'error':
      return 'red.500'
  }
}

export default DebouncedTextField
