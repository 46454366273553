import {
  Box,
  Circle,
  Flex,
  Hide,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
  useBreakpointValue,
  useColorMode,
} from '@chakra-ui/react'
import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  BellAlertIcon,
  BoltIcon,
  Button,
  DebouncedTextField,
  DeleteConfirmButton,
  FireIcon,
  IconButton,
  LightModeBox,
  MagnifyingGlassIcon,
  NewspaperIcon,
  Table,
  TableColumn,
  TableProps,
  Tooltip,
} from 'components'
import { DashedCircleIcon } from 'components/icons'
import { SortDirection } from 'ka-table'
import { startCase } from 'lodash'
import { useEffect, useState } from 'react'
import { IUserSession, LeadTemperature } from 'types'
import { useIntercomUtils, usePermission } from 'utils'
import { defaultProps, makeTypeByField } from 'utils/tableUtils'
import noLeads from '../../assets/emptyStates/no_leads.svg'
import { useEvent } from '../../utils/eventUtils'
import {
  createLeadWithProps,
  defaultColumnSpecs,
  exportSessionFile,
  hideColumns,
  mobileDefSessionFields,
} from '../../utils/userSessionUtils'
import EmptyState from '../EmptyState'
import LeadTableColumnSelector from './LeadTableColumnSelector'
import LeadDrawer from './SessionDrawer'

interface Props {
  expanded: boolean
  updateExpanded: (expanded: boolean) => void
}

export default function EventLeads({ expanded, updateExpanded }: Props) {
  const { event, sessions, status, updates, deleteSessions } = useEvent()
  const permission = usePermission()
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })
  const [tableProps, setTableProps] = useState(initialTableProps)
  const [search, setSearch] = useState('')
  const [showNewSessionButton, setShowNewSessionButton] = useState(false)
  const { colorMode } = useColorMode()
  const [selectedId, setSelectedId] = useState<string>('')
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const selectedSession = sessions?.find(r => r.id === selectedId)
  useIntercomUtils(Boolean(selectedId))
  const isAdmin = permission.event === 'admin'

  const ExpandIcon = expanded ? ArrowsPointingInIcon : ArrowsPointingOutIcon

  const updateSelectedRows = (newSelectedRows: string[]) => {
    setSelectedRows(newSelectedRows)
  }

  useEffect(() => {
    if (!sessions) return
    if (!sessions.length) return setTableProps(s => ({ ...s, data: [] }))

    const data = sessions.map(createLeadWithProps)
    const allColumns = makeColumns(data.map(l => hideColumns(l, 'table')))
    setTableProps(s => {
      const newColumns = allColumns.filter(c => s.columns.every(n => n.key !== c.key))
      let columns = [...s.columns, ...newColumns]
      if (isMobile)
        columns = columns.map(c => ({ ...c, visible: mobileDefSessionFields.includes(c.key) }))
      return { ...s, columns, ...(isMobile && { pinnedColumns: ['name'] }), data, updates }
    })

    const hasNew = updates.some(u => u.type === 'added')
    const sortColumn = tableProps.columns.find(c => c.sortDirection)
    const atLive =
      !search &&
      (!sortColumn || (sortColumn.key === 'created' && sortColumn.sortDirection === 'descend'))
    if (hasNew && !atLive) setShowNewSessionButton(true)
  }, [sessions, updates, isMobile])

  function handleExport() {
    exportSessionFile(tableProps.data!, event!.title)
  }

  function handleNewSessionButton() {
    setTableProps(s => {
      const columns = s.columns.map(c => {
        const sortDirection = c.key === 'created' ? SortDirection.Descend : undefined
        return { ...c, sortDirection }
      })
      return { ...s, columns }
    })
    setSearch('')
    setShowNewSessionButton(false)
  }

  async function handleDelete() {
    await deleteSessions(selectedRows)
    updateSelectedRows?.([])
  }

  return (
    <>
      <Flex
        direction="column"
        overflow="hidden"
        mx={{ base: 4, sm: 0 }}
        pt={5}
        gap={3}
        minH="300px"
      >
        <Flex gap={2}>
          <InputGroup width={{ base: '100%', sm: '250px' }}>
            <InputLeftElement pointerEvents="none">
              <MagnifyingGlassIcon
                color={colorMode === 'light' ? 'rgba(23, 23, 23, 0.6)' : 'white'}
              />
            </InputLeftElement>
            <DebouncedTextField
              isDisabled={status !== 'success' || !sessions?.length}
              onChange={setSearch}
              placeholder="Search..."
              value={search}
              controlled
              boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.06)'}
              backdropFilter={'blur(14px)'}
              bg={colorMode === 'light' ? 'rgba(255, 255, 255, 0.74)' : 'rgba(255, 255, 255, 0.14)'}
              borderRadius={'10px'}
              border={0}
              pl={10}
            />
          </InputGroup>
          <Flex flex={1} justifyContent="space-between" gap={2} align="center">
            <Box>
              {showNewSessionButton && (
                <Button
                  palette="primary"
                  leftIcon={<BellAlertIcon type="outline" />}
                  onClick={handleNewSessionButton}
                >
                  New session!
                </Button>
              )}
            </Box>
            <Hide below="sm" ssr={false}>
              <Flex gap={2}>
                <LeadTableColumnSelector
                  columns={tableProps.columns}
                  onUpdate={columns => setTableProps(s => ({ ...s, columns }))}
                />
                <Button
                  isDisabled={status !== 'success' || !sessions?.length}
                  leftIcon={<ArrowDownTrayIcon />}
                  onClick={handleExport}
                  borderRadius="sm"
                >
                  Export
                </Button>
              </Flex>
            </Hide>
          </Flex>
        </Flex>

        {event?.sessionCount! > 0 ? (
          <Box display="flex" flexDir="column" overflowY="auto" borderRadius="14px">
            <LightModeBox
              display="flex"
              alignItems="center"
              justifyContent={selectedRows.length > 0 ? 'flex-start' : 'center'}
              h="47px"
              pl={4}
              gap={2}
              borderTopRadius="14px"
              borderBottom="1px solid rgba(203, 203, 203, 0.4)"
              overflow="hidden"
              backgroundColor="white"
              flexShrink={0}
            >
              <BoltIcon fontSize="md" color="primary.500" />
              {selectedRows.length > 0 ? (
                <Flex gap={2} align={'center'}>
                  <Text
                    textStyle={'bodySmall'}
                    borderRight={'2px solid rgba(23, 23, 23, 0.2)'}
                    pr={3}
                  >
                    {selectedRows.length} of {sessions!.length} Leads Selected
                  </Text>
                  {isAdmin && (
                    <DeleteConfirmButton
                      onDelete={handleDelete}
                      isDisabled={selectedRows.length === 0}
                      description='Type "Delete" if you are certain you want to delete the selected userSessions'
                      textStyle="bodySmall"
                    />
                  )}
                </Flex>
              ) : (
                <Text textStyle="bodySmall">
                  Total Leads: {sessions?.length.toLocaleString() || '--'}
                </Text>
              )}
              <Spacer />
              <Tooltip label={`${expanded ? 'Collapse' : 'Expand'} table`} placement="top">
                <IconButton
                  aria-label="expand table"
                  onClick={() => updateExpanded?.(!expanded)}
                  icon={<ExpandIcon fontSize="lg" />}
                  variant="ghost"
                />
              </Tooltip>
            </LightModeBox>

            <Table
              {...tableProps}
              onChange={setTableProps}
              onRowClick={setSelectedId}
              onSelectedRowsChange={isMobile ? undefined : updateSelectedRows}
              searchText={search}
              selectedRows={isMobile || !isAdmin ? undefined : selectedRows}
              emptyText="No sessions"
            />
          </Box>
        ) : (
          <Flex
            bg="white"
            borderRadius="14px"
            justify="center"
            align="center"
            p={10}
            flexShrink={0}
          >
            <EmptyState
              iconSVG={noLeads}
              title="No Leads"
              description="Once an event begins, leads captured will be available here to view and manage."
              textProps={{ color: 'black', fontSize: { base: 'sm', sm: 'md' } }}
            />
          </Flex>
        )}
      </Flex>

      <LeadDrawer session={selectedSession} onDone={() => setSelectedId('')} />
    </>
  )
}

const customSortOrders: Record<string, string[]> = {
  temp: ['cold', 'warm', 'hot'],
}

const initialTableProps: TableProps = {
  ...defaultProps,
  columnReordering: true,
  pinnedColumns: ['temp', 'name'],
  customSortOrder: customSortOrders,
}

function makeColumns(userSessions: Partial<IUserSession>[]): TableColumn[] {
  function makeProps(key: string, overrides: Partial<TableColumn> = {}): TableColumn {
    return {
      isEditable: false,
      key,
      title: startCase(key),
      width: 175,
      isResizable: true,
      visible: false,
      renderCell: getRenderCell(key),
      ...overrides,
    }
  }

  const columnEntries = Object.entries(makeTypeByField(userSessions))
  const defaultColumns = defaultColumnSpecs.map(c => makeProps(c.key, { ...c, visible: true }))
  const remainingEntries = columnEntries.filter(([key]) => defaultColumns.every(d => d.key !== key))
  const remainingColumns = remainingEntries.map(([key, dataType]) => makeProps(key, { dataType }))
  return [...defaultColumns, ...remainingColumns]
}

function getRenderCell(key: string): TableColumn['renderCell'] {
  switch (key) {
    case 'notes':
      return props => {
        if (!props.value) return null

        return <NewspaperIcon color="black" type="outline" />
      }

    case 'visitCount':
      return props => {
        if (props.value > 1)
          return (
            <Flex direction="row" gap={2} align="center">
              <Text>{props.value}</Text>
              <ArrowPathIcon color="rgba(190, 190, 190, 1)" fontSize="lg" />
            </Flex>
          )
        else return <Text>{props.value}</Text>
      }

    case 'temp':
      return props => {
        const temperature = leadTemperatures[(props.value as LeadTemperature) || 'unset']
        return (
          <Tooltip label={temperature.label} hasArrow p={2} px={3}>
            <Flex flex={1} justify="center">
              {temperature.icon}
            </Flex>
          </Tooltip>
        )
      }
  }
}

export function getTempIcon(temp: LeadTemperature) {
  switch (temp) {
    case 'hot':
      return <FireIcon fontSize="lg" color="rgba(232, 58, 90, 1)" />
    case 'warm':
      return <Circle size="16px" bg="rgba(232, 194, 58, 1)" />
    case 'cold':
      return <Circle size="16px" bg="rgba(119, 182, 255, 1)" />

    default:
      return <DashedCircleIcon color="rgba(0, 0, 0, 0.6)" size="sm" />
  }
}

export const leadTemperatures: Record<LeadTemperature, { label: string; icon: JSX.Element }> = {
  hot: { label: 'Hot', icon: <FireIcon fontSize="lg" color="rgba(232, 58, 90, 1)" /> },
  warm: { label: 'Warm', icon: <Circle size="16px" bg="rgba(232, 194, 58, 1)" /> },
  cold: { label: 'Cold', icon: <Circle size="16px" bg="rgba(119, 182, 255, 1)" /> },
  unset: { label: 'Unset', icon: <DashedCircleIcon color="rgba(0, 0, 0, 0.6)" size="sm" /> },
}
