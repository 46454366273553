import {
  AccordionButtonProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  ArrowsRightLeftIcon,
  Button,
  ChevronLeftIcon,
  Dialog,
  FieldMapper,
  LightModeBox,
  LoaderCover,
  QrCodeIcon,
} from 'components'
import { AuthContext, useAlert } from 'components/providers'
import { lazy, Suspense, useContext, useEffect, useState } from 'react'
import { PrimaryLeadProperties, primaryLeadProperties, PrimaryLeadProperty } from 'types'
import { updateDoc, useIntercomUtils } from 'utils'
import { useEvent } from '../../../utils/eventUtils'

const QrScanner = lazy(() => import('./QrScanner'))

interface Props {
  isOpen: boolean
  onClose: () => void
}

export default function QrMappingDrawer({ isOpen, onClose }: Props) {
  useIntercomUtils(isOpen)
  const { user } = useContext(AuthContext)
  const { event } = useEvent()
  const [qrFields, setQrFields] = useState<Record<string, string>>()
  const [mappedFields, setMappedFields] = useState<PrimaryLeadProperties>({})
  const [customFields, setCustomFields] = useState<Record<string, string>>(qrFields || {})
  const [repeatedFields, setRepeatedFields] = useState<Record<string, string[]>>({})
  const { isOpen: dialogIsOpen, onOpen: dialogOnOpen, onClose: dialogOnClose } = useDisclosure()
  const { alert } = useAlert()
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false })

  useEffect(() => {
    if (qrFields) {
      const customFields = Object.keys(qrFields).reduce((acc, key) => {
        const valuesInMappedFields = new Set(Object.values(mappedFields ?? {}))
        if (!valuesInMappedFields.has(key)) {
          acc[key] = qrFields[key]
        }
        return acc
      }, {} as Record<string, string>)
      setCustomFields(customFields)
    }
  }, [mappedFields])

  function updateMappedFields(field: PrimaryLeadProperty, value: string) {
    setMappedFields(prev => {
      if (value === '') {
        const { [field]: _, ...rest } = prev || {}

        setRepeatedFields(prev => {
          const updated = { ...prev }

          Object.keys(updated).forEach(key => {
            if (updated[key].includes(field)) {
              if (updated[key].length === 2) {
                delete updated[key]
              } else {
                updated[key] = updated[key].filter(f => f !== field)
                if (updated[key].length === 0) delete updated[key]
              }
            }
          })

          return updated
        })

        return rest
      } else {
        const updatedFields = { ...prev, [field]: value }
        const repeatedKeys = Object.keys(updatedFields).filter(
          key => updatedFields[key as PrimaryLeadProperty] === value
        )

        setRepeatedFields(prev => {
          const updated = { ...prev }

          if (repeatedKeys.length > 1) {
            updated[value] = Array.from(new Set([...(updated[value] || []), ...repeatedKeys]))
            alert('Please map each field to a unique value')
          } else {
            Object.keys(updated).forEach(key => {
              if (updated[key].includes(field)) {
                if (updated[key].length === 2) {
                  delete updated[key]
                } else {
                  updated[key] = updated[key].filter(f => f !== field)
                  if (updated[key].length === 0) delete updated[key]
                }
              }
            })
          }
          return updated
        })
        return updatedFields
      }
    })
  }

  async function updateEvent() {
    if (event) {
      await updateDoc(user!.id, 'events', {
        id: event.id!,
        qrCode: {
          ...event.qrCode!,
          leadMapping: mappedFields,
        },
      })
    }
  }

  function saveMapping() {
    updateEvent()
    onClose()
  }

  const customFieldItems: AccordionItem[] = [
    {
      title: `Custom Fields ${
        Object.keys(customFields).length > 0 ? ` (${Object.keys(customFields).length})` : ''
      }`,
      content: (
        <Flex direction="column" gap={3}>
          <Text textStyle="bodyXSmall" opacity={0.6} fontWeight="normal">
            Any additional fields brought in from QR code that does not match the mapping above will
            appear here.
          </Text>
          <Flex w="100%" gap={3}>
            <Text textStyle="bodySmall" w="60%">
              Field Name
            </Text>
            <Text textStyle="bodySmall" w="40%">
              Field Value
            </Text>
          </Flex>
          {Object.entries(customFields).map(([key, value]) => (
            <Flex key={key} w="100%" gap={3}>
              <Text w="60%" {...textStyling}>
                {key}
              </Text>
              <Text {...textStyling} w="40%">
                {value}
              </Text>
            </Flex>
          ))}
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        autoFocus={false}
        size={isMobile ? 'xs' : 'lg'}
        isOpen={isOpen}
        closeOnEsc
      >
        <DrawerOverlay />
        <LightModeBox>
          <DrawerContent borderRadius="md" my={2} mr={2}>
            <DrawerHeader borderBottom="1px solid rgba(222, 226, 244, 1)" py={2}>
              <Button
                variant="ghost"
                leftIcon={<ChevronLeftIcon fontSize="lg" />}
                onClick={onClose}
                pl={0}
              >
                Back
              </Button>

              <DrawerCloseButton h="40px" />
            </DrawerHeader>

            <DrawerBody>
              <Flex direction="column" gap={5}>
                <Flex justify="space-between">
                  <Flex gap={2} align="center" p={2}>
                    <ArrowsRightLeftIcon fontSize="xl" />
                    <Text textStyle="h6">Map Fields</Text>
                  </Flex>
                  {qrFields && (
                    <Button variant="outline" leftIcon={<QrCodeIcon />} onClick={dialogOnOpen}>
                      Re-Scan Test QR Code
                    </Button>
                  )}
                </Flex>

                {qrFields ? (
                  <Flex direction="column" gap={3}>
                    <SimpleGrid w="100%" columns={3} spacing={3}>
                      <Text textStyle="bodySmall">Momentify Fields</Text>
                      <Text textStyle="bodySmall">XpressLeads Fields</Text>
                      <Text textStyle="bodySmall">Scanned Data Input</Text>
                    </SimpleGrid>
                    {primaryLeadProperties.map((field, index) => (
                      <FieldMapper
                        key={index}
                        referenceField={field}
                        targetFieldsAndValues={qrFields}
                        onChange={value => updateMappedFields(field, value)}
                        error={Object.values(repeatedFields).some(fields => fields.includes(field))}
                      />
                    ))}

                    {customFields && (
                      <Accordion
                        alignSelf="stretch"
                        allowToggle
                        items={customFieldItems}
                        buttonProps={accordionButtonProps}
                      />
                    )}
                  </Flex>
                ) : (
                  <Suspense fallback={<LoaderCover show={true} />}>
                    <QrScanner onRead={setQrFields} />
                  </Suspense>
                )}
              </Flex>
            </DrawerBody>

            <DrawerFooter>
              {Object.keys(mappedFields).length !== 0 && (
                <Button
                  palette="primary"
                  isDisabled={Object.keys(repeatedFields).length > 0}
                  onClick={saveMapping}
                >
                  Save
                </Button>
              )}
            </DrawerFooter>
          </DrawerContent>
        </LightModeBox>
      </Drawer>
      <Dialog
        isOpen={dialogIsOpen}
        onClose={dialogOnClose}
        size="2xl"
        isCentered
        bodyProps={{ display: 'flex', p: 6 }}
      >
        <QrScanner
          onRead={value => {
            setQrFields(value)
            dialogOnClose()
          }}
        />
      </Dialog>
    </>
  )
}

const textStyling = {
  bg: 'rgba(23, 23, 23, 0.10)',
  borderRadius: '10px',
  minW: '100px',
  h: '40px',
  py: '10px',
  px: '16px',
  textStyle: 'bodyMedium',
  fontWeight: '500',
  isTruncated: true,
}

const accordionButtonProps: AccordionButtonProps = {
  backgroundColor: 'transparent',
  textStyle: 'bodySmall',
  pl: 0,
}
