import { Text, useColorMode } from '@chakra-ui/react'
import { Button, ButtonProps } from 'components'

interface SideMenuItem extends Omit<ButtonProps, 'children'> {
  children?: string
  isCurrent?: boolean
}

export default function SideMenuButton({ isCurrent, children, ...rest }: SideMenuItem) {
  const { colorMode } = useColorMode()

  return (
    <Button
      bg={
        isCurrent
          ? colorMode === 'light'
            ? 'rgba(60, 75, 211, 0.10)'
            : 'rgba(255, 255, 255, 0.20)'
          : ''
      }
      color={isCurrent ? (colorMode === 'light' ? 'primary.500' : 'white') : ''}
      size="lg"
      px={children ? 5 : 0}
      iconSpacing={children ? 2 : 0}
      justifyContent={children ? 'start' : 'center'}
      palette={colorMode === 'light' ? 'primary' : undefined}
      borderRadius="sm"
      variant="ghost"
      {...rest}
    >
      <Text textStyle="bodySmall">{children}</Text>
    </Button>
  )
}
