import { Divider, Flex, Image, Link, Text, useColorMode, useToast } from '@chakra-ui/react'
import {
  AnimatedTiles,
  Backdrop,
  Button,
  LightModeBox,
  LoaderCover,
  LogoLoader,
  TextField,
  TextFieldProps,
  Tooltip,
} from 'components'
import { AuthContext } from 'components/providers'
import { Dispatch, ReactNode, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { IUser, OmitDBProps } from 'types'
import {
  acceptInvite,
  resetPassword,
  signIn,
  signOut,
  signUp,
  useDoc,
  useGoToWorkspace,
  userWorkspaceStatus,
} from 'utils'

type Path = 'signed-in' | 'existing' | 'new' | 'missing'

export default function AcceptInvitePage() {
  const { workspaceId } = useParams<{ workspaceId: string }>()
  const [workspace] = useDoc('Workspace', workspaceId)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const toast = useToast()
  const goToWorkspace = useGoToWorkspace()

  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [path, setPath] = useState<Path>()
  const [wrongUser, setWrongUser] = useState(false)
  const email = (searchParams.get('email') || '').trim()
  const [userInfo, setUserInfo] = useState<OmitDBProps<IUser>>({ email })
  const [workspaceTitle, setWorkspaceTitle] = useState('')
  const joined = useRef(false)

  const isUser = user?.email === email
  const goToRoot = () => goToWorkspace(workspaceId, null, { replace: true })

  useEffect(() => {
    if (user === undefined || !workspaceId) return

    if (path) {
      if (isUser && !joined.current) join()
      return
    }

    userWorkspaceStatus({ email, workspaceId })
      .then(res => {
        const { inAuth, workspaceStatus, workspaceTitle } = res.data
        setWorkspaceTitle(workspaceTitle)
        if (workspaceStatus === 'missing') return setPath('missing')

        if (isUser) {
          setPath('signed-in')
        } else {
          setWrongUser(Boolean(user))
          setPath(inAuth ? 'existing' : 'new')
        }
      })
      .catch(e => {
        toast({ title: e.message, status: 'error', isClosable: true })
        navigate('/', { replace: true })
      })
  }, [user])

  useEffect(() => {
    if (isUser && workspace?.userIds.includes(user.id)) goToRoot()
  }, [workspace])

  async function join() {
    try {
      joined.current = true
      setLoading(true)
      await acceptInvite({ ...userInfo, workspaceId: workspaceId! })
      setLoading(false)
      toast({ title: 'Joined workspace', isClosable: true })
    } catch (e: any) {
      joined.current = false
      toast({ title: e.message, status: 'error', isClosable: true })
      navigate('/', { replace: true })
    }
  }

  if (!path) return <LogoLoader />

  return (
    <Container loading={loading} title={workspaceTitle}>
      {path === 'signed-in' ? (
        <SignedIn join={join} />
      ) : path === 'new' ? (
        <NewUser setLoading={setLoading} setUserInfo={setUserInfo} userInfo={userInfo} />
      ) : path === 'existing' ? (
        <ExistingUser email={email} setLoading={setLoading} />
      ) : (
        <Text>
          However, you do not have access, so reach out to your administrator to get access.
        </Text>
      )}

      {wrongUser && (
        <Flex
          direction="column"
          gap={4}
          position="absolute"
          align="center"
          justifyContent="center"
          bg="#E9EDF0"
          inset={0}
        >
          <Text>You are signed in to a different account. Sign out first.</Text>
          <Button
            palette="primary"
            onClick={async () => {
              await signOut(true)
              setWrongUser(false)
            }}
          >
            Sign out
          </Button>
        </Flex>
      )}
    </Container>
  )
}

function SignedIn({ join }: { join: () => void }) {
  return (
    <>
      <Text>You have been invited to join a workspace.</Text>
      <Button palette="primary" onClick={join}>
        Join
      </Button>
    </>
  )
}

interface ExistingUserProps {
  email: string
  setLoading: (loading: boolean) => void
}

function ExistingUser({ email, setLoading }: ExistingUserProps) {
  const toast = useToast()
  const [password, setPassword] = useState('')

  async function handleSignIn() {
    setLoading(true)
    try {
      await signIn(email, password)
    } catch (e: any) {
      setLoading(false)
      handleError(e)
    }
  }

  function handleReset() {
    resetPassword(email).then(() =>
      toast({ title: 'Password reset email sent', status: 'success' })
    )
  }

  async function handleError(e: any) {
    let title = 'An error occurred'
    switch (e.code) {
      case 'auth/wrong-password':
        title = 'Invalid credentials'
        break
      case 'auth/invalid-email':
        title = 'Invalid email address'
        break
      case 'auth/too-many-requests':
        title = 'Too many attempts. Try again later'
        break
      default:
        title = 'An error occurred'
        console.error(e)
    }
    toast({ title, status: 'error' })
  }

  return (
    <Flex direction="column" alignSelf="stretch" gap={4}>
      <Input label="Email" isDisabled type="email" value={email} />
      <Input
        onChange={e => setPassword(e.target.value)}
        onKeyDown={e => e.key === 'Enter' && password && handleSignIn()}
        placeholder="Enter password..."
        type="password"
        label="Password"
      />
      <Flex direction="row" justifyContent="space-between">
        <Button palette="primary" onClick={handleSignIn}>
          Sign in
        </Button>
        <Button palette="secondary" variant="link" onClick={handleReset}>
          Forgot Password?
        </Button>
      </Flex>
    </Flex>
  )
}

interface NewUserProps {
  userInfo: OmitDBProps<IUser>
  setLoading: (loading: boolean) => void
  setUserInfo: Dispatch<SetStateAction<OmitDBProps<IUser>>>
}

function NewUser({ userInfo, setLoading, setUserInfo }: NewUserProps) {
  const toast = useToast()

  const [password, setPassword] = useState('')
  const [passwordBlur, setPasswordBlur] = useState(false)

  const { email, name = '', company = getDefaultCompany(email), title = '' } = userInfo

  function handleUpdate(body: Partial<OmitDBProps<IUser>>) {
    setUserInfo(s => ({ ...s, ...body }))
  }

  async function handleSignUp() {
    setLoading(true)
    try {
      await signUp(email, password)
    } catch (e: any) {
      setLoading(false)
      toast({ title: 'Error signing up', status: 'error' })
      console.error(e)
    }
  }

  return (
    <Flex direction="column" alignSelf="stretch" gap={4}>
      <Input label="Email" isDisabled type="email" value={email} />
      <Input
        label="Name"
        onChange={e => handleUpdate({ name: e.target.value })}
        placeholder="Enter full name..."
        value={name}
      />
      <Flex gap={3}>
        <Input
          label="Company (Optional)"
          onChange={e => handleUpdate({ company: e.target.value })}
          placeholder="Enter company..."
          value={company}
        />
        <Input
          label="Title (Optional)"
          onChange={e => handleUpdate({ title: e.target.value })}
          placeholder="Enter title..."
          value={title}
        />
      </Flex>
      <Input
        onBlur={() => setPasswordBlur(true)}
        onChange={e => setPassword(e.target.value)}
        onFocus={() => setPasswordBlur(false)}
        onKeyDown={e => e.key === 'Enter' && handleSignUp()}
        isError={password.length < 8 && passwordBlur}
        placeholder="Enter password..."
        helperText="Password must be at least 8 characters long."
        type="password"
        label="Create Password"
      />
      <Tooltip
        label={
          !name
            ? 'Enter your name'
            : password.length < 8
            ? 'Password must be at least 8 characters'
            : undefined
        }
      >
        <Button
          alignSelf="flex-start"
          isDisabled={password.length < 8 || !name}
          palette="primary"
          onClick={handleSignUp}
        >
          Sign up
        </Button>
      </Tooltip>
      <Divider />
      <Text textStyle="bodySmall">
        By creating an account, you agree to our{' '}
        <Link
          target="_blank"
          color="secondary.600"
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=00fc46db-0a39-40f9-b2d5-17e8484559dd"
        >
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link
          target="_blank"
          color="secondary.600"
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=b5c58f21-00f2-4ee2-9391-79a13199d76c"
        >
          Privacy Policy
        </Link>
      </Text>
      .
    </Flex>
  )
}

function getDefaultCompany(email: string) {
  const domain = email.split('@')[1]
  switch (domain) {
    case 'cat.com':
      return 'Caterpillar Inc.'
    default:
      return ''
  }
}

interface ContainerProps {
  children: ReactNode
  loading: boolean
  title: string
}

function Container({ children, loading, title }: ContainerProps) {
  const { colorMode } = useColorMode()

  return (
    <Backdrop justify="center" align="center" flex={1} gap={14}>
      <LoaderCover
        overflow="hidden"
        display="flex"
        width="500px"
        maxWidth="100vw"
        height={{ base: '100vh', sm: 'auto' }}
        borderRadius={{ base: 0, sm: '40px' }}
        border="1px solid #FFF"
        boxShadow="0px 7px 16px 0px rgba(0, 0, 0, 0.08)"
        show={loading}
      >
        <LightModeBox
          flex={1}
          display="flex"
          bg="linear-gradient(167deg, rgba(255, 255, 255, 0.70) 2.36%, rgba(255, 255, 255, 0.60) 92.17%)"
          px={14}
          py={10}
          alignItems="flex-start"
          flexDirection="column"
          backdropFilter="blur(40px)"
          justifyContent="center"
          gap={3}
          position="relative"
        >
          <Image src="/common/momentify-logo-dark.svg" alt="momentify logo" height="30px" mb={8} />
          <Text textStyle="h1">Welcome!</Text>
          <Text>
            You have been invited to join <b>{title}</b>
          </Text>

          {children}
        </LightModeBox>
      </LoaderCover>

      <AnimatedTiles />
    </Backdrop>
  )
}

function Input(props: TextFieldProps) {
  return (
    <TextField
      bg="#fff"
      backdropFilter={'blur(27px)'}
      borderRadius={'10px'}
      border={'1px solid rgba(23, 23, 23, 0.20)'}
      {...props}
    />
  )
}
