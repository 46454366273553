import { useColorMode } from '@chakra-ui/react'
import { Chart as GoogleChart } from 'react-google-charts'
import { ChartProps } from 'types'
import {
  defaultChartProps,
  makeChartData,
  makeDefaultChartOptions,
  pieCustomSliceColors,
} from '../chartUtils'

export default function PieChart(props: ChartProps) {
  const { colorMode } = useColorMode()

  const customSliceIndices = props.data
    .map((row, index) => {
      return pieCustomSliceColors(row.label) ? index : -1
    })
    .filter(index => index !== -1)

  const options = {
    ...makeDefaultChartOptions(props),
    chartArea: {
      height: '90%',
      width: '90%',
    },
    pieHole: 0.4,
    legend: {
      position: 'labeled',
      textStyle: {
        color: colorMode === 'light' ? '#333' : '#FFF',
      },
    },
    pieSliceBorderColor: '',
    slices: Object.fromEntries(
      customSliceIndices.map(index => {
        const label = props.data[index].label
        return [index, { color: pieCustomSliceColors(label) }]
      })
    ),
  }

  return (
    <GoogleChart
      {...defaultChartProps}
      chartType="PieChart"
      data={makeChartData(props)}
      options={options}
    />
  )
}
