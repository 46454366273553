import { where } from 'firebase/firestore'
import { useContext } from 'react'
import useSWR from 'swr'
import { IFacility, OmitDBProps } from 'types'
import { getDocs, useWorkspace } from 'utils'
import { FacilityContext } from '../components/Facility'

const makeFacilitiesKey = (workspaceId?: string) => ['facilities', workspaceId]

export function useFacilities() {
  const { workspace } = useWorkspace()

  const { data, ...rest } = useSWR(makeFacilitiesKey(workspace?.id), ([_, workspaceId]) =>
    workspaceId ? getFacilities(workspaceId) : undefined
  )

  return { facilities: data, ...rest }
}

async function getFacilities(workspaceId: string) {
  return getDocs('facilities', 0, where('workspaceId', '==', workspaceId))
}

export function makeEmptyFacility(workspaceId: string, managerId: string): OmitDBProps<IFacility> {
  return { title: '', managerId, workspaceId }
}

export const getFacilityImagePath = (id: string) => `facilities/${id}/image`

export const useFacility = () => useContext(FacilityContext)
