import { RowUpdate } from 'components'
import { createContext } from 'react'
import { useParams } from 'react-router-dom'
import { IEvent, ITemplate, IUserSession, SessionLead } from 'types'
import { FetchStatus, useDoc, useStorageUrl } from 'utils'
import { getEventImagePath } from '../../utils/eventUtils'
import { useEventSessions } from '../../utils/userSessionUtils'

export interface EventContextProps {
  deleteSessions: (ids: string[]) => Promise<void>
  event: IEvent | null
  imageUrl?: string
  refetchImage: () => void
  sessions?: IUserSession[]
  sessionsStatus: FetchStatus
  status: FetchStatus
  template?: ITemplate | null
  updateLead: (id: string, body: Partial<SessionLead>) => Promise<void>
  updates: RowUpdate[]
}

export const EventContext = createContext<EventContextProps>({} as any)

export function EventProvider({ children }: { children: any }) {
  const id = useParams().id
  const [event, status] = useDoc('events', id)
  const [template] = useDoc('templates', event?.template?.id)
  const { hasImage } = event || {}
  const { url, mutate } = useStorageUrl(hasImage && id ? getEventImagePath(id) : undefined)

  const {
    sessions,
    status: sessionsStatus,
    updates,
    updateLead,
    deleteSessions,
  } = useEventSessions(event || null)

  return (
    <EventContext.Provider
      value={{
        deleteSessions,
        event: event || null,
        imageUrl: url,
        refetchImage: () => mutate(),
        sessions,
        sessionsStatus,
        status,
        template,
        updateLead,
        updates,
      }}
    >
      {children}
    </EventContext.Provider>
  )
}
