import { doc, getDoc, getFirestore, where } from 'firebase/firestore'
import { IContent, OmitDBProps } from 'types'
import { makeDoc, useDocs, usePrefCurrentWorkspace } from 'utils'
import firebaseApp from '../../../utils/firebase'

export const WorkspacePath = '/:workspaceId'
const db = getFirestore(firebaseApp)

export const makeEmptyContent = (workspaceId: string): OmitDBProps<IContent> => ({
  childIds: [],
  // mediaIds: [],
  parentId: '',
  // toolIds: [],
  workspaceId,
  value: {
    en: '',
  },
  name: '',
})

export const makeEmptyChildContent = (
  workspaceId: string,
  parentId: string
): OmitDBProps<IContent> => ({
  childIds: [],
  // mediaIds: [],
  parentId,
  // toolIds: [],
  workspaceId,
  name: '',
  value: {
    en: '',
  },
})

export function useContent() {
  const [workspaceId] = usePrefCurrentWorkspace()
  const [content, status] = useDocs('content', [
    where('workspaceId', '==', workspaceId),
    where('parentId', '==', ''),
  ])

  return { content, status }
}

export async function getContentById(id: string): Promise<IContent | null> {
  try {
    const docRef = doc(db, 'content', id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      return makeDoc<IContent>(docSnap)
    } else {
      console.error(`No such document with id: ${id}`)
      return null
    }
  } catch (error) {
    console.error('Error fetching document:', error)
    return null
  }
}
