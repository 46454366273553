import {
  Box,
  CircularProgress,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  InputGroup,
  InputLeftElement,
  Spacer,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {
  Button,
  ChevronRightIcon,
  DebouncedTextField,
  DeleteConfirmButton,
  DevDetailsButton,
  IconButton,
  InputContainer,
  LightModeBox,
  MagnifyingGlassIcon,
  MarkdownEditor,
  Menu,
  Panel,
  PencilIcon,
  PlusIcon,
  XMarkIcon,
} from 'components'
import { AuthContext } from 'components/providers'
import { useContext, useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { IContent } from 'types'
import {
  createDoc,
  deleteDoc,
  getDocById,
  updateDoc,
  useIntercomUtils,
  usePermission,
  usePrefCurrentWorkspace,
} from 'utils'
import ContentTile from '../components/ContentTile'
import { makeEmptyChildContent, makeEmptyContent, useContent } from '../utils/contentUtils'
import { RoutePath } from '../utils/routing'

export default function ManageTextContentPage() {
  const toast = useToast({ position: 'top' })
  const permission = usePermission()
  const [workspaceId] = usePrefCurrentWorkspace()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const { colorMode } = useColorMode()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { content, status } = useContent()
  const [selectedContent, setSelectedContent] = useState<IContent | null>(null)
  const [parentContent, setParentContent] = useState<IContent | null>(null)
  const [childContents, setChildContents] = useState<IContent[]>([])
  const [search, setSearch] = useState('')
  const [editedName, setEditedName] = useState<string | undefined>(undefined)
  const [editedValue, setEditedValue] = useState<string | undefined>(undefined)
  const { id } = useParams()
  useIntercomUtils(isOpen)

  const isViewer = permission.content === 'viewer'

  useEffect(() => {
    const fetchParentAndChildren = async (contentItem: IContent) => {
      setChildContents([])

      if (contentItem.parentId) {
        const parent = await getDocById('content', contentItem.parentId)
        if (parent) {
          setParentContent(parent)
        }
      }

      if (contentItem.childIds?.length) {
        const childPromises = contentItem.childIds.map(childId => getDocById('content', childId))
        const children = await Promise.all(childPromises)
        setChildContents(children.filter(child => child !== null) as IContent[])
      }
    }

    const fetchContentAndOpen = async () => {
      if (id && content) {
        const availableContent = [
          ...(childContents || []),
          ...(parentContent ? [parentContent] : []),
          ...content,
        ]

        let contentItem = availableContent.find(item => item.id === id) ?? null
        if (!contentItem) {
          contentItem = await getDocById('content', id)
        }
        setSelectedContent(contentItem)
        await fetchParentAndChildren(contentItem!)
        onOpen()
      }
    }

    fetchContentAndOpen()
  }, [id, content])

  useEffect(() => {
    if (content && content?.length > 0) {
      const newestItem = content[content.length - 1]
      if (!newestItem.name) {
        navigate(generatePath(RoutePath.TextInfo, { workspaceId: workspaceId!, id: newestItem.id }))
      }
    }
  }, [content])

  function handleOnClose() {
    navigate(generatePath(RoutePath.Text, { workspaceId: workspaceId! }))
    onClose()
  }

  async function updateTextContent(name?: string, value?: string) {
    const updatedTextContent: { [key: string]: any } = {}
    if (name) {
      updatedTextContent.name = name
    }
    if (value) {
      updatedTextContent.value = { en: value }
    }
    if (Object.keys(updatedTextContent).length > 0) {
      try {
        await updateDoc(user!.id, 'content', { id: selectedContent?.id!, ...updatedTextContent })
        toast({ status: 'success', description: 'Content successfully updated.' })
        handleOnClose()
      } catch (error) {
        console.error('Error updating document:', error)
        toast({ status: 'error', description: 'An error occurred. Please try again.' })
      }
    }
  }

  async function handleDelete() {
    await deleteDoc('content', selectedContent!.id).catch(() =>
      toast({ status: 'error', description: 'Failed to delete content' })
    )
    handleOnClose()
  }

  function performSearch(val: string): void {
    setSearch(val)
  }

  async function createNewText() {
    await createDoc(user!.id, 'content', makeEmptyContent(workspaceId!))
  }

  return status === 'loading' ? (
    <Flex p={20} justifyContent="center" maxW={'100%'}>
      <CircularProgress isIndeterminate />
    </Flex>
  ) : content ? (
    <>
      <Flex mb={2} direction={'column'} gap={2} overflowY="auto" overflowX={'hidden'}>
        <Flex direction={'row'} gap={2} justify={'space-between'} my={3}>
          <InputGroup width={{ base: '200px', sm: '300px' }}>
            <InputLeftElement pointerEvents="none">
              <MagnifyingGlassIcon
                color={colorMode === 'light' ? 'rgba(23, 23, 23, 0.6)' : 'white'}
              />
            </InputLeftElement>
            <DebouncedTextField
              isDisabled={content.length === 0}
              onChange={performSearch}
              placeholder="Search..."
              value={search}
              width={'100%'}
              controlled
              boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.06)'}
              backdropFilter={'blur(14px)'}
              bg={colorMode === 'light' ? 'rgba(255, 255, 255, 0.74)' : 'rgba(255, 255, 255, 0.14)'}
              borderRadius="sm"
              border={0}
              pl={10}
            />
          </InputGroup>
          <Panel>
            <Button leftIcon={<PlusIcon />} variant="ghost" onClick={() => createNewText()}>
              Add Text
            </Button>
          </Panel>
        </Flex>

        <Text textStyle={'bodySmall'}>Name</Text>
        {content
          .filter(
            item =>
              item.name?.toLowerCase().includes(search.toLowerCase()) ||
              item.value.en?.toLowerCase().includes(search.toLowerCase())
          )
          .map((item, index) => (
            <ContentTile
              key={index}
              onEdit={() =>
                navigate(
                  generatePath(RoutePath.TextInfo, { workspaceId: workspaceId!, id: item.id })
                )
              }
              workspaceId={workspaceId}
              contentId={item.id}
              contentName={item.name}
              contentValue={item.value}
              isSubContent={item.parentId ? true : false}
              contentChildCount={item.childIds?.length}
            />
          ))}
      </Flex>

      <Drawer
        placement="right"
        onClose={handleOnClose}
        size={{ base: 'xs', sm: 'sm' }}
        isOpen={isOpen}
      >
        <DrawerOverlay />
        <LightModeBox>
          <DrawerContent borderRadius="md" my={2} mr={2}>
            <DrawerHeader borderBottom={'1px solid rgba(222, 226, 244, 1)'} p={2}>
              <Flex width={'100%'} height={'35px'} align={'center'} gap={2} px={4}>
                <PencilIcon fontSize={'20px'} />
                <Text textStyle={{ base: 'bodySmall', sm: 'bodyMedium' }}>Edit Content</Text>
                <DevDetailsButton
                  items={[
                    {
                      collection: 'content',
                      id: selectedContent?.id,
                      label: 'Content',
                      type: 'db',
                    },
                  ]}
                />
                <Spacer />
                <Menu
                  noPortal
                  _hover={{ color: colorMode === 'light' && 'primary.500' }}
                  buttonLabel="Actions"
                  menuProps={{ placement: 'bottom-start' }}
                  options={[
                    {
                      type: 'custom',
                      label: (
                        <DeleteConfirmButton
                          onDelete={handleDelete}
                          description='If you are sure type "Delete" below.'
                        />
                      ),
                    },
                  ]}
                />
                <IconButton
                  variant="ghost"
                  aria-label="close drawer"
                  icon={<XMarkIcon />}
                  onClick={handleOnClose}
                />
              </Flex>
            </DrawerHeader>

            <DrawerBody>
              <Flex gap={5} direction={'column'} mt={3} height={'95%'}>
                <Flex direction={'column'} gap={2}>
                  {selectedContent?.parentId && (
                    <Flex justify={'center'} gap={1}>
                      <Text textStyle={'bodySmall'} opacity={0.7} color={'rgba(23, 23, 23, 0.6)'}>
                        Sub content of
                      </Text>
                      <Text
                        textStyle={'bodySmall'}
                        opacity={0.7}
                        color={'rgba(23, 23, 23, 0.6)'}
                        as="u"
                        cursor={'pointer'}
                        onClick={() => {
                          navigate(
                            generatePath(RoutePath.TextInfo, {
                              workspaceId,
                              id: selectedContent?.parentId!,
                            })
                          )
                        }}
                      >
                        {parentContent?.name}
                      </Text>
                    </Flex>
                  )}
                  <DebouncedTextField
                    label="Name"
                    isDisabled={isViewer}
                    key={selectedContent?.id}
                    id="smallTitle"
                    placeholder="Please enter name for content"
                    textStyle={'bodyLarge'}
                    fontWeight={'500'}
                    value={selectedContent?.name || ''}
                    onChange={name => setEditedName(name)}
                    autoFocus
                  />
                </Flex>
                <Flex direction={'column'} gap={2}>
                  <InputContainer label="Value">
                    <Box border={'1px solid rgb(226, 232, 240)'} borderRadius={'10px'} p={1}>
                      <MarkdownEditor
                        key={selectedContent?.id}
                        hideBlockTypes
                        markdown={selectedContent?.value?.en || ''}
                        onChange={value => setEditedValue(value)}
                        readOnly={isViewer}
                      />
                    </Box>
                  </InputContainer>
                </Flex>
                <Flex direction={'column'} gap={2}>
                  <InputContainer label="Manage Sub Content">
                    {childContents.length !== 0 ? (
                      <Flex
                        direction={'column'}
                        gap={2}
                        px={4}
                        py={1}
                        border={'1px solid rgba(23, 23, 23, 0.20)'}
                        borderRadius="sm"
                      >
                        {childContents.map(childContent => (
                          <Flex
                            key={childContent.id}
                            direction={'row'}
                            gap={1}
                            onClick={() =>
                              navigate(
                                generatePath(RoutePath.TextInfo, {
                                  workspaceId,
                                  id: childContent.id,
                                })
                              )
                            }
                            cursor="pointer"
                            pt={2}
                            pb={4}
                            borderBottom={'1px solid rgba(23, 23, 23, 0.20)'}
                            align={'center'}
                          >
                            <Flex direction={'column'} gap={1}>
                              <Text textStyle={'bodyLarge'}>{childContent.name}</Text>
                              <MarkdownEditor
                                readOnly
                                limitTo2Lines
                                markdown={childContent.value?.en || ''}
                              />
                            </Flex>
                            <Spacer />
                            <ChevronRightIcon fontSize="md" />
                          </Flex>
                        ))}
                        <Flex
                          direction={'row'}
                          gap={1}
                          onClick={async () => {
                            const childContent = await createDoc(
                              user!.id,
                              'content',
                              makeEmptyChildContent(workspaceId, selectedContent!.id)
                            )
                            navigate(
                              generatePath(RoutePath.TextInfo, { workspaceId, id: childContent.id })
                            )
                          }}
                          cursor="pointer"
                          pt={2}
                          pb={4}
                          align={'center'}
                          opacity={0.5}
                        >
                          <PlusIcon fontSize="md" />
                          <Text textStyle={'bodyMedium'}>Add new sub-content</Text>
                          <Spacer />
                          <ChevronRightIcon fontSize="sm" />
                        </Flex>
                      </Flex>
                    ) : (
                      <Flex
                        direction={'row'}
                        gap={1}
                        onClick={async () => {
                          const childContent = await createDoc(
                            user!.id,
                            'content',
                            makeEmptyChildContent(workspaceId, selectedContent!.id)
                          )
                          navigate(
                            generatePath(RoutePath.TextInfo, { workspaceId, id: childContent.id })
                          )
                        }}
                        cursor="pointer"
                        p={4}
                        align={'center'}
                        opacity={0.5}
                        border={'1px solid rgba(23, 23, 23, 0.20)'}
                        borderRadius="sm"
                      >
                        <PlusIcon fontSize="md" />
                        <Text textStyle={'bodyMedium'}>Add new sub-content</Text>
                        <Spacer />
                        <ChevronRightIcon fontSize="sm" />
                      </Flex>
                    )}
                  </InputContainer>
                </Flex>
              </Flex>
            </DrawerBody>
            <DrawerFooter borderTopWidth="1px" p={3}>
              <Button onClick={handleOnClose} variant="outline">
                Cancel
              </Button>
              <Spacer />
              <Button
                palette="primary"
                onClick={async () => {
                  await updateTextContent(editedName!, editedValue!)
                  setEditedName('')
                  setEditedValue('')
                }}
              >
                Save
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </LightModeBox>
      </Drawer>
    </>
  ) : (
    <Flex p={20} justifyContent="center" maxW={'100%'}>
      <Text>No content available</Text>
    </Flex>
  )
}
