import { Circle, Flex, FlexProps, useColorMode } from '@chakra-ui/react'

export interface Props extends FlexProps {}

export default function Backdrop({ children, ...rest }: Props) {
  const { colorMode } = useColorMode()

  return (
    <Flex
      bg={
        colorMode === 'light'
          ? '#E9F0F5'
          : 'linear-gradient(308deg, #5B9C85 18%, #3E7F6D 25%, #1A2E73 88%)'
      }
      minHeight="100vh"
      flex={1}
    >
      <Flex position="absolute" height="100vh" width="100vw" overflow="hidden">
        {colorMode === 'light' && (
          <Circle
            position="absolute"
            top="0vh"
            left="0"
            size="50vh"
            bg="#FFE2C2"
            filter="blur(170px)"
          />
        )}
        <Circle
          position="absolute"
          bottom="5vh"
          size="35vh"
          bg="#E83A5A"
          filter={colorMode == 'light' ? 'blur(150px)' : 'blur(250px)'}
        />
        <Circle
          position="absolute"
          top="40vh"
          left="15vw"
          size="30vh"
          bg="#C9B7ED"
          filter={colorMode == 'light' ? 'blur(130px)' : 'blur(220px)'}
        />

        <Circle
          position="absolute"
          top="15vh"
          right="0px"
          size="30vh"
          bg="#3C4BD3"
          filter={colorMode == 'light' ? 'blur(200px)' : 'blur(150px)'}
        />
      </Flex>

      <Flex zIndex={1} {...rest}>
        {children}
      </Flex>
    </Flex>
  )
}
