import { Icon } from '@chakra-ui/react'
import { forwardRef } from 'react'
import HeroIcon, { HeroIconProps } from '../HeroIcon'

export default forwardRef<SVGSVGElement, HeroIconProps>((props, ref) => {
  const { type = 'solid', ...rest } = props
  return <HeroIcon ref={ref} Component={Component} {...rest} />
})

const Component = forwardRef<SVGSVGElement, any>((props, ref) => {
  return (
    <Icon
      ref={ref}
      width="24"
      height="24"
      viewBox="0 0 17.2955 24.4212"
      fill="currentColor"
      {...props}
    >
      <path d="M0 23.6863C0 24.0893 0.320157 24.4095 0.719533 24.4095C1.13426 24.4095 1.45442 24.0893 1.45442 23.6863L1.45442 2.06825C1.45442 1.69325 1.69688 1.45078 2.04844 1.45078L14.8991 1.45078C15.247 1.45078 15.4895 1.69325 15.4895 2.06825L15.4895 23.6863C15.4895 24.0893 15.8097 24.4095 16.2244 24.4095C16.6274 24.4095 16.9439 24.0893 16.9439 23.6863L16.9439 1.95469C16.9439 0.786564 16.1456 0 14.9306 0L2.01328 0C0.806369 0 0 0.786564 0 1.95469Z" />
      <path d="M2.66567 23.8105C2.66567 24.0005 2.82411 24.0995 3.01043 24.0166L6.92625 22.2243C7.18289 22.1099 7.27102 22.0161 7.27102 21.7696L7.27102 4.90465C7.27102 4.66618 7.19379 4.56797 6.9525 4.46532L3.01043 2.65758C2.82411 2.57473 2.66567 2.67375 2.66567 2.8718Z" />
    </Icon>
  )
})
