import { SVGProps, forwardRef } from 'react'

export interface SvgProps extends SVGProps<SVGSVGElement> {
  /** default 24 */
  size?: number | string
}

const MakeSvg = forwardRef<SVGSVGElement, SvgProps>(
  ({ height, width, size = 48, ...props }, ref) => {
    const _height = height ?? size
    const _width = width ?? size

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        height={_height}
        width={_width}
        fill="currentColor"
        stroke="currentColor"
        viewBox="0 0 24 24"
        {...props}
      />
    )
  }
)

export default MakeSvg
