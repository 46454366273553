import { Icon } from '@chakra-ui/react'
import { forwardRef } from 'react'
import HeroIcon, { HeroIconProps } from '../HeroIcon'

export default forwardRef<SVGSVGElement, HeroIconProps>((props, ref) => {
  const { type = 'solid', ...rest } = props
  return <HeroIcon ref={ref} Component={Component} {...rest} />
})

const Component = forwardRef<SVGSVGElement, any>((props, ref) => {
  return (
    <Icon ref={ref} width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <circle
        cx="8.02148"
        cy="8"
        r="6.5"
        stroke="black"
        strokeOpacity="0.1"
        strokeWidth="3"
        strokeDasharray="5 3"
      />
    </Icon>
  )
})
