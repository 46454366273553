import { CalendarDaysIcon, ChartBarIcon, Cog6ToothIcon, SquaresPlusIcon, TvIcon } from 'components'
import { AuthContext } from 'components/providers'
import { useContext } from 'react'
import { isContentPage, useCurrentPage } from '../utils/pageUtils'
import { RouteName, RoutePath } from './routing'

export interface SideMenuItem {
  text: string
  page: RoutePath
  LeftIcon?: any
  isDisabled?: boolean
  isCurrent?: boolean
}

export const useSideMenuItems = (): SideMenuItem[] => {
  const currentPage = useCurrentPage()
  const { developmentFeatures } = useContext(AuthContext)

  const currentSection = currentPage?.rootPath || currentPage?.parentPath || currentPage?.path

  return [
    {
      text: 'Events',
      page: RoutePath.Events,
      LeftIcon: CalendarDaysIcon,
      isCurrent: currentSection === RouteName.Events,
    },
    {
      text: 'Facilities',
      page: RoutePath.Facilities,
      LeftIcon: TvIcon,
      isCurrent: currentSection === RouteName.Facilities,
      isDisabled: !developmentFeatures,
    },
    {
      text: 'Content',
      page: RoutePath.Text,
      LeftIcon: SquaresPlusIcon,
      isCurrent: isContentPage(currentSection),
      isDisabled: !developmentFeatures,
    },
    // {
    //   text: 'Analytics',
    //   page: RoutePath.Workspace,
    //   LeftIcon: ChartBarIcon,
    //   isDisabled: true,
    // },
    {
      text: 'Settings',
      page: RoutePath.Workspace, // change to settings page when implemented
      LeftIcon: Cog6ToothIcon,
      isDisabled: true,
    },
  ]
}
