import { Dialog, DialogProps, ManageMedia, ManageMediaProps } from 'components'

export interface Props extends ManageMediaProps {
  isOpen: boolean
  onClose: () => void
  dialogProps?: Omit<DialogProps, 'children' | 'isOpen' | 'onClose'>
}

export default function ManageMediaDialog({ isOpen, onClose, dialogProps, ...rest }: Props) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      size="5xl"
      isCentered
      scrollBehavior="inside"
      contentProps={{ maxWidth: '90vw', height: '80vh' }}
      bodyProps={{ display: 'flex' }}
      title="Manage Media"
      {...dialogProps}
    >
      <ManageMedia {...rest} />
    </Dialog>
  )
}
