import { Image, ImageProps } from '@chakra-ui/react'
import {
  Button,
  FileDrop,
  InputContainer,
  InputContainerProps,
  PencilSquareIcon,
  SingleUseMediaDialog,
  SingleUseMediaDialogProps,
} from 'components'
import { useState } from 'react'
import ReactPlayer from 'react-player'
import { acceptedImages, acceptedVideos, sleep, useStorageMeta, useStorageUrl } from 'utils'

export interface Props
  extends Partial<InputContainerProps>,
    Pick<SingleUseMediaDialogProps, DialogMediaProps> {
  dialogProps?: Omit<SingleUseMediaDialogProps, DialogMediaProps | 'isOpen'>
  imageProps?: ImageProps
}

type DialogMediaProps = 'defaultImage' | 'filePath' | 'noFile' | 'onClose' | 'type' | 'siteUrl'

export default function SingleUseMediaInput({
  defaultImage,
  dialogProps,
  filePath,
  siteUrl,
  imageProps,
  noFile,
  onClose,
  type,
  ...rest
}: Props) {
  const [isEditing, setIsEditing] = useState(false)
  const [newMedia, setNewMedia] = useState<File>()

  const { url } = useStorageUrl(noFile ? undefined : filePath)
  const { meta } = useStorageMeta(noFile ? undefined : filePath)

  const typeFromMeta = meta?.contentType?.includes('image') ? 'image' : 'video'
  const fileType = type || typeFromMeta

  async function handleFiles(files: File[]) {
    setNewMedia(files[0])
    await sleep(200)
    setIsEditing(true)
  }

  return (
    <InputContainer
      label="Image"
      labelActions={
        <Button
          size="sm"
          palette="primary"
          variant="ghost"
          leftIcon={<PencilSquareIcon type="outline" />}
          onClick={() => setIsEditing(true)}
        >
          Edit
        </Button>
      }
      gap={1}
      {...rest}
    >
      <FileDrop
        onDrop={handleFiles}
        options={{
          accept: {
            ...(type !== 'image' && acceptedVideos),
            ...(type !== 'video' && acceptedImages),
          },
        }}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
      >
        {() =>
          fileType === 'image' ? (
            <Image
              height="200px"
              cursor="pointer"
              onClick={() => setIsEditing(true)}
              objectFit="cover"
              src={noFile ? defaultImage : url}
              borderRadius="xl"
              {...imageProps}
            />
          ) : (
            <ReactPlayer width="100%" height="200px" url={url} />
          )
        }
      </FileDrop>

      <SingleUseMediaDialog
        isOpen={isEditing}
        newMedia={newMedia}
        defaultImage={defaultImage}
        siteUrl={siteUrl}
        noFile={noFile}
        filePath={filePath}
        onClose={async action => {
          await onClose?.(action)
          setIsEditing(false)
          setNewMedia(undefined)
        }}
        type={type}
        {...dialogProps}
      />
    </InputContainer>
  )
}
