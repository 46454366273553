import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs, useColorMode } from '@chakra-ui/react'
import { AuthContext } from 'components/providers'
import { useContext } from 'react'
import { IWorkspace } from 'types'
import { updateDoc, useWorkspace } from 'utils'
import WorkspaceSettings from './WorkspaceSettings'
import WorkspaceUsers from './WorkspaceUsers'

export interface Props {
  onDeleted?: () => void
  onRefresh?: () => void
}

export default function ManageWorkspace({ onDeleted, onRefresh }: Props) {
  const { workspace } = useWorkspace()
  const { user } = useContext(AuthContext)
  const { colorMode } = useColorMode()

  if (!workspace) return null

  const { id, title } = workspace

  async function update(body: Partial<IWorkspace>) {
    await updateDoc(user!.id, 'Workspace', { id: id, ...body })
  }

  return (
    <Flex direction="column" gap={2} overflow="hidden">
      <Tabs
        size={'md'}
        height="inherit"
        display="flex"
        overflow="hidden"
        flexDirection="column"
        gap={2}
        defaultIndex={title ? 0 : 1}
        color={colorMode === 'light' ? 'rgba(23, 23, 23, 0.7)' : 'rgba(255, 255, 255, 0.7)'}
      >
        <TabList
          borderBottom={
            colorMode === 'light'
              ? '2px solid rgba(0, 0, 0, 0.10)'
              : '2px solid rgba(255, 255, 255, 0.10)'
          }
        >
          <Tab
            textStyle={'bodyMedium'}
            _selected={{
              borderBottom:
                colorMode === 'light' ? '1px solid rgba(60, 75, 211, 1)' : '1px solid white',
              color: colorMode === 'light' ? 'rgba(23, 23, 23, 1)' : 'rgba(255, 255, 255, 1)',
            }}
          >
            Members
          </Tab>
          <Tab
            textStyle={'bodyMedium'}
            _selected={{
              borderBottom:
                colorMode === 'light' ? '1px solid rgba(60, 75, 211, 1)' : '1px solid white',
              color: colorMode === 'light' ? 'rgba(23, 23, 23, 1)' : 'rgba(255, 255, 255, 1)',
            }}
          >
            Workspace Settings
          </Tab>
        </TabList>

        <TabPanels display="flex" overflow="hidden">
          <TabPanel p={0} display="flex" overflow="hidden">
            <WorkspaceUsers onUpdate={update} workspace={workspace} />
          </TabPanel>
          <TabPanel p={0} display="flex" overflow="hidden">
            <WorkspaceSettings onDeleted={onDeleted} onRefresh={onRefresh} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}
