const global = () => {}

global.TOP_BAR_HEIGHT = '70px'
global.TOP_BAR_HEIGHT_MOBILE = '90px'
global.EVENT_CARD_MAX_WIDTH = '308px'
global.EVENT_CARD_MIN_WIDTH = '280px'
global.EVENT_CARD_MOB_MAX_WIDTH = '294px'
global.SIDE_PADDING = 7

export default global
