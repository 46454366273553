import {
  AccordionButtonProps,
  Avatar,
  Circle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  Button,
  DevDetailsButton,
  InputContainer,
  Menu,
  MenuOption,
  PlusIcon,
  TextField,
} from 'components'
import { DashedCircleIcon, UsersIcon } from 'components/icons'
import { useAlert } from 'components/providers'
import { DataType } from 'ka-table'
import { isEmpty, startCase } from 'lodash'
import { isValidElement, ReactNode, useContext, useEffect, useState } from 'react'
import {
  IUser,
  IUserSession,
  LeadTemperature,
  primaryLeadProperties,
  PrimaryLeadProperty,
  SessionLead,
} from 'types'
import { getUsers, sleep, usePermission, useWorkspace } from 'utils'
import { makeTypeByField } from 'utils/tableUtils'
import { formatSession, getTargetedSessionMetrics, hideColumns } from '../../utils/userSessionUtils'
import { leadTemperatures } from './EventLeads'
import { EventContext } from './EventProvider'

interface Props {
  session?: IUserSession
  onDone?: () => void
}

export default function SessionDrawer({ session, onDone }: Props) {
  const permission = usePermission()
  const { updateLead } = useContext(EventContext)
  const { confirm } = useAlert()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [draft, setDraft] = useState<Partial<SessionLead>>({})
  const [actionMetrics, setActionMetrics] = useState<
    { title: string; duration?: number }[] | undefined
  >(undefined)

  const [mounted, setMounted] = useState(false)
  useEffect(() => setMounted(!isEmpty(draft)), [draft])

  useEffect(() => {
    if (!session) return

    const { lead } = formatSession(session)
    if (session.app == 'explorer')
      setActionMetrics(getTargetedSessionMetrics(session.metrics!, 'Actions'))
    if (!lead) return

    const { visibleEditProperties } = parseEditProperties(lead)
    let draft: SessionLead = visibleEditProperties.reduce((acc, key) => {
      if (!lead[key]) return acc
      return { ...acc, [key]: lead[key] }
    }, {})

    if (lead._custom) {
      Object.entries(lead._custom).forEach(([key, value]) => {
        if (!value) return
        draft._custom = { ...draft._custom, [key]: value }
      })
    }

    setDraft(draft)
    onOpen()
  }, [session])

  if (!session) return null

  const { name, traitFieldMap, lead, followUpOwner, ...rest } = formatSession(session)
  const { apiDataNeeded, badge, followUp, optIn } = lead || {}
  const dataFromAPI = apiDataNeeded != null ? !apiDataNeeded : undefined
  const remainingFieldMap = hideColumns({ ...rest, dataFromAPI, badge, followUp, optIn }, 'panel')
  const { visibleEditProperties, hiddenEditProperties } = parseEditProperties(draft)
  const fieldTypes = makeTypeByField([remainingFieldMap])
  const hasEdits = Object.entries(draft).some(([key, value]) => {
    if (key === '_custom') {
      return Object.entries(value).some(([k, v]) => lead?._custom?.[k] !== v)
    }
    return (lead?.[key as PrimaryLeadProperty] || '') !== (value || '')
  })
  const canEdit = permission.event === 'admin'

  async function handleSave() {
    updateLead(session!.id, draft)
    handleDone()
  }

  async function handleClose() {
    if (hasEdits) {
      const confirmed = await confirm('Discard changes?')
      if (!confirmed) return
    }

    handleDone()
  }

  async function handleDone() {
    onClose()
    await sleep(200)
    setDraft({})
    onDone?.()
  }

  let items: AccordionItem[] = [
    {
      title: 'Trait Selections',
      content: (
        <Flex direction="column" gap={2} px={6}>
          {Object.entries(traitFieldMap || {})
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .map(([key, value]) => (
              <ReadOnlyProperty
                key={key}
                name={startCase(key)}
                value={value}
                dataType={fieldTypes[key]}
              />
            ))}
        </Flex>
      ),
    },
    {
      title: 'Content Viewed',
      content: (
        <Flex direction="column" gap={2} px={6}>
          {actionMetrics?.length ? (
            actionMetrics
              .sort((a, b) => a.title.localeCompare(b.title))
              .map(({ title, duration }) => (
                <ReadOnlyProperty
                  key={title}
                  name={title}
                  value={duration ? `${duration} seconds` : 'N/A'}
                  dataType={DataType.String}
                />
              ))
          ) : (
            <Text textStyle="bodyMedium" fontWeight="500" as="i" opacity={0.5}>
              No content viewed
            </Text>
          )}
        </Flex>
      ),
    },
    {
      title: 'Additional Details',
      content: (
        <Flex direction="column" gap={2} px={6}>
          {Object.entries(remainingFieldMap)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .map(([key, value]) => (
              <ReadOnlyProperty key={key} name={key} value={value} dataType={fieldTypes[key]} />
            ))}
        </Flex>
      ),
    },
  ]

  items = items.filter(({ content }) => {
    //@ts-ignore
    const { children } = content.props
    return Array.isArray(children) ? children.some(isValidElement) : isValidElement(children)
  })

  return (
    <Drawer placement="right" onClose={handleClose} isOpen={isOpen} size="xs">
      <DrawerOverlay />
      <DrawerContent borderRadius="md" my={2} mr={2}>
        <DrawerHeader borderBottomWidth="1px">
          {name}
          <DevDetailsButton
            items={[{ collection: 'userSessions', id: session.id, label: 'Session', type: 'db' }]}
          />
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody px={0}>
          <Flex direction="column" gap={2} px={6}>
            {visibleEditProperties.map(key =>
              canEdit ? (
                <EditProperty
                  autofocus={mounted}
                  editor={editPropertyConfig[key]}
                  key={key}
                  label={startCase(key)}
                  onChange={v => setDraft(s => ({ ...s, [key]: v }))}
                  value={draft[key]}
                />
              ) : (
                <ReadOnlyProperty
                  key={key}
                  name={startCase(key)}
                  value={lead?.[key]}
                  dataType={fieldTypes[key]}
                />
              )
            )}

            {draft._custom &&
              Object.entries(draft._custom).map(([key, value]) =>
                canEdit ? (
                  <EditProperty
                    key={key}
                    editor="input"
                    label={startCase(key)}
                    onChange={v =>
                      setDraft(s => ({ ...s, _custom: { ...s._custom, [key]: v as string } }))
                    }
                    value={value}
                  />
                ) : (
                  <ReadOnlyProperty
                    key={key}
                    name={startCase(key)}
                    value={lead?._custom?.[key]}
                    dataType={fieldTypes[key]}
                  />
                )
              )}

            {canEdit && (
              <UnusedPropertyMenu
                properties={hiddenEditProperties}
                onSelect={key => setDraft(s => ({ ...s, [key]: '' }))}
              />
            )}
          </Flex>
          <Accordion
            allowMultiple
            items={items}
            defaultIndex={
              items.length > 2
                ? actionMetrics?.length
                  ? [0, 1]
                  : [0]
                : actionMetrics?.length
                ? [0]
                : []
            }
            buttonProps={accordionButtonProps}
          />
        </DrawerBody>
        {hasEdits && (
          <DrawerFooter borderTopWidth="1px">
            <Button variant="ghost" onClick={handleDone}>
              Cancel
            </Button>
            <Button palette="primary" onClick={handleSave}>
              Save
            </Button>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}

interface ReadOnlyPropertyProps {
  name: string
  value: any
  dataType: DataType
}

function ReadOnlyProperty(props: ReadOnlyPropertyProps) {
  if (!props.value) return null

  return (
    <InputContainer gap={0} label={startCase(props.name)}>
      <Text>{formatReadOnlyValue(props)}</Text>
    </InputContainer>
  )
}

function formatReadOnlyValue({ name: label, value, dataType }: ReadOnlyPropertyProps) {
  switch (label) {
    case 'duration':
      return `${value} seconds`

    case 'Follow Up Owner':
      return value.label
  }

  switch (dataType) {
    case DataType.Date:
      return new Date(value).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      })
    case DataType.Boolean:
      return value ? 'Yes' : 'No'
    case DataType.Number:
      return value.toLocaleString()
    default:
      return value?.toString()
  }
}

interface EditPropertyProps {
  autofocus?: boolean
  label: string
  value: any
  editor?: EditorTypes
  onChange?: (value: string | { group: string; label: string }) => void
}

function EditProperty({ autofocus, label, value, editor, onChange }: EditPropertyProps) {
  return (
    <InputContainer label={label}>
      {(() => {
        switch (editor) {
          case 'input':
            return (
              <TextField
                autoFocus={autofocus}
                size="sm"
                value={value || ''}
                onChange={e => onChange?.(e.target.value)}
              />
            )
          case 'textarea':
            return (
              <Textarea
                autoFocus={autofocus}
                value={value || ''}
                onChange={e => onChange?.(e.target.value)}
                size="sm"
              />
            )
          case 'menu':
            return CreateMenu({ field: label, value: value, onChange: onChange! })

          default:
            return <Text>{value}</Text>
        }
      })()}
    </InputContainer>
  )
}

interface EditorMenuProps {
  field: string
  value: any
  onChange: (value: string | { group: string; label: string }) => void
}

function CreateMenu({ field, value, onChange }: EditorMenuProps) {
  const { workspace } = useWorkspace()
  const [menuOptions, setMenuOptions] = useState<ReactNode>(null)

  useEffect(() => {
    async function fetchOptions() {
      switch (field) {
        case 'Temp':
          setMenuOptions(
            <Menu
              variant="outline"
              borderRadius="10px"
              menuProps={{ matchWidth: true }}
              noPortal
              options={Object.keys(leadTemperatures).map((key: string) => {
                const temperature = leadTemperatures[key as LeadTemperature]
                return {
                  value: key,
                  label: (
                    <Flex align="center" gap={3}>
                      {temperature.icon}
                      <Text textStyle="bodySmall" fontWeight="500">
                        {temperature.label}
                      </Text>
                    </Flex>
                  ),
                }
              })}
              value={value || 'unset'}
              onChange={value => onChange?.(value)}
            />
          )
          break

        case 'Follow Up Owner':
          const ownerGroups = workspace?.followUpGroups
            ? Object.values(workspace.followUpGroups).map(group => group.title)
            : []
          const ownerMembers: IUser[] = workspace?.followUpUsersDisabled
            ? []
            : Object.values(await getUsers(workspace?.userIds!))
          const ownerValue = value.label
          const menuOptions: MenuOption[] = [
            {
              type: 'group',
              groupType: 'radio',
              title: 'Groups',
              options: [
                ...ownerGroups.map(option => ({
                  type: 'item' as const,
                  value: option,
                  label: (
                    <Flex align="center" gap={3}>
                      <UsersIcon color="rgba(113, 113, 113, 1)" />
                      <Text textStyle="bodySmall" fontWeight="500">
                        {option}
                      </Text>
                    </Flex>
                  ),
                  onClick: () => {
                    onChange({ group: 'group', label: option })
                  },
                })),
              ],
            },
            ...(ownerMembers.length
              ? [
                  { type: 'divider' as const },
                  {
                    type: 'group' as const,
                    groupType: 'radio' as const,
                    title: 'Momentify Members',
                    options: ownerMembers.map((user: IUser) => ({
                      type: 'item' as const,
                      value: user.id,
                      label: (
                        <Flex align="center" gap={2} overflow="hidden">
                          <Avatar src={user.photoUrl} name={user.name} size="sm" />
                          <Text textStyle="bodySmall" fontWeight="500">
                            {user.name}
                          </Text>
                        </Flex>
                      ),
                      onClick: () => {
                        onChange({ group: 'user', label: user.id })
                      },
                    })),
                  },
                ]
              : []),
          ]
          setMenuOptions(
            <Menu
              variant="outline"
              borderRadius="10px"
              menuProps={{ matchWidth: true }}
              value={ownerValue}
              noPortal
              options={menuOptions}
            />
          )
          break
      }
    }

    fetchOptions()
  }, [field, value, workspace, onChange])

  return menuOptions
}

interface UnusedPropertyMenuProps {
  properties: EditProperty[]
  onSelect: (key: string) => void
}

function UnusedPropertyMenu({ properties, onSelect }: UnusedPropertyMenuProps) {
  if (!properties.length) return null

  return (
    <Menu
      buttonLabel="Empty Fields"
      size="sm"
      borderRadius="xs"
      noPortal
      palette="primary"
      leftIcon={<PlusIcon fontSize="small" />}
      options={properties.map(field => ({
        value: field,
        label: startCase(field),
        onClick: () => onSelect(field),
      }))}
    />
  )
}

type EditProperty = PrimaryLeadProperty | 'notes' | 'temp' | 'followUpOwner'

const editProperties: EditProperty[] = [...primaryLeadProperties, 'notes', 'followUpOwner']

const editPropertyConfig: Record<EditProperty, EditorTypes> = {
  firstName: 'input',
  lastName: 'input',
  company: 'input',
  email: 'input',
  title: 'input',
  phone: 'input',
  notes: 'textarea',
  temp: 'menu',
  followUpOwner: 'menu',
}

function parseEditProperties(lead: SessionLead) {
  const alwaysVisibleProperties: EditProperty[] = ['temp']
  const visibleEditProperties = [
    ...editProperties.filter(key => lead[key] != null),
    ...alwaysVisibleProperties,
  ]
  const hiddenEditProperties = editProperties.filter(key => lead[key] == null)
  return { visibleEditProperties, hiddenEditProperties }
}

const accordionButtonProps: AccordionButtonProps = {
  backgroundColor: 'transparent',
  px: 6,
}

type EditorTypes = 'input' | 'textarea' | 'menu'
